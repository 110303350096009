import { ChangeEvent, useContext, useMemo, useState } from 'react';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { AppContext } from '../../shared/context/context';
import { createToast } from '../../shared/services/toastService';
import Button from '../../shared/components/buttons/Button';
import SelectList from '../../shared/components/lists/SelectList';
import useDebounce from '../../shared/hooks/useDebounce';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import { isGuidNull } from '../../shared/utils/guidUtils';
import { useCreateInvitationMutation } from '../Request/queries/invitationQueries';
import { useContactsQuery } from '../Contacts/queries/contactQueries';
import { ToastType } from '../../shared/components/toasts/constants/ToastTypes';
import SearchBar from '../../shared/components/search/SearchBar';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { useSearchUsersForInvitationQuery } from '../Profile/queries/userProfileQueries';
import { useInviteMemberMutation, useMembersQuery } from './queries/MemberQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import { ProfileCard } from '../../shared/components/cards/ProfileCard';
import Alert from '../../shared/components/alerts/Alert';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';

interface IListItem {
  userId: string;
  headline: string;
  information: string;
  photoUrl: string;
}

function MemberInviteSelectList({
  type,
  resourceIds,
  searchTerm,
  selectedItem,
  onSelectItem,
}: {
  type: ResourceType;
  resourceIds: ResourceIds;
  searchTerm: string;
  selectedItem: IListItem | undefined;
  onSelectItem: (item: IListItem) => void;
}) {
  const membersQuery = useMembersQuery(type, resourceIds);
  const contactsQuery = useContactsQuery({
    searchTerm,
    hasSubscriptionOfType: 'InternalUser',
    limit: 20,
  });

  const listItems = useMemo(() => {
    if (!membersQuery.data || !contactsQuery.data) {
      return [];
    }

    return contactsQuery.data
      .filter((contact) => !membersQuery.data.some((member) => member.userId === contact.userId))
      .map((contact) => ({
        userId: contact.userId as string,
        headline: `${contact.displayName}`,
        information: contact.company,
        photoUrl: contact.photoUrl,
      }));
  }, [membersQuery.data, contactsQuery.data]);

  if (listItems) {
    return (
      <SelectList
        data={listItems}
        isLoading={false}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        itemIdProperty={({ userId }) => userId}
        itemHeadlineProperty={({ headline }) => headline}
        itemInformationProperty={({ information }) => information}
        itemImgSrcProperty={({ photoUrl }) => photoUrl}
      />
    );
  }

  return null;
}

interface IProps {
  resourceIds: ResourceIds;
  type: ResourceType;
  customInviteMemberMutation?: ReturnType<typeof useInviteMemberMutation>;
  onComplete?: () => void;
}

export default function InviteMember({
  resourceIds,
  type,
  customInviteMemberMutation,
  onComplete,
}: IProps) {
  const { dispatch } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const [selectedItem, setSelectedItem] = useState<IListItem>();

  const inviteMemberMutationDefault = useInviteMemberMutation(type);
  const inviteMemberMutation = customInviteMemberMutation ?? inviteMemberMutationDefault;
  const createInvitationMutation = useCreateInvitationMutation();
  const usersQuery = useSearchUsersForInvitationQuery(debouncedSearchTerm);

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
    setSelectedItem(undefined);
  };

  const invokeOnComplete = () => {
    if (typeof onComplete !== 'undefined') {
      onComplete();
    }
  };

  const handleInviteContact = () => {
    const actualResourceIds = Array.isArray(resourceIds) ? resourceIds : [resourceIds];
    if (actualResourceIds.some((item) => isGuidNull(item))) {
      return;
    }

    if (selectedItem) {
      inviteMemberMutation.mutate(
        { resourceIds, userId: selectedItem.userId },
        {
          onSuccess: () => {
            dispatch(createToast('Success!', ToastType.Success, 'A contact has been invited'));
            invokeOnComplete();
          },
        },
      );
    } else if (debouncedSearchTerm.match(REGEX_EMAIL)) {
      if (usersQuery.isSuccess && usersQuery.data.length === 1) {
        inviteMemberMutation.mutate(
          {
            resourceIds,
            userId: usersQuery.data[0].id,
          },
          {
            onSuccess: () => {
              dispatch(
                createToast(
                  'Success!',
                  ToastType.Success,
                  `An invitation has been sent to ${debouncedSearchTerm}`,
                ),
              );
              invokeOnComplete();
            },
          },
        );
      } else {
        createInvitationMutation.mutate(
          {
            email: debouncedSearchTerm,
            resourceId: actualResourceIds[actualResourceIds.length - 1],
            resourceType: type,
          },
          { onSuccess: () => invokeOnComplete() },
        );
      }
    }
  };

  const handleCancel = () => {
    invokeOnComplete();
  };

  const searchTermIsEmail = searchTerm.match(REGEX_EMAIL);
  const searchTermChecked = searchTermIsEmail && usersQuery.isSuccess;
  const searchTermIsUser = usersQuery.isSuccess && usersQuery.data.length === 1;

  return (
    <div className="text-left space-y-8">
      <div className="space-y-8">
        <header className="pt-6 pb-4 bg-white">
          <SearchBar
            onSearchChange={handleOnSearchChange}
            searchTerm={searchTerm}
            placeholder="Search contacts or email"
          />
        </header>
        {searchTermChecked && searchTermIsUser && (
          <ProfileCard as="div" width="full" profile={usersQuery.data[0]} />
        )}
        {searchTermChecked && !searchTermIsUser && (
          <Alert
            type="Information"
            text="An email with an invitation to Yoin will be sent to this email address."
          />
        )}
        {!searchTermIsEmail && (
          <ScrollBarWrapper>
            <MemberInviteSelectList
              type={type}
              resourceIds={resourceIds}
              searchTerm={debouncedSearchTerm}
              selectedItem={selectedItem}
              onSelectItem={setSelectedItem}
            />
          </ScrollBarWrapper>
        )}
      </div>
      <div>
        <div className="pt-5">
          <div className="flex justify-end gap-x-3">
            <Button color={ButtonColors.White} text="Cancel" onClick={handleCancel} />
            <Button
              color={ButtonColors.Blue}
              text="Invite"
              disabled={!(selectedItem || searchTermIsEmail)}
              onClick={handleInviteContact}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
