import { useState } from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import { ExpandableCard } from '../../shared/components/cards/ExpandableCard';
import { ProfilePropertyList } from '../Profile/ProfilePropertyList';
import { CardMenuBarV2, ICardMenuItem } from '../../shared/components/cards/CardMenuBar';
import Text from '../../shared/components/text/Text';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import { IContactDetailDto, IContactDto } from './models/IContactDto';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { IEntityCardProps } from '../../shared/components/cards/EntityCardProps';
import ModalLegacy from '../../shared/components/modal/ModalLegacy';
import ContactCreateForm, { useContactCreateForm } from './ContactCreateForm';
import { useContactsQuery } from './queries/contactQueries';
import Alert from '../../shared/components/alerts/Alert';
import { ResourceType } from '../../shared/model/ResourceType';

function getEmailAddressIntersection(contacts: IContactDto[], emailAddresses: IContactDetailDto[]) {
  const foundEmailAddresses = contacts
    .map((contact) => contact.emailAddresses.map((email) => email.value))
    .flat();
  return emailAddresses
    .map((email) => email.value)
    .filter((email) => foundEmailAddresses.includes(email));
}

interface ICreateContactModalProps {
  entity: IContactDto;
  duplicateEmailAddresses: string[];
  triggerClassName?: string;
}

function CreateContactModal({
  duplicateEmailAddresses,
  entity,
  triggerClassName,
}: ICreateContactModalProps) {
  const [open, setOpen] = useState(false);
  const [unsavedDataWarningOpen, setUnsavedDataWarningOpen] = useState(false);

  const formObject = useContactCreateForm({ defaultValues: entity });

  const handleModalClose = (e: Event | React.SyntheticEvent) => {
    const { isDirty } = formObject.formState;
    if (!isDirty) return;

    e.preventDefault();
    setUnsavedDataWarningOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const modalTrigger = <UserIcon className={triggerClassName} />;

  return (
    <ModalLegacy
      trigger={modalTrigger}
      open={open}
      onOpenChange={setOpen}
      onClose={handleModalClose}
    >
      {duplicateEmailAddresses.length > 0 && (
        <Alert
          type="Warning"
          text={`You already have a contact or contacts with the email address(es) ${duplicateEmailAddresses.join(
            ', ',
          )}, a new contact will still be created if you proceed.`}
        />
      )}
      <ContactCreateForm
        form={formObject}
        unsavedDataWarningOpen={unsavedDataWarningOpen}
        onUnsavedDataWarningOpenChange={setUnsavedDataWarningOpen}
        onSuccess={close}
        onCancel={close}
      />
    </ModalLegacy>
  );
}

export function PassiveContactCard({
  entity,
  menuItems,
  headerRenderer,
  header,
  defaultOpen,
}: IEntityCardProps<IContactDto>) {
  const entityManifest = useEntityManifest(ResourceType.ContactPassive);
  const findContactsQuery = useContactsQuery({
    searchTerm: entity.emailAddresses?.map((ea) => ea.value)?.join(' '),
    searchFields: ['EmailAddress'],
    missingSubscriptionOfType: 'InternalUser',
  });

  const { displayName } = entity;
  const actualHeader = header || (
    <Text as="p" size="small" weight="medium">
      {displayName}
    </Text>
  );

  const actualHeaderRenderer =
    headerRenderer ||
    (() => {
      const avatar = (
        <ProfileAvatar
          avatarProps={{
            src: entity.photoUrl || entityManifest.icon,
            alt: displayName,
            widthClass: 'w-10',
            heightClass: 'h-10',
          }}
        />
      );

      return (
        <div className="flex items-center gap-3">
          <div className="flex-shrink-0">{avatar}</div>
          <div className="flex-none overflow-x-hidden px-1">{actualHeader}</div>
        </div>
      );
    });

  const copyContactActions: ICardMenuItem = {
    key: `${entity.id}-copy`,
    label: 'Copy',
    icon: (classes) => (
      <CreateContactModal
        entity={entity}
        triggerClassName={classes}
        duplicateEmailAddresses={
          (!!findContactsQuery.data &&
            getEmailAddressIntersection(findContactsQuery.data, entity.emailAddresses ?? [])) ||
          []
        }
      />
    ),
    onClick: () => undefined, // Make optional ???
  };
  const leftMenuItems: ICardMenuItem[] = [copyContactActions];
  const rightMenuItems: ICardMenuItem[] = menuItems || [];

  return (
    <ExpandableCard renderHead={actualHeaderRenderer} as="div" defaultOpen={defaultOpen}>
      <div className="mt-2">
        <ProfilePropertyList profile={entity} />
      </div>
      <CardMenuBarV2 leftItems={leftMenuItems} rightItems={rightMenuItems} />
    </ExpandableCard>
  );
}
