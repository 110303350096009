import {
  IPipelineCreateBudgetDto,
  IPipelineDetailDto,
  IPipelineDto,
  IShareResourceWithPipelineDto,
  IPipelineUpdateDto,
  IPipelineCreateDto,
} from '../models/IPipelineDto';
import apiClient from '../../../shared/utils/apiClient';
import { ISharedResourceDto } from '../models/IPipelineItemDto';

export const getAllPipelines = () =>
  apiClient.get('v1/pipelines').then<Array<IPipelineDto>>((response) => response.data.data);

export const getPipeline = (pipelineId: string) =>
  apiClient.get(`v1/pipelines/${pipelineId}`).then<IPipelineDetailDto>((response) => response.data);

export const createPipeline = (pipeline: IPipelineCreateDto) =>
  apiClient.post('v1/pipelines', pipeline).then<IPipelineDto>((response) => response.data);

export const updatePipeline = (pipelineId: string, pipeline: IPipelineUpdateDto) =>
  apiClient.patch(`v1/pipelines/${pipelineId}`, pipeline);

export const createPipelineBudget = (
  pipelineId: string,
  pipelineBudget: IPipelineCreateBudgetDto,
) => apiClient.post(`v1/pipelines/${pipelineId}/budgets`, pipelineBudget);

export const updatePipelineBudget = (
  pipelineId: string,
  budgetId: string,
  pipelineBudget: IPipelineCreateBudgetDto,
) => apiClient.patch(`v1/pipelines/${pipelineId}/budgets/${budgetId}`, pipelineBudget);

export const deletePipeline = (pipelineId: string) =>
  apiClient.delete(`v1/pipelines/${pipelineId}`);

export const shareResource = (pipelineId: string, request: IShareResourceWithPipelineDto) =>
  apiClient
    .put(`v1/pipelines/${pipelineId}/sharedResources`, request)
    .then<ISharedResourceDto>((response) => response.data);

export const getSharedResources = (pipelineId: string) =>
  apiClient
    .get(`v1/pipelines/${pipelineId}/sharedResources`)
    .then<Array<ISharedResourceDto>>((response) => response.data.data);

export const getSharedResource = (pipelineId: string, sharedResourceId: string) =>
  apiClient
    .get(`v1/pipelines/${pipelineId}/sharedResources/${sharedResourceId}`)
    .then<ISharedResourceDto>((response) => response.data);
