import { ButtonColors } from '../../constants/ButtonColors';
import { useButtonStyle } from './styles/useButtonStyle';

interface IProps {
  text: string;
  hidden?: boolean;
  color?: ButtonColors;
  isFullWidth?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * The button can be used to make a whole parent element clickable.
 * The parent element must have `relative` position.
 */
export function BreakoutButton({ text, hidden = false, color, isFullWidth, onClick }: IProps) {
  const breakoutClassName = 'before:block before:absolute before:inset-0';
  const { className } = useButtonStyle({
    color: color ?? ButtonColors.Blue,
    isFullWidth,
    className: breakoutClassName,
  });
  return (
    <button
      type="button"
      onClick={onClick}
      className={hidden ? `static float-right ${breakoutClassName}` : className} // float-right is a hack to not create a new row if the button sits after a block element
    >
      {hidden ? <span className="sr-only">{text}</span> : text}
    </button>
  );
}
