import { useForm } from 'react-hook-form';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ICreateUpdateOrganizationDto } from './models/ICreateUpdateOrganizationDto';
import {
  useCreateUpdateOrganizationMutation,
  useOrganizationQuery,
} from './queries/organizationQueries';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { useOrganizationPhotoMutation } from './queries/organizationPhotoQueries';

interface ICreateUpdateOrganizationFormData extends ICreateUpdateOrganizationDto {
  id?: string;
}
type UpdateValues = ICreateUpdateOrganizationFormData & { id: string };

interface IOrganizationFormProps {
  form: ReturnType<typeof useForm<ICreateUpdateOrganizationFormData>>;
  onSuccess?: (organizationId: string) => void;
  onCancel?: () => void;
  footerType?: React.ElementType;
}

interface IUseOrganizationCreateEditFormProps {
  values?: ICreateUpdateOrganizationFormData;
}

export function useOrganizationCreateEditForm({
  values,
}: IUseOrganizationCreateEditFormProps = {}) {
  return useForm<ICreateUpdateOrganizationFormData>({
    mode: 'onChange',
    values,
  });
}

export function CompanyCreateEditForm({
  form,
  onSuccess,
  onCancel,
  footerType: Footer = ButtonGroup,
}: IOrganizationFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = form;

  const createUpdateOrganizationMutation = useCreateUpdateOrganizationMutation();

  const manifest = useEntityManifest(ResourceType.Organization);
  const photoMutations = useOrganizationPhotoMutation();

  const values = form.getValues() as UpdateValues;
  const isUpdate = !!values.id;
  const submitText = isUpdate ? 'Edit' : 'Create';

  const organization = useOrganizationQuery(values.id).data;

  const submitHandler = (data: ICreateUpdateOrganizationFormData) => {
    createUpdateOrganizationMutation.mutate(data, {
      onSuccess: ({ id }) => {
        if (onSuccess) {
          onSuccess(id);
        }
      },
    });
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <SpacingContainer>
        {isUpdate && (
          <SpacingContainerItem>
            <ProfileAvatarForm
              url={(organization && organization.photoUrl) || manifest.icon}
              altText={values.name}
              onUpload={(file) => photoMutations.mutate({ id: values.id, file })}
              onRemove={() => photoMutations.mutate({ id: values.id })}
              isPending={photoMutations.isPending}
            />
          </SpacingContainerItem>
        )}
      </SpacingContainer>
      <TextFieldInput
        labelText="Name"
        name="name"
        register={register}
        errors={errors}
        validationRules={{ required: true, maxLength: 400 }}
      />
      <Footer>
        {onCancel && <Button color={ButtonColors.White} text="Cancel" onClick={handleCancel} />}
        <SubmitButton
          text={submitText}
          isDisabled={!isDirty || !isValid || createUpdateOrganizationMutation.isPending}
        />
      </Footer>
    </form>
  );
}
