import { useQuery } from '@tanstack/react-query';
import { getNetworkPrivileges } from '../../../shared/services/networkService';

const networkPrivilegesKeys = {
  all: ['network-privileges'] as const,
  details: () => [...networkPrivilegesKeys.all, 'detail'] as const,
  detail: (id: string) => [...networkPrivilegesKeys.details(), id] as const,
};

export function useNetworkPrivilegesQuery(networkId?: string) {
  return useQuery({
    queryKey: networkPrivilegesKeys.detail(networkId as string),
    queryFn: () => getNetworkPrivileges(networkId as string),
    enabled: !!networkId,
  });
}
