import { useProjectQuery } from './queries/projectQueries';
import { useProjectPrivilegesQuery } from './queries/projectPriviligesQueries';
import { IProjectPrivileges } from './models/IProjectDto';
import MemberList from '../Members/MemberList';
import { ResourceType } from '../../shared/model/ResourceType';

export interface ProjectMembersProps {
  projectId: string;
}

export default function ProjectMembers({ projectId }: ProjectMembersProps) {
  const privilegesQuery = useProjectPrivilegesQuery(projectId);
  const privileges = privilegesQuery.data as IProjectPrivileges;
  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;

  if (!project) {
    return <></>;
  }

  return (
    <MemberList
      privileges={privileges}
      tenantId={project.tenantId}
      resourceIds={[projectId]}
      type={ResourceType.Project}
      resolveConfirmText={(member) =>
        `Are you sure that you want to remove ${member?.name} from the project?`
      }
      resolveHelpText={() =>
        'Invite other people to add and make changes to your board and stages.'
      }
    />
  );
}
