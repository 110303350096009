import { IContactDto } from './models/IContactDto';
import { UserProfileDto } from '../Profile/models/UserProfile';
import { ProfilePropertyList } from '../Profile/ProfilePropertyList';

interface IProps {
  user: IContactDto | UserProfileDto | undefined | null;
}

export default function ContactUserTabBody({ user }: IProps) {
  if (!user) return <div />;
  return (
    <>
      <ProfilePropertyList profile={user} />
    </>
  );
}
