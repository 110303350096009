import { useState } from 'react';
import { PlusIcon } from 'lucide-react';
import { useNavigate } from 'react-router';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import BoardCreateModal from './BoardCreateModal';

export function BoardActionButtons({ projectId }: { projectId: string }) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleCreateBoardSuccess = (boardId: string) => {
    navigate(`boards/${boardId}`);
  };

  return (
    <>
      <BoardCreateModal
        projectId={projectId}
        open={isEditModalOpen}
        onOpenChange={setEditModalOpen}
        onSuccess={handleCreateBoardSuccess}
      />
      <Button
        color={ButtonColors.Blue}
        icon={<PlusIcon className="h-4 w-4 inline-block pr-1" aria-hidden="true" />}
        text="Add Pipeline"
        onClick={() => setEditModalOpen(true)}
      />
    </>
  );
}
