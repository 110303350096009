import { IPublicClientApplication } from '@azure/msal-browser';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { baseURL } from '../utils/configuration';
import { getAccessToken } from '../auth/authUtils';

export default function createSignalRConnection(
  msalInstance: IPublicClientApplication,
): HubConnection {
  return new HubConnectionBuilder()
    .withUrl(`${baseURL}/live`, {
      accessTokenFactory: () => getAccessToken(msalInstance),
      headers: process.env.NODE_ENV === 'development'
      ? {
        'ngrok-skip-browser-warning': '69420',
        'Access-Control-Allow-Origin': '*',
        'Referrer-Policy': 'no-referrer-when-downgrade',
        }
      : undefined,
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Error)
    .build();
}
