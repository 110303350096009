import { useState } from 'react';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import { DialogFooter } from '../../shared/components/dialog/Dialog';
import { ICreateUpdateOrganizationDto } from './models/ICreateUpdateOrganizationDto';
import { CompanyCreateEditForm, useOrganizationCreateEditForm } from './CompanyCreateEditForm';
import useModals from '../../shared/components/modal/hooks/useModal';

interface IProps {
  values?: ICreateUpdateOrganizationDto;
  onSuccess?: (organizationId: string) => void;
}

export function CompanyCreateEditModal({ values, onSuccess }: IProps) {
  const [unsavedDataWarningOpen, setUnsavedDataWarningOpen] = useState(false);
  const { closeModal } = useModals();
  const form = useOrganizationCreateEditForm({ values });
  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleClose = () => {
    reset();
    closeModal();
  };

  const handleSuccess = (organizationId: string) => {
    handleClose();
    if (onSuccess) onSuccess(organizationId);
  };

  const handleCancel = () => {
    if (isDirty) {
      setUnsavedDataWarningOpen(true);
      return;
    }
    handleClose();
  };

  return (
    <>
      <CompanyCreateEditForm
        form={form}
        footerType={DialogFooter}
        onCancel={handleCancel}
        onSuccess={handleSuccess}
      />

      <UnsavedDataAlertDialog
        open={unsavedDataWarningOpen}
        onOpenChange={setUnsavedDataWarningOpen}
        onLeaveClick={handleClose}
      />
    </>
  );
}
