import {
  InfiniteData,
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  keepPreviousData,
  QueryClient,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';

type ApiPageParam = string | undefined;
type InfiniteApiData<TData> = InfiniteData<TData[], ApiPageParam>;

type InfiniteApiQueryOptions<TData> = {
  queryKey: QueryKey;
  queryFn: QueryFunction<IPagedResult<TData>, QueryKey>;
  staleTime?: number;
  enabled?: boolean;
  useKeepPreviousData?: boolean;
  select?: (data: InfiniteData<IPagedResult<TData>, ApiPageParam>) => InfiniteApiData<TData>;
};

export type UseInfiniteApiQueryResult<TData> = UseInfiniteQueryResult<
  InfiniteApiData<TData>,
  Error
>;

type FetchInfiniteApiQueryOptions<TData> = {
  queryKey: QueryKey;
  queryFn: QueryFunction<IPagedResult<TData>, QueryKey>;
  staleTime?: number;
};

export function useInfiniteApiQuery<TData>({
  queryKey,
  queryFn,
  staleTime,
  enabled,
  useKeepPreviousData,
  select: providedSelect,
}: InfiniteApiQueryOptions<TData>): UseInfiniteApiQueryResult<TData> {
  const pagedQueryFn = (context: QueryFunctionContext<QueryKey, ApiPageParam>) => {
    const { pageParam } = context;
    return pageParam
      ? apiClient.get(pageParam).then<Awaited<ReturnType<typeof queryFn>>>((result) => result.data)
      : queryFn(context);
  };
  const getNextPageParam = (lastPage: IPagedResult<TData>) => lastPage.next;
  const select =
    providedSelect ||
    ((data: InfiniteData<IPagedResult<TData>, ApiPageParam>) => {
      const newData = { pageParams: data.pageParams, pages: data.pages.map((page) => page.data) };
      return newData;
    });

  const result = useInfiniteQuery({
    queryKey,
    queryFn: pagedQueryFn,
    getNextPageParam,
    initialPageParam: undefined,
    staleTime,
    enabled,
    select,
    placeholderData: useKeepPreviousData ? keepPreviousData : undefined,
  });

  return result;
}

export function prefetchInfiniteApiQuery<TData>(
  queryClient: QueryClient,
  { queryKey, queryFn, staleTime }: FetchInfiniteApiQueryOptions<TData>,
) {
  const pagedQueryFn = (context: QueryFunctionContext<QueryKey, ApiPageParam>) => {
    const { pageParam } = context;
    return pageParam
      ? apiClient.get(pageParam).then<Awaited<ReturnType<typeof queryFn>>>((result) => result.data)
      : queryFn(context);
  };
  const getNextPageParam = (lastPage: IPagedResult<TData>) => lastPage.next;

  return queryClient.prefetchInfiniteQuery<
    IPagedResult<TData>,
    Error,
    IPagedResult<TData>,
    QueryKey,
    ApiPageParam
  >({
    queryKey,
    queryFn: pagedQueryFn,
    getNextPageParam,
    initialPageParam: undefined,
    staleTime,
  });
}
