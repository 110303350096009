import { parseISO, isDate } from 'date-fns';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import HorizontalSpacer from '../../shared/components/spacing/HorizontalSpacer';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import getProfileImageUrl from '../../shared/services/profileImageService';
import { ContactUserProfileLink } from '../Contacts/ContactUserProfileLink';
import { ResourceLink } from './ResourceLink';
import Text from '../../shared/components/text/Text';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import DateOnly from '../../shared/components/dates/DateOnly';
import Button from '../../shared/components/buttons/Button';
import { NoNotificationsMessage } from './NoNotificationsMessage';
import { UseInfiniteApiQueryResult } from '../../shared/hooks/useInfiniteApiQuery';
import { InfiniteApiQueryScroll } from '../../shared/components/scrolling/InfiniteApiQueryScroll';
import { getInfiniteQueryDataLength } from '../../shared/utils/queryClientUtils';

const DayMillis = 1000 * 3600 * 24;

function renderDateTime(date: string | Date) {
  let dateClone: Date;

  if (typeof date === 'string') {
    dateClone = parseISO(date);
  } else if (isDate(date)) {
    dateClone = date;
  } else {
    return null;
  }

  const currentDate = new Date();
  const differenceInDays = Math.floor((currentDate.getTime() - dateClone.getTime()) / DayMillis);

  if (differenceInDays < 7) {
    return <TimeAgo date={dateClone} />;
  }

  return <DateOnly date={dateClone} />;
}

interface IProps {
  query: UseInfiniteApiQueryResult<INotificationDto>;
  closeCallback: () => void;
  onExecuteAction: (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => void;
}

function Loader() {
  return (
    <div className="py-4 text-center">
      <Text as="p" size="small">
        Loading...
      </Text>
    </div>
  );
}

export default function NotificationList({ query, closeCallback, onExecuteAction }: IProps) {
  const handleExecuteAction = (notification: INotificationDto, action: Action) =>
    onExecuteAction(action.execute(notification.id), notification, action);

  if (query.isPending) {
    return <Loader />;
  }

  if (query.isSuccess && getInfiniteQueryDataLength(query.data) === 0) {
    return <NoNotificationsMessage />;
  }

  return (
    <div className="h-full overflow-y-hidden">
      <InfiniteApiQueryScroll query={query} className="sm:pl-2" loader={<Loader />}>
        {({ data }) => (
          <>
            {data.map((notification) => (
              <div
                key={notification.id}
                className="flex items-start p-2 relative border-slate-300 border-b"
              >
                <div className="mr-4 sm:mr-1 sm:p-1">
                  <ContactUserProfileLink userId={notification.payload['source.id']}>
                    <ProfileAvatar
                      avatarProps={{
                        src: getProfileImageUrl(notification.payload['source.id']),
                        alt: notification?.message,
                        widthClass: 'w-10',
                        heightClass: 'h-10',
                      }}
                    />
                  </ContactUserProfileLink>
                </div>
                <div className="relative min-h-24 flex-1 flex flex-col sm:p-1 overflow-hidden">
                  {!notification.isRead && (
                    <div className="absolute rounded-full h-2 w-2 bg-blue right-0 top-0 " />
                  )}
                  <div className="flex-1 flex justify-between items-end">
                    <Text as="span" size="small" weight="medium" leading="relaxed">
                      <ContactUserProfileLink userId={notification.payload['source.id']}>
                        {notification.payload['source.displayName']}
                      </ContactUserProfileLink>
                    </Text>

                    <Text as="span" size="xSmall" brightness="light" leading="relaxed">
                      {renderDateTime(notification?.createdTime)}
                    </Text>
                  </div>
                  <div className=" min-h-16 flex-1 flex flex-col">
                    <Text as="p" leading="snug" size="small" weight="normal" breakMode="words">
                      {notification.message}
                    </Text>
                    {notification.type === 'Request' &&
                      notification.isResponded &&
                      notification.response === 'Accept' && (
                        <>
                          <HorizontalSpacer distance="small" />
                          <Text as="p" variant="italic" leading="snug" size="small">
                            You accepted this request.
                          </Text>
                        </>
                      )}
                    {notification.type === 'Request' &&
                      notification.isResponded &&
                      notification.response === 'Reject' && (
                        <>
                          <HorizontalSpacer distance="small" />
                          <Text as="p" variant="italic" leading="snug" size="small">
                            You rejected this request.
                          </Text>
                        </>
                      )}
                    <div className="mb-1 ">
                      {notification.resourceLink && (
                        <>
                          <HorizontalSpacer distance="small" />
                          <Text as="p" leading="snug" size="xSmall">
                            <ResourceLink
                              key={notification.id}
                              onClick={closeCallback}
                              {...notification.resourceLink}
                            />
                          </Text>
                        </>
                      )}
                    </div>
                    <div>
                      {!notification.isResponded &&
                        notification.actions &&
                        notification.actions.length > 0 && (
                          <ButtonGroup>
                            {notification.actions.map((action) => (
                              <Button
                                key={action.text}
                                className="h-7"
                                color={
                                  action.type === 'Accept'
                                    ? ButtonColors.Blue
                                    : ButtonColors.White
                                }
                                text={action.text}
                                disabled={notification.isResponded}
                                onClick={() => handleExecuteAction(notification, action)}
                              />
                            ))}
                          </ButtonGroup>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </InfiniteApiQueryScroll>
    </div>
  );
}
