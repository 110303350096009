interface IProps {
  children: React.ReactNode;
}

export default function MainPanelMobile({ children }: IProps) {
  return (
    <section className="flex flex-col flex-1 overflow-hidden p-0 md:p-10 md:pb-10">
      {children}
    </section>
  );
}
