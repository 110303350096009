import { formatDistanceToNow, format, formatISO, parseISO } from 'date-fns';

interface IProps {
  date: Date | string | undefined;
  addSuffix?: boolean;
}

export default function TimeAgo({ date, addSuffix }: IProps) {
  const dateClone = typeof date === 'string' ? parseISO(date) : date;
  if (!dateClone) return <time />;
  const options = addSuffix ? { addSuffix, includeSeconds: true } : {};
  return (
    <time title={format(dateClone, 'iiip')} dateTime={formatISO(dateClone)}>
      {formatDistanceToNow(dateClone, options)}
    </time>
  );
}
