import {
  DescriptionList,
  IDescriptionListItem,
} from '../../shared/components/lists/DescriptionList';
import { IProfile } from '../../shared/model/IProfile';
import { useUserProfileQuery } from './queries/userProfileQueries';
import { ProfileIdentifier } from './Types';

function toItems(profile: Partial<IProfile>, hideNameAttributes = true) {
  const items = [] as IDescriptionListItem[];
  if (profile.firstName && !hideNameAttributes) {
    items.push({ name: 'First name', value: profile.firstName });
  }
  if (profile.lastName && !hideNameAttributes) {
    items.push({ name: 'Last name', value: profile.lastName });
  }
  if (
    profile.emailAddresses &&
    profile.emailAddresses.length > 0 &&
    profile.emailAddresses[0].value
  ) {
    items.push({ name: 'Email', value: profile.emailAddresses[0].value, type: 'email' });
  }
  if (profile.phoneNumbers && profile.phoneNumbers.length > 0 && profile.phoneNumbers[0].value) {
    items.push({ name: 'Phone', value: profile.phoneNumbers[0].value, type: 'phone' });
  }
  if (profile.title) items.push({ name: 'Title', value: profile.title });
  if (profile.company) items.push({ name: 'Company', value: profile.company });
  if (profile.location) items.push({ name: 'Location', value: profile.location });
  if (profile.additionalInformation) {
    items.push({
      name: 'Additional information',
      value: profile.additionalInformation,
      columnSpan: 2,
    });
  }

  return items;
}

interface IProfilePropertyListProps {
  profile: Partial<IProfile>;
  hideNameAttributes?: boolean;
}

export function ProfilePropertyList({ profile, hideNameAttributes }: IProfilePropertyListProps) {
  return <DescriptionList items={toItems(profile, hideNameAttributes)} />;
}

export function LazyProfilePropertyList({ id }: ProfileIdentifier) {
  const profileQuery = useUserProfileQuery(id);
  if (profileQuery.isSuccess) return <DescriptionList items={toItems(profileQuery.data)} />;
  return <div />;
}
