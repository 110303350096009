/* eslint-disable react/state-in-constructor */
/* eslint-disable react/destructuring-assignment */
import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorScreen from './ErrorScreen';

interface IProps {
  children: ReactNode;
}

interface State {
  message: string;
  hasError: boolean;
}

class ErrorBoundary extends Component<IProps, State> {
  public state: State = {
    hasError: false,
    message: '',
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: _.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorScreen message={this.state.message} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
