import { IPrivilegesDto } from '../../../shared/model/IPrivilegesDto';
import apiClient from '../../../shared/utils/apiClient';
import { IMember } from '../models/IMember';
import { ResourceType } from '../../../shared/model/ResourceType';
import { ResourceIds, resolveEndpointName } from '../../../shared/hooks/useEntityManifest';

export const getMembers = (type: ResourceType, ids: ResourceIds) => {
  const endpointName = resolveEndpointName(type, ids);
  return apiClient
    .get(`v1/${endpointName}/members`)
    .then<Array<IMember>>((response) => response.data.data);
};

export const deleteMember = (
  type: ResourceType,
  ids: ResourceIds,
  userId: string) => {
  const endpointName = resolveEndpointName(type, ids);
  return apiClient.delete(`v1/${endpointName}/members/${userId}`);
};

export const invite = (
  type: ResourceType,
  ids: ResourceIds,
  targetUserId: string | undefined,
) => {
  const inviteDto = { targetUserId };
  const endpointName = resolveEndpointName(type, ids);
  return apiClient
    .post(`v1/${endpointName}/invite`, inviteDto)
    .then((response) => response.data.data);
};

export const getPrivileges = (type: ResourceType, ids: ResourceIds) => {
  const endpointName = resolveEndpointName(type, ids);
  return apiClient.get(`v1/${endpointName}/privileges`).then<IPrivilegesDto>((result) => result.data);
};
