import { IPagedResult } from '../../../shared/model/IPagedResult';
import apiClient from '../../../shared/utils/apiClient';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import queryStringBuilder from '../../../shared/utils/query-string-builder/queryStringBuilder';
import { IBoardDto, IUpdateBoardDto, ICreateBoardDto, BoardType } from '../models/IBoardDto';
import { ICreateUpdateProjectDto, IProjectDto } from '../models/IProjectDto';
import { deletePipeline } from '../../Pipelines/services/pipelineService';

export const getProjects = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get<IPagedResult<IProjectDto>>(`v1/projects${queryString}`)
    .then<IPagedResult<IProjectDto>>((response) => response.data);
};

export const getProject = (projectId: string) =>
  apiClient.get(`v1/projects/${projectId}`).then<IProjectDto>((response) => response.data);

export const createProject = (project: ICreateUpdateProjectDto) =>
  apiClient.post('v1/projects', project).then<IProjectDto>((response) => response.data);

export const updateProject = (project: ICreateUpdateProjectDto, projectId: string) =>
  apiClient.patch(`v1/projects/${projectId}`, project);

export const deleteProject = (projectId: string) => apiClient.delete(`v1/projects/${projectId}`);

export const getBoards = (projectId: string, ...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get<IPagedResult<IBoardDto>>(`v1/projects/${projectId}/boards${queryString}`)
    .then<IPagedResult<IBoardDto>>((response) => response.data);
};

export const getBoard = (projectId: string, boardId: string) =>
  apiClient
    .get(`v1/projects/${projectId}/boards/${boardId}`)
    .then<IBoardDto>((response) => response.data);

export const createBoard = async (projectId: string, board: ICreateBoardDto) => {
  const boardResult = await apiClient
    .post(`v1/projects/${projectId}/boards/`, board)
    .then<IBoardDto>((response) => response.data);
  return boardResult;
};

export const updateBoard = async (projectId: string, boardId: string, board: IUpdateBoardDto) => {
  const result = await apiClient.patch(`v1/projects/${projectId}/boards/${boardId}`, board);
  return result;
};

export const deleteBoard = async (projectId: string, boardId: string) => {
  const board = await getBoard(projectId, boardId);
  if (board.type === BoardType.Pipeline && board.resourceId) {
    await deletePipeline(board.resourceId);
  }
  await apiClient.delete(`v1/projects/${projectId}/boards/${boardId}`);
};

export function uploadProjectPhoto(projectId: string, imageFile: Blob) {
  const formData = new FormData();
  formData.append('file', imageFile);

  return apiClient.post(`v1/projects/${projectId}/photo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const deleteProjectPhoto = (projectId: string) =>
  apiClient.delete(`v1/projects/${projectId}/photo`);

export function uploadBoardPhoto(projectId: string, boardId: string, imageFile: Blob) {
  const formData = new FormData();
  formData.append('file', imageFile);

  return apiClient.post(`v1/projects/${projectId}/boards/${boardId}/photo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const deleteBoardPhoto = (projectId: string, boardId: string) =>
  apiClient.delete(`v1/projects/${projectId}/boards/${boardId}/photo`);
