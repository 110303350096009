import InfiniteScroll from 'react-infinite-scroll-component';
import { useRef } from 'react';
import { MessageType } from '../../shared/constants/MessageTypes';
import { IMessageFileDto, IMessageDto } from '../../shared/model/IMessageDto';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import MessageDivider from './MessageDivider';
import Message from './Message';
import { useStandaloneEditor } from '../../shared/lexical/useStandaloneEditor';
import { IMessageSubmitProps } from './editor/useMessageSubmitCommand';
import { MESSAGE_EDITOR_CONFIG } from './editor/messageEditorConfig';
import Text from '../../shared/components/text/Text';
import { MessageIncoming } from './MessageIncoming';

interface IProps {
  messages?: Array<IMessageDto>;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  onFileClick: (file: IMessageFileDto) => void;
  onReplyToMessage: (message: IMessageDto) => void;
  onUpdateMessage: (message: IMessageDto, submitParams: IMessageSubmitProps) => Promise<void>;
}

function MessagesList({
  messages = [],
  hasNextPage,
  fetchNextPage,
  onFileClick,
  onReplyToMessage,
  onUpdateMessage,
}: IProps) {
  const bottomRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const { editor, isEditorInitialized } = useStandaloneEditor(MESSAGE_EDITOR_CONFIG);

  if (!isEditorInitialized) {
    return null;
  }

  return (
    <ScrollBarWrapper
      id="messages-scrollbar"
      className="flex flex-col-reverse overflow-x-hidden bg-white p-4 rounded-lg "
    >
      {/* overflow set to '' to over write overflow auto that disturbd the emojipicker */}
      <InfiniteScroll
        dataLength={messages.length}
        next={fetchNextPage}
        hasMore={hasNextPage ?? false}
        style={{ display: 'flex', flexDirection: 'column-reverse', overflow: '' }}
        inverse
        scrollableTarget="messages-scrollbar"
        loader={
          <div className="py-4 text-center">
            <Text as="p" size="small">
              Loading...
            </Text>
          </div>
        }
      >
        <div ref={bottomRef} />
        {messages.map((message) => (
          <div key={message.id} className="md:mr-4">
            {message.dividerHeadline && <MessageDivider text={message.dividerHeadline} />}

            {message.messageType === MessageType.Incoming ? (
              <div className="relative w-4/5">
                <MessageIncoming
                  message={message}
                  editor={editor}
                  onFileClick={onFileClick}
                  onReplyClick={() => {
                    onReplyToMessage(message);
                    scrollToBottom();
                  }}
                  onUpdateMessage={onUpdateMessage}
                />
              </div>
            ) : (
              <div className="w-4/5 ml-auto">
                <Message
                  message={message}
                  editor={editor}
                  onFileClick={onFileClick}
                  onReplyClick={() => {
                    onReplyToMessage(message);
                    scrollToBottom();
                  }}
                  onUpdateMessage={onUpdateMessage}
                />
              </div>
            )}
          </div>
        ))}
      </InfiniteScroll>
    </ScrollBarWrapper>
  );
}

export default MessagesList;
