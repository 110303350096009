import apiClient from '../../../shared/utils/apiClient';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import queryStringBuilder from '../../../shared/utils/query-string-builder/queryStringBuilder';
import { IInviteRequestDto } from '../models/IInviteRequestDto';
import { InviteRequestType } from '../models/InviteRequestType';

export const getInviteRequest = (type: InviteRequestType, ...queryParams: QueryParam[]) => {
    const queryString = queryStringBuilder()
        .add(...queryParams)
        .toQueryString();
    return apiClient
        .get(`v1/inviterequests/${type}${queryString}`)
        .then<Array<IInviteRequestDto>>((response) => response.data.data);
};

export const deleteInviteRequest = (type: InviteRequestType, id: string) =>
    apiClient.delete(`v1/inviterequests/${type}/${id}`);

export const acceptInvitation = (type: InviteRequestType, id: string) =>
    apiClient.post(`v1/inviterequests/${type}/${id}/accept`);

export const rejectInvitation = (type: InviteRequestType, id: string) =>
    apiClient.post(`v1/inviterequests/${type}/${id}/reject`);
