import { Pill } from '../../indicators/Pill';
import { TextColor } from '../../text/Text';

export interface IPillValue {
  label: string;
  color: TextColor;
  value: string;
}

interface PillRowCellProps {
  values: IPillValue[];
  value: string;
}

const DefaultPillValue = {
  label: 'Unknown',
  color: 'green',
  value: '',
} as IPillValue;

export default function PillRowCell({ values, value }: PillRowCellProps) {
  const actualValues = values || [];

  let match = actualValues.find((item) => item.value === value);
  match = match || DefaultPillValue;
  return <Pill as="span" color={match.color} label={match.label} />;
}
