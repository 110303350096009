import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import React from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import {
  ICreateUpdateNetworkDto,
  INetworkDto,
  NetworkTypes,
  NetworkVisibilities,
} from '../../shared/model/INetwork';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import { useNetworkPhotoMutation } from './queries/networkPhotoQueries';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';

const defaultValues: ICreateUpdateNetworkDto = {
  name: '',
  description: '',
  type: NetworkTypes.Private,
  visibility: NetworkVisibilities.Hidden,
};

type UseNetworkCreateEditFormProps = {
  values?: INetworkDto;
};

export function useNetworkCreateEditForm({ values }: UseNetworkCreateEditFormProps = {}) {
  const actualValues: ICreateUpdateNetworkDto = !values
    ? defaultValues
    : {
        name: values.name,
        description: values.description,
        type: values.type,
        visibility: values.visibility,
      };
  return useForm<ICreateUpdateNetworkDto>({
    mode: 'onChange',
    defaultValues: actualValues,
    values: actualValues,
  });
}

type Props = {
  form: UseFormReturn<ICreateUpdateNetworkDto>;
  submitButtonText?: string;
  cancelButtonText?: string;
  network?: INetworkDto;
  isDisabled?: boolean;
  onSubmit: SubmitHandler<ICreateUpdateNetworkDto>;
  onCancel: (event: React.BaseSyntheticEvent) => void;
};

export function NetworkCreateEditForm({
  form,
  submitButtonText,
  cancelButtonText = 'Cancel',
  network,
  isDisabled = false,
  onSubmit,
  onCancel,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = form;

  const _onSubmit = (_network: ICreateUpdateNetworkDto) => {
    if (!isValid || !isDirty) return;

    onSubmit(_network);
    reset();
  };
  const manifest = useEntityManifest(ResourceType.Network);
  const photoMutations = useNetworkPhotoMutation();
  const isUpdate = !!network;
  const _submitButtonText = submitButtonText || (isUpdate ? 'Save' : 'Create');

  return (
    <form onSubmit={handleSubmit(_onSubmit)} className="text-left">
      <SpacingContainer>
        {isUpdate && (
          <SpacingContainerItem>
            <ProfileAvatarForm
              url={network.photoUrl || manifest.icon}
              altText={network.name}
              onUpload={(file) => photoMutations.mutate({ id: network.id, file })}
              onRemove={() => photoMutations.mutate({ id: network.id })}
              isPending={photoMutations.isPending}
            />
          </SpacingContainerItem>
        )}

        <SpacingContainerItem>
          <TextFieldInput
            labelText="Name"
            name="name"
            type="text"
            register={register}
            errors={errors}
            errorMessage="Must enter a name"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <TextAreaInput
            labelText="Description"
            name="description"
            rows={3}
            register={register}
            errors={errors}
            errorMessage="Must enter a description"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <ButtonGroup>
          <Button
            color={ButtonColors.White}
            text={cancelButtonText}
            disabled={isDisabled}
            onClick={onCancel}
          />
          <SubmitButton text={_submitButtonText} isDisabled={!isValid || !isDirty || isDisabled} />
        </ButtonGroup>
      </SpacingContainer>
    </form>
  );
}
