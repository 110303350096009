export enum InvitationStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
}

export interface IInvitationDto {
  id: string;
  emailAddress: string;
  sentByUserId: string;
  createdTime: string;
  status: InvitationStatus;
  acceptedTime: string;
  receiverUserId: string;
  canBeResent: boolean;
}
