import { useForm } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import { ICreateContactDto } from './models/ICreateContactDto';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import { useCreateContactMutation } from './queries/contactQueries';
import Text from '../../shared/components/text/Text';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import { useOptionalControlledState } from '../../shared/hooks/useOptionalControlledState';

const baseDefaultValues: ICreateContactDto = {
  firstName: '',
    lastName: '',
    emailAddresses: [],
    phoneNumbers: [],
    location: '',
    company: '',
    title: '',
    additionalInformation: '',
};

interface IUseContactCreateFormProps {
  defaultValues?: ICreateContactDto;
  values?: Partial<ICreateContactDto>;
}

export function useContactCreateForm({
  defaultValues: defaultValuesFromProps,
  values: valuesFromProps,
}: IUseContactCreateFormProps = {}) {
  const defaultValues = defaultValuesFromProps || baseDefaultValues;
  const values = { ...defaultValues, ...valuesFromProps };
  return useForm<ICreateContactDto>({
    mode: 'onChange',
    defaultValues,
    values,
  });
}

interface IProps {
  form: ReturnType<typeof useContactCreateForm>;
  unsavedDataWarningOpen?: boolean;
  onUnsavedDataWarningOpenChange?: Dispatch<SetStateAction<boolean>>;
  onSuccess: (data: ICreateContactDto) => void;
  onCancel?: () => void;
}

export default function ContactCreateForm({
  form,
  unsavedDataWarningOpen: unsavedDataWarningOpenFromProps,
  onUnsavedDataWarningOpenChange: onUnsavedDataWarningOpenChangeFromProps,
  onSuccess,
  onCancel,
}: IProps) {
  const [unsavedDataWarningOpen, onUnsavedDataWarningOpenChange] = useOptionalControlledState(
    unsavedDataWarningOpenFromProps,
    onUnsavedDataWarningOpenChangeFromProps,
    false,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, errors },
  } = form;
  const createContactMutation = useCreateContactMutation();

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleContinueClosing = () => {
    reset();
    handleCancel();
  };

  const handleCancelSaveContact = () => {
    if (isDirty) {
      onUnsavedDataWarningOpenChange(true);
    } else {
      reset();
      handleCancel();
    }
  };

  const submit = (contact: ICreateContactDto) => {
    createContactMutation.mutate(
      { contact },
      {
        onSuccess: () => {
          reset();
          if (onSuccess) {
            onSuccess(contact);
          }
        },
      },
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="text-left space-y-8">
        <div>
          <Text as="h1" size="xLarge" weight="medium">
            Personal Information
          </Text>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="First Name"
                name="firstName"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Required and between 2 and 50 characters"
                validationRules={{ required: true, minLength: 2, maxLength: 50 }}
              />
            </div>
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="Last Name"
                name="lastName"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Required and between 2 and 50 characters"
                validationRules={{ required: true, minLength: 2, maxLength: 50 }}
              />
            </div>

            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="Email"
                name="emailAddresses.0.value"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Must be a valid email"
                validationRules={{ pattern: REGEX_EMAIL }}
              />
            </div>
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="Phone Number"
                name="phoneNumbers.0.value"
                type="text"
                register={register}
              />
            </div>
            <div className="sm:col-span-3">
              <TextFieldInput
                labelText="Location"
                name="location"
                type="text"
                register={register}
              />
            </div>
            <div className="sm:col-span-3" />
            <div className="sm:col-span-3">
              <TextFieldInput labelText="Title" name="title" type="text" register={register} />
            </div>
            <div className="sm:col-span-3">
              <TextFieldInput labelText="Company" name="company" type="text" register={register} />
            </div>
          </div>
        </div>
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="mt-1">
                <TextAreaInput
                  name="additionalInformation"
                  register={register}
                  labelText="Additional Information"
                />
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end gap-x-3">
              <Button color={ButtonColors.White} text="Cancel" onClick={handleCancelSaveContact} />
              <SubmitButton
                text="Create"
                isDisabled={!isValid || createContactMutation.isPending}
              />
            </div>
          </div>
        </div>
      </form>
      <UnsavedDataAlertDialog
        open={unsavedDataWarningOpen}
        onOpenChange={onUnsavedDataWarningOpenChange}
        onLeaveClick={handleContinueClosing}
      />
    </>
  );
}
