import { BellIcon } from '@heroicons/react/24/outline';
import Text from '../../shared/components/text/Text';

export function NoNotificationsMessage() {
  return (
    <div className="flex flex-col justify-center items-center  min-w-0 p-2 bg-white border-slate-300 border-t gap-2">
      <BellIcon className="h-8 w-8 rounded-2xl bg-gray-400 text-white mt-4" />
      <span className="text-center font-Lato text-14 font-bold leading-150 ">
        <Text as="p" brightness="light" breakMode="words">
          Looks like there are no notifications here yet. Stay tuned for updates and important
          messages.
        </Text>
      </span>
    </div>
  );
}
