import { Fragment } from 'react';
import { IMenuItem } from '../../model/IMenuItem';
import {
  DropdownMenuBadge,
  DropdownMenuDivider,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuShortcut,
} from './DropdownMenu';

export function renderMenuItems(menuItems: IMenuItem[]) {
  const anyIcon = menuItems.some(({ icon }) => icon);
  return menuItems.map(({ id, icon, title, shortcut, useDivider, badgeCount, ...props }) => (
    <Fragment key={id}>
      <DropdownMenuItem inset={anyIcon && !icon} {...props}>
        {icon && <DropdownMenuIcon icon={icon} />}
        {title}
        {shortcut && <DropdownMenuShortcut>{shortcut}</DropdownMenuShortcut>}
        {!shortcut && <DropdownMenuBadge count={badgeCount} />}
      </DropdownMenuItem>
      {useDivider && <DropdownMenuDivider />}
    </Fragment>
  ));
}
