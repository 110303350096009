import { Column } from '@tanstack/react-table';
import { DropdownMenuCheckboxItem } from '../../../shared/components/drop-down-menu/DropdownMenu';
import { INetworkDto, NetworkTypes } from '../../../shared/model/INetwork';
import DataTableHeader from '../../../shared/components/table/cells/DataTableHeader';

interface VisibilityCellProps {
  column: Column<INetworkDto, unknown>;
}

export default function VisibilityHeaderCell({ column }: VisibilityCellProps) {
  const filterState = (column.getFilterValue() || []) as string[];
  return (
    <DataTableHeader header="Visibility" showFilter>
      <DropdownMenuCheckboxItem
        checked={filterState.includes(NetworkTypes.Public)}
        onSelect={(e) => e.preventDefault()}
        onCheckedChange={(checked) => {
          let newFilterState = [...filterState];
          if (checked) {
            newFilterState.push(NetworkTypes.Public);
          } else {
            newFilterState = newFilterState.filter((item) => item !== NetworkTypes.Public);
          }

          column.setFilterValue(newFilterState);
        }}
      >
        Public
      </DropdownMenuCheckboxItem>
      <DropdownMenuCheckboxItem
        checked={filterState.includes(NetworkTypes.Private)}
        onSelect={(e) => e.preventDefault()}
        onCheckedChange={(checked) => {
          let newFilterState = [...filterState];
          if (checked) {
            newFilterState.push(NetworkTypes.Private);
          } else {
            newFilterState = newFilterState.filter((item) => item !== NetworkTypes.Private);
          }

          column.setFilterValue(newFilterState);
        }}
      >
        Private
      </DropdownMenuCheckboxItem>
    </DataTableHeader>
  );
}
