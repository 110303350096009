import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { MsalReactRouterNavigation } from './shared/auth/MsalReactRouterNavigation';

interface IProps {
  msalInstance: PublicClientApplication;
  queryClient: QueryClient;
}

function App({ msalInstance, queryClient }: IProps) {
  return (
    <Router>
      <MsalReactRouterNavigation msalInstance={msalInstance}>
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </MsalProvider>
      </MsalReactRouterNavigation>
    </Router>
  );
}

export default App;
