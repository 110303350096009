import { IConversationDto } from '../model/IConversationDto';
import { ResourceType } from '../model/ResourceType';

export type ConversationFilter = (user: IConversationDto) => boolean;

const connectionConversationFilter = (c: IConversationDto) =>
  c.resourceId !== null && c.resourceType === ResourceType.Connection;

const networkConversationFilter = (c: IConversationDto) =>
  c.resourceId !== null && c.resourceType === ResourceType.Network;

export { connectionConversationFilter, networkConversationFilter };
