import { GetConnectionsParams } from '../../features/Contacts/models/GetConnectionsParams';
import { IConnectionDto } from '../model/IConnectionDto';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';

export function getMyConnections(params: GetConnectionsParams) {
  return apiClient
    .get('v1/connections', { params })
    .then<IPagedResult<IConnectionDto>>((response) => response.data);
}
