import { XIcon } from 'lucide-react';
import Text from '../text/Text';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../popovers/Popover';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';

interface IProps {
  title?: string;
  description?: React.ReactNode | string;
}

export default function Howto({ title, description }: IProps) {
  return (
    <Popover>
      <PopoverTrigger className="ml-auto">
        <QuestionMarkIcon className="w-8 h-8" />
      </PopoverTrigger>
      <PopoverContent
        align="end"
        alignOffset={25}
        className="flex flex-col ring-red-light p-0 mx-2 sm:m-auto w-[calc(100vw-1rem)] sm:w-[30rem] min-h-52"
      >
        <div className="bg-red flex items-center gap-2 rounded-t-lg h-10 px-2 ">
          <PopoverClose>
            <XIcon className="text-white hover:text-gray-200" />
          </PopoverClose>
          {/* Pixel hack */}
          <div className="mb-[1px]">
            <Text size="large" color="white" weight="medium">
              {title}
            </Text>
          </div>
        </div>
        <div className="flex-1 p-2">
          <Text size="normal" breakMode="words">
            {description}
          </Text>
        </div>
        {/* <div className=" pl-24 pt-16 ml-28">
                <Text size="small" color="normal" weight="normal">
                   More questions? Chat with us <span className="underline">here!</span>
                </Text>
                <ChatIcon className="relative left-4 w-8 h-8   mt-auto" />
              </div> */}
      </PopoverContent>
    </Popover>
  );
}
