import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { REGEX_EMAIL } from '../../../shared/constants/RegexValidations';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { ListUsersParams, UpdateUserProfileDto, UserProfileDto } from '../models/UserProfile';
import {
  getMyUserProfile,
  getUserProfile,
  getUsers,
  updateMyUserProfile,
} from '../services/userProfileService';

// TODO: This export shouldn't be necessary. Remove when EventBus has been removed.
export const userProfileKeys = {
  all: ['user-profile'] as const,
  lists: () => [...userProfileKeys.all, 'list'] as const,
  list: (props: ListUsersParams) => [...userProfileKeys.lists(), props] as const,
  details: () => [...userProfileKeys.all, 'detail'] as const,
  detail: (id: string) => [...userProfileKeys.details(), id] as const,
};

const STALE_TIME = 10 * 60 * 1000;

export function usersQueryBase(params: ListUsersParams) {
  return {
    queryKey: userProfileKeys.list(params),
    queryFn: () => getUsers(params).then((result) => result.data),
    staleTime: STALE_TIME,
  };
}

export function searchUsersQueryBase(params: Omit<ListUsersParams, 'excludeSelf'>) {
  const { searchTerm } = params;
  const allParams = { ...params, excludeSelf: true };
  return {
    ...usersQueryBase(allParams),
    queryFn: () =>
      searchTerm && searchTerm.length > 0
        ? getUsers(allParams).then((result) => result.data)
        : Promise.resolve([] as UserProfileDto[]),
  };
}

export function userProfileQueryBase(userId: string) {
  return {
    queryKey: userProfileKeys.detail(userId),
    queryFn: () => getUserProfile(userId),
    staleTime: STALE_TIME,
  };
}

export function useSearchUsersQuery(searchTerm?: string) {
  return useQuery({
    ...searchUsersQueryBase({ searchTerm, excludeContacts: true }),
    enabled: !!searchTerm,
  });
}

export function useSearchUsersForInvitationQuery(searchTerm?: string) {
  return useQuery({
    ...searchUsersQueryBase({ searchTerm, searchFields: ['EmailAddress'] }),
    enabled: !!searchTerm?.match(REGEX_EMAIL),
  });
}

export function useUserProfileQuery(userId?: string) {
  return useQuery({
    ...userProfileQueryBase(userId || ''),
    enabled: !!userId,
  });
}

export function useMyUserProfileQuery(enabled = true) {
  return useQuery({
    queryKey: userProfileKeys.detail('my'),
    queryFn: () => getMyUserProfile(),
    staleTime: Infinity,
    enabled,
  });
}

export function useUpdateMyUserProfileMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userProfile: UpdateUserProfileDto) => updateMyUserProfile(userProfile),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userProfileKeys.detail('my') });
    },
    onError: useDispatchApiError(),
  });
}
