import { Fragment, useState } from 'react';
import { XIcon } from 'lucide-react';
import SearchBar from '../../search/SearchBar';
import { UseInfiniteApiQueryResult } from '../../../hooks/useInfiniteApiQuery';
import { InfiniteApiQueryScroll } from '../../scrolling/InfiniteApiQueryScroll';
import DataTable, { DataTableProps } from '../../table/DataTable';
import { BaseLayout, IBaseLayoutProps } from './BaseLayout';
import { useOptionalControlledState } from '../../../hooks/useOptionalControlledState';
import { ButtonColors } from '../../../constants/ButtonColors';
import Button from '../../buttons/Button';
import { ILayoutActionItem } from './types';

// Potentially, we can add KPI's here.

export interface IListLayoutProps<TData, TValue>
  extends Omit<IBaseLayoutProps, 'children'>,
    Omit<
      DataTableProps<TData, TValue>,
      'data' | 'isLoading' | 'globalFilter' | 'columnFilters' | 'onColumnFiltersChange'
    >,
    Partial<Pick<DataTableProps<TData, TValue>, 'columnFilters' | 'onColumnFiltersChange'>> {
  query: UseInfiniteApiQueryResult<TData>;
  actions?: ILayoutActionItem[];
  searchPlaceholder?: string;
}

export function ListLayout<TData, TValue>({
  title,
  headerActions,
  actions,
  query,
  searchPlaceholder,
  columnFilters: controlledColumnFilters,
  onColumnFiltersChange,
  ...dataTableProps
}: IListLayoutProps<TData, TValue>) {
  // Implement controlled search later. Needed for server side search.
  const [searchTerm, setSearchTerm] = useState('');
  const [columnFilters, setColumnFilters] = useOptionalControlledState(
    controlledColumnFilters,
    onColumnFiltersChange,
    [],
  );

  const handleClearFilters = () => {
    setColumnFilters([]);
    setSearchTerm('');
  };

  return (
    <BaseLayout title={title} headerActions={headerActions}>
      <div className="flex flex-col h-full w-full overflow-hidden">
        <div className="flex w-full overflow-hidden gap-3">
          {/*
          TODO: Search, actions and clear filters need better mobile looks.
          Might be best to only show search bar + a menu.
            */}
          <div className="md:max-w-96">
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={(e) => setSearchTerm(e.target.value)}
              placeholder={searchPlaceholder}
            />
          </div>
          {actions &&
            actions.length > 0 &&
            actions.map(({ id, render, icon: Icon, ...rest }) => (
              <Fragment key={id}>
                {render &&
                  render(
                    <Button
                      color={ButtonColors.Blue}
                      icon={<Icon className="h-5 w-5 mr-1" />}
                      {...rest}
                    />,
                  )}
                {!render && (
                  <Button
                    color={ButtonColors.Blue}
                    icon={<Icon className="h-5 w-5 mr-1" />}
                    {...rest}
                  />
                )}
              </Fragment>
            ))}
          <div className="ml-auto">
            <Button
              color={ButtonColors.White}
              disabled={columnFilters.length === 0 && searchTerm.length === 0}
              onClick={handleClearFilters}
              icon={<XIcon className="h-5 w-5 mr-1" />}
              text="Clear Filter"
            />
          </div>
        </div>
        <div className="flex-1 w-full overflow-hidden">
          <InfiniteApiQueryScroll query={query}>
            {({ data }) => (
              <DataTable
                data={data}
                isLoading={query.isPending}
                globalFilter={searchTerm}
                columnFilters={columnFilters}
                onColumnFiltersChange={setColumnFilters}
                {...dataTableProps}
              />
            )}
          </InfiniteApiQueryScroll>
        </div>
      </div>
    </BaseLayout>
  );
}
