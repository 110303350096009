import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';
import { IPagedResult, isPagedResult } from '../model/IPagedResult';

const MILLISECS_PER_SECOND = 1000;
const MAX_RETRY_DELAY = 30 * MILLISECS_PER_SECOND;

type UpdateFunc<TData> = (pageData: TData[]) => TData[];
type PageFilterFunc<TData> = (
  page: IPagedResult<TData>,
  pageIndex: number,
  allPages: IPagedResult<TData>[],
) => boolean;

const setInfiniteQueryDataFn = <TData>(
  data: InfiniteData<IPagedResult<TData>> | undefined,
  updateFunc: UpdateFunc<TData>,
  pageFilter?: PageFilterFunc<TData>,
) => {
  if (!data) return { pageParams: [], pages: [] };

  return {
    pageParams: data.pageParams,
    pages: data.pages.map((page, pageIndex) => {
      if (pageFilter && !pageFilter(page, pageIndex, data.pages)) return page;

      return {
        next: page.next,
        data: updateFunc(page.data),
      };
    }),
  };
};

export const updateInfiniteQueriesData = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  updateFunc: UpdateFunc<TData>,
  pageFilter?: PageFilterFunc<TData>,
) =>
  queryClient.setQueriesData({ queryKey }, (data: InfiniteData<IPagedResult<TData>> | undefined) =>
    setInfiniteQueryDataFn(data, updateFunc, pageFilter),
  );

export const updateInfiniteQueryData = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  updateFunc: UpdateFunc<TData>,
  pageFilter?: PageFilterFunc<TData>,
) =>
  queryClient.setQueryData(queryKey, (data: InfiniteData<IPagedResult<TData>> | undefined) =>
    setInfiniteQueryDataFn(data, updateFunc, pageFilter),
  );

const findQueryItem = <TData>(
  queryData: IPagedResult<TData> | Array<TData> | undefined,
  filter: (data: TData) => boolean,
) => {
  if (!queryData) return undefined;
  return isPagedResult(queryData) ? queryData.data.find(filter) : queryData.find(filter);
};

const getFirstMatchedQueryFromQueries = <TData, TResult extends IPagedResult<TData> | Array<TData>>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => {
  const tupleFilter = ([, _data]: [QueryKey, TResult | undefined]) => {
    if (!_data || !Array.isArray(_data)) return false;

    return (isPagedResult(_data) ? _data.data.findIndex(filter) : _data.findIndex(filter)) !== -1;
  };

  return queryClient.getQueriesData<TResult>({ queryKey }).find(tupleFilter);
};

const getFirstMatchedItemFromQueries = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => {
  const tuple = getFirstMatchedQueryFromQueries(queryClient, queryKey, filter);

  if (!tuple) return undefined;
  return findQueryItem(tuple[1], filter);
};

const getFirstMatchedStateFromQueries = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => {
  const tuple = getFirstMatchedQueryFromQueries(queryClient, queryKey, filter);
  if (!tuple) return undefined;
  return queryClient.getQueryState(tuple[0]);
};

export const getInitialDataPropsFromQuery = <
  TData,
  TResult extends IPagedResult<TData> | Array<TData>,
>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => ({
  initialData: () => {
    const queryData = queryClient.getQueryData<TResult>(queryKey);
    return findQueryItem(queryData, filter);
  },
  initialDataUpdatedAt: () => queryClient.getQueryState(queryKey)?.dataUpdatedAt,
});

export const getInitalDataPropsFromQueries = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  filter: (data: TData) => boolean,
) => ({
  initialData: () => getFirstMatchedItemFromQueries(queryClient, queryKey, filter),
  initialDataUpdatedAt: () =>
    getFirstMatchedStateFromQueries(queryClient, queryKey, filter)?.dataUpdatedAt,
});

export const exponentialRetryDelay = (attempt: number) =>
  Math.min(
    attempt > 1 ? 2 ** attempt * MILLISECS_PER_SECOND : MILLISECS_PER_SECOND,
    MAX_RETRY_DELAY,
  );

export const getInfiniteQueryDataLength = <TData>(
  data: InfiniteData<TData[]> | undefined,
  filter?: (data: TData) => boolean,
) => {
    if (!data) return 0;
    if (!filter) return data.pages.flat().length;
    return data.pages.flat().filter((d) => filter(d)).length;
  };
