/* eslint-disable max-len */
import * as msal from '@azure/msal-browser';
import {
  b2cClientId,
  b2cTenantName,
  b2cApiScope,
  b2cLogoutRedirectUrl,
} from '../utils/configuration';

const signUpSignInPolicy = 'B2C_1A_SIGNUP_SIGNIN';
const passwordResetPolicy = 'B2C_1A_PASSWORDRESET';

export const b2cPolicies = {
  names: {
    signUpSignIn: signUpSignInPolicy,
    passwordReset: passwordResetPolicy,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${b2cTenantName}.b2clogin.com/${b2cTenantName}.onmicrosoft.com/${signUpSignInPolicy}`,
    },
    passwordReset: {
      authority: `https://${b2cTenantName}.b2clogin.com/${b2cTenantName}.onmicrosoft.com/${passwordResetPolicy}`,
    },
  },
  authorityDomain: `${b2cTenantName}.b2clogin.com`,
};

export const msalConfig: msal.Configuration = {
  auth: {
    clientId: b2cClientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Use signup signin user-flow as a default authority
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/',
    postLogoutRedirectUri: b2cLogoutRedirectUrl,
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  // system: {
  //   loggerOptions: {
  //     logLevel: msal.LogLevel.Verbose,
  //     loggerCallback: (level, message, containsPii) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case msal.LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case msal.LogLevel.Info:
  //           console.info(message);
  //           return;
  //         // case msal.LogLevel.Verbose:
  //         //   console.debug(message);
  //         //   return;
  //         case msal.LogLevel.Warning:
  //           console.warn(message);
  //           break;
  //         default:
  //           break;
  //       }
  //     },
  //     piiLoggingEnabled: true,
  //   },
  // },
};

// Scopes:
// openid: Permission to sign in and get data about the user
// offline_access: Get refresh token for extended access to resources
// client id: Indicates that the issued token is intended for use with B2C registered client/application
// b2cApiScope: Access to backend API
const baseLoginRequest = {
  authority: b2cPolicies.authorities.signUpSignIn.authority,
  scopes: ['openid', 'offline_access', b2cApiScope],
};

export const loginRequest: msal.RedirectRequest = {
  ...baseLoginRequest,
  redirectStartPage: '/contacts',
};

export const signUpRequest: msal.RedirectRequest = {
  ...baseLoginRequest,
  redirectStartPage: '/profile',
  extraQueryParameters: {
    signUp: 'true',
  },
};

export function getLoginRequest(tenantId?: string, redirectStartPage?: string): msal.RedirectRequest {
  const extraQueryParameters = tenantId ? { tenantId } : undefined;

  return {
    ...baseLoginRequest,
    redirectStartPage,
    extraQueryParameters,
  };
}

// TODO: Add blank.html redirect uri for silent requests
export function getSsoRequest(email?: string, tenantId?: string): msal.SsoSilentRequest {
  const extraQueryParameters = tenantId ? { tenantId } : undefined;
  const loginHint = email;

  return {
    ...baseLoginRequest,
    loginHint,
    extraQueryParameters,
  };
}

export const pwdResetRequest: msal.RedirectRequest = {
  authority: b2cPolicies.authorities.passwordReset.authority,
  scopes: ['openid', 'offline_access', msalConfig.auth.clientId],
  redirectStartPage: '/signin',
};

export const acquireApiTokenRequest: msal.SilentRequest = {
  authority: b2cPolicies.authorities.signUpSignIn.authority,
  scopes: [b2cApiScope],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);
export { msalInstance };
