import { MouseEvent, useEffect, useRef, useState } from 'react';
import {
  FaceSmileIcon,
  PencilIcon,
  TrashIcon,
  ArrowUturnLeftIcon,
} from '@heroicons/react/24/outline';
import { groupBy } from 'lodash';
import { LexicalEditor } from 'lexical';
import { IMessageFileDto, IMessageDto } from '../../shared/model/IMessageDto';
import { MessageType } from '../../shared/constants/MessageTypes';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import MessageMetaData from './MessageMetaData';
import { REGEX_EMOJI } from '../../shared/constants/RegexValidations';
import MessageReactions from './MessageReactions';
import { IReaction } from '../../shared/model/IReaction';
import {
  useAddReactionMutation,
  useDeleteMessageMutation,
  useDeleteReactionMutation,
  useMessageQuery,
} from './queries/messageQueries';
import MessageEmoji from './MessageEmoji';
import MessageReactionListPopover from './MessageReactionListPopover';
import HoverPopover from '../../shared/components/popovers/HoverPopover';
import IconButton from '../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useIsMeByUserId } from '../../shared/auth/accountHooks';
import { useDeleteFileAttachmentMutation } from '../../shared/components/attachments/queries/fileAttachmentQueries';
import { useDeleteLinkAttachmentMutation } from '../../shared/components/attachments/queries/linkAttachmentQueries';
import { MessageEditor, MessageEditorRefType } from './editor/MessageEditor';
import { MessageContent } from './MessageContent';
import { MessageResponseContent } from './MessageResponseContent';
import { MessageSharedContacts } from './MessageSharedContacts';
import { IMessageSubmitProps } from './editor/useMessageSubmitCommand';
import Text from '../../shared/components/text/Text';
import { Popover, PopoverContent, PopoverTrigger } from '../../shared/components/popovers/Popover';
import { ContactUserProfileLink } from '../Contacts/ContactUserProfileLink';

const processReactions = (reactions: IReaction[]) => {
  const groupedReactions = groupBy(reactions, 'reactionValue');
  const sortedReactions = Object.entries(groupedReactions).sort(
    ([, a], [, b]) => b.length - a.length,
  );

  const top3Reactions = sortedReactions.slice(0, 3).map(([, _reactions]) => {
    const reactionWithCount: IReaction = {
      ..._reactions[0],
      count: _reactions.length,
    };
    return reactionWithCount;
  });

  return top3Reactions;
};

export interface IMessageProps {
  message: IMessageDto;
  editor: LexicalEditor;
  onFileClick: (file: IMessageFileDto) => void;
  onReplyClick: () => void;
  onUpdateMessage: (message: IMessageDto, submitParams: IMessageSubmitProps) => Promise<void>;
}

export default function Message({
  message,
  editor,
  onFileClick,
  onReplyClick,
  onUpdateMessage,
}: IMessageProps) {
  const [isReactionsOpen, setIsReactionsOpen] = useState(false);
  const [topReactions, setTopReactions] = useState<IReaction[]>([]);

  const isMe = useIsMeByUserId();

  const addReactionMutation = useAddReactionMutation();
  const deleteReactionMutation = useDeleteReactionMutation();
  const deleteMessageMutation = useDeleteMessageMutation();
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const deleteFileAttachmentMutation = useDeleteFileAttachmentMutation();
  const deleteLinkAttachmentMutation = useDeleteLinkAttachmentMutation();
  const replyToMessage = useMessageQuery(
    message.conversationId,
    message.metaData?.responseToMessage?.id,
  );

  const editorRef = useRef<MessageEditorRefType>(null);

  const handleChangeReaction = (emoji: string) => {
    setIsReactionsOpen(false);
    addReactionMutation.mutate({
      conversationId: message.conversationId as string,
      messageId: message.id as string,
      emoji: emoji as string,
    });
  };

  const handleDeleteReaction = (reaction: IReaction) => {
    if (!isMe(reaction.userId)) return;
    deleteReactionMutation.mutate({ message });
  };

  const handleDeleteMessage = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    deleteMessageMutation.mutate({ message });

    if (message.metaData?.files?.length) {
      message.metaData.files.forEach((file) =>
        deleteFileAttachmentMutation.mutate({
          id: file.fileId,
          conversationId: message.conversationId as string,
        }),
      );
    }
    if (message.metaData?.links?.length) {
      message.metaData.links.forEach((link) =>
        deleteLinkAttachmentMutation.mutate({
          id: link.linkId,
          conversationId: message.conversationId as string,
        }),
      );
    }
  };

  const handleEditMessage = () => {
    setIsEditModeActive((_isEditModeActive) => !_isEditModeActive);
  };

  const handleUpdateMessage = (submitParams: IMessageSubmitProps) => {
    onUpdateMessage(message, submitParams).then(() =>
      setTimeout(() => setIsEditModeActive(false), 500),
    );
  };

  const enlargeEmoji = message.text.trimEnd().length === 2 && REGEX_EMOJI.test(message.text);
  const totalReactions = message.reactions.length;
  const isOutgoing = message.messageType === MessageType.Outgoing;
  const sumTopReactionsCount = topReactions.reduce(
    (sum, reaction) => sum + (reaction.count ?? 0),
    0,
  );

  useEffect(() => {
    setTopReactions(processReactions(message.reactions));
  }, [message.reactions]);

  useEffect(() => {
    if (!editorRef.current) return;
    if (!isEditModeActive) return;

    editorRef.current.focus();
  }, [isEditModeActive]);

  return (
    <>
      <div className="flex flex-col pt-2 pb-4">
        {!isEditModeActive ? (
          <>
            <div
              className={`${
                message.messageType === MessageType.Incoming
                  ? 'bg-white self-start border-[1px] border-slate-300'
                  : 'bg-blue-light self-end'
              } ${
                isReactionsOpen ? '' : 'group'
              } max-w-full relative rounded-md px-3 py-2 md:px-2.5 md:py-2 break-words whitespace-pre-line`}
            >
              <div
                className={`rounded-md absolute shadow top-[-15px] z-10 bg-white border-[1px] py-1 justify-center group-active:flex group-hover:flex divide-x-[1px] ${
                  message.messageType === MessageType.Incoming ? ' right-[-20px]' : ' right-[0px]'
                } ${isReactionsOpen ? 'flex' : 'hidden'}`}
              >
                <Popover open={isReactionsOpen} onOpenChange={setIsReactionsOpen}>
                  <PopoverTrigger>
                    <FaceSmileIcon className="mx-1 w-4 h-4 text-light" />
                  </PopoverTrigger>
                  <PopoverContent side="top">
                    <MessageReactions onChangeReactionState={handleChangeReaction} />
                  </PopoverContent>
                </Popover>

                <div className="flex">
                  {isMe(message.senderUserId) && (
                    <>
                      <IconButton
                        color={ButtonColors.Gray}
                        icon={<PencilIcon className="mx-1 w-4 h-4" />}
                        onClick={handleEditMessage}
                      />
                      <IconButton
                        color={ButtonColors.Red}
                        icon={<TrashIcon className="mx-1 w-4 h-4" />}
                        onClick={handleDeleteMessage}
                      />
                    </>
                  )}
                  {!isMe(message.senderUserId) && (
                    <>
                      <IconButton
                        color={ButtonColors.Gray}
                        icon={<ArrowUturnLeftIcon className="mx-1 w-4 h-4" />}
                        onClick={onReplyClick}
                      />
                    </>
                  )}
                </div>
              </div>
              {message.displayName && (
                <>
                  {message.messageType === MessageType.Incoming && (
                    <div className="flex flex-row">
                      <Text size="small" brightness="dark" weight="bold">
                        <ContactUserProfileLink userId={message.senderUserId}>
                          {message.displayName}
                        </ContactUserProfileLink>
                      </Text>
                      <div className="flex mt-0.5 mb-1 ml-1">
                        <Text size="xSmall" brightness="light">
                          <TimeAgo date={message.createdTime} />
                        </Text>
                      </div>
                    </div>
                  )}
                </>
              )}

              <>
                {message.metaData?.responseToMessage && replyToMessage.data && (
                  <MessageResponseContent
                    messageType={message.messageType}
                    displayName={replyToMessage.data.displayName || ''}
                    text={message.metaData.responseToMessage.text || ''}
                    editor={editor}
                  />
                )}
                <span className={enlargeEmoji ? 'text-4xl' : ''}>
                  <MessageContent editor={editor} text={message.text} />
                </span>
                {message.conversationId && (
                  <MessageMetaData
                    conversationId={message.conversationId}
                    data={message.metaData}
                    onFileClick={onFileClick}
                    isOutgoing={isOutgoing}
                  />
                )}
              </>
              {totalReactions > 0 && (
                <div
                  className={`absolute ${
                    isMe(message.senderUserId) ? 'left-[-8px]' : 'right-[-8px]'
                  } bottom-[-15px] bg-white rounded-md shadow-md`}
                >
                  <HoverPopover
                    trigger={
                      <div className="flex">
                        {topReactions.map((reaction) => (
                          <MessageEmoji
                            key={`MessageEmoji-${reaction.id}`}
                            reaction={reaction}
                            count={reaction.count}
                            onClick={() => handleDeleteReaction(reaction)}
                          />
                        ))}
                        {totalReactions > sumTopReactionsCount && (
                          <div className="flex last:border-x-[1px]">
                            <MessageEmoji
                              text={`+${totalReactions - sumTopReactionsCount}`}
                              onClick={() => undefined}
                            />
                          </div>
                        )}
                      </div>
                    }
                  >
                    <MessageReactionListPopover message={message} reactions={message.reactions} />
                  </HoverPopover>
                </div>
              )}
            </div>

            {message.updatedTime && (
              <div
                className={`${
                  message.messageType === MessageType.Incoming ? 'text-left' : 'mr-1 text-right'
                }`}
              >
                <Text as="span" size="xSmall" brightness="light">
                  Edited
                </Text>
              </div>
            )}
            {message?.metaData?.sharedContacts && (
              <MessageSharedContacts
                messageType={message.messageType}
                sharedContacts={message.metaData.sharedContacts}
                alignHorizontal={message.messageType === MessageType.Incoming ? 'left' : 'right'}
              />
            )}
          </>
        ) : (
          <div className="flex flex-col mt-2">
            <MessageEditor
              ref={editorRef}
              conversationId={message.conversationId as string}
              message={message}
              onCancel={() => setIsEditModeActive(false)}
              onSubmit={handleUpdateMessage}
            />
          </div>
        )}
      </div>
    </>
  );
}
