import { REGEX_IMAGE_ATTACHMENT } from '../constants/RegexValidations';

export default function checkIfImageExists(url: string, callback: (exists: boolean) => void) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

export const downloadUrlByLink = (url: string, fileName: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.setAttribute('download', fileName);

  // target supposely overwrites download attribute, let's try it
  if (!/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    anchor.target = '_blank';
  }

  document.body.appendChild(anchor);
  anchor.click();
  anchor.parentNode?.removeChild(anchor);
};

interface IFilesByType<T> {
  images: T[];
  others: T[];
}

export function getFilesByType<T extends { name: string }>(files: T[]) {
  return files.reduce(
    (current: IFilesByType<T>, file: T) => {
      if (REGEX_IMAGE_ATTACHMENT.test(file.name)) current.images.push(file);
      else current.others.push(file);
      return current;
    },
    { images: [] as T[], others: [] as T[] },
  );
}
