import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import { Card } from '../cards/Card';

export default function ListItemLink({ children, to, ...props }: LinkProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Card className="relative" showBorder={!!match}>
      <Link to={to} className="flex items-center space-x-3" {...props}>
        {children}
      </Link>
    </Card>
  );
}
