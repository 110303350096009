import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import ModalLegacy, { ModalSize } from '../../shared/components/modal/ModalLegacy';
import { ICreateBoardDto, BoardType } from './models/IBoardDto';
import { useCreateBoardMutation } from './queries/boardQueries';
import { BoardCreateForm, useBoardCreateForm } from './BoardCreateForm';
import { useCreatePipelineMutation } from '../Pipelines/queries/pipelineQueries';

interface IProps {
  projectId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: (projectId: string) => void;
}

export default function BoardCreateModal({ projectId, open, onOpenChange, onSuccess }: IProps) {
  const [openUnsavedDataWarning, setOpenUnsavedDataWarning] = useState(false);
  const form = useBoardCreateForm();

  const { tenantId } = useParams();

  const createBoard = useCreateBoardMutation();
  const createPipeline = useCreatePipelineMutation();
  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleSubmit: SubmitHandler<ICreateBoardDto> = (boardToSubmit) => {
    if (!tenantId) return;

    const updatedBoardToSubmit: ICreateBoardDto = {
      ...boardToSubmit,
      tenantId,
    };

    createBoard.mutate(
      { projectId, board: updatedBoardToSubmit },
      {
        onSuccess: (_board) => {
          if (_board.type === BoardType.Pipeline) {
            createPipeline.mutate(
              {
                pipeline: {
                  tenantId,
                  projectId,
                  boardId: _board.id,
                  name: _board.name,
                  currencyIsoCode: boardToSubmit.resourcePayload.currencyIsoCode,
                },
              },
              {
                onSuccess: () => {
                  if (onSuccess) {
                    onSuccess(_board.id);
                  }
                  onOpenChange(false);
                },
              },
            );
          }
        },
      },
    );
  };

  const handleClose = () => {
    reset();
    onOpenChange(false);
  };

  const handleCancel = (event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setOpenUnsavedDataWarning(true);
    } else {
      handleClose();
    }
  };

  const handleModalClose = (event: Event | React.SyntheticEvent) => {
    if (!isDirty) return;

    event.preventDefault();
    setOpenUnsavedDataWarning(true);
  };

  return (
    <ModalLegacy
      open={open}
      onOpenChange={onOpenChange}
      hideCloseButton
      modalSize={ModalSize.Small}
      onClose={handleModalClose}
    >
      <BoardCreateForm
        form={form}
        isDisabled={createBoard.isPending}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <UnsavedDataAlertDialog
        open={openUnsavedDataWarning}
        onOpenChange={setOpenUnsavedDataWarning}
        onLeaveClick={handleClose}
      />
    </ModalLegacy>
  );
}
