import { forwardRef } from 'react';
import { SVGIconProps, SVGIconRef } from './types';

function BoardPipelineIcon(
  { titleId, title, height, width, ...props }: SVGIconProps,
  ref: SVGIconRef,
) {
  const actualHeight = height || 59;
  const actualWidth = width || 89;
  return (
    <svg
      ref={ref}
      width={actualWidth}
      height={actualHeight}
      viewBox="0 0 89 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title && <title id={titleId}>{title}</title>}
      <rect width={width} height={height} rx="2" fill="#D4F4FF" />
      <rect x="33" y="12" width="23" height="41" rx="1" fill="#91C8FA" />
      <rect x="33" y="6" width="10" height="4" rx="1" fill="#4D82B4" />
      <rect x="35" y="17" width="19" height="3" rx="1.5" fill="#4D82B4" />
      <rect x="35" y="22" width="19" height="3" rx="1.5" fill="#4D82B4" />
      <rect x="35" y="27" width="12" height="3" rx="1.5" fill="#4D82B4" />
      <rect x="44" y="42" width="10" height="9" rx="1" fill="#4D82B4" />
    </svg>
  );
}

export default forwardRef(BoardPipelineIcon);
