import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { differenceWith, intersectionWith, uniqBy } from 'lodash';
import { IMentionDto, ISharedContactDto } from '../../shared/model/IMessageDto';
import { contactBaseQuery } from '../Contacts/queries/contactQueries';
import { useShareContactMutation } from '../Request/queries/shareContactRequestQueries';
import { useMyUserId } from '../../shared/auth/accountHooks';
import {
  AccountMentionExport,
  MentionExport,
  ShareContactMentionExport,
} from './editor/useMessageSubmitCommand';
import { IConversationDto } from '../../shared/model/IConversationDto';

function filterShareContactMentions(mentions?: MentionExport[]) {
  if (!mentions || mentions.length <= 0) return undefined;
  const shareContactMentions = mentions?.filter((mention) => mention.type === 'share');
  if (!shareContactMentions || shareContactMentions.length <= 0) return undefined;
  return shareContactMentions as ShareContactMentionExport[];
}

function filterAccountMentions(mentions?: MentionExport[]) {
  if (!mentions || mentions.length <= 0) return undefined;
  const accountMentions = mentions?.filter((mention) => mention.type === 'mention');
  if (!accountMentions || accountMentions.length <= 0) return undefined;
  return accountMentions as AccountMentionExport[];
}

async function getSharedContactMetaDataDto(
  queryClient: QueryClient,
  contactId: string,
): Promise<ISharedContactDto> {
  const contact = await queryClient.fetchQuery(contactBaseQuery(contactId, queryClient));
  return {
    sharedContactId: contactId,
    sharedUserId: contact.userId,
    emailAddress: contact.emailAddresses[0]?.value ?? '',
    displayName: contact.displayName,
    photoUrl: contact.photoUrl,
  };
}

async function getSharedContactMetaDataDtos(
  queryClient: QueryClient,
  mentions: ShareContactMentionExport[],
) {
  const shareContactDtoPromises = mentions.map((mention) =>
    getSharedContactMetaDataDto(queryClient, mention.contactId),
  );
  const shareContactDtos = await Promise.all(shareContactDtoPromises);
  return shareContactDtos;
}

type ProcessMessageMentionsResult = {
  sharedContactsMetaData?: ISharedContactDto[];
  mentionsMetaData?: IMentionDto[];
  newShareContactMentions?: ShareContactMentionExport[];
};

async function processShareContactMentions(
  queryClient: QueryClient,
  shareContactMentions?: ShareContactMentionExport[],
  oldSharedContactsMetaData?: ISharedContactDto[],
) {
  if (!shareContactMentions) {
    return { sharedContactsMetaData: undefined, newShareContactMentions: undefined };
  }

  let sharedContactsMetaData: ISharedContactDto[] | undefined;
  let newShareContactMentions: ShareContactMentionExport[] | undefined;
  if (oldSharedContactsMetaData && oldSharedContactsMetaData.length > 0) {
    newShareContactMentions = differenceWith(
      shareContactMentions,
      oldSharedContactsMetaData,
      (mention, meta) => mention.contactId === meta.sharedContactId,
    );
    sharedContactsMetaData = intersectionWith(
      oldSharedContactsMetaData,
      shareContactMentions,
      (meta, mention) => meta.sharedContactId === mention.contactId,
    );
  }

  if (shareContactMentions.length > 0) {
    newShareContactMentions = uniqBy(shareContactMentions, 'contactId');
    const newSharedContactsMetaData = await getSharedContactMetaDataDtos(
      queryClient,
      shareContactMentions,
    );
    sharedContactsMetaData = [...(sharedContactsMetaData ?? []), ...newSharedContactsMetaData];
  }

  return { sharedContactsMetaData, newShareContactMentions };
}

function processAccountMentions(accountMentions?: AccountMentionExport[]) {
  if (!accountMentions) return undefined;
  return accountMentions.map((mention) => ({
    userId: mention.userId,
    displayName: mention.displayName,
  }));
}

/**
 * Processes mentions to find the resulting MentionMetaData, SharedContactMetaData
 * and new ShareContactMentions
 *
 * @param queryClient The QueryClient
 * @param mentions Mentions found in the message
 * @param oldSharedContactsMetaData previously shared contacts from the message meta data
 * @returns new share contact mentions and the resulting shared contact meta data
 */
async function processMessageMentions(
  queryClient: QueryClient,
  mentions?: MentionExport[],
  oldSharedContactsMetaData?: ISharedContactDto[],
): Promise<ProcessMessageMentionsResult> {
  const shareContactMentions = filterShareContactMentions(mentions);
  const accountMentions = filterAccountMentions(mentions);

  const { sharedContactsMetaData, newShareContactMentions } = await processShareContactMentions(
    queryClient,
    shareContactMentions,
    oldSharedContactsMetaData,
  );
  const mentionsMetaData = processAccountMentions(accountMentions);

  return { sharedContactsMetaData, mentionsMetaData, newShareContactMentions };
}

function createShareContactRequests(
  conversation: IConversationDto,
  mentions: ShareContactMentionExport[],
  shareContactMutation: ReturnType<typeof useShareContactMutation>,
  myUserId?: string,
) {
  const targetUserIds = conversation.participants
    .filter((participant) => participant.userId !== myUserId)
    .map((participant) => participant.userId);
  mentions.forEach((mention) =>
    shareContactMutation.mutate({
      contactId: mention.contactId,
      targetUserIds,
    }),
  );
}

interface IUseMessageMentionsProps {
  conversation: IConversationDto;
}

export function useMessageMentions({ conversation }: IUseMessageMentionsProps) {
  const queryClient = useQueryClient();
  const shareContactMutation = useShareContactMutation();
  const myUserId = useMyUserId();

  return {
    processMessageMentions: (
      mentions?: MentionExport[],
      oldSharedContactsMetaData?: ISharedContactDto[],
    ) => processMessageMentions(queryClient, mentions, oldSharedContactsMetaData),
    createShareContactRequests: (mentions: ShareContactMentionExport[]) =>
      createShareContactRequests(conversation, mentions, shareContactMutation, myUserId),
  };
}
