import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import { ContactUserProfileLink } from '../Contacts/ContactUserProfileLink';
import { useUserProfileQuery } from '../Profile/queries/userProfileQueries';
import Message, { IMessageProps } from './Message';

export function MessageIncoming(props: IMessageProps) {
  const { message } = props;
  const userProfileQuery = useUserProfileQuery(message.senderUserId);
  return (
    <>
      {message.isProfileImageVisible && (
        <span className="absolute top-[5px]">
          <ContactUserProfileLink userId={message.senderUserId}>
            <ProfileAvatar
              avatarProps={{
                src: userProfileQuery.data?.photoUrl,
                widthClass: 'w-8',
                heightClass: 'h-8',
              }}
            />
          </ContactUserProfileLink>
        </span>
      )}
      <div className="ml-10">
        <Message {...props} />
      </div>
    </>
  );
}
