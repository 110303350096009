import { useNavigate } from 'react-router';
import ConfirmDialog from '../../shared/components/alert-dialog/ConfirmDialog';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { useRemoveMemberMutation } from '../Members/queries/MemberQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import { useDeleteProjectMutation } from './queries/projectQueries';
import { useGlobalStore } from '../../shared/store/useGlobalStore';

export default function ProjectConfirmDialogs() {
  const myUserId = useMyUserId();
  const deleteMutation = useDeleteProjectMutation();
  const removeMemberMutation = useRemoveMemberMutation(ResourceType.Project);

  const navigate = useNavigate();

  const {
    isProjectConfirmDeleteOpen,
    setProjectIsConfirmDeleteOpen,
    isProjectConfirmLeaveOpen,
    setProjectIsConfirmLeaveOpen,
    currentProject,
  } = useGlobalStore();

  const projectId = currentProject?.id;

  const handleContinueLeave = () => {
    setProjectIsConfirmLeaveOpen(false);
    if (!myUserId) return;
    if (!projectId) return;

    removeMemberMutation.mutate(
      {
        member: {
          name: '',
          resourceId: projectId,
          userId: myUserId,
        },
      },
      {
        onSuccess: () => {
          setProjectIsConfirmLeaveOpen(false);
          navigate(`/companies/${currentProject?.tenantId}`);
        },
      },
    );
  };

  const handleContinueDelete = () => {
    setProjectIsConfirmDeleteOpen(false);
    if (!projectId) return;
    deleteMutation.mutate({ projectId });
    navigate(`/companies/${currentProject?.tenantId}`);
  };

  if (!currentProject) return <></>;

  return (
    <>
      <ConfirmDialog
        open={isProjectConfirmDeleteOpen}
        onOpenChange={setProjectIsConfirmDeleteOpen}
        title="Delete Project"
        description="Are you sure you want to delete this project?"
        actionText="Delete"
        onAction={handleContinueDelete}
      />
      <ConfirmDialog
        open={isProjectConfirmLeaveOpen}
        onOpenChange={setProjectIsConfirmLeaveOpen}
        title="Leave Project"
        description="Are you sure you want to leave this project?"
        actionText="Leave"
        onAction={handleContinueLeave}
      />
    </>
  );
}
