import Spinner from '../../shared/components/loading/Spinner';
import MyProfileForm from './MyProfileForm';
import { useMyUserProfileQuery } from './queries/userProfileQueries';

function Profile() {
  const myProfileQuery = useMyUserProfileQuery();

  if (myProfileQuery.isLoading) {
    return (
      <div className="flex justify-center justify-items-center mt-20 w-full h-full">
        <Spinner />
      </div>
    );
  }

  if (myProfileQuery.isSuccess) {
    return (
      <section className="flex flex-1 flex-col h-full relative  overflow-y-auto  px-2">
        <div className="flex-[3] text-left py-6 md:py-20">
          <MyProfileForm myProfile={myProfileQuery.data} />
        </div>
      </section>
    );
  }

  return <div />; // Error state goes here.
}

export default Profile;
