import { useEffect } from 'react';

import PipelineEditPipeline from './PipelineEditPipeline';
import {
  ActivePipelineSettings,
  SidePanelViewType,
  usePipelineStoreContext,
} from './context/pipeline-context';
import SidePanel from '../../shared/components/layout/building-blocks/SidePanel';
import { ViewName } from '../../shared/store/slices/viewSlice';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import PipelineEditItem from './PipelineEditItem';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';

function PipelineClosedSidePanel({
  activeSidePanelView,
  activePipelineSettings,
}: {
  activeSidePanelView: SidePanelViewType;
  activePipelineSettings: ActivePipelineSettings;
}) {
  const resourceType =
    activePipelineSettings.item.sharedResource?.resourceType || ResourceType.Pipeline;
  const resourceManifest = useEntityManifest(resourceType);
  const pipelineItemManifest = useEntityManifest(ResourceType.PipelineItem);
  const pipelineManifest = useEntityManifest(ResourceType.Pipeline);

  if (activeSidePanelView === 'EditPipeline') {
    return (
      <ProfileAvatar
        avatarProps={{
          src: pipelineManifest.icon,
          widthClass: 'w-14',
          heightClass: 'h-14',
        }}
      />
    );
  }

  if (!activePipelineSettings.item.sharedResource) {
    return (
      <ProfileAvatar
        avatarProps={{
          src: pipelineItemManifest.icon,
          widthClass: 'w-14',
          heightClass: 'h-14',
        }}
      />
    );
  }

  const photoUrl =
    activePipelineSettings.item.sharedResource.payload?.photoUrl || resourceManifest.icon;
  return (
    <ProfileAvatar
      avatarProps={{
        src: photoUrl,
        widthClass: 'w-14',
        heightClass: 'h-14',
      }}
    />
  );
}

export interface PipelineSidePanelProps {
  projectId: string;
  boardId: string;
  pipelineId: string;
}

export default function PipelineSidePanel({
  projectId,
  boardId,
  pipelineId,
}: PipelineSidePanelProps) {
  const { activePipelineSettings, activeSidePanelView } = usePipelineStoreContext();

  const currentView: ViewName = 'pipeline';
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const viewSettings = getViewSettings(currentView);

  const resourceType =
    activePipelineSettings.item.sharedResource?.resourceType || ResourceType.Pipeline;
  const resourceManifest = useEntityManifest(resourceType);
  const pipelineManifest = useEntityManifest(ResourceType.Pipeline);
  const activeManifest =
    activeSidePanelView === 'EditPipeline' ? pipelineManifest : resourceManifest;
  useEffect(() => {
    setSidePanelOpen(currentView, true);
  }, [activePipelineSettings]);

  const sidePanelViewMapping = {
    EditPipeline: (
      <PipelineEditPipeline projectId={projectId} boardId={boardId} pipelineId={pipelineId} />
    ),
    EditItem: <PipelineEditItem />,
  };

  return (
    <>
      {(activePipelineSettings.stageId || activePipelineSettings.pipelineId) && (
        <SidePanel
          renderClosed={() => (
            <PipelineClosedSidePanel
              activeSidePanelView={activeSidePanelView}
              activePipelineSettings={activePipelineSettings}
            />
          )}
          colorSetting={activeManifest.color}
          isOpen={viewSettings.isSidePanelOpen}
          openSidePanel={() => setSidePanelOpen(currentView, true)}
          closeSidePanel={() => setSidePanelOpen(currentView, false)}
          onToggle={() => toggleSidePanel(currentView)}
        >
          {sidePanelViewMapping[activeSidePanelView]}
        </SidePanel>
      )}
    </>
  );
}
