import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { ArrowRightIcon, PencilIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import {
  ISidePanelLayoutAction,
  ISidePanelLayoutContent,
  SidePanelLayout,
} from '../../shared/components/layout/pages/SidePanelLayout';
import { useNetworkQuery } from './queries/networkQueries';
import networkIcon from '../../assets/images/network-icon.svg';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { NetworkContextProvider, useNetworkStoreContext } from './context/network-context';
import NetworkInfoModals from './NetworkInfoModals';
import { useNavigateToCurrentBasePath } from '../../shared/hooks/useNavigateToCurrentBasePath';
import Messaging from '../Messaging/Messaging';
import { useResourceConversationQuery } from '../Messaging/queries/conversationQueries';
import { useMessagesQuery } from '../Messaging/queries/messageQueries';
import { createActivityAction } from '../Messaging/editor/messageEditorMenuActions';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import NetworkInfo from './NetworkInfo';
import NetworkMembers from './NetworkMembers';
import Activities from '../Activities/Activities';
import { ActivityEntityType } from '../../shared/model/IActitityDto';
import Attachments from '../../shared/components/attachments/Attachments';
import Howto from '../../shared/components/message-popups/HowTo';
import { useNetworkPrivilegesQuery } from './queries/networkPriviligesQueries';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';

function useNetworkFeatures(networkId: string) {
  const isMobile = useCheckMobileScreen();
  const networkQuery = useNetworkQuery(networkId);
  const converstionQuery = useResourceConversationQuery(networkId, ResourceType.Network);
  const messagesQuery = useMessagesQuery(converstionQuery.data?.id);

  const { setSidePanelOpen } = useGlobalStore();

  return useMemo(() => {
    const content: ISidePanelLayoutContent[] = [];
    if (converstionQuery.isSuccess) {
      content.push({
        id: 'chat',
        title: 'Chat',
        renderContent: () => (
          <Messaging
            messages={messagesQuery}
            conversation={converstionQuery.data}
            externalMenuActions={[
              createActivityAction('?tab=activities', () => setSidePanelOpen('network', true)),
            ]}
          />
        ),
        desktopRenderTarget: 'main',
      });
    }
    content.push(
      {
        id: 'info',
        title: 'Info',
        renderContent: () => <NetworkInfo network={networkQuery.data} />,
        mobileRenderTarget: 'headerLink',
      },
      {
        id: 'members',
        title: 'Members',
        renderContent: () => <NetworkMembers networkId={networkId} />,
      },
      {
        id: 'activities',
        title: 'Tasks',
        renderContent: () => <Activities parentId={networkId} type={ActivityEntityType.Network} />,
      },
    );
    if (converstionQuery.isSuccess) {
      content.push({
        id: 'attachments',
        title: 'Attachments',
        renderContent: () => (
          <>
            <div className="flex-1 overflow-hidden">
              <Attachments conversationId={converstionQuery.data.id} />
            </div>
            <div className="flex pt-2">
              <Howto
                title="Attachments "
                description="View the attatchments you have sent within the networks. "
              />
            </div>
          </>
        ),
        mobileRenderTarget: 'menu',
      });
    }
    return {
      content,
      default: isMobile ? 'chat' : 'info',
      isPending: networkQuery.isPending || converstionQuery.isPending,
      isSuccess: networkQuery.isSuccess && converstionQuery.isSuccess,
    };
  }, [
    isMobile,
    converstionQuery.isSuccess,
    converstionQuery.isPending,
    converstionQuery.data,
    messagesQuery,
    networkQuery.isSuccess,
    networkQuery.isPending,
    networkQuery.data,
  ]);
}

// TODO: This is duplicated
function useNetworkActions(networkId: string) {
  const networkPrivilegesQuery = useNetworkPrivilegesQuery(networkId);

  const {
    setInviteMemberModalOpen,
    setIsEditNetworkModalOpen,
    setIsConfirmDeleteNetworkOpen,
    setIsConfirmLeaveNetworkOpen,
  } = useNetworkStoreContext();

  return useMemo(() => {
    const { data: networkPrivileges, isPending, isSuccess } = networkPrivilegesQuery;
    if (!isSuccess) {
      return { actions: [], isPending };
    }

    const menuItems: ISidePanelLayoutAction[] = [];

    if (networkPrivileges.canInviteMember) {
      menuItems.push({
        id: 'invite',
        title: 'Invite',
        icon: UserPlusIcon,
        onClick: () => setInviteMemberModalOpen(true),
      });
    }
    if (networkPrivileges.canEdit) {
      menuItems.push({
        id: 'edit',
        title: 'Edit',
        icon: PencilIcon,
        onClick: () => setIsEditNetworkModalOpen(true),
      });
    }
    if (networkPrivileges.canDelete) {
      menuItems.push({
        id: 'delete',
        title: 'Delete',
        icon: TrashIcon,
        onClick: () => setIsConfirmDeleteNetworkOpen(true),
      });
    } else {
      menuItems.push({
        id: 'leave',
        title: 'Leave',
        icon: ArrowRightIcon,
        onClick: () => setIsConfirmLeaveNetworkOpen(true),
      });
    }

    return { actions: menuItems, isPending };
  }, [
    networkPrivilegesQuery.isPending,
    networkPrivilegesQuery.isSuccess,
    networkPrivilegesQuery.data,
  ]);
}

function NetworkInternal() {
  const params = useParams();
  const manifest = useEntityManifest(ResourceType.Network);

  const networkId = params.id as string;
  const networkQuery = useNetworkQuery(networkId);

  const networkFeatures = useNetworkFeatures(networkId);
  const networkActions = useNetworkActions(networkId);

  const navigateToCurrentBasePath = useNavigateToCurrentBasePath();

  if (networkQuery.isPending || networkFeatures.isPending || networkActions.isPending) {
    return <div />; // Use loader?
  }

  if (networkQuery.isSuccess && networkFeatures.isSuccess) {
    return (
      <>
        <SidePanelLayout
          title={networkQuery.data.name}
          subTitle={`${networkQuery.data.visibility} & ${networkQuery.data.type}`}
          imgSource={networkQuery.data.photoUrl || networkIcon}
          imgAlt="Network photo"
          colorSetting={manifest.color}
          sidePanelViewSettingName="network"
          content={networkFeatures.content}
          actions={networkActions.actions}
          defaultTab={networkFeatures.default}
        />
        {/* Can we handle modals in some ohter way? */}
        <NetworkInfoModals
          network={networkQuery.data}
          onDeleteNetwork={navigateToCurrentBasePath}
          onLeaveNetwork={navigateToCurrentBasePath}
        />
      </>
    );
  }

  return (
    <ErrorComponent
      queryResults={[networkQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The network could not be found.' }]}
    />
  );
}

export function NetworkDetail() {
  return (
    <NetworkContextProvider>
      <NetworkInternal />
    </NetworkContextProvider>
  );
}
