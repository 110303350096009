/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';

interface IProps {
  message: string;
}

export default function ErrorScreen({ message }: IProps) {
  return (
    <div className="min-h-full pt-16 pb-12 flex flex-col">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <Link to="/" className="inline-flex">
            <span className="sr-only">Error</span>
            <img className="h-12 w-auto" src={logo} alt="" />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-neutral-600 uppercase tracking-wide">
              An error has occured.
            </p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              We are working on it.
            </h1>
            <p className="mt-2 text-base text-gray-500">{message}</p>
            <div className="mt-6">
              <Link
                to="/"
                className="text-base font-medium text-neutral-600 hover:text-neutral-500"
              >
                Go back home
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <Link to="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Contact Support
          </Link>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <Link to="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Status
          </Link>
          <span className="inline-block border-l border-gray-300" aria-hidden="true" />
          <Link to="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Twitter
          </Link>
        </nav>
      </footer>
    </div>
  );
}
