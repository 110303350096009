import { Dispatch, SetStateAction, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ICreateUpdateNetworkDto, INetworkDto } from '../../shared/model/INetwork';
import { useCreateUpdateNetworkMutation } from './queries/networkQueries';
import { NetworkCreateEditForm, useNetworkCreateEditForm } from './NetworkCreateEditForm';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import ModalLegacy, { IModalProps, ModalSize } from '../../shared/components/modal/ModalLegacy';
import { useOptionalControlledState } from '../../shared/hooks/useOptionalControlledState';

interface IProps extends Pick<IModalProps, 'open' | 'trigger' | 'triggerAsChild'> {
  network?: INetworkDto;
  onSuccess?: (networkId: string) => void;
  onOpenChange?: Dispatch<SetStateAction<boolean>>;
}

export default function NetworkCreateEditModal({
  network,
  open: controlledOpen,
  onOpenChange,
  trigger,
  triggerAsChild,
  onSuccess,
}: IProps) {
  const [open, setOpen] = useOptionalControlledState(controlledOpen, onOpenChange, false);
  const [openUnsavedDataWarning, setOpenUnsavedDataWarning] = useState(false);
  const form = useNetworkCreateEditForm({ values: network });
  const createUpdateNetwork = useCreateUpdateNetworkMutation();

  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleSubmit: SubmitHandler<ICreateUpdateNetworkDto> = (networkToSubmit) => {
    createUpdateNetwork.mutate(
      { id: network?.id, network: networkToSubmit },
      {
        onSuccess: (_network) => {
          if (onSuccess) {
            onSuccess(_network.id);
          }
          setOpen(false);
        },
      },
    );
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleCancel = (event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setOpenUnsavedDataWarning(true);
    } else {
      handleClose();
    }
  };

  const handleModalClose = (event: Event | React.SyntheticEvent) => {
    if (!isDirty) return;

    event.preventDefault();
    setOpenUnsavedDataWarning(true);
  };
  const title = network ? 'Update network' : 'Create new network';
  return (
    <ModalLegacy
      open={open}
      onOpenChange={setOpen}
      trigger={trigger}
      triggerAsChild={triggerAsChild}
      hideCloseButton
      modalSize={ModalSize.Small}
      onClose={handleModalClose}
      title={title}
    >
      <NetworkCreateEditForm
        form={form}
        network={network}
        isDisabled={createUpdateNetwork.isPending}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <UnsavedDataAlertDialog
        open={openUnsavedDataWarning}
        onOpenChange={setOpenUnsavedDataWarning}
        onLeaveClick={handleClose}
      />
    </ModalLegacy>
  );
}
