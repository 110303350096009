import Text, { TextColor } from '../text/Text';

interface IProps {
  value: string | number | undefined;
  textColor: TextColor;
  content: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

/**
 * @deprecated This component is deprecated and should not be used anymore.
 *
 */

export default function OldKpiIndicator({
  value,
  textColor,
  content,
  isActive = false,
  onClick,
}: IProps) {
  return (
    <button
      type="button"
      className={`flex flex-1 items-center flex-col text-center hover:brightness-110 ${
        isActive ? 'brightness-110' : ''
      }`}
      onClick={onClick}
    >
      <Text size="x2Large" color={textColor} weight={isActive ? 'bold' : 'semibold'}>
        {value}
      </Text>
      <Text size="small" weight={isActive ? 'semibold' : 'normal'}>
        {content}
      </Text>
    </button>
  );
}
