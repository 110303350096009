import { ProfilePropertyList } from '../Profile/ProfilePropertyList';
import { Card } from '../../shared/components/cards/Card';
import { IProfile } from '../../shared/model/IProfile';

interface IProps {
  profile: Partial<IProfile>;
}
export default function ContactUserInformation({ profile }: IProps) {
  return (
    <Card isInteractive={false} as="div" className="w-fit min-h-28 min-w-80">
      <ProfilePropertyList profile={profile} />
    </Card>
  );
}
