import { XMarkIcon } from '@heroicons/react/24/outline';
import { ICardMenuItem } from '../../shared/components/cards/CardMenuBar';
import Text from '../../shared/components/text/Text';
import { useUserProfileQuery } from '../Profile/queries/userProfileQueries';
import { SocialContactCard } from '../Contacts/SocialContactCard';
import { IMemberPrivileges } from './models/IMemberPrivileges';
import { IMember } from './models/IMember';
import { useIsMeByUserId } from '../../shared/auth/accountHooks';
import { ButtonColors } from '../../shared/constants/ButtonColors';

interface IProps {
  member: IMember;
  isOwner: boolean;
  privileges?: IMemberPrivileges;
  onRemove: () => void;
}

export function MemberCard({ member, isOwner, privileges, onRemove }: IProps) {
  const { userId } = member;
  const userProfileQuery = useUserProfileQuery(userId);
  const isMe = useIsMeByUserId();
  const rightItems = [];
  if (privileges?.canRemoveMember && !isMe(userId)) {
    rightItems.push({
      key: `${userId}-remove-member`,
      label: 'Remove member',
      onClick: onRemove,
      icon: (classes) => <XMarkIcon className={classes} />,
      color: ButtonColors.Red,
    } as ICardMenuItem);
  }

  if (!userProfileQuery.isSuccess && !userProfileQuery.data) {
    return null;
  }

  const header = (
    <>
      <Text as="p" size="small" weight="medium">
        {member.name}
      </Text>
      <Text as="p" size="xSmall" brightness="light" whitespace="preWrap">
        {isOwner ? 'Owner' : ' '}
      </Text>
    </>
  );
  return (
    <SocialContactCard
      entity={userProfileQuery.data}
      userId={userProfileQuery.data.id}
      defaultOpen={false}
      menuItems={rightItems}
      header={header}
    />
  );
}
