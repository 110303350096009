import { IColorSetting } from '../../../hooks/useEntityManifest';

interface ISidePanelClosedWrapperProps {
  colorSettings: IColorSetting;
  children: React.ReactNode;
}

export default function SidePanelClosedWrapper({
  colorSettings,
  children,
}: ISidePanelClosedWrapperProps) {
  return (
    <div className="flex flex-col flex-1 w-full items-center">
      <div
        className={`flex flex-col items-center w-full rounded-t-lg py-14 h-38 space-y-2 ${colorSettings.background}`}
      >
        {children}
      </div>
    </div>
  );
}
