import { FunnelIcon } from '@heroicons/react/24/outline';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../drop-down-menu/DropdownMenu';

interface DataTableHeaderProps {
  showFilter?: boolean;
  header: string;
  children?: React.ReactNode;
}

export default function DataTableHeader({ showFilter, header, children }: DataTableHeaderProps) {
  return (
    <div className="flex flex-1">
      <span className="mr-1">{header}</span>
      {showFilter && (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <FunnelIcon stroke="#313131" width="20" height="20" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>{children}</DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
}
