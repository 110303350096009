import { ArrowRightIcon, PencilIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { IMenuItem } from '../../../shared/model/IMenuItem';
import { useboardPriviligesQueries } from '../queries/boardPriviligesQueries';
import { useBoardStoreContext } from '../context/board-context';

export default function useBoardActionButtons(projectId?: string, boardId?: string) {
  const privilegesQuery = useboardPriviligesQueries(projectId, boardId);
  const privileges = privilegesQuery.data;

  const {
    setIsEditBoardModalOpen,
    setBoardInviteMemberModalOpen,
    setBoardIsConfirmDeleteOpen,
    setBoardIsConfirmLeaveOpen,
  } = useBoardStoreContext();

  const menuItems: IMenuItem[] = [];
  if (privileges?.canInviteMember) {
    menuItems.push({
      id: 'Invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () => setBoardInviteMemberModalOpen(true),
    });
  }
  if (privileges?.canEdit) {
    menuItems.push({
      id: 'Edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () => setIsEditBoardModalOpen(true),
    });
  }
  if (privileges?.canDelete) {
    menuItems.push({
      id: 'Delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () => setBoardIsConfirmDeleteOpen(true),
    });
  } else {
    menuItems.push({
      id: 'Leave',
      title: 'Leave',
      icon: ArrowRightIcon,
      onClick: () => setBoardIsConfirmLeaveOpen(true),
    });
  }

  return { items: menuItems, isPending: privilegesQuery.isPending };
}
