export enum InviteRequestType {
    /// <summary>
    /// None
    /// </summary>
    None = 'None',
    /// <summary>
    /// Networks
    /// </summary>
    Network = 'Network',
    /// <summary>
    /// Projects
    /// </summary>
    Project = 'Project',
    /// <summary>
    /// Boards
    /// </summary>
    Board = 'Board',
}
