import ConfirmDialog from '../../shared/components/alert-dialog/ConfirmDialog';
import ModalLegacy from '../../shared/components/modal/ModalLegacy';
import { useMyUserId } from '../../shared/auth/accountHooks';
import InviteMember from '../Members/MemberInvite';
import { useRemoveMemberMutation } from '../Members/queries/MemberQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import { IBoardDto } from './models/IBoardDto';
import { useDeleteBoardMutation } from './queries/boardQueries';
import BoardEditModal from './BoardEditModal';
import { useBoardStoreContext } from './context/board-context';

interface IProps {
  board: IBoardDto;
  onDelete?: () => void;
  onLeave?: () => void;
}

export default function BoardModals({ board, onDelete, onLeave }: IProps) {
  const myUserId = useMyUserId();
  const deleteMutation = useDeleteBoardMutation();
  const removeMemberMutation = useRemoveMemberMutation(ResourceType.Board);
  const { projectId, id: boardId } = board;

  const {
    isEditBoardModalOpen,
    setIsEditBoardModalOpen,
    isBoardInviteMemberModalOpen,
    setBoardInviteMemberModalOpen,
    isBoardConfirmDeleteOpen,
    setBoardIsConfirmDeleteOpen,
    isBoardConfirmLeaveOpen,
    setBoardIsConfirmLeaveOpen,
  } = useBoardStoreContext();

  const handleContinueLeave = () => {
    setBoardIsConfirmLeaveOpen(false);
    if (!myUserId) return;

    removeMemberMutation.mutate(
      {
        member: {
          name: '',
          resourceId: [board.projectId, board.id],
          userId: myUserId,
        },
      },
      {
        onSuccess: () => {
          setBoardIsConfirmLeaveOpen(false);
          if (onLeave) onLeave();
        },
      },
    );
  };

  const handleContinueDelete = () => {
    setBoardIsConfirmDeleteOpen(false);
    deleteMutation.mutate({ projectId, boardId });
    if (onDelete) onDelete();
  };
  return (
    <>
      <BoardEditModal
        open={isEditBoardModalOpen}
        onOpenChange={setIsEditBoardModalOpen}
        board={board}
      />
      <ModalLegacy
        open={isBoardInviteMemberModalOpen}
        onOpenChange={setBoardInviteMemberModalOpen}
        title="Invite Member"
      >
        <InviteMember
          onComplete={() => setBoardInviteMemberModalOpen(false)}
          resourceIds={[projectId, boardId]}
          type={ResourceType.Board}
        />
      </ModalLegacy>
      <ConfirmDialog
        open={isBoardConfirmDeleteOpen}
        onOpenChange={setBoardIsConfirmDeleteOpen}
        title="Delete Board"
        description="Are you sure you want to delete this board?"
        actionText="Delete"
        onAction={handleContinueDelete}
      />
      <ConfirmDialog
        open={isBoardConfirmLeaveOpen}
        onOpenChange={setBoardIsConfirmLeaveOpen}
        title="Leave Board"
        description="Are you sure you want to leave this board?"
        actionText="Leave"
        onAction={handleContinueLeave}
      />
    </>
  );
}
