import { useQueryClient } from '@tanstack/react-query';
import { matchPath, resolvePath, useHref, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { IContactDto } from './models/IContactDto';
import { contactKeys, contactUserQuery } from './queries/contactQueries';

interface IProps {
  userId: string;
  className?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export function ContactUserProfileLink({ userId, className, children, onClick }: IProps) {
  const navigate = useNavigate();
  const { pathname: currentPath } = useLocation();
  const queryClient = useQueryClient();
  const profilePath = `/contacts/external/${userId}`;
  const profileHref = useHref(profilePath);

  const getContactFromQueryCache = () => {
    const contacts = queryClient.getQueryData<IContactDto[]>(contactKeys.list({ userId }));
    if (contacts && contacts.length > 0) {
      return contacts[0];
    }

    return queryClient
      .getQueryData<IContactDto[]>(contactKeys.list({}))
      ?.find((contact) => contact?.userId === userId);
  };

  const getContactFromServer = async () => {
    try {
      const contacts = await queryClient.fetchQuery(contactUserQuery(userId));
      return contacts.length > 0 ? contacts[0] : undefined;
    } catch (error) {
      return undefined;
    }
  };

  const internalOnClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    let existingContact = getContactFromQueryCache();
    if (!existingContact) {
      existingContact = await getContactFromServer();
    }

    const to = existingContact ? `/contacts/${existingContact.id}` : profilePath;
    const replace = !!matchPath(currentPath, resolvePath(to).pathname);
    navigate(to, { replace });
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) onClick(event);
    if (!event.isDefaultPrevented()) internalOnClick(event);
  };

  return <Link to={profileHref} className={className} onClick={handleClick}>{children}</Link>;
}
