import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import React from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import Header2 from '../../shared/components/headers/Header2';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import { IBoardDto, IUpdateBoardDto } from './models/IBoardDto';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { useBoardPhotoMutation } from './queries/boardPhotoQueries';

const defaultValues: IUpdateBoardDto = {
  name: '',
  description: '',
};

type UseBoardUpdateFormProps = {
  values: IUpdateBoardDto;
};

export function useBoardEditForm({ values }: UseBoardUpdateFormProps) {
  const valuesToUse = values || defaultValues;
  return useForm<IUpdateBoardDto>({
    mode: 'onChange',
    defaultValues: { name: valuesToUse.name, description: valuesToUse.description },
    values: { name: valuesToUse.name, description: valuesToUse.description },
  });
}

type Props = {
  form: UseFormReturn<IUpdateBoardDto>;
  submitButtonText?: string;
  cancelButtonText?: string;
  board: IBoardDto;
  isDisabled?: boolean;
  onSubmit: SubmitHandler<IUpdateBoardDto>;
  onCancel: (event: React.BaseSyntheticEvent) => void;
};

export function BoardEditForm({
  form,
  submitButtonText,
  cancelButtonText = 'Cancel',
  board,
  isDisabled = false,
  onSubmit,
  onCancel,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = form;

  const _onSubmit = (_board: IUpdateBoardDto) => {
    if (!isValid || !isDirty) return;

    onSubmit(_board);
    reset();
  };
  const photoMutations = useBoardPhotoMutation();
  const _submitButtonText = submitButtonText || 'Save';
  const _title = 'Update board';
  const manifest = useEntityManifest(ResourceType.Board);

  return (
    <form onSubmit={handleSubmit(_onSubmit)} className="text-left">
      <SpacingContainer>
        <Header2>{_title}</Header2>
        <SpacingContainerItem>
          <ProfileAvatarForm
            url={board.photoUrl || manifest.icon}
            altText={board.displayName}
            onUpload={(file) =>
              photoMutations.mutate({ projectId: board.projectId, boardId: board.id, file })
            }
            onRemove={() =>
              photoMutations.mutate({ projectId: board.projectId, boardId: board.id })
            }
            isPending={photoMutations.isPending}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <TextFieldInput
            labelText="Name"
            name="name"
            type="text"
            register={register}
            errors={errors}
            errorMessage="Must enter a name"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <TextAreaInput
            labelText="Description"
            name="description"
            rows={3}
            register={register}
            errors={errors}
            errorMessage="Must enter a description"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <ButtonGroup>
          <Button
            color={ButtonColors.White}
            text={cancelButtonText}
            disabled={isDisabled}
            onClick={onCancel}
          />
          <SubmitButton text={_submitButtonText} isDisabled={!isValid || !isDirty || isDisabled} />
        </ButtonGroup>
      </SpacingContainer>
    </form>
  );
}
