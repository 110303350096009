import { ActivityItemType } from '../../../shared/components/activity/IActivityItem';

export interface IVisitResource {
  userId: string;
  contactsCount?: number;
  conversationsCount?: number;
  tasksCount?: number;
  mentionsCount?: number;
  lastVisitedTime: string;
}

export const activityItemTypeToVisitValueLookup: Record<
  ActivityItemType,
  (item?: IVisitResource) => number
> = {
  Contact: (item) => item?.contactsCount || 0,
  Conversation: (item) => item?.conversationsCount || 0,
  Task: (item) => item?.tasksCount || 0,
  Mention: (item) => item?.mentionsCount || 0,
};
