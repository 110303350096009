import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
} from './AlertDialog';

interface IProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onLeaveClick?: () => void;
}

export function UnsavedDataAlertDialog({ open, onOpenChange, onLeaveClick }: IProps) {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
        <AlertDialogDescription>
          You have unsaved changes. They will be lost if you continue.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogAction onClick={onLeaveClick}>Leave</AlertDialogAction>
          <AlertDialogCancel>Continue editing</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
