import useIsBaseRoute from '../../../hooks/useIsNavigationMode';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import { DesktopMobileSwitch } from '../../screen-discriminators/DesktopMobileSwitch';

export default function Header() {
  const isBaseRoute = useIsBaseRoute();
  return (
    <DesktopMobileSwitch
      desktopElement={<HeaderDesktop />}
      mobileElement={isBaseRoute ? <HeaderMobile /> : null}
    />
  );
}
