import { IContactDto, IContactWithShareInfoDto } from '../models/IContactDto';
import { IShareContactRequestDto } from '../../../shared/model/IShareContactRequestDto';

/**
 * Combines contacts and share contact requests by matching with the shared contact.
 * @param contacts
 * @param shareContactRequests
 * @returns
 */
export function MergeContactsAndSharedRequests(
  contacts: IContactDto[],
  shareContactRequests: IShareContactRequestDto[],
): IContactWithShareInfoDto[] {
  return contacts.map((contact) => {
    const matchingRequests = shareContactRequests?.filter(
      (datareq) => datareq.sharedContactId === contact.id,
    );

    if (matchingRequests.length > 0) {
      const acceptedRequest = matchingRequests.find((datareq) => datareq.status === 'Accepted');
      if (acceptedRequest) {
        return { ...contact, shareInformation: acceptedRequest };
      }

      const pendingRequest = matchingRequests.find((datareq) => datareq.status === 'Pending');
      if (pendingRequest) {
        return { ...contact, shareInformation: pendingRequest };
      }

      const rejectedRequest = matchingRequests.find((datareq) => datareq.status === 'Rejected');
      if (rejectedRequest) {
        return { ...contact, shareInformation: rejectedRequest };
      }
    }

    return { ...contact, shareInformation: undefined };
  });
}
