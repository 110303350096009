import { MouseEventHandler } from 'react';
import { ButtonColors } from '../../constants/ButtonColors';
import IconButton from '../buttons/IconButton';
import { Filler } from '../layout/Filler';

export interface ICardMenuItem {
  key: string;
  label: string;
  icon: (classes: string) => React.ReactElement;
  onClick: MouseEventHandler<HTMLElement>;
  onMouseDown?: MouseEventHandler<HTMLElement>;
  isDisabled?: boolean;
  color?: ButtonColors;
}

interface IPropsV2 {
  leftItems?: ICardMenuItem[];
  rightItems?: ICardMenuItem[];
}

interface IProps {
  children: React.ReactNode;
}

const createAction = (item: ICardMenuItem) => (
  <IconButton
    key={item.key}
    icon={item.icon('w-5 h-5')}
    color={item.color || ButtonColors.Gray}
    isDisabled={!!item.isDisabled}
    onClick={item.onClick}
    onMouseDown={item.onMouseDown}
    label={item.label}
  />
);

export function CardMenuBarV2({ leftItems, rightItems }: IPropsV2) {
  return (
    <div className="flex gap-3 items-start px-2 mt-2">
      {leftItems && leftItems.length > 0 && leftItems.map(createAction)}
      {rightItems && rightItems.length > 0 && (
        <>
          <Filler />
          {rightItems.map(createAction)}
        </>
      )}
    </div>
  );
}

export function CardMenuBar({ children }: IProps) {
  return <div className="flex gap-3 px-2">{children}</div>;
}
