import apiClient from '../../../shared/utils/apiClient';
import { ICreateStageDto, IStageDetailDto, IStageDto } from '../models/IStageDto';
import { IMoveStage } from '../queries/stageQueries';

export const getStages = (pipelineId: string) =>
  apiClient
    .get(`v1/pipelines/${pipelineId}/stages`)
    .then<IStageDto[]>((response) => response.data.data);

export const getStage = (pipelineId: string, stageId: string) =>
  apiClient
    .get(`v1/pipelines/${pipelineId}/stages/${stageId}`)
    .then<IStageDetailDto>((response) => response.data);

export const createStage = (piplineId: string, stage: ICreateStageDto) =>
  apiClient
    .post(`v1/pipelines/${piplineId}/stages`, stage)
    .then<IStageDto>((response) => response.data);

export const updateStage = (pipelineId: string, stageId: string, stage: ICreateStageDto) =>
  apiClient.patch(`v1/pipelines/${pipelineId}/stages/${stageId}`, stage);

export const deleteStage = (pipelineId: string, stageId: string) =>
  apiClient.delete(`v1/pipelines/${pipelineId}/stages/${stageId}`);

export const moveStage = (pipelineId: string, stageId: string, moveSettings: IMoveStage) =>
  apiClient.put(`v1/pipelines/${pipelineId}/stages/${stageId}/move`, moveSettings);
