import ConfirmDialog from '../../shared/components/alert-dialog/ConfirmDialog';
import ContactEditModal from './ContactEditModal';
import { IContactDto } from './models/IContactDto';
import { useDeleteContactMutation } from './queries/contactQueries';

interface IContactActionModalsProps {
  contact: IContactDto;
  isEditContactModalOpen: boolean;
  onEditContactModalOpenChange: (isOpen: boolean) => void;
  isDeleteContactWarningOpen: boolean;
  onDeleteContactWarningOpenChange: (isOpen: boolean) => void;
  onContactDeleted?: () => void;
}

export function ContactActionModals({
  contact,
  isEditContactModalOpen,
  onEditContactModalOpenChange,
  isDeleteContactWarningOpen,
  onDeleteContactWarningOpenChange,
  onContactDeleted,
}: IContactActionModalsProps) {
  const deleteContactMutation = useDeleteContactMutation();

  const handleDeleteContact = () => {
    deleteContactMutation.mutate({ contactId: contact.id });
    if (onContactDeleted) {
      onContactDeleted();
    }
  };

  return (
    <>
      <ContactEditModal
        open={isEditContactModalOpen}
        onOpenChange={onEditContactModalOpenChange}
        contact={contact}
      />
      <ConfirmDialog
        open={isDeleteContactWarningOpen}
        onOpenChange={onDeleteContactWarningOpenChange}
        title="Delete Contact"
        description="Are you sure you want to delete this contact?"
        actionText="Delete"
        onAction={handleDeleteContact}
      />
    </>
  );
}
