/* eslint-disable react/jsx-no-useless-fragment */

import React from 'react';

interface IProps<T extends string> {
  id: T;
  activeTabId: T;
  children: React.ReactNode;
}

export default function TabContent<T extends string>({ id, activeTabId, children }: IProps<T>) {
  return <>{id === activeTabId && <>{children}</>}</>;
}
