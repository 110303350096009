import { ExpandableCard } from '../../shared/components/cards/ExpandableCard';
import { CardMenuBarV2, ICardMenuItem } from '../../shared/components/cards/CardMenuBar';
import Text from '../../shared/components/text/Text';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { INetworkDto } from '../../shared/model/INetwork';
import { NetworkPropertyList } from './NetworkPropertyList';
import { IEntityCardProps } from '../../shared/components/cards/EntityCardProps';
import { ResourceType } from '../../shared/model/ResourceType';

export function NetworkCard({
  entity,
  menuItems,
  headerRenderer,
  header,
  defaultOpen,
}: IEntityCardProps<INetworkDto>) {
  const entityManifest = useEntityManifest(ResourceType.Network);
  const displayName = entity.name;
  const actualHeader = header || (
    <Text as="p" size="small" weight="medium">
      {displayName}
    </Text>
  );
  const actualHeaderRenderer =
    headerRenderer ||
    (() => {
      const avatar = (
        <ProfileAvatar
          avatarProps={{
            src: entity.photoUrl || entityManifest.icon,
            alt: entity.name,
            widthClass: 'w-10',
            heightClass: 'h-10',
          }}
        />
      );

      return (
        <div className="flex items-center space-x-3">
          <div className="flex-shrink-0">{avatar}</div>
          <div className="flex-none overflow-x-hidden px-1">{actualHeader}</div>
        </div>
      );
    });

  const leftMenuItems: ICardMenuItem[] = [];
  const rightMenuItems = menuItems || [];
  return (
    <ExpandableCard renderHead={actualHeaderRenderer} as="div" defaultOpen={defaultOpen}>
      <div className="mt-2">
        <NetworkPropertyList network={entity} />
      </div>
      <CardMenuBarV2 leftItems={leftMenuItems} rightItems={rightMenuItems} />
    </ExpandableCard>
  );
}
