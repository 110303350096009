import {
  ArrowRightIcon,
  LinkIcon,
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { IMenuItem } from '../../../shared/model/IMenuItem';
import { usePipelineStoreContext } from '../context/pipeline-context';
import { usePipelinePrivilegesQuery } from '../queries/pipelinePriviligesQueries';

export default function usePipelineInfoMenuItems(pipelineId: string) {
  const privilegesQuery = usePipelinePrivilegesQuery(pipelineId);
  const privileges = privilegesQuery.data;
  const {
    setInviteMemberModalOpen,
    setIsConfirmDeleteOpen,
    setIsConfirmLeaveOpen,
    setIsEditModalOpen,
    setActiveEditPipelineTab,
  } = usePipelineStoreContext();

  const menuItems: IMenuItem[] = [];
  if (privileges?.canReadMembers) {
    menuItems.push({
      id: 'Attachments',
      title: 'Attachments',
      icon: LinkIcon,
      onClick: () => setActiveEditPipelineTab('tab_attachments'),
      useDivider: true,
    });
  }

  if (privileges?.canInviteMember) {
    menuItems.push({
      id: 'Invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () => setInviteMemberModalOpen(true),
    });
  }
  if (privileges?.canEdit) {
    menuItems.push({
      id: 'Edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () => setIsEditModalOpen(true),
    });
  }
  if (privileges?.canDelete) {
    menuItems.push({
      id: 'Delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () => setIsConfirmDeleteOpen(true),
    });
  } else {
    menuItems.push({
      id: 'Leave',
      title: 'Leave',
      icon: ArrowRightIcon,
      onClick: () => setIsConfirmLeaveOpen(true),
    });
  }

  return menuItems;
}
