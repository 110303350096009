import { LexicalEditor } from 'lexical';
import { MessageType } from '../../shared/constants/MessageTypes';
import { useLexicalMarkdownContent } from '../../shared/lexical/markdown/useLexicalMarkdownContent';
import Text from '../../shared/components/text/Text';
import HorizontalSpacer from '../../shared/components/spacing/HorizontalSpacer';

interface IProps {
  messageType?: MessageType;
  displayName: string;
  text: string;
  editor: LexicalEditor;
}

export function MessageResponseContent({ messageType, displayName, text, editor }: IProps) {
  const content = useLexicalMarkdownContent({ markdown: text, editor, asPlainText: true });
  return (
    <div
      className={`${
        messageType === MessageType.Incoming ? 'mt-1' : ''
      } border-l-4 border pl-2 mb-2 text-sm text`}
    >
      <Text as="span" size="small" brightness="dark" variant="italic" weight="medium">
        Replied to:
      </Text>
      <Text as="span" size="small" brightness="dark" variant="italic">
        {` ${displayName}`}
      </Text>
      <HorizontalSpacer distance="small" />
      <Text size="xSmall" brightness="dark" variant="italic">
        {content}
      </Text>
    </div>
  );
}
