import { useNavigate } from 'react-router';
import { useState } from 'react';
import SelectCardList, { SelectCardListItem } from '../../shared/components/lists/SelectCardList';
import boardDummy from '../../assets/mocks/boardsMock.png';
import SelectPrompt from '../../shared/components/prompts/SelectPrompt';
import SearchBar from '../../shared/components/search/SearchBar';
import useDebounce from '../../shared/hooks/useDebounce';
import { useBoardsInfiniteQuery } from './queries/boardQueries';
import { InfiniteApiQueryScroll } from '../../shared/components/scrolling/InfiniteApiQueryScroll';
import { BoardActionButtons } from './BoardActionButtons';
import NavigationList from '../../shared/components/navigation/NavigationList';
import WrapperDesktop from '../../shared/components/screen-discriminators/WrapperDesktop';
import WrapperMobile from '../../shared/components/screen-discriminators/WrapperMobile';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { BoardTypeToResourceType } from './models/IBoardDto';
import useBoardActionButtons from './hooks/useBoardActionButtons';
import BoardModals from './BoardModals';
import { BoardContextProvider } from './context/board-context';
import { IActivityItem } from '../../shared/components/activity/IActivityItem';
import { useMyUserId } from '../../shared/auth/accountHooks';

export interface IBoardsCardViewProps {
  projectId: string;
}

export default function BoardsCardView({ projectId }: IBoardsCardViewProps) {
  const navigate = useNavigate();
  const myUserId = useMyUserId();
  const [searchTerm, setSearchTerm] = useState('');
  const debounceSearchTerm = useDebounce(searchTerm);
  const boardsQuery = useBoardsInfiniteQuery({ projectId, searchTerm: debounceSearchTerm });
  const showPrompt = boardsQuery.isFetched && !searchTerm && boardsQuery.data?.pages.length === 0;
  return (
    <>
      <div className="flex items-center gap-3">
        <div className="w-full lg:w-96">
          <SearchBar
            placeholder="Search to find boards"
            searchTerm={searchTerm}
            onSearchChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>
        <BoardActionButtons projectId={projectId} />
      </div>
      {showPrompt && (
        <SelectPrompt
          imageUrl={boardDummy}
          text={
            <>
              Start your collaboration by adding a board to your project
              <br /> Click on the &apos;Add board&apos; to get started
            </>
          }
        />
      )}
      {!showPrompt && (
        <InfiniteApiQueryScroll query={boardsQuery}>
          {({ data }) => (
            <>
              <WrapperMobile>
                <NavigationList
                  data={data}
                  isPending={boardsQuery.isPending}
                  itemIdProperty={({ id }) => id}
                  itemUrlProperty={(item) => `boards/${item.id}`}
                  itemHeadlineProperty={({ name }) => name}
                  itemInformationProperty={({ name }) => name}
                  itemImgSrcProperty={({ photoUrl, type }) =>
                    photoUrl || useEntityManifest(BoardTypeToResourceType[type]).icon
                  }
                />
              </WrapperMobile>
              <WrapperDesktop>
                <SelectCardList
                  orientation="horizontal"
                  isEmpty={data.length === 0}
                  isLoading={boardsQuery.isPending}
                >
                  {data.map((item) => {
                    const myVisit = item.visits.find((visit) => visit.userId === myUserId);
                    const items = [
                      { type: 'Contact', value: myVisit?.contactsCount || 0 },
                      { type: 'Conversation', value: myVisit?.conversationsCount || 0 },
                      { type: 'Task', value: myVisit?.tasksCount || 0 },
                      { type: 'Mention', value: myVisit?.mentionsCount || 0 },
                    ] as IActivityItem[];
                    return (
                      <BoardContextProvider key={`board-context-provider${item.id}`}>
                        <SelectCardListItem
                          id={item.id}
                          key={item.id}
                          title={item.displayName || item.name}
                          updatedTime={item.updatedTime}
                          imageUrl={boardDummy}
                          onClick={() => navigate(`boards/${item.id}`)}
                          activityItems={items}
                          actionsResolver={(isOpen) => {
                            const payload = isOpen ? { projectId, boardId: item.id } : {};
                            return useBoardActionButtons(payload.projectId, payload.boardId);
                          }}
                        />
                        <BoardModals board={item} />
                      </BoardContextProvider>
                    );
                  })}
                </SelectCardList>
              </WrapperDesktop>
            </>
          )}
        </InfiniteApiQueryScroll>
      )}
    </>
  );
}
