import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { getSharedResource, getSharedResources, shareResource } from '../services/pipelineService';
import { IShareResourceWithPipelineDto } from '../models/IPipelineDto';

interface IUseSharedResourcesProps {
  pipelineId: string;
}

export const sharedResourceKeys = {
  all: ['sharedReosurces'] as const,
  lists: () => [...sharedResourceKeys.all, 'list'] as const,
  list: (props: IUseSharedResourcesProps) => [...sharedResourceKeys.lists(), props] as const,
  details: () => [...sharedResourceKeys.all, 'detail'] as const,
  detail: (id: string) => [...sharedResourceKeys.details(), id] as const,
};

const sharedResourcesBaseQuery = ({ pipelineId }: IUseSharedResourcesProps) => ({
  queryKey: sharedResourceKeys.list({ pipelineId }),
  queryFn: () => getSharedResources(pipelineId),
  enabled: !!pipelineId,
});

export function useSharedResourcesQuery(props: IUseSharedResourcesProps) {
  return useQuery({
    ...sharedResourcesBaseQuery(props),
  });
}

export const sharedResourceBaseQuery = (
  pipelineId: string,
  sharedResourceId: string) => ({
    queryKey: sharedResourceKeys.detail(sharedResourceId),
    queryFn: () => getSharedResource(pipelineId, sharedResourceId),
  });

export function useSharedResourceQuery(piplineId?: string, sharedResourceId?: string) {
  return useQuery({
    ...sharedResourceBaseQuery(piplineId || '', sharedResourceId || ''),
    enabled: !!piplineId && !!sharedResourceId,
  });
}
export function useShareResourceMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (
      { pipelineId, request }:
        {
          pipelineId: string,
          request: IShareResourceWithPipelineDto
        }) => shareResource(pipelineId, request),
    onSuccess: (_, { pipelineId }) => {
      queryClient.invalidateQueries({ queryKey: sharedResourceKeys.list({ pipelineId }) });
    },
    onError: useDispatchApiError(),
  });
}
