import { useEffect, useState } from 'react';
import { IMessageFileDto } from '../../shared/model/IMessageDto';
import { getFileLease } from '../../shared/services/conversationFileService';

interface IProps {
  file: IMessageFileDto;
  onClick: (file: IMessageFileDto) => void;
  conversationId: string;
}

export default function MessageImageAttachment({ file, onClick, conversationId }: IProps) {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (conversationId && file.fileId) {
      getFileLease(conversationId, file.fileId).then(({ url }) => {
        setImageUrl(url);
      });
    }
  }, [file, conversationId]);

  return (
    <button type="button" onClick={() => onClick(file)} className="rounded-md p-1 md:w-1/4 grow">
      {imageUrl && <img src={imageUrl} alt={file.name} className="rounded-md" />}
    </button>
  );
}
