export enum ResourceType {
  Unknown = 'Unknown',
  Contact = 'Contact',
  ContactPassive = 'ContactPassive',
  Network = 'Network',
  Pipeline = 'Pipeline',
  PipelineStage = 'PipelineStage',
  PipelineItem = 'PipelineItem',
  Organization = 'Organization',
  Project = 'Project',
  Board = 'Board',
  Connection = 'Connection',
}
