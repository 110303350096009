import ConfirmDialog from '../../shared/components/alert-dialog/ConfirmDialog';
import ModalLegacy from '../../shared/components/modal/ModalLegacy';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { usePipelineStoreContext } from './context/pipeline-context';
import InviteMember from '../Members/MemberInvite';
import { useRemoveMemberMutation } from '../Members/queries/MemberQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import { useBoardQuery, useDeleteBoardMutation } from '../Projects/queries/boardQueries';
import PipelineEditModal from './PipelineEditModal';
import { usePipelineQuery } from './queries/pipelineQueries';

interface IProps {
  projectId: string;
  boardId: string;
  pipelineId: string;
  onDelete?: () => void;
  onLeave?: () => void;
}

export default function PipelineModals({
  projectId,
  boardId,
  pipelineId,
  onDelete,
  onLeave,
}: IProps) {
  const myUserId = useMyUserId();
  const deleteMutation = useDeleteBoardMutation();
  const removeMemberMutation = useRemoveMemberMutation(ResourceType.Board);
  const boardQuery = useBoardQuery(projectId, boardId);
  const pipelineQuery = usePipelineQuery(pipelineId);
  const {
    isInviteMemberModalOpen,
    setInviteMemberModalOpen,
    isConfirmDeleteOpen,
    setIsConfirmDeleteOpen,
    isConfirmLeaveOpen,
    setIsConfirmLeaveOpen,
    isEditModalOpen,
    setIsEditModalOpen,
  } = usePipelineStoreContext();

  const handleContinueLeave = () => {
    setIsConfirmLeaveOpen(false);
    if (!myUserId) return;

    removeMemberMutation.mutate(
      {
        member: {
          name: '',
          resourceId: [projectId, boardId],
          userId: myUserId,
        },
      },
      {
        onSuccess: () => {
          setIsConfirmLeaveOpen(false);
          if (onLeave) onLeave();
        },
      },
    );
  };

  const handleContinueDelete = () => {
    setIsConfirmDeleteOpen(false);
    deleteMutation.mutate({ projectId, boardId });
    if (onDelete) onDelete();
  };
  return (
    <>
      {pipelineQuery.data && boardQuery.data && (
        <PipelineEditModal
          pipeline={pipelineQuery.data}
          board={boardQuery.data}
          open={isEditModalOpen}
          onOpenChange={setIsEditModalOpen}
        />
      )}
      <ModalLegacy
        open={isInviteMemberModalOpen}
        onOpenChange={setInviteMemberModalOpen}
        title="Invite Member"
      >
        <InviteMember
          onComplete={() => setInviteMemberModalOpen(false)}
          resourceIds={[pipelineId]}
          type={ResourceType.Board}
        />
      </ModalLegacy>
      <ConfirmDialog
        open={isConfirmDeleteOpen}
        onOpenChange={setIsConfirmDeleteOpen}
        title="Delete Pipeline"
        description="Are you sure you want to delete this pipeline?"
        actionText="Delete"
        onAction={handleContinueDelete}
      />
      <ConfirmDialog
        open={isConfirmLeaveOpen}
        onOpenChange={setIsConfirmLeaveOpen}
        title="Leave Pipeline"
        description="Are you sure you want to leave this pipeline?"
        actionText="Leave"
        onAction={handleContinueLeave}
      />
    </>
  );
}
