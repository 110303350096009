import ServiceHeader, { IServiceHeaderAction } from '../building-blocks/ServiceHeader';

export interface IBaseLayoutProps {
  title: string;
  headerActions?: IServiceHeaderAction[];
  children: React.ReactNode;
}

function ServiceWindow({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-col h-full w-full overflow-hidden px-3 md:px-6 pb-3 md:py-4">
      {children}
    </div>
  );
}

function ServiceContent({ children }: { children: React.ReactNode }) {
  return <div className="flex-1 overflow-hidden mt-6 ">{children}</div>;
}

export function BaseLayout({ title, headerActions, children }: IBaseLayoutProps) {
  return (
    <ServiceWindow>
      <ServiceHeader text={title} actions={headerActions} />
      <ServiceContent>{children}</ServiceContent>
    </ServiceWindow>
  );
}
