import { useParams } from 'react-router';
import { useBoardQuery } from './queries/boardQueries';
import Pipelines from '../Pipelines/Pipelines';
import useVisitTracking from '../Tracking/hooks/useVisitTracking';
import { ResourceType } from '../../shared/model/ResourceType';
import { useVisitMutation } from '../Tracking/queries/visitQueries';

export default function Board() {
  const params = useParams();
  const { projectId, boardId } = params;
  const boardQuery = useBoardQuery(projectId, boardId);
  const { data } = boardQuery;

  const visitMutation = useVisitMutation();
  useVisitTracking({
    onVisit: () => {
      if (!projectId || !boardId) {
        return;
      }
      visitMutation.mutate({ resourceType: ResourceType.Board, resourceId: [projectId, boardId] });
    },
  });

  if (!data) {
    return <></>;
  }

  if (!data.resourceId) {
    return (
      <p>
        The required resources for the board has not yet been created. Please refresh and try
        again...
      </p>
    );
  }

  return <Pipelines projectId={data.projectId} boardId={data.id} pipelineId={data.resourceId} />;
}
