import ModalLegacy from '../../shared/components/modal/ModalLegacy';
import InviteMember from './MemberInvite';
import { ResourceType } from '../../shared/model/ResourceType';
import { useInviteMemberMutation } from './queries/MemberQueries';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';

interface IProps {
  open?: boolean;
  onOpenChange: (open: boolean) => void;
  resourceIds: ResourceIds;
  type: ResourceType;
  customInviteMemberMutation?: ReturnType<typeof useInviteMemberMutation>;
}

export default function InviteMemberModal({
  customInviteMemberMutation,
  type,
  resourceIds,
  open,
  onOpenChange,
}: IProps) {
  return (
    <ModalLegacy title="Invite members" open={open} onOpenChange={onOpenChange}>
      <InviteMember
        customInviteMemberMutation={customInviteMemberMutation}
        onComplete={() => onOpenChange(false)}
        resourceIds={resourceIds}
        type={type}
      />
    </ModalLegacy>
  );
}
