import apiClient from '../../../shared/utils/apiClient';

export function uploadMyUserPhoto(imageFile: Blob) {
  const formData = new FormData();

  formData.append('file', imageFile);

  return apiClient.post('v1/users/my/photo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteMyUserPhoto() {
  return apiClient.delete('v1/users/my/photo');
}
