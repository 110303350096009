import { useState } from 'react';
import { PencilIcon, TrashIcon, UserPlusIcon } from 'lucide-react';
import { Bars3Icon, Cog6ToothIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router';
import { BaseLayout } from '../../shared/components/layout/pages/BaseLayout';
import ProjectsSearch from '../Projects/ProjectsSearch';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../shared/components/drop-down-menu/DropdownMenu';
import { renderMenuItems } from '../../shared/components/drop-down-menu/utils';
import { IServiceHeaderAction } from '../../shared/components/layout/building-blocks/ServiceHeader';
import { IMenuItem } from '../../shared/model/IMenuItem';
import { useOrganizationPrivilegesQuery } from './queries/organizationPrivilegesQueries';
import { useInviteOrganizationMemberMutation } from './queries/organizationMemberQueries';
import { useDeleteOrganizationMutation, useOrganizationQuery } from './queries/organizationQueries';
import { CompanyCreateEditModal } from './CompanyCreateEditModal';
import { ResourceType } from '../../shared/model/ResourceType';
import ConfirmDialog from '../../shared/components/alert-dialog/ConfirmDialog';
import ProjectConfirmDialogs from '../Projects/ProjectConfirmDialogs';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import InviteMember from '../Members/MemberInvite';
import useModals from '../../shared/components/modal/hooks/useModal';

export default function Company() {
  const navigate = useNavigate();
  const { tenantId } = useParams();

  const { openModal, closeModal } = useModals();

  const setActiveViewLayout = useGlobalStore((state) => state.setActiveViewLayout);
  const activeViewLayout = useGlobalStore(
    (state) => state.getViewSettings('project').activeViewLayout,
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const organizationQuery = useOrganizationQuery(tenantId as string);

  const organization = organizationQuery.data;
  const deleteOrganizationMutation = useDeleteOrganizationMutation();

  const inviteMemberMutation = useInviteOrganizationMemberMutation();
  const organizationPrivilegesQuery = useOrganizationPrivilegesQuery(tenantId as string);

  const privileges = organizationPrivilegesQuery.data;

  const tabMenuItems: IMenuItem[] = [];
  if (privileges?.canEdit) {
    tabMenuItems.push({
      id: 'edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () =>
        openModal({
          title: 'Edit company',
          content: <CompanyCreateEditModal values={organization} />,
        }),
    });
  }
  if (privileges?.canInviteMember) {
    tabMenuItems.push({
      id: 'invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () =>
        openModal({
          title: 'Invite member',
          content: (
            <InviteMember
              customInviteMemberMutation={inviteMemberMutation}
              resourceIds={organization ? [organization.id] : []}
              type={ResourceType.Organization}
              onComplete={() => closeModal()}
            />
          ),
        }),
    });
  }
  if (privileges?.canDelete) {
    tabMenuItems.push({
      id: 'delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () => setDeleteDialogOpen(true),
    });
  }

  const headerActions: IServiceHeaderAction[] = [
    {
      id: 'cardView',
      onClick: () => {
        setActiveViewLayout('project', 'card');
        return Promise.resolve();
      },
      icon: Squares2X2Icon,
      isActive: activeViewLayout === 'card',
    },
    {
      id: 'listView',
      onClick: () => {
        setActiveViewLayout('project', 'list');
        return Promise.resolve();
      },
      icon: Bars3Icon,
      isActive: activeViewLayout === 'list',
    },
    {
      id: 'editOrganization',
      onClick: () => Promise.resolve(),
      icon:
        tabMenuItems.length > 0 ? (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Cog6ToothIcon className="w-6 h-6 text-gray-dark stroke-2" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>{renderMenuItems(tabMenuItems)}</DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <></>
        ),
    },
  ];
  if (!organization) {
    return <></>;
  }

  const handleDelete = () => {
    deleteOrganizationMutation.mutate(organization);
    navigate('/companies');
  };

  return (
    <>
      <BaseLayout title={organizationQuery.data?.name} headerActions={headerActions}>
        <ProjectsSearch activeViewLayout={activeViewLayout} />
        <ProjectConfirmDialogs />
      </BaseLayout>

      <ConfirmDialog
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        title="Delete company"
        description="Are you sure you want to delete this company?"
        onAction={handleDelete}
        actionText="Delete"
      />
    </>
  );
}
