// eslint-disable-file no-param-reassign
import missingImage from '../../../assets/images/missing-image.png';

export interface IProfileAvatarProps {
  src?: string;
  alt?: string;
  widthClass?: string;
  heightClass?: string;
}

interface IProps {
  avatarProps: IProfileAvatarProps;
  includeBorder?: boolean;
}

export default function ProfileAvatar({ avatarProps, includeBorder = false }: IProps) {
  const imgUrl = avatarProps.src || '';
  return (
    <img
      src={imgUrl}
      onError={({ currentTarget }) => {
        const target = currentTarget;
        target.onerror = null; // prevents looping
        target.src = missingImage;
      }}
      alt={avatarProps.alt}
      className={`${avatarProps.widthClass} ${avatarProps.heightClass} rounded-full bg-gray-dark ${
        includeBorder ? 'ring-4 ring-white' : ''
      }`}
    />
  );
}
