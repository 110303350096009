import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import Attachments from '../../shared/components/attachments/Attachments';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import {
  ISidePanelLayoutAction,
  ISidePanelLayoutContent,
  SidePanelLayout,
} from '../../shared/components/layout/pages/SidePanelLayout';
import Howto from '../../shared/components/message-popups/HowTo';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { useNavigateToCurrentBasePath } from '../../shared/hooks/useNavigateToCurrentBasePath';
import { ActivityEntityType } from '../../shared/model/IActitityDto';
import { ResourceType } from '../../shared/model/ResourceType';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import Activities from '../Activities/Activities';
import {
  createActivityAction,
  createContactNoteAction,
} from '../Messaging/editor/messageEditorMenuActions';
import Messaging from '../Messaging/Messaging';
import { useResourceConversationQuery } from '../Messaging/queries/conversationQueries';
import { useMessagesQuery } from '../Messaging/queries/messageQueries';
import {
  useConnectionIsInitatedQuery,
  useConnectWithUserMutation,
} from '../Request/queries/connectionRequestQueries';
import { ContactActionModals } from './ContactActionModals';
import { ContactInfoTab } from './ContactInfoTab';
import ContactInfoMain from './ContactInfoMain';
import ContactPrivateNotes from './ContactPrivateNotes';
import ContactShareContact from './ContactShareContact';
import { useHasConnectionQuery, useUserConnectionQuery } from './queries/connectionQueries';
import { useContactQuery } from './queries/contactQueries';

function getDefaultTab(isConnected: boolean, isMobile: boolean) {
  if (!isConnected) return 'notes';
  if (isMobile) return 'chat';
  return 'info';
}

function useContactFeatures(contactId: string) {
  const isMobile = useCheckMobileScreen();
  const contactQuery = useContactQuery(contactId);
  const connectionQuery = useUserConnectionQuery(contactQuery.data?.userId);
  const conversationQuery = useResourceConversationQuery(
    connectionQuery.data?.id,
    ResourceType.Connection,
  );
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);

  const { setSidePanelOpen } = useGlobalStore();

  return useMemo(() => {
    const isPending =
      // conversationQuery.isLoading is used to avoid getting stuck on enabled: false
      contactQuery.isPending || connectionQuery.isPending || conversationQuery.isLoading;

    const isSuccess =
      contactQuery.isSuccess &&
      (!contactQuery.data.userId || (connectionQuery.isSuccess && conversationQuery.isSuccess));

    const content: ISidePanelLayoutContent[] = [];
    if (!isSuccess) return { content, default: undefined, isPending, isSuccess };

    if (!contactQuery.data.userId) {
      content.push({
        id: 'mainInfo',
        title: 'Info',
        renderContent: () => <ContactInfoMain contact={contactQuery.data} />,
        desktopRenderTarget: 'main',
        mobileRenderTarget: 'none',
      });
    } else if (conversationQuery.isSuccess) {
      content.push({
        id: 'chat',
        title: 'Chat',
        renderContent: () => (
          <Messaging
            messages={messagesQuery}
            conversation={conversationQuery.data}
            externalMenuActions={[
              createContactNoteAction('?tab=notes', () => setSidePanelOpen('contact', true)),
              createActivityAction('?tab=activities', () => setSidePanelOpen('contact', true)),
            ]}
          />
        ),
        desktopRenderTarget: 'main',
      });
    }
    content.push(
      {
        id: 'info',
        title: 'Info',
        renderContent: () => <ContactInfoTab contact={contactQuery.data} />,
        desktopRenderTarget: contactQuery.data.userId ? 'tab' : 'none',
        mobileRenderTarget: 'headerLink',
      },
      {
        id: 'notes',
        title: 'Notes',
        renderContent: () => <ContactPrivateNotes contact={contactQuery.data} showHeader={false} />,
      },
      {
        id: 'activities',
        title: 'Tasks',
        renderContent: () => <Activities parentId={contactId} type={ActivityEntityType.Contact} />,
      },
    );
    if (contactQuery.data.userId && conversationQuery.isSuccess) {
      content.push({
        id: 'attachments',
        title: 'Attachments',
        renderContent: () => (
          <>
            <div className="flex-1 overflow-hidden">
              <Attachments conversationId={conversationQuery.data.id} />
            </div>
            <div className="flex pt-2">
              <Howto
                title="Attachments "
                description="View the attatchments you have sent in the chat."
              />
            </div>
          </>
        ),
        mobileRenderTarget: 'menu',
      });
    }
    content.push({
      id: 'share',
      title: 'Share',
      renderContent: () => <ContactShareContact contact={contactQuery.data} />,
    });

    const defaultContentId = getDefaultTab(!!connectionQuery.data, isMobile);

    return { content, default: defaultContentId, isPending, isSuccess };
  }, [
    isMobile,
    contactId,
    contactQuery.isPending,
    contactQuery.isSuccess,
    contactQuery.data,
    conversationQuery.isPending,
    conversationQuery.isSuccess,
    conversationQuery.data,
    messagesQuery,
    setSidePanelOpen,
  ]);
}

function useContactActions(contactId: string) {
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false);
  const [isEditContactOpen, setIsEditContactOpen] = useState(false);

  const { data: contact, isSuccess } = useContactQuery(contactId);
  const hasConnectionQuery = useHasConnectionQuery(contact?.userId);
  const connectionIsInitiatedQuery = useConnectionIsInitatedQuery(contact?.userId);

  const connectWithUserMutation = useConnectWithUserMutation();

  const handleCreateConnectionRequest = () => {
    if (!isSuccess || !contact.userId) {
      return;
    }
    connectWithUserMutation.mutate({
      userId: contact.userId,
      displayName: contact.displayName,
    });
  };

  const actions: ISidePanelLayoutAction[] = [
    {
      id: 'edit',
      title: 'Edit',
      onClick: () => setIsEditContactOpen(true),
    },
  ];
  if (
    isSuccess &&
    contact.userId &&
    hasConnectionQuery.isSuccess &&
    connectionIsInitiatedQuery.isSuccess &&
    !hasConnectionQuery.data
  ) {
    actions.push({
      id: 'connect',
      title: connectionIsInitiatedQuery.data ? 'Connecting...' : 'Connect',
      disabled: connectionIsInitiatedQuery.data,
      onClick: handleCreateConnectionRequest,
    });
  }
  actions.push({
    id: 'delete',
    title: 'Delete',
    onClick: () => setIsDeleteWarningOpen(true),
  });

  return {
    actions,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
    isEditContactOpen,
    setIsEditContactOpen,
  } as const;
}

function useContactMobileHeaderActions(contactId: string) {
  const { data: contact, isSuccess } = useContactQuery(contactId);

  if (!isSuccess) {
    return [];
  }

  const emailAddress = contact.emailAddresses[0]?.value;
  const phoneNumber = contact.phoneNumbers[0]?.value;
  return [
    {
      id: 'email',
      title: 'Mail to',
      icon: EnvelopeIcon,
      disabled: !emailAddress,
      onClick: () => {
        window.location.href = `mailto:${emailAddress}`;
      },
    },
    {
      id: 'phone',
      title: 'Call',
      icon: PhoneIcon,
      disabled: !phoneNumber,
      onClick: () => {
        window.location.href = `tel:${phoneNumber}`;
      },
    },
  ];
}

export function ContactDetail() {
  const params = useParams();
  const { color: socialColor, icon: defaultIcon } = useEntityManifest(ResourceType.Contact);
  const { color: passiveColor } = useEntityManifest(ResourceType.ContactPassive);

  const contactId = params.id as string;
  const contactQuery = useContactQuery(contactId);

  const features = useContactFeatures(contactId);
  const {
    actions,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
    isEditContactOpen,
    setIsEditContactOpen,
  } = useContactActions(contactId);
  const mobileHeaderActions = useContactMobileHeaderActions(contactId);

  const navigateToCurrentBasePath = useNavigateToCurrentBasePath();

  if (contactQuery.isPending || features.isPending) {
    return <div />;
  }

  if (contactQuery.isSuccess && features.isSuccess) {
    return (
      <>
        <SidePanelLayout
          title={contactQuery.data.displayName}
          subTitle={contactQuery.data.emailAddresses[0]?.value}
          imgSource={contactQuery.data.photoUrl || defaultIcon}
          imgAlt="Contact photo"
          colorSetting={contactQuery.data.userId ? socialColor : passiveColor}
          sidePanelViewSettingName="contact"
          content={features.content}
          actions={actions}
          mobileHeaderActions={mobileHeaderActions}
          defaultTab={features.default}
        />
        <ContactActionModals
          contact={contactQuery.data}
          isDeleteContactWarningOpen={isDeleteWarningOpen}
          onDeleteContactWarningOpenChange={setIsDeleteWarningOpen}
          isEditContactModalOpen={isEditContactOpen}
          onEditContactModalOpenChange={setIsEditContactOpen}
          onContactDeleted={navigateToCurrentBasePath}
        />
      </>
    );
  }

  return (
    <ErrorComponent
      queryResults={[contactQuery]}
      defaultErrorTexts={[{ code: 404, text: 'The contact could not be found.' }]}
    />
  );
}
