import TimeAgo from '../../shared/components/dates/TimeAgo';
import { pipelineBaseQuery } from '../Pipelines/queries/pipelineQueries';
import { IInviteRequestDto } from './models/IInviteRequestDto';
import { useDeleteBoardInviteRequestMutation } from './queries/boardInviteQueries';
import { RequestItems } from './RequestItems';

interface IProps {
  pipelineInviteRequests: IInviteRequestDto[];
}

export default function InviteToPipelineRequest({ pipelineInviteRequests }: IProps) {
  const deletePipelineInviteRequestMutation = useDeleteBoardInviteRequestMutation();

  return (
    <RequestItems
      requests={pipelineInviteRequests}
      resourceQuery={(request: IInviteRequestDto) => pipelineBaseQuery(request.sharedResourceId)}
      text={(request, pipeline) => (
        <>
          Invite to pipeline &apos;{pipeline?.name}&apos; sent{' '}
          <TimeAgo date={request.createdTime} /> ago.
        </>
      )}
      actions={(request) => [
        {
          text: 'Cancel',
          onClick: () => deletePipelineInviteRequestMutation.mutate(request),
          disabled: deletePipelineInviteRequestMutation.isPending,
        },
      ]}
    />
  );
}
