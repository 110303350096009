import { MouseEventHandler, ReactElement, Ref, forwardRef, useId } from 'react';
import { ButtonColors } from '../../constants/ButtonColors';
import { useButtonStyle } from './styles/useButtonStyle';

export type ButtonStyle = 'filled' | 'outline';

interface IProps {
  color: ButtonColors;
  text: string;
  icon?: ReactElement;
  disabled?: boolean;
  isFullWidth?: boolean;
  buttonStyle?: ButtonStyle;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Button = forwardRef(
  ({ text, icon = undefined, ...rest }: IProps, ref: Ref<HTMLButtonElement>) => {
    const styledProps = useButtonStyle(rest);
    const id = useId();
    return (
      <button ref={ref} key={id} type="button" {...styledProps}>
        {icon}
        {text}
      </button>
    );
  },
);

export default Button;
