import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import ModalLegacy, { ModalSize } from '../../shared/components/modal/ModalLegacy';
import { IBoardDto, IUpdateBoardDto } from './models/IBoardDto';
import { useUpdateBoardMutation } from './queries/boardQueries';
import { BoardEditForm, useBoardEditForm } from './BoardEditForm';

interface IProps {
  board: IBoardDto;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: (projectId: string) => void;
}

export default function BoardEditModal({ board, open, onOpenChange, onSuccess }: IProps) {
  const [openUnsavedDataWarning, setOpenUnsavedDataWarning] = useState(false);
  const form = useBoardEditForm({ values: board });
  const updateBoard = useUpdateBoardMutation();
  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleSubmit: SubmitHandler<IUpdateBoardDto> = (boardToSubmit) =>
    updateBoard.mutate(
      { projectId: board.projectId, boardId: board.id, board: boardToSubmit },
      {
        onSuccess: () => {
          if (onSuccess) {
            onSuccess(board.id);
          }
          onOpenChange(false);
        },
      },
    );

  const handleClose = () => {
    reset();
    onOpenChange(false);
  };

  const handleCancel = (event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setOpenUnsavedDataWarning(true);
    } else {
      handleClose();
    }
  };

  const handleModalClose = (event: Event | React.SyntheticEvent) => {
    if (!isDirty) return;

    event.preventDefault();
    setOpenUnsavedDataWarning(true);
  };

  return (
    <ModalLegacy
      open={open}
      onOpenChange={onOpenChange}
      hideCloseButton
      modalSize={ModalSize.Small}
      onClose={handleModalClose}
    >
      <BoardEditForm
        form={form}
        board={board}
        isDisabled={updateBoard.isPending}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <UnsavedDataAlertDialog
        open={openUnsavedDataWarning}
        onOpenChange={setOpenUnsavedDataWarning}
        onLeaveClick={handleClose}
      />
    </ModalLegacy>
  );
}
