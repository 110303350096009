import { ChangeEvent, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Button from '../../shared/components/buttons/Button';
import SelectList from '../../shared/components/lists/SelectList';
import ModalLegacy, { ModalSize } from '../../shared/components/modal/ModalLegacy';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SearchBar from '../../shared/components/search/SearchBar';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import useDebounce from '../../shared/hooks/useDebounce';
import { ActivityEntityType } from '../../shared/model/IActitityDto';
import { useContactQuery } from '../Contacts/queries/contactQueries';
import { useMyUserProfileQuery, userProfileQueryBase } from '../Profile/queries/userProfileQueries';
import { useMembersWithFilterQuery } from '../Members/queries/MemberQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import { DialogFooter } from '../../shared/components/dialog/Dialog';

export interface IListItem {
  id: string;
  headline: string;
  information: string;
  photoUrl: string;
}

const inMemoryfilterPredicate = (searchTerm: string, item: IListItem) => {
  if (!searchTerm) {
    return true;
  }

  return (
    item.headline.toLocaleLowerCase().indexOf(searchTerm) >= 0 ||
    item.information.toLocaleLowerCase().indexOf(searchTerm) >= 0
  );
};

function AssignToContactSelectList({
  contactId,
  searchTerm,
  selectedItem,
  onSelectItem,
}: {
  contactId: string;
  searchTerm: string;
  selectedItem?: IListItem;
  onSelectItem: (item: IListItem) => void;
}) {
  const myUserProfileQuery = useMyUserProfileQuery();
  const contactQuery = useContactQuery(contactId);
  const [listItems, setListItems] = useState<IListItem[]>();

  useEffect(() => {
    if (!myUserProfileQuery.data || !contactQuery.data) {
      return;
    }

    const cleanedSearchTerm = searchTerm.toLocaleLowerCase().trim();
    const result: IListItem[] = [
      {
        id: myUserProfileQuery.data.id,
        headline: `${myUserProfileQuery.data.firstName} ${myUserProfileQuery.data.lastName}`,
        information: myUserProfileQuery.data.primaryEmailAddress,
        photoUrl: myUserProfileQuery.data.photoUrl,
      },
    ];
    if (contactQuery.data.userId) {
      result.push({
        id: contactQuery.data.userId,
        headline: `${contactQuery.data.displayName}`,
        information: contactQuery.data.emailAddresses[0]?.value ?? '',
        photoUrl: contactQuery.data.photoUrl,
      });
    }
    const filtered = result.filter((item) => inMemoryfilterPredicate(cleanedSearchTerm, item));
    setListItems(filtered);
  }, [myUserProfileQuery.data, contactQuery.data, searchTerm]);

  if (listItems) {
    return (
      <SelectList
        data={listItems}
        isLoading={false}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        itemIdProperty={({ id }) => id}
        itemHeadlineProperty={({ headline }) => headline}
        itemInformationProperty={({ information }) => information}
        itemImgSrcProperty={({ photoUrl }) => photoUrl}
      />
    );
  }

  return null;
}

function AssignToNetworkMemberSelectList({
  networkId,
  searchTerm,
  selectedItem,
  onSelectItem,
}: {
  networkId: string;
  searchTerm: string;
  selectedItem?: IListItem;
  onSelectItem: (item: IListItem) => void;
}) {
  const queryClient = useQueryClient();
  const cleanedSearchTerm = searchTerm.toLocaleLowerCase().trim();
  const networkMembersQuery = useMembersWithFilterQuery(
    ResourceType.Network,
    networkId,
    (member) => member.name.toLocaleLowerCase().indexOf(cleanedSearchTerm) >= 0,
  );

  const [listItems, setListItems] = useState<IListItem[]>();
  useEffect(() => {
    async function GetListItems() {
      if (!networkMembersQuery.data) {
        return;
      }

      const result: IListItem[] = await Promise.all(
        networkMembersQuery.data.map(async (member) => {
          const userProfile = await queryClient.fetchQuery(userProfileQueryBase(member.userId));

          return {
            id: member.userId,
            headline: member.name,
            information: member.name,
            photoUrl: userProfile.photoUrl,
          };
        }),
      );

      setListItems(result);
    }

    GetListItems();
  }, [networkMembersQuery.data]);

  if (listItems) {
    return (
      <SelectList
        data={listItems}
        isLoading={false}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        itemIdProperty={({ id }) => id}
        itemHeadlineProperty={({ headline }) => headline}
        itemInformationProperty={({ information }) => information}
        itemImgSrcProperty={({ photoUrl }) => photoUrl}
      />
    );
  }

  return null;
}

interface IProps {
  parentId: string;
  activityId: string;
  activityEntityType: ActivityEntityType;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  isPending: boolean;
  onAssign: (result: IListItem) => void;
}

export default function ActivityAssignToModal({
  parentId,
  activityId,
  activityEntityType,
  open,
  isPending,
  onOpenChange,
  onAssign,
}: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState<IListItem | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
    setSelectedAssignee(undefined);
  };

  const handleOpenChange = (_open: boolean) => {
    if (!_open) {
      setSelectedAssignee(undefined);
    }
    onOpenChange(_open);
  };

  const handleAssign = () => {
    if (!parentId || !activityId) {
      return;
    }
    if (!selectedAssignee) return;
    onAssign(selectedAssignee);
    handleOpenChange(false);
  };
  return (
    <ModalLegacy open={open} onOpenChange={handleOpenChange} modalSize={ModalSize.Small}>
      <div className="mt-6">
        <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
      </div>
      <ScrollBarWrapper>
        <div className="max-h-96 text-left">
          {activityEntityType === ActivityEntityType.Contact ? (
            <AssignToContactSelectList
              contactId={parentId}
              searchTerm={debouncedSearchTerm}
              selectedItem={selectedAssignee}
              onSelectItem={setSelectedAssignee}
            />
          ) : (
            <AssignToNetworkMemberSelectList
              networkId={parentId}
              searchTerm={debouncedSearchTerm}
              selectedItem={selectedAssignee}
              onSelectItem={setSelectedAssignee}
            />
          )}
        </div>
      </ScrollBarWrapper>
      <DialogFooter>
        <Button
          color={ButtonColors.Blue}
          text="Assign"
          onClick={handleAssign}
          disabled={!selectedAssignee || isPending}
        />
      </DialogFooter>
    </ModalLegacy>
  );
}
