/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'lucide-react';
import SidebarNavNotification from './SidebarNavNotification';
import BorderActiveVertical from '../../indicators/BorderActiveVertical';
import { cn } from '../../../../lib/utils';
import useMainNavigation from './hooks/useMainNavigation';
import useCheckScreenSize from '../../../hooks/useCheckScreenSize';
import ProfileAvatar from '../../avatar/ProfileAvatar';
import { ISidebarNavItem } from '../../../model/ISidebarNavItem';

interface ISidebarLayoutProps {
  children: React.ReactNode;
}

function SidebarLayout({ children }: ISidebarLayoutProps) {
  return (
    <nav
      aria-label="Sidebar"
      className="block overflow-y-auto overflow-x-hidden rounded-tr-lg py-4 pl-4 pt-4 border bg-white"
    >
      <div className="flex flex-col relative h-full w-12 2xl:w-56 space-y-4 rounded-lg ">
        {children}
      </div>
    </nav>
  );
}

interface ISidebarNavItemProps {
  item: ISidebarNavItem;
}

function SidebarNavItemChild({ item }: ISidebarNavItemProps) {
  const { screenSize } = useCheckScreenSize();
  const isLargeScreen = screenSize === 'large';

  const avatarProps = {
    src: item.photoUrl || item?.entityManifest?.icon,
    alt: item.name,
    widthClass: isLargeScreen ? 'w-5' : 'w-8',
    heightClass: isLargeScreen ? 'h-5' : 'h-8',
  };

  const profileAvatar = isLargeScreen ? (
    <div className="flex flex-row space-x-2">
      <ProfileAvatar avatarProps={avatarProps} />
      <span className="truncate">{item.name}</span>
    </div>
  ) : (
    <ProfileAvatar avatarProps={avatarProps} />
  );

  return (
    <Link
      to={item.route}
      key={item.name}
      className={cn(
        screenSize === 'medium'
          ? 'flex items-center justify-center w-8 h-8 opacity-40 grayscale'
          : 'w-auto block text-sm truncate',
        item.current &&
          (screenSize === 'medium'
            ? 'rounded-full ring-offset-2 ring-cyan-500 ring-1 opacity-100 grayscale-0'
            : screenSize === 'large' && 'text-cyan-dark rounded-none font-bold'),
        item.className,
        'text hover:text-cyan-dark hover:opacity-70 hover:grayscale-0',
      )}
    >
      {profileAvatar}
    </Link>
  );
}

function SidebarNavItem({ item }: ISidebarNavItemProps) {
  const [isChildrenExpanded, setIsChildrenExpanded] = useState(true);
  const { screenSize } = useCheckScreenSize();

  return (
    <>
      <div className="flex flex-row">
        <Link
          to={item.route}
          onClick={(e) => {
            if (item.isDisabled) e.preventDefault();
            if (!item.route) setIsChildrenExpanded((current) => !current);
          }}
          className={cn(
            item.current ? 'text-cyan-dark rounded-none font-bold ' : 'text hover:text-cyan-dark',
            'rounded-lg w-full relative',
            item.className && item.className,
          )}
        >
          <span className="flex flex-row items-center min-w-20">
            <SidebarNavNotification
              numberOfNewNotifications={item.numberOfUnreadNotifications || 0}
            />

            {item.current ? (
              <>
                <item.activeIcon className="w-7 h-7 text-cyan" aria-hidden="true" />
                {screenSize === 'large' && (
                  <span className="block font-bold ml-4">{item.name}</span>
                )}
              </>
            ) : (
              <>
                <item.icon className="w-7 h-7 text-cyan-dark" aria-hidden="true" />
                {screenSize === 'large' && (
                  <span className="font-semibold ml-4 block">{item.name}</span>
                )}
              </>
            )}
          </span>
        </Link>

        {item.children && item.children.length > 0 && screenSize === 'large' && (
          <div className="mt-1.5 mr-2">
            {isChildrenExpanded ? (
              <ChevronUp
                className="w-5 h-5 cursor-pointer"
                onClick={() => setIsChildrenExpanded((current) => !current)}
              />
            ) : (
              <ChevronDown
                className="w-5 h-5 cursor-pointer"
                onClick={() => setIsChildrenExpanded((current) => !current)}
              />
            )}
          </div>
        )}

        {item.current && <BorderActiveVertical />}
      </div>

      {item.contentBeforeChildren && <>{item.contentBeforeChildren}</>}

      {isChildrenExpanded && item.children && item.children.length > 0 && (
        <div className="ml-0 2xl:ml-11 space-y-3">
          {item.children.map((child) => (
            <SidebarNavItemChild key={child.name} item={child} />
          ))}
        </div>
      )}
    </>
  );
}

export default function SidebarNav() {
  const sidebarNavItems = useMainNavigation();
  const { screenSize } = useCheckScreenSize();

  if (screenSize === 'small') {
    return <></>;
  }

  return (
    <SidebarLayout>
      {sidebarNavItems
        .filter((item) => !item.isHidden && !item.isMobileOnly)
        .map((item) => (
          <SidebarNavItem key={item.name} item={item} />
        ))}
    </SidebarLayout>
  );
}
