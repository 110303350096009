import { UserPlusIcon } from '@heroicons/react/24/outline';
import ActivityBadge, { IActivityBadgeProps } from './ActivityBadge';

export type IContactActivityBadgeProps = Omit<IActivityBadgeProps, 'icon' | 'classNames'>;

export default function ContactActivityBadge(props: IContactActivityBadgeProps) {
  return (
    <ActivityBadge
      icon={<UserPlusIcon width="20" height="21" stroke="#5B9279" />}
      classNames="bg-green-light"
      {...props}
    />
  );
}
