import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ILayoutActionItem } from '../../../shared/components/layout/pages/types';
import NetworkCreateEditModal from '../NetworkCreateEditModal';

export function useNetworksActions(): ILayoutActionItem[] {
  const navigate = useNavigate();

  const handleCreateNetworkSuccess = (networkId: string) => {
    navigate(networkId);
  };

  return [
    {
      id: 'create',
      text: 'Add Network',
      icon: PlusIcon,
      render: (button) => (
        <NetworkCreateEditModal
          trigger={button}
          triggerAsChild
          onSuccess={handleCreateNetworkSuccess}
        />
      ),
    },
  ];
}
