import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import {
  createBoard,
  deleteBoard,
  getBoards,
  getBoard,
  updateBoard,
} from '../services/projectService';
import { ICreateBoardDto, IUpdateBoardDto } from '../models/IBoardDto';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';

type BoardSearchField = 'Name' | 'Description';

interface IBoardsKeyProps {
  projectId: string;
  id?: string;
  limit?: number;
  searchTerm?: string;
  searchFields?: BoardSearchField[];
}

export const boardKeys = {
  all: ['boards'] as const,
  lists: () => [...boardKeys.all, 'list'] as const,
  paged: (props: IBoardsKeyProps) => [...boardKeys.lists(), 'paged', props] as const,
  list: (props: IBoardsKeyProps) => [...boardKeys.lists(), props] as const,
  details: (projectId: string) => [...boardKeys.all, 'detail', projectId] as const,
  detail: (projectId: string, boardId: string) =>
    [...boardKeys.details(projectId), boardId] as const,
};

const STALE_TIME = 1000 * 60; // 1 minute, boards aren't updated that often.

const boardsBaseQuery = (props: IBoardsKeyProps) => ({
  queryKey: boardKeys.list(props),
  queryFn: () =>
    getBoards(
      props.projectId,
      new QueryParam('limit', props.limit || 250),
      new QueryParam('searchTerm', props.searchTerm),
      ...(props.searchFields || []).map((field) => new QueryParam('searchFields', field)),
    ),
});

export function useBoardsQuery(props: IBoardsKeyProps) {
  return useQuery({
    ...boardsBaseQuery(props),
  });
}

export function useBoardsInfiniteQuery(props: IBoardsKeyProps) {
  const { projectId, limit = 150, searchTerm = '', searchFields = [] } = props;
  return useInfiniteApiQuery({
    queryKey: boardKeys.paged({ projectId, limit, searchTerm, searchFields }),
    queryFn: () =>
      getBoards(
        projectId,
        new QueryParam('limit', limit),
        new QueryParam('searchTerm', searchTerm),
        ...searchFields.map((field) => new QueryParam('searchFields', field)),
      ),
  });
}

export const boardBaseQuery = (projectId: string, boardId: string) => ({
  queryKey: boardKeys.detail(projectId, boardId),
  queryFn: () => getBoard(projectId, boardId),
  staleTime: STALE_TIME,
});

export function useBoardQuery(projectId?: string, boardId?: string) {
  return useQuery({
    ...boardBaseQuery(projectId as string, boardId as string),
    enabled: !!projectId && !!boardId,
  });
}

interface ICreateBoardProps {
  projectId: string;
  board: ICreateBoardDto;
}

export function useCreateBoardMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, board }: ICreateBoardProps) =>
      createBoard(projectId, board),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: boardKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}

export interface IUpdateBoardProps {
  board: IUpdateBoardDto;
  boardId: string;
  projectId: string;
}

export function useUpdateBoardMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, boardId, board }: IUpdateBoardProps) =>
      updateBoard(projectId, boardId, board).then((response) => response.data),
    onSuccess: (_, board) => {
      queryClient.invalidateQueries({ queryKey: boardKeys.detail(board.projectId, board.boardId) });
      queryClient.invalidateQueries({ queryKey: boardKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}

export function useDeleteBoardMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, boardId }: { projectId: string; boardId: string }) =>
      deleteBoard(projectId, boardId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: boardKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
