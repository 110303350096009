import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import React from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { useBoardPhotoMutation } from '../Projects/queries/boardPhotoQueries';
import CurrencySelector from '../../shared/components/selectors/CurrencySelector';
import { IPipelineDto, IPipelineUpdateDto } from './models/IPipelineDto';
import { IBoardDto } from '../Projects/models/IBoardDto';

const defaultValues: IPipelineUpdateDto = {
  id: '',
  name: '',
  description: '',
  currencyIsoCode: '',
};

type UsePipelineUpdateFormProps = {
  values?: IPipelineDto;
};

export function usePipelineEditForm({ values }: UsePipelineUpdateFormProps = {}) {
  const valuesToUse = values || defaultValues;
  return useForm<IPipelineUpdateDto>({
    mode: 'onChange',
    defaultValues: {
      id: valuesToUse.id,
      name: valuesToUse.name,
      description: valuesToUse.description,
      currencyIsoCode: valuesToUse.currencyIsoCode,
    },
    values: {
      id: valuesToUse.id,
      name: valuesToUse.name,
      description: valuesToUse.description,
      currencyIsoCode: valuesToUse.currencyIsoCode,
    },
  });
}

type Props = {
  form: UseFormReturn<IPipelineUpdateDto>;
  submitButtonText?: string;
  cancelButtonText?: string;
  board: IBoardDto;
  isDisabled?: boolean;
  onSubmit: SubmitHandler<IPipelineUpdateDto>;
  onCancel: (event: React.BaseSyntheticEvent) => void;
};

export function PipelineEditForm({
  form,
  submitButtonText,
  cancelButtonText = 'Cancel',
  board,
  isDisabled = false,
  onSubmit,
  onCancel,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = form;

  const _onSubmit = (_entity: IPipelineUpdateDto) => {
    if (!isValid || !isDirty) return;

    onSubmit(_entity);
    reset();
  };
  const manifest = useEntityManifest(ResourceType.Pipeline);
  const photoMutations = useBoardPhotoMutation(); // Board is owning the photo information
  const isUpdate = true;
  const _submitButtonText = submitButtonText || (isUpdate ? 'Save' : 'Create');
  return (
    <form onSubmit={handleSubmit(_onSubmit)} className="text-left">
      <SpacingContainer>
        {isUpdate && (
          <SpacingContainerItem>
            <ProfileAvatarForm
              url={board.photoUrl || manifest.icon}
              altText={board.displayName}
              onUpload={(file) =>
                photoMutations.mutate({ projectId: board.projectId, boardId: board.id, file })
              }
              onRemove={() =>
                photoMutations.mutate({ projectId: board.projectId, boardId: board.id })
              }
              isPending={photoMutations.isPending}
            />
          </SpacingContainerItem>
        )}
        <SpacingContainerItem>
          <TextFieldInput
            labelText="Name"
            name="name"
            type="text"
            register={register}
            errors={errors}
            errorMessage="Must enter a name"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <TextAreaInput
            labelText="Description"
            name="description"
            rows={3}
            register={register}
            errors={errors}
            errorMessage="Must enter a description"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <CurrencySelector register={register} name="currencyIsoCode" />
        </SpacingContainerItem>
        <ButtonGroup>
          <Button
            color={ButtonColors.White}
            text={cancelButtonText}
            disabled={isDisabled}
            onClick={onCancel}
          />
          <SubmitButton text={_submitButtonText} isDisabled={!isValid || !isDirty || isDisabled} />
        </ButtonGroup>
      </SpacingContainer>
    </form>
  );
}
