import { IMessageFileDto, IMessageMetaDataDto } from '../../shared/model/IMessageDto';
import MessageImageAttachment from './MessageImageAttachment';
import MessageFileAttachment from './MessageFileAttachment';
import { getFilesByType } from '../../shared/utils/fileUtils';

interface IProps {
  data?: IMessageMetaDataDto;
  onFileClick: (file: IMessageFileDto) => void;
  conversationId: string;
  isOutgoing: boolean;
}

export default function MessageMetaData({ data, onFileClick, conversationId, isOutgoing }: IProps) {
  if (!data || !data.files || data.files.length === 0) return null;

  const groupedFiles = getFilesByType(data.files);

  return (
    <>
      <div className={`flex flex-wrap ${isOutgoing ? 'justify-end' : ''} gap-1 pt-2`}>
        {groupedFiles.images.map((image) => (
          <MessageImageAttachment
            key={image.fileId}
            file={image}
            onClick={onFileClick}
            conversationId={conversationId}
          />
        ))}
      </div>
      <div className="flex flex-wrap gap-1 pt-2">
        {groupedFiles.others.map((file) => (
          <MessageFileAttachment key={file.fileId} file={file} onClick={onFileClick} />
        ))}
      </div>
    </>
  );
}
