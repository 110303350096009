import { ClipboardIcon } from '@heroicons/react/24/outline';
import ActivityBadge, { IActivityBadgeProps } from './ActivityBadge';

export type ITaskActivityBadgeProps = Omit<IActivityBadgeProps, 'icon' | 'classNames'>;

export default function TaskActivityBadge(props: ITaskActivityBadgeProps) {
  return (
    <ActivityBadge
      icon={<ClipboardIcon width="20" height="21" stroke="#D527B7" />}
      classNames="bg-pink-200"
      {...props}
    />
  );
}
