import { useMemo } from 'react';
import { useListShareContactRequestsQuery } from '../Request/queries/shareContactRequestQueries';
import { IContactDto } from './models/IContactDto';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { TagColor } from '../../shared/components/tags/Tag';
import { ShareContactRequestStatuses } from '../../shared/model/IShareContactRequestDto';
import {
  mergeConnectionsAndShareContactRequests,
} from './mergecontacts/MergeContactsAndShareRequests';
import SelectListMultiSelect from '../../shared/components/lists/SelectListMultiSelect';
import { useConnectionsQuery } from './queries/connectionQueries';

const statusColorMap: Record<ShareContactRequestStatuses, TagColor> = {
  Accepted: 'green',
  Pending: 'yellow',
  Rejected: 'red',
};

interface IProps {
  contact: IContactDto;
  status?: ShareContactRequestStatuses | 'All';
  searchTerm?: string;
}

export function ContactSharedContactsKpiList({ contact, status = 'All', searchTerm }: IProps) {
  const connectionsQuery = useConnectionsQuery({ searchTerm, excludeContactIds: [contact.id] });

  const shareContactRequestsQuery = useListShareContactRequestsQuery({
    sharedContactId: contact.id,
    searchType: 'Sent',
    status: status === 'All' ? ['Accepted', 'Pending', 'Rejected'] : [status],
  });
  const connectionsWithShareInfo = useMemo(() => {
    if (!connectionsQuery.isSuccess || !shareContactRequestsQuery.isSuccess) {
      return [];
    }
    const mergedContacts = mergeConnectionsAndShareContactRequests(
      connectionsQuery.data,
      shareContactRequestsQuery.data,
    );

    const filteredContacts = mergedContacts.filter((_contact) => !!_contact.shareInformation);

    return filteredContacts;
  }, [
    connectionsQuery.data,
    connectionsQuery.isSuccess,
    shareContactRequestsQuery.data,
    shareContactRequestsQuery.isSuccess,
  ]);
  const shareContactQuery = shareContactRequestsQuery.isPending;

  return (
    <div className="flex flex-col h-full ">
      <ScrollBarWrapper>
        <SelectListMultiSelect
          key={searchTerm}
          data={connectionsWithShareInfo}
          isLoading={shareContactQuery}
          itemIdProperty={({ id }) => id}
          itemHeadlineProperty={({ displayName }) => `${displayName}`}
          itemInformationProperty={({ emailAddresses }) => emailAddresses[0]?.value}
          itemImgSrcProperty={({ photoUrl }) => photoUrl}
          itemTagsProperty={({ shareInformation }) =>
            shareInformation
              ? [
                  {
                    title: shareInformation.status,
                    color: statusColorMap[shareInformation.status],
                  },
                ]
              : []
          }
        />
      </ScrollBarWrapper>
    </div>
  );
}
