import { DocumentIcon, LinkIcon } from '@heroicons/react/24/outline';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ConversationAttachmentType,
} from '../../model/IConversationAttachmentDto';
import { useAttachmentsQuery } from './queries/attachmentQueries';
import ListEmptyState from '../lists/ListEmptyState';
import ScrollBarWrapper from '../scrolling/ScrollBarWrapper';
import { CardList } from '../cards/CardList';
import { Filler } from '../layout/Filler';
import Text from '../text/Text';
import { Card } from '../cards/Card';
import { ErrorComponent } from '../error-boundary/ErrorComponent';
import { AttachmentActionMenu } from './AttachmentActionMenu';

interface IProps {
  conversationId: string;
  searchTerm?: string;
}

export default function AttachmentList({ conversationId, searchTerm }: IProps) {
  const attachmentsQuery = useAttachmentsQuery({ conversationId, searchTerm });

  const absoluteUrl = (url: string) => {
    if (/^http[s]?:\/\//gi.test(url)) {
      return url;
    }

    return `http://${url}`;
  };

  if (attachmentsQuery.isLoading) {
    return null;
  }

  if (attachmentsQuery.isSuccess) {
    const flattenedAttachments = attachmentsQuery.data.pages.flat();

    return (
      <>
        {!flattenedAttachments.length && (
          <ListEmptyState>
            When you send files or links in the chat they will end up here.
          </ListEmptyState>
        )}
        <ScrollBarWrapper id="attachmentsScroll">
          <InfiniteScroll
            scrollableTarget="attachmentsScroll"
            next={() => attachmentsQuery.fetchNextPage()}
            hasMore={attachmentsQuery.hasNextPage || false}
            loader={
              <div className="py-4 text-center">
                <Text as="p" size="small">
                  Loading...
                </Text>
              </div>
            }
            dataLength={flattenedAttachments.length}
            style={{ overflow: '' }} // This fixes overflow problem with context menu.
          >
            <CardList>
              {flattenedAttachments.map((attachment) => (
                <Card className="flex space-x-2" key={attachment.id}>
                  {attachment.type === ConversationAttachmentType.Link ? (
                    <LinkIcon className="flex-none w-5 h-5" />
                  ) : (
                    <DocumentIcon className="flex-none w-5 h-5" />
                  )}

                  <Text as="span" size="small" truncate>
                    {attachment.type === ConversationAttachmentType.Link ? (
                      <a href={absoluteUrl(attachment.location)} rel="noreferrer" target="_blank">
                        {attachment.name}
                      </a>
                    ) : (
                      attachment.name
                    )}
                  </Text>

                  <Filler />
                  <AttachmentActionMenu attachment={attachment} />
                </Card>
              ))}
            </CardList>
          </InfiniteScroll>
        </ScrollBarWrapper>
      </>
    );
  }

  return <ErrorComponent queryResults={[attachmentsQuery]} />;
}
