import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import {
  createPipeline,
  createPipelineBudget,
  deletePipeline,
  getAllPipelines,
  getPipeline,
  updatePipeline,
  updatePipelineBudget,
} from '../services/pipelineService';
import {
  IPipelineCreateBudgetDto as ICreatePipelineBudgetDto,
  IPipelineCreateDto,
  IPipelineUpdateDto,
} from '../models/IPipelineDto';
import { pipelineKpiKeys } from './pipelineKpiQueries';

export const pipelineKeys = {
  all: ['pipelines'] as const,
  list: () => [...pipelineKeys.all, 'list'] as const,
  details: () => [...pipelineKeys.all, 'detail'] as const,
  detail: (id: string) => [...pipelineKeys.details(), id] as const,
};

const STALE_TIME = 1000 * 60; // 1 minute, pipelines aren't updated that often.

const pipelinesBaseQuery = () => ({
  queryKey: pipelineKeys.list(),
  queryFn: () => getAllPipelines(),
});

export function usePipelinesQuery() {
  return useQuery({
    ...pipelinesBaseQuery(),
  });
}

export const pipelineBaseQuery = (id: string) => ({
  queryKey: pipelineKeys.detail(id),
  queryFn: () => getPipeline(id),
  staleTime: STALE_TIME,
});

export function usePipelineQuery(id?: string) {
  return useQuery({
    ...pipelineBaseQuery(id as string),
    enabled: !!id,
  });
}

interface ICreatePipelineProps {
  pipeline: IPipelineCreateDto;
}

export function useCreatePipelineMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ pipeline }: ICreatePipelineProps) => createPipeline(pipeline),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: pipelineKeys.list() });
    },
    onError: useDispatchApiError(),
  });
}

export interface IUpdatePipelineProps {
  pipeline: IPipelineUpdateDto;
}

export function useUpdatePipelineMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ pipeline }: IUpdatePipelineProps) =>
      updatePipeline(pipeline.id, pipeline).then((response) => response.data),
    onSuccess: (_, { pipeline }) => {
      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipeline.id) });
      queryClient.invalidateQueries({ queryKey: pipelineKeys.list() });
    },
    onError: useDispatchApiError(),
  });
}

export function useDeletePipelineMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ pipelineId }: { projectId: string, boardId: string, pipelineId: string }) =>
      deletePipeline(pipelineId).then((response) => response.data),
    onSuccess: ({ pipelineId }) => {
      queryClient.invalidateQueries({ queryKey: pipelineKeys.list() });
      queryClient.invalidateQueries({
        queryKey: pipelineKpiKeys.detail({ pipelineId, periodStartTime: new Date() }),
      });
    },
    onError: useDispatchApiError(),
  });
}

interface ICreatePipelineBudgetProps {
  pipelineBudget: ICreatePipelineBudgetDto;
  pipelineId: string;
}

export function useCreatePipelineBudgetMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ pipelineId, pipelineBudget }: ICreatePipelineBudgetProps) =>
      createPipelineBudget(pipelineId, pipelineBudget).then((response) => response.data),
    onSuccess: (_, { pipelineId }) => {
      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipelineId) });
      queryClient.invalidateQueries({
        queryKey: pipelineKpiKeys.detail({ pipelineId }),
      });
    },
    onError: useDispatchApiError(),
  });
}

interface IUpdatePipelineBudgetProps {
  pipelineId: string;
  budgetId: string;
  pipelineBudget: ICreatePipelineBudgetDto;
}

export function useUpdatePipelineBudgetMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ pipelineId, budgetId, pipelineBudget }: IUpdatePipelineBudgetProps) =>
      updatePipelineBudget(pipelineId, budgetId, pipelineBudget).then((response) => response.data),
    onSuccess: (_, { pipelineId }) => {
      queryClient.invalidateQueries({ queryKey: pipelineKeys.detail(pipelineId) });
      queryClient.invalidateQueries({
        queryKey: pipelineKpiKeys.detail({ pipelineId }),
      });
    },
    onError: useDispatchApiError(),
  });
}
