import { IInvitationDto } from '../../shared/model/IInvitationDto';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { useResendInvitationMutation } from './queries/invitationQueries';
import { RequestItems } from './RequestItems';

interface IProps {
  invitations: IInvitationDto[];
}

export default function Invitations({ invitations }: IProps) {
  const resendInvitationMutation = useResendInvitationMutation();

  function handleResendInvitation(invitationId: string) {
    resendInvitationMutation.mutate({ id: invitationId });
  }

  return (
    <RequestItems
      requests={invitations}
      title={(request) => request.emailAddress}
      text={(request) => (
        <>
          Invitation to Yoin sent <TimeAgo date={request.createdTime} /> ago.
        </>
      )}
      actions={(request) => [
        {
          text: 'Re-Send',
          onClick: () => handleResendInvitation(request.id),
          color: ButtonColors.Blue,
          disabled: !request.canBeResent || resendInvitationMutation.isPending,
        },
      ]}
    />
  );
}
