import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { UnsavedDataAlertDialog } from '../../shared/components/alert-dialog/UnsavedDataAlertDialog';
import ModalLegacy, { ModalSize } from '../../shared/components/modal/ModalLegacy';
import { PipelineEditForm, usePipelineEditForm } from './PipelineEditForm';
import { IBoardDto } from '../Projects/models/IBoardDto';
import { IPipelineDetailDto, IPipelineUpdateDto } from './models/IPipelineDto';
import { useUpdateBoardMutation } from '../Projects/queries/boardQueries';
import { useUpdatePipelineMutation } from './queries/pipelineQueries';

interface IProps {
  board: IBoardDto;
  pipeline: IPipelineDetailDto;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: (projectId: string, boardId: string, pipelineId: string) => void;
}

export default function PipelineEditModal({
  board,
  pipeline,
  open,
  onOpenChange,
  onSuccess,
}: IProps) {
  const [openUnsavedDataWarning, setOpenUnsavedDataWarning] = useState(false);
  const form = usePipelineEditForm({
    values: { ...pipeline, name: board.name, description: board.description },
  });
  // Board is owning the edit name, description
  const updateBoardMutations = useUpdateBoardMutation();
  const updatePipelineMutations = useUpdatePipelineMutation();
  const title = pipeline ? 'Update pipeline' : 'Create new pipeline';
  const {
    reset,
    formState: { isDirty },
  } = form;

  const handleSubmit: SubmitHandler<IPipelineUpdateDto> = (pipelineToSubmit) => {
    updateBoardMutations.mutate(
      {
        projectId: board.projectId,
        boardId: board.id,
        board: { name: pipelineToSubmit.name, description: pipelineToSubmit.description },
      },
      {
        onSuccess: () => {
          updatePipelineMutations.mutate({
            pipeline: {
              id: pipelineToSubmit.id,
              name: pipelineToSubmit.name, // Should not be needed!
              description: pipelineToSubmit.description, // Should not be needed!
              currencyIsoCode: pipelineToSubmit.currencyIsoCode,
            },
          });

          if (onSuccess) {
            onSuccess(board.projectId, board.id, pipelineToSubmit.id);
          }
          onOpenChange(false);
        },
      },
    );
  };

  const handleClose = () => {
    reset();
    onOpenChange(false);
  };

  const handleCancel = (event: React.BaseSyntheticEvent) => {
    if (isDirty) {
      event.preventDefault();
      setOpenUnsavedDataWarning(true);
    } else {
      handleClose();
    }
  };

  const handleModalClose = (event: Event | React.SyntheticEvent) => {
    if (!isDirty) return;

    event.preventDefault();
    setOpenUnsavedDataWarning(true);
  };

  return (
    <ModalLegacy
      open={open}
      onOpenChange={onOpenChange}
      hideCloseButton
      modalSize={ModalSize.Small}
      onClose={handleModalClose}
      title={title}
    >
      <PipelineEditForm
        form={form}
        board={board}
        isDisabled={updateBoardMutations.isPending}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <UnsavedDataAlertDialog
        open={openUnsavedDataWarning}
        onOpenChange={setOpenUnsavedDataWarning}
        onLeaveClick={handleClose}
      />
    </ModalLegacy>
  );
}
