import {
  ICreatePipelineItemDto,
  IMovePipelineItemDto,
  IPipelineItemDto,
  IUpdatePipelineItemDto,
} from '../models/IPipelineItemDto';
import apiClient from '../../../shared/utils/apiClient';

export const getPipelineItems = (pipelineId: string, stageId: string) =>
  apiClient
    .get(`v1/pipelines/${pipelineId}/stages/${stageId}/items`)
    .then<IPipelineItemDto[]>((response) => response.data.data);

export const getPipelineItem = (pipelineId: string, stageId: string, itemId: string) =>
  apiClient
    .get(`v1/pipelines/${pipelineId}/stages/${stageId}/items/${itemId}`)
    .then<IPipelineItemDto>((response) => response.data);

export const createPipelineItem = (
  piplineId: string,
  stageId: string,
  item: ICreatePipelineItemDto,
) => apiClient.post(`v1/pipelines/${piplineId}/stages/${stageId}/items`, item);

export const updatePipelineItem = (
  pipelineId: string,
  stageId: string,
  itemId: string,
  item: IUpdatePipelineItemDto,
) => apiClient.patch(`v1/pipelines/${pipelineId}/stages/${stageId}/items/${itemId}`, item);

export const movePipelineItem = (
  pipelineId: string,
  stageId: string,
  itemId: string,
  item: IMovePipelineItemDto,
) => apiClient.put(`v1/pipelines/${pipelineId}/stages/${stageId}/items/${itemId}/move`, item);

export const deletePipelineItem = (pipelineId: string, stageId: string, itemId: string) =>
  apiClient.delete(`v1/pipelines/${pipelineId}/stages/${stageId}/items/${itemId}`);
