import { usePipelineQuery } from './queries/pipelineQueries';
import { usePipelinePrivilegesQuery } from './queries/pipelinePriviligesQueries';
import { IPipelinePrivileges } from './models/IPipelineDto';
import MemberList from '../Members/MemberList';
import { ResourceType } from '../../shared/model/ResourceType';

export interface PipelineMembersProps {
  projectId: string;
  boardId: string;
  pipelineId: string;
}

export default function PipelineMembers({ projectId, boardId, pipelineId }: PipelineMembersProps) {
  const privilegesQuery = usePipelinePrivilegesQuery(pipelineId);
  const privileges = privilegesQuery.data as IPipelinePrivileges;
  const pipelineQuery = usePipelineQuery(pipelineId);
  const pipeline = pipelineQuery.data;

  if (!pipeline) {
    return <></>;
  }

  return (
    <MemberList
      privileges={privileges}
      tenantId={pipeline.tenantId}
      resourceIds={[projectId, boardId]}
      type={ResourceType.Board}
      resolveConfirmText={(member) =>
        `Are you sure that you want to remove ${member?.name} from the board?`
      }
      resolveHelpText={() =>
        'Invite other people to add and make changes to your board and stages.'
      }
    />
  );
}
