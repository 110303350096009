import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Fragment } from 'react';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ContactUserProfileLink } from '../Contacts/ContactUserProfileLink';
import Text from '../../shared/components/text/Text';
import { UserProfileDto } from '../Profile/models/UserProfile';
import { useUserProfileQuery } from '../Profile/queries/userProfileQueries';
import { RequestLoader } from './RequestLoader';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';

interface IRequest {
  id: string;
  targetUserId?: string;
}

type ResourceQueryFn<TRequest extends IRequest, TResource> = (
  request: TRequest,
) => UseQueryOptions<TResource>;

type IRequestAction = {
  text: string;
  onClick: () => void;
  color?: ButtonColors;
  disabled?: boolean;
};

interface IProps<TRequest extends IRequest, TResource> {
  requests: TRequest[];
  resourceQuery?: ResourceQueryFn<TRequest, TResource>;
  title?: (request: TRequest, user?: UserProfileDto) => string;
  text: (request: TRequest, resource?: TResource) => React.ReactNode;
  actions?: (request: TRequest) => IRequestAction[];
}

type RequestDataRenderFn<TRequest extends IRequest, TResource> = (
  request: TRequest,
  resource: TResource,
  user?: UserProfileDto,
) => React.ReactElement;

interface IRequestDataProps<TRequest extends IRequest, TResource> {
  request: TRequest;
  render: RequestDataRenderFn<TRequest, TResource>;
}

interface IRequestDataWithResourceProps<TRequest extends IRequest, TResource>
  extends IRequestDataProps<TRequest, TResource> {
  resourceQuery: ResourceQueryFn<TRequest, TResource>;
}

interface IItemProps {
  body: React.ReactNode;
  buttons?: React.ReactNode;
}

function RequestItem({ body, buttons }: IItemProps) {
  return (
    <div className="min-h-24 flex flex-col p-2 bg-white border-slate-300 border-b">
      <div className="flex">{body}</div>
      {buttons && (
        <div className="py-1 pr-1">
          <ButtonGroup>{buttons}</ButtonGroup>
        </div>
      )}
    </div>
  );
}

interface IBodyProps {
  title: string;
  photoUrl?: string;
  linkToUserId?: string;
  children: React.ReactNode;
}

function RequestItemBody({ title, photoUrl, linkToUserId, children }: IBodyProps) {
  return (
    <>
      <div className="mr-4 sm:mr-1 sm:p-1">
        {linkToUserId ? (
          <ContactUserProfileLink userId={linkToUserId}>
            <ProfileAvatar
              avatarProps={{
                src: photoUrl || '',
                widthClass: 'w-10',
                heightClass: 'h-10',
              }}
            />
          </ContactUserProfileLink>
        ) : (
          <ProfileAvatar
            avatarProps={{
              src: photoUrl || '',
              widthClass: 'w-10',
              heightClass: 'h-10',
            }}
          />
        )}
      </div>
      <div className="flex flex-col sm:p-1 overflow-hidden">
        {linkToUserId ? (
          <ContactUserProfileLink userId={linkToUserId}>
            <Text as="span" size="small" weight="medium" leading="relaxed">
              {title}
            </Text>
          </ContactUserProfileLink>
        ) : (
          <Text as="span" size="small" weight="medium" leading="relaxed">
            {title}
          </Text>
        )}
        <Text as="p" leading="snug" size="small" weight="normal" breakMode="words">
          {children}
        </Text>
      </div>
    </>
  );
}

function RequestData<TRequest extends IRequest>({
  request,
  render: renderFunction,
}: IRequestDataProps<TRequest, undefined>) {
  const userProfileQuery = useUserProfileQuery(request.targetUserId);

  if (request.targetUserId && userProfileQuery.isPending) {
    return <RequestLoader />;
  }

  if (!request.targetUserId || userProfileQuery.isSuccess) {
    return renderFunction(request, undefined, userProfileQuery.data);
  }

  return null;
}

function RequestDataWithResource<TRequest extends IRequest, TResource>({
  request,
  render: renderFunction,
  resourceQuery: resourceQueryOptionsFn,
}: IRequestDataWithResourceProps<TRequest, TResource>) {
  const userProfileQuery = useUserProfileQuery(request.targetUserId);
  const resourceQuery = useQuery(resourceQueryOptionsFn(request));

  if ((request.targetUserId && userProfileQuery.isPending) || resourceQuery.isPending) {
    return <RequestLoader />;
  }

  if ((!request.targetUserId || userProfileQuery.isSuccess) && resourceQuery.isSuccess) {
    return renderFunction(request, resourceQuery.data, userProfileQuery.data);
  }

  return <div />;
}

export function RequestItems<TRequest extends IRequest, TResource>({
  requests,
  resourceQuery,
  title: titleProp,
  text,
  actions,
}: IProps<TRequest, TResource>) {
  const render: RequestDataRenderFn<TRequest, TResource | undefined> = (
    request,
    resource,
    user,
  ) => {
    let title = '';
    if (titleProp) {
      title = titleProp(request, user);
    } else if (user) {
      title = `${user.firstName} ${user.lastName}`;
    }

    return (
      <RequestItem
        body={
          <RequestItemBody
            title={title}
            linkToUserId={user ? user.id : ''}
            photoUrl={user ? user.photoUrl : ''}
          >
            {text(request, resource)}
          </RequestItemBody>
        }
        buttons={
          actions && (
            <>
              {actions(request).map(({ text: _text, color, disabled, onClick }) => (
                <Button
                  key={_text}
                  text={_text}
                  className="!h-7 rounded-lg"
                  color={color || ButtonColors.White}
                  disabled={disabled}
                  onClick={onClick}
                />
              ))}
            </>
          )
        }
      />
    );
  };

  return (
    <>
      {requests.map((request) => (
        <Fragment key={request.id}>
          {resourceQuery ? (
            <RequestDataWithResource
              request={request}
              resourceQuery={resourceQuery}
              render={render}
            />
          ) : (
            <RequestData request={request} render={render} />
          )}
        </Fragment>
      ))}
    </>
  );
}
