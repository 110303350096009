import { IActivityItem } from '../../activity/IActivityItem';
import ActivityList from '../../activity/ActivityList';

export interface ActivityRowCellProps {
  items: IActivityItem[];
}

export default function ActivityRowCell({ items }: ActivityRowCellProps) {
  return <ActivityList items={items} />;
}
