import { QueryKey } from '@tanstack/react-query';
import {
  NotificationArea,
  NotificationResponseType,
  NotificationType,
} from '../constants/NotificationConstants';
import { respond } from '../services/notificationService';
import { ResourceIds } from '../hooks/useEntityManifest';

export class Action {
  public text: string;

  public type: NotificationResponseType;

  public defferedAction: () => Promise<unknown>;

  public invalidateQueryKeys: Array<QueryKey>;

  constructor(
    text: string,
    type: NotificationResponseType,
    defferedAction: () => Promise<unknown>,
    invalidateQueryKeys?: Array<QueryKey>,
  ) {
    this.text = text;
    this.type = type;
    this.defferedAction = defferedAction;
    this.invalidateQueryKeys = invalidateQueryKeys || [];
  }

  execute(id: string): Promise<unknown> {
    return Promise.all([respond(id, { response: this.type }), this.defferedAction()]).then(
      (result) => result,
    );
  }
}

export enum NotificationResourceType {
  Profile,
  Contact,
  Network,
  Conversation,
  Pipeline,
  Organization,
  Project,
  Board
}

export interface IResourceLink {
  text?: string;
  url: string;
  ids: ResourceIds;
  type: NotificationResourceType;
}

export interface INotificationDto {
  id: string;
  targetUserId: string;
  type: NotificationType;
  area: NotificationArea;
  isRead: boolean;
  readTime: Date;
  respondedTime: Date;
  response: NotificationResponseType;
  reason?: string;
  isResponded: boolean;
  createdTime: Date;
  payload: any;
  // below are added for UI
  message: string;
  acceptMessage: string;
  actions?: Action[];
  resourceLink?: IResourceLink;
}
