import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { useInitialDataPropsFromQuery } from '../../../shared/hooks/queryHooks';
import {
  getConversationById,
  getConversations,
} from '../../../shared/services/conversationService';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { getInitialDataPropsFromQuery } from '../../../shared/utils/queryClientUtils';
import IParticipantDto from '../../../shared/model/IParticipantDto';
import { useMyUserId } from '../../../shared/auth/accountHooks';
import { ResourceType } from '../../../shared/model/ResourceType';
import { IConversationDto } from '../../../shared/model/IConversationDto';

interface UseConversationQueryProps {
  resourceId?: string;
  resourceType?: ResourceType;
}

export const conversationKeys = {
  all: ['conversations'] as const,
  lists: () => [...conversationKeys.all, 'list'] as const,
  list: (props: UseConversationQueryProps) => [...conversationKeys.lists(), props] as const,
  details: () => [...conversationKeys.all, 'detail'] as const,
  detail: (id?: string) => [...conversationKeys.details(), id] as const,
};

export const conversationQuery = (queryClient: QueryClient, conversationId?: string) =>
  ({
    queryKey: conversationKeys.detail(conversationId),
    queryFn: () =>
      typeof conversationId === 'undefined'
        ? Promise.reject()
        : getConversationById(conversationId),
    enabled: !!conversationId,
    ...getInitialDataPropsFromQuery(
      queryClient,
      conversationKeys.lists(),
      (conversation: IConversationDto) => conversation.id === conversationId,
    ),
  } as const);

export function useConversationQuery(conversationId?: string) {
  const queryClient = useQueryClient();
  return useQuery(conversationQuery(queryClient, conversationId));
}

export function useConversationParticipantsForMentionQuery<TData>(
  conversationId: string,
  searchTerm: string | null,
  transformFunction: (participant: IParticipantDto) => TData,
) {
  const queryClient = useQueryClient();
  const myUserId = useMyUserId();
  const lowerCaseSearchTerm = searchTerm?.toLowerCase();
  return useQuery({
    ...conversationQuery(queryClient, conversationId),
    select: (conversation) =>
      conversation.participants
        .filter(
          (participant) =>
            lowerCaseSearchTerm !== undefined &&
            participant.userId !== myUserId &&
            participant.displayName
              .toLowerCase()
              .split(' ')
              .some((s: string) => s.startsWith(lowerCaseSearchTerm)),
        )
        .slice(0, 9)
        .map(transformFunction),
    enabled: searchTerm !== null && searchTerm !== undefined,
  });
}

export function useResourceConversationQuery(
  resourceId: string | undefined,
  resourceType: ResourceType,
) {
  return useQuery({
    queryKey: conversationKeys.list({ resourceId, resourceType }),
    queryFn: () =>
      getConversations(
        new QueryParam('resourceId', resourceId),
        new QueryParam('resourceType', resourceType),
      ).then((pagedResult) =>
        pagedResult.data.length > 0
          ? pagedResult.data[0]
          : Promise.reject(new Error('Network conversation not found.')),
      ),
    enabled: !!resourceId,
    ...useInitialDataPropsFromQuery<IConversationDto>(
      conversationKeys.lists(),
      (conversation) => conversation.resourceId === resourceId,
    ),
  });
}
