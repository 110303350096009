import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import {
  createInvitation,
  getInvitationsRequest,
  resendInvitation,
} from '../../../shared/services/invitationService';
import { InvitationStatus } from '../../../shared/model/IInvitationDto';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { AppContext } from '../../../shared/context/context';
import { createToast } from '../../../shared/services/toastService';
import { ToastType } from '../../../shared/components/toasts/constants/ToastTypes';
import { ResourceType } from '../../../shared/model/ResourceType';

interface IUseInvitationProps {
  status?: InvitationStatus;
}

const invitationKeys = {
  all: ['invitation'] as const,
  lists: () => [...invitationKeys.all, 'list'] as const,
  list: (props: IUseInvitationProps) => [...invitationKeys.lists(), props] as const,
};

interface ICreateInvitationProps {
  email: string;
  resourceId?: string;
  resourceType?: ResourceType;
}

export function useCreateInvitationMutation() {
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ email, resourceId, resourceType }: ICreateInvitationProps) =>
      createInvitation(email, resourceId, resourceType),
    onSuccess: (_, { email }) => {
      queryClient.invalidateQueries({ queryKey: invitationKeys.lists() });
      dispatch(
        createToast('Success!', ToastType.Success, `An invitation has been sent to ${email}`),
      );
    },
    onError: useDispatchApiError(),
  });
}

export function useInvitationsQuery({ status }: IUseInvitationProps) {
  return useQuery({
    queryKey: invitationKeys.list({ status }),
    queryFn: () => getInvitationsRequest(new QueryParam('status', status)),
  });
}

interface IResendInvitationProps {
  id: string;
}

export function useResendInvitationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IResendInvitationProps) => resendInvitation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: invitationKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
