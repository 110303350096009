import { formatDistanceToNow } from 'date-fns';
import { IConversationDto } from '../model/IConversationDto';
import { IMessageDto, IUpdateMessageDto } from '../model/IMessageDto';
import { IPagedResult } from '../model/IPagedResult';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';
import IParticipantDto from '../model/IParticipantDto';
import getTime from '../utils/dateUtils';
import { IsMeByUserIdFunc } from '../auth/accountHooks';

export enum EntityType {
  contact,
  network,
}

export const getConversationMessage = (conversationId: string, messageId: string) =>
  apiClient
    .get<IMessageDto>(`v1/conversations/${conversationId}/messages/${messageId}`)
    .then((response) => response.data);

export const getConversationMessages = (conversationId: string, ...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();

  return apiClient
    .get<IPagedResult<IMessageDto>>(`v1/conversations/${conversationId}/messages${queryString}`)
    .then((response) => response.data);
};

export const createConversationMessage = (conversationId: string, message: IMessageDto) =>
  apiClient
    .post(`v1/conversations/${conversationId}/messages`, message)
    .then<IMessageDto>((response) => response.data);

export const updateConversationMessage = (
  conversationId: string,
  messageId: string,
  message: IUpdateMessageDto,
) =>
  apiClient
    .patch(`v1/conversations/${conversationId}/messages/${messageId}`, message)
    .then<IMessageDto>((response) => response.data);

export const getConversationById = (id: string) =>
  apiClient.get(`v1/conversations/${id}`).then<IConversationDto>((response) => response.data);

export const getConversations = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();

  return apiClient
    .get<IPagedResult<IConversationDto>>(`v1/conversations${queryString}`)
    .then((response) => response.data);
};

export const deleteMessage = (conversationId: string, messageId: string) =>
  apiClient.delete(`v1/conversations/${conversationId}/messages/${messageId}`);

export const setConversationRead = (conversationId: string) =>
  apiClient.put(`v1/conversations/${conversationId}/read`);

export default function sortConversation<T extends { latestMessage?: IMessageDto }>(
  conversations: IConversationDto[],
  entities: T[],
  match: (entity: T, conversation: IConversationDto) => boolean,
  isMe: IsMeByUserIdFunc,
) {
  const entitiesSorted: T[] = [];
  for (let i = 0; i < entities.length; i += 1) {
    const conversationForEntity = conversations.find((c) => match(entities[i], c));

    const entityClone = { ...entities[i] };

    if (conversationForEntity && conversationForEntity.latestMessage) {
      entityClone.latestMessage = conversationForEntity.latestMessage;
      entityClone.latestMessage.createdTimeFriendly = formatDistanceToNow(
        new Date(conversationForEntity.latestMessage.createdTime),
      );

      const currentUserAsParticipant = conversationForEntity.participants.find(
        (participant: IParticipantDto) => isMe(participant.userId),
      );

      if (currentUserAsParticipant) {
        entityClone.latestMessage.isUnread =
          new Date(entityClone.latestMessage.createdTime).getTime() >
          new Date(currentUserAsParticipant.lastReadTime).getTime();
      }
    } else {
      entityClone.latestMessage = {} as IMessageDto;
      entityClone.latestMessage.text = '-';
    }

    entitiesSorted.push(entityClone);
  }

  entitiesSorted.sort(
    (a: T, b: T) => getTime(b.latestMessage?.createdTime) - getTime(a.latestMessage?.createdTime),
  );

  return entitiesSorted;
}

export const setReaction = (conversationId: string, messageId: string, reactionValue: string) =>
  apiClient.put(`v1/conversations/${conversationId}/messages/${messageId}/reaction`, {
    reactionValue,
  });

export const deleteReaction = (conversationId: string, messageId: string) =>
  apiClient.delete(`v1/conversations/${conversationId}/messages/${messageId}/reaction`);
