import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

interface IProps {
  desktopElement: React.ReactNode;
  mobileElement: React.ReactNode;
}

export function DesktopMobileSwitch({ desktopElement, mobileElement }: IProps) {
  const isMobile = useCheckMobileScreen();

  if (isMobile) return <>{mobileElement}</>;

  return <>{desktopElement}</>;
}
