import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { To, useNavigate } from 'react-router';
import { ButtonColors } from '../../../constants/ButtonColors';
import ProfileAvatar from '../../avatar/ProfileAvatar';
import IconButton from '../../buttons/IconButton';
import Text from '../../text/Text';

interface IMobileHeaderDetailAction {
  key: string;
  icon: React.ElementType;
  onClick: () => void;
  disabled?: boolean;
}

interface IHeaderMobileProps {
  title: string;
  subTitle?: string;
  avatarPhotoUrl?: string;
  avatarAlt?: string;
  backNavigationTo?: To;
  headerNavigationTo?: To;
  actions?: IMobileHeaderDetailAction[];
}

function HeaderMobileActionButton({
  icon: Icon,
  onClick,
  disabled,
}: Omit<IMobileHeaderDetailAction, 'key'>) {
  return (
    <IconButton
      color={ButtonColors.Gray}
      isDisabled={disabled}
      icon={
        <div className="inline-flex w-8 h-8 items-center justify-center">
          <Icon className="w-5 h-5" />
        </div>
      }
      onClick={onClick}
    />
  );
}

export function HeaderMobileDetail({
  title,
  subTitle,
  avatarPhotoUrl,
  avatarAlt,
  backNavigationTo,
  headerNavigationTo,
  actions,
}: IHeaderMobileProps) {
  const navigate = useNavigate();

  const handleBackNavigation = () => {
    const to = backNavigationTo || '..';
    navigate(to);
  };

  const handleHeaderNavigation = () => {
    if (!headerNavigationTo) return;
    navigate(headerNavigationTo);
  };

  return (
    <header className="flex gap-2 items-center h-14 py-10 px-2">
      <IconButton
        color={ButtonColors.Gray}
        icon={<ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />}
        onClick={handleBackNavigation}
      />
      <button
        type="button"
        onClick={handleHeaderNavigation}
        className="flex flex-1 items-center text-left overflow-hidden"
      >
        <ProfileAvatar
          avatarProps={{
            src: avatarPhotoUrl,
            alt: avatarAlt,
            widthClass: 'w-10',
            heightClass: 'h-10',
          }}
        />

        <div className="flex flex-col flex-1 ml-4 overflow-hidden">
          <Text as="h3" size="normal" weight="medium" truncate>
            {title}
          </Text>
          {subTitle && (
            <Text as="span" size="small" brightness="light" truncate>
              {subTitle}
            </Text>
          )}
        </div>
      </button>
      {actions && (
        <div className="md:hidden flex content-center justify-center text-center mr-3">
          {actions?.map(({ key, ...rest }) => (
            <HeaderMobileActionButton key={key} {...rest} />
          ))}
        </div>
      )}
    </header>
  );
}
