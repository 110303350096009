import { ConnectionRequestStatuses } from '../constants/ConnectionRequestStatuses';
import { IConnectionRequestDto } from '../model/IConnectionRequestDto';
import apiClient from '../utils/apiClient';

export const getSentConnectionRequests = () =>
  apiClient
    .get('v1/connectionrequests?type=sent')
    .then<Array<IConnectionRequestDto>>((response) => response.data.data);

export const getRecievedConnectionRequests = () =>
  apiClient
    .get('v1/connectionrequests?type=received')
    .then<Array<IConnectionRequestDto>>((response) => response.data.data);

type GetParams = {
  userId?: string;
  status?: ConnectionRequestStatuses;
  type?: 'Sent' | 'Received';
};

export const getConnectionRequests = (params: GetParams) =>
  apiClient
    .get('v1/connectionrequests', { params })
    .then<Array<IConnectionRequestDto>>((response) => response.data.data);

export const acceptConnectionRequest = (id: string) =>
  apiClient.put(`v1/connectionrequests/${id}/accept`).then((response) => response.data.data);

export const rejectConnectionRequest = (id: string) =>
  apiClient.put(`v1/connectionrequests/${id}/reject`).then((response) => response.data.data);

export const deleteConnectionRequest = (id: string) =>
  apiClient.delete(`v1/connectionrequests/${id}`);
