import { IPagedResult } from '../../../shared/model/IPagedResult';
import { IPrivilegesDto } from '../../../shared/model/IPrivilegesDto';
import apiClient from '../../../shared/utils/apiClient';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import queryStringBuilder from '../../../shared/utils/query-string-builder/queryStringBuilder';
import { ICreateUpdateOrganizationDto } from '../models/ICreateUpdateOrganizationDto';
import { IOrganizationDto } from '../models/IOrganizationDto';
import { IOrganizationInvitationDto } from '../models/IOrganizationInvitationDto';
import { IOrganizationInvitationFilters } from '../models/IOrganizationInvitationFilters';
import { IOrganizationMemberDto } from '../models/IOrganizationMemberDto';

export function createOrganization(data: ICreateUpdateOrganizationDto) {
  return apiClient
    .post('v1/organizations', data)
    .then<IOrganizationDto>((response) => response.data);
}

export function updateOrganization(id: string, data: ICreateUpdateOrganizationDto) {
  return apiClient.patch(`v1/organizations/${id}`, data);
}

export function deleteOrganization(id: string) {
  return apiClient.delete(`v1/organizations/${id}`);
}

export function getOrganization(id: string) {
  return apiClient
    .get(`v1/organizations/${id}`)
    .then<IOrganizationDto>((response) => response.data);
}

export function getOrganizations(...queryParams: QueryParam[]) {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/organizations${queryString}`)
    .then<IPagedResult<IOrganizationDto>>((response) => response.data);
}

export function getOrganizationMembers(id: string) {
  return apiClient
    .get<IPagedResult<IOrganizationMemberDto>>(`v1/organizations/${id}/members`)
    .then((response) => response.data);
}

export function deleteOrganizationMember(organizationId: string, userId: string) {
  return apiClient.delete(`v1/organizations/${organizationId}/members/${userId}`);
}

export function getOrganizationInvitations(filters: IOrganizationInvitationFilters) {
  return apiClient
    .get<IPagedResult<IOrganizationInvitationDto>>('v1/organizationinvitations', {
      params: filters,
    })
    .then((response) => response.data.data);
}

export function createOrganizationInvitation(id: string, targetUserId: string) {
  return apiClient.post(`v1/organizations/${id}/invitations`, { targetUserId, message: '' });
}

export function acceptOrganizationInvitation(organizationId: string, invitationId: string) {
  return apiClient.put(`v1/organizations/${organizationId}/invitations/${invitationId}/accept`);
}

export function rejectOrganizationInvitation(organizationId: string, invitationId: string) {
  return apiClient.put(`v1/organizations/${organizationId}/invitations/${invitationId}/reject`);
}

export function deleteOrganizationInvitation(organizationId: string, invitationId: string) {
  return apiClient.delete(`v1/organizations/${organizationId}/invitations/${invitationId}`);
}

export function getOrganizationPrivileges(id: string) {
  return apiClient
    .get<IPrivilegesDto>(`v1/organizations/${id}/privileges`)
    .then((response) => response.data);
}

export function uploadOrganizationPhoto(id: string, imageFile: Blob) {
  const formData = new FormData();

  formData.append('file', imageFile);

  return apiClient.post(`v1/organizations/${id}/photo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteOrganizationPhoto(id: string) {
  return apiClient.delete(`v1/organizations/${id}/photo`);
}
