import { Column } from '@tanstack/react-table';
import { DropdownMenuCheckboxItem } from '../../drop-down-menu/DropdownMenu';

import { activityBadgeLookup } from '../../activity/ActivityList';
import { IActivityItem } from '../../activity/IActivityItem';
import DataTableHeader from './DataTableHeader';

interface ActivityHeaderCell<T> {
  column: Column<T, unknown>;
  items: IActivityItem[];
}

export default function ActivityHeaderCell<T>({ items, column }: ActivityHeaderCell<T>) {
  const filterState = (column.getFilterValue() || []) as string[];

  return (
    <DataTableHeader header="Activity" showFilter>
      {items.map(({ type, label, value }) => {
        const BadgeComponent = activityBadgeLookup[type];
        return (
          <DropdownMenuCheckboxItem
            key={type}
            checked={filterState.includes(type)}
            onSelect={(e) => e.preventDefault()}
            onCheckedChange={(checked) => {
              let newFilterState = [...filterState];
              if (checked) {
                newFilterState.push(type);
              } else {
                newFilterState = newFilterState.filter((item) => item !== type);
              }

              column.setFilterValue(newFilterState);
            }}
          >
            <BadgeComponent label={label} value={value} />
          </DropdownMenuCheckboxItem>
        );
      })}
    </DataTableHeader>
  );
}
