import { IShareContactRequestDto } from '../../../shared/model/IShareContactRequestDto';
import { IConnectionDto, IConnectionWithShareInfoDto } from '../../../shared/model/IConnectionDto';

/**
 * Combines connections and share contact requests by matching on
 * the target user of the share contact request.
 *
 * Should be used for share contact requests for a single contact.
 * I.e. the sharedContactId should be the same for all requests.
 *
 * @param connections
 * @param shareContactRequests
 * @returns
 */
export function mergeConnectionsAndShareContactRequests(
  connections: IConnectionDto[],
  shareContactRequests: IShareContactRequestDto[],
): IConnectionWithShareInfoDto[] {
  return connections.map((connection) => {
    const matchingRequests = shareContactRequests?.filter(
      (datareq) => datareq.targetUserId === connection.userId,
    );

    if (matchingRequests.length > 0) {
      const acceptedRequest = matchingRequests.find((datareq) => datareq.status === 'Accepted');
      if (acceptedRequest) {
        return { ...connection, shareInformation: acceptedRequest };
      }

      const pendingRequest = matchingRequests.find((datareq) => datareq.status === 'Pending');
      if (pendingRequest) {
        return { ...connection, shareInformation: pendingRequest };
      }

      const rejectedRequest = matchingRequests.find((datareq) => datareq.status === 'Rejected');
      if (rejectedRequest) {
        return { ...connection, shareInformation: rejectedRequest };
      }
    }

    return { ...connection, shareInformation: undefined };
  });
}
