import { Controller, useForm } from 'react-hook-form';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import Label from '../../shared/components/text/Label';
import { IPipelineItemDto } from './models/IPipelineItemDto';
import CurrencyInput from '../../shared/components/inputs/number/CurrencyInput';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import convertFormattedCurrencyToNumber from '../../shared/utils/numberUtils';
import { useUpdatePipelineItemMutation } from './queries/itemQueries';
import { usePipelineStoreContext } from './context/pipeline-context';
import { usePipelineQuery } from './queries/pipelineQueries';
import Button from '../../shared/components/buttons/Button';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';

interface IPipelineEditItemFormProps {
  pipelineItem: IPipelineItemDto;
}

export default function PipelineEditItemForm({ pipelineItem }: IPipelineEditItemFormProps) {
  const { activePipelineSettings } = usePipelineStoreContext();

  const pipelineQuery = usePipelineQuery(activePipelineSettings.pipelineId);
  const updatePipelineItemMutation = useUpdatePipelineItemMutation();

  const {
    control,
    getValues,
    register,
    formState: { isDirty, isValid },
  } = useForm<IPipelineItemDto>({
    values: pipelineItem,
  });

  const save = () => {
    if (!pipelineQuery.data) return;
    const value = convertFormattedCurrencyToNumber(getValues('value'));
    const name = getValues('name');
    updatePipelineItemMutation.mutate({
      pipelineId: activePipelineSettings.pipelineId,
      stageId: activePipelineSettings.stageId,
      itemId: activePipelineSettings.item.id,
      item: { ...pipelineItem, name, value },
    });
  };

  return (
    <SpacingContainer>
      <SpacingContainerItem>
        <TextFieldInput
          name="name"
          type="text"
          register={register}
          labelText="Name"
          validationRules={{ required: true, maxLength: 100 }}
        />
      </SpacingContainerItem>
      <SpacingContainerItem>
        <Label htmlFor="value" text={`Amount (${pipelineQuery?.data?.currencyIsoCode})`} />
        <Controller
          name="value"
          control={control}
          render={({ field }) => <CurrencyInput {...field} />}
        />
      </SpacingContainerItem>
      <ButtonGroup>
        <Button
          color={ButtonColors.Blue}
          text="Save"
          disabled={!isValid || !isDirty}
          onClick={save}
        />
      </ButtonGroup>
    </SpacingContainer>
  );
}
