interface IProps {
  text: string;
  type?: 'Information' | 'Warning' | 'Danger' | 'Success';
}

export default function Alert({ text, type = 'Information' }: IProps) {
  let classNames = 'text-blue-800 bg-blue-50 dark:bg-gray-800 dark:text-blue-400';
  if (type === 'Danger') {
    classNames = 'text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-40';
  } else if (type === 'Success') {
    classNames = 'text-green-800 bg-green-50 dark:bg-gray-800 dark:text-green-400';
  } else if (type === 'Warning') {
    classNames = ' text-yellow-800 bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300';
  }
  return (
    <div className={`p-4 mb-4 text-sm rounded-lg ${classNames}`} role="alert">
      {text}
    </div>
  );
}
