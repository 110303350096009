import { useState } from 'react';
import { PlayCircleIcon, StopCircle } from 'lucide-react';
import { usePipelineStoreContext } from './context/pipeline-context';
import { usePipelineItemQuery } from './queries/itemQueries';
import PipelineEditItemForm from './PipelineEditItemForm';
import SidePanelHeadline from '../../shared/components/layout/building-blocks/SidePanelHeadline';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { Pill } from '../../shared/components/indicators/Pill';
import Text from '../../shared/components/text/Text';
import { useShareResourceMutation } from './queries/pipelineSharedResourcesQueries';
import { NetworkCard } from '../Networks/NetworkCard';
import { ICardMenuItem } from '../../shared/components/cards/CardMenuBar';
import { IEntityCardProps } from '../../shared/components/cards/EntityCardProps';
import { SocialContactCard } from '../Contacts/SocialContactCard';
import { PassiveContactCard } from '../Contacts/PassiveContactCard';
import { ResourceType } from '../../shared/model/ResourceType';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import { useMyUserId } from '../../shared/auth/accountHooks';

export default function PipelineEditItem() {
  const userId = useMyUserId();
  const { activePipelineSettings } = usePipelineStoreContext();
  const [activeTab, setActiveTab] = useState('tab_info');
  const sharingMutation = useShareResourceMutation();
  const { pipelineId } = activePipelineSettings;
  const pipelineItemQuery = usePipelineItemQuery({
    pipelineId,
    stageId: activePipelineSettings.stageId,
    itemId: activePipelineSettings.item.id,
  });

  const pipelineItem = pipelineItemQuery.data;
  const resourceType = pipelineItem?.sharedResource?.resourceType || ResourceType.PipelineItem;
  const resourceManifest = useEntityManifest(resourceType);

  if (!pipelineItemQuery.isSuccess || !pipelineItem) {
    return <></>;
  }

  const resolveCard = (type: ResourceType, options: IEntityCardProps<any>) => {
    if (type === ResourceType.Contact && options.entity.userId) {
      return <SocialContactCard {...options} userId={options.entity.userId} />;
    }
    if (type === ResourceType.Contact || type === ResourceType.ContactPassive) {
      return <PassiveContactCard {...options} />;
    }
    if (type === ResourceType.Network) {
      return <NetworkCard {...options} />;
    }
    return <></>;
  };

  const stopSharingAction: ICardMenuItem = {
    key: `${pipelineItem.id}-connection`,
    label: 'Stop sync',
    icon: (classes) => <StopCircle className={classes} />,
    onClick: () => {
      if (!pipelineItem.sharedResource) {
        return;
      }

      sharingMutation.mutate(
        {
          pipelineId,
          request: {
            isLiveUpdated: false,
            resourceId: pipelineItem.sharedResource.resourceId,
            type: pipelineItem.sharedResource.resourceType,
          },
        },
        {
          onSuccess: () => {
            pipelineItemQuery.refetch();
          },
        },
      );
    },
  };

  const startSharingAction: ICardMenuItem = {
    key: `${pipelineItem.id}-connection`,
    label: 'Start sync',
    icon: (classes) => <PlayCircleIcon className={classes} />,
    onClick: () => {
      if (!pipelineItem.sharedResource) {
        return;
      }
      sharingMutation.mutate(
        {
          pipelineId,
          request: {
            isLiveUpdated: true,
            resourceId: pipelineItem.sharedResource.resourceId,
            type: pipelineItem.sharedResource.resourceType,
          },
        },
        {
          onSuccess: () => {
            pipelineItemQuery.refetch();
          },
        },
      );
    },
  };

  const menuItems = [];
  if (
    pipelineItem.sharedResource &&
    pipelineItem.sharedResource.isLiveUpdated &&
    pipelineItem.sharedResource.sharedBy === userId
  ) {
    menuItems.push(stopSharingAction);
  }

  if (
    pipelineItem.sharedResource &&
    !pipelineItem.sharedResource.isLiveUpdated &&
    pipelineItem.sharedResource.sharedBy === userId
  ) {
    menuItems.push(startSharingAction);
  }

  const renderHeader = () => (
    <div className="flex flex-row items-center justify-start space-x-2">
      <Pill
        color={pipelineItem.sharedResource?.isLiveUpdated ? 'green' : 'yellow'}
        label={pipelineItem.sharedResource?.isLiveUpdated ? 'Synchronized' : 'Copy'}
      />
      <Text weight="medium" size="small">
        {pipelineItem.sharedResource?.resourceDisplayName}
      </Text>
    </div>
  );

  return (
    <>
      <SidePanelHeadline
        headline={pipelineItem?.name}
        imageSrc={pipelineItem?.sharedResource?.payload?.photoUrl || resourceManifest.icon}
        resourceId={pipelineItem?.sharedResource?.resourceId}
        resourceLink={`/${resourceManifest.endpointName}/${pipelineItem?.sharedResource?.resourceId}`}
        showLinkToResource={!!pipelineItem?.sharedResource}
        colorSetting={resourceManifest.color}
      />
      <TabWrapper>
        <TabHeaderWrapper>
          <TabNavItem
            id="tab_info"
            activeId={activeTab}
            title="Info"
            setActiveTab={() => setActiveTab('tab_info')}
          />
        </TabHeaderWrapper>
        <TabContentWrapper>
          <TabContent id="tab_info" activeTabId={activeTab}>
            <PipelineEditItemForm pipelineItem={pipelineItem} />
            {pipelineItem.sharedResource && (
              <>
                <HorizontalDivider distance="xLarge" text="Picked sales type" />
                {resolveCard(resourceType, {
                  entity: pipelineItem.sharedResource?.payload,
                  menuItems,
                  headerRenderer: renderHeader,
                  defaultOpen: true,
                })}
              </>
            )}
          </TabContent>
        </TabContentWrapper>
      </TabWrapper>
    </>
  );
}
