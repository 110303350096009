import { IConversationDto } from '../model/IConversationDto';
import { IMessageDto } from '../model/IMessageDto';
import { getConversationMessages, getConversations } from '../services/conversationService';
import QueryParam from '../utils/query-string-builder/QueryParam';

const processConversationData = (_conversations: IConversationDto[]) => {
  const conversations = [] as IConversationDto[];
  const promises = [];

  for (let i = 0; i < _conversations.length; i += 1) {
    promises.push(getConversationMessages(_conversations[i].id, new QueryParam('Limit', 1)));
  }

  const promiseList = Promise.all(promises);

  return promiseList.then((_conversationResult) => {
    const flattenedConversations = _conversationResult.flatMap((m) => m.data);

    for (let i = 0; i < _conversations.length; i += 1) {
      const latestMessage = flattenedConversations.find(
        (m) => m.conversationId === _conversations[i].id,
      ) as IMessageDto;

      conversations.push({
        id: _conversations[i].id,
        resourceId: _conversations[i].resourceId,
        resourceType: _conversations[i].resourceType,
        participants: _conversations[i].participants,
        latestMessage,
      });
    }

    return conversations;
  });
};

const setupConversations = () =>
  // Using a high limit here to make sure we get conversation for all contacts/networks.
  getConversations(new QueryParam('limit', 500)).then((_conversations) => {
    const conversationMessages = processConversationData(_conversations.data);
    return conversationMessages;
  });

export default setupConversations;
