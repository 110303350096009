import { Card } from '../cards/Card';

interface IProps {
  isSelected?: boolean;
  children: React.ReactNode;
  isInteractive?: boolean;
}

export default function ListItem({ isSelected, children, isInteractive }: IProps) {
  const listItemClassName = isInteractive
    ? [
        'flex items-center space-x-3 relative cursor-pointer',
        isSelected ? 'outline-cyan-500 outline outline-offset-[-2px]' : 'bg-white',
      ].join(' ')
    : 'flex items-center space-x-3 relative';

  return (
    <Card isInteractive={isInteractive} className={listItemClassName}>
      {children}
    </Card>
  );
}
