const validReactions = [
  { id: 'U+1F600', emoji: '😀' },
  { id: 'U+FE0F', emoji: '❤️' },
  { id: 'U+1F923', emoji: '🤣' },
  { id: 'U+1F60E', emoji: '😎' },
  { id: 'U+1F62D', emoji: '😭' },
  { id: 'U+1F90C', emoji: '🤌' },
  { id: 'U+1F44D', emoji: '👍' },
  { id: 'U+1F44E', emoji: '👎' },
  { id: 'U+1F44F', emoji: '👏' },
  { id: 'U+1F37E', emoji: '🍾' },
  { id: 'U+1F3C6', emoji: '🏆' },
];

interface IProps {
  onChangeReactionState: (emoji: string) => void;
}

export default function MessageReactions({ onChangeReactionState }: IProps) {
  return (
    <div className="flex flex-row">
      {validReactions.map((reaction) => (
        <button
          key={reaction.id}
          type="button"
          className="text-xl"
          onClick={() => onChangeReactionState(reaction.emoji)}
        >
          {reaction.emoji}
        </button>
      ))}
    </div>
  );
}
