import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useIsMeByUserId } from '../../../shared/auth/accountHooks';
import { AppContext } from '../../../shared/context/context';
import { connectionConversationFilter } from '../../../shared/filters/filters';
import {
  IConnectionDto,
  IConnectionWithMessageInfoDto,
} from '../../../shared/model/IConnectionDto';
import { IPagedResult } from '../../../shared/model/IPagedResult';
import { getMyConnections } from '../../../shared/services/connectionService';
import sortConversation from '../../../shared/services/conversationService';
import { GetConnectionsParams } from '../models/GetConnectionsParams';

const connectionKeys = {
  all: ['connections'] as const,
  lists: () => [...connectionKeys.all, 'list'] as const,
  list: (props: GetConnectionsParams) => [...connectionKeys.lists(), props] as const,
};

function connectionsBaseQuery(props: GetConnectionsParams) {
  return {
    queryKey: connectionKeys.list(props),
    queryFn: () => getMyConnections(props).then((result) => result.data),
    staleTime: 1000 * 60 * 5, // 5 minutes
  };
}

export function useConnectionsQuery(props: GetConnectionsParams = {}) {
  const propsWithDefault = { limit: 200, ...props };
  return useQuery(connectionsBaseQuery(propsWithDefault));
}

export function useConnectionsForChatQuery(props: GetConnectionsParams = {}) {
  const propsWithDefault = { limit: 200, ...props };
  const { state } = useContext(AppContext);
  const isMe = useIsMeByUserId();
  return useQuery({
    ...connectionsBaseQuery(propsWithDefault),
    select: (connections) => {
      const contactConversations = state.conversations?.filter(connectionConversationFilter) ?? [];

      const sortedConnections = sortConversation(
        contactConversations,
        connections as IConnectionWithMessageInfoDto[],
        (connection, conversation) => connection.id === conversation.resourceId,
        isMe,
      );

      return sortedConnections;
    },
  });
}

export function useUserConnectionQuery(userId?: string) {
  const props = { userId };
  return useQuery({
    queryKey: connectionKeys.list(props),
    queryFn: () =>
      userId
        ? getMyConnections(props)
        : Promise.resolve<IPagedResult<IConnectionDto>>({ data: [], next: undefined }),
    select: (result) => (result.data.length > 0 ? result.data[0] : undefined),
  });
}

export function useHasConnectionQuery(userId?: string) {
  const props = { userId };
  return useQuery({
    queryKey: connectionKeys.list(props),
    queryFn: () => getMyConnections(props),
    select: (result) => result.data.length > 0,
    enabled: !!userId,
  });
}
