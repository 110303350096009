import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import { createOrganizationInvitation, deleteOrganizationMember, getOrganizationMembers } from './organizationServices';
import { ResourceIds } from '../../../shared/hooks/useEntityManifest';

const organizationMembersKeys = {
  all: ['members', 'Organization'] as const,
  lists: () => [...organizationMembersKeys.all, 'list'] as const,
  list: (id: string) => [...organizationMembersKeys.all, id] as const,
};

export function useOrganizationMembersQuery(id: string) {
  return useInfiniteApiQuery({
    queryKey: organizationMembersKeys.list(id),
    queryFn: () => getOrganizationMembers(id),
  });
}

interface IUseInviteOrganizationMemberProps {
  resourceIds: ResourceIds; // To match generic member API
  userId: string;
}

export function useInviteOrganizationMemberMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ resourceIds, userId }: IUseInviteOrganizationMemberProps) =>
      createOrganizationInvitation(resourceIds[0], userId),
    onSuccess: (_, { resourceIds }) => {
      queryClient.invalidateQueries({ queryKey: organizationMembersKeys.list(resourceIds[0]) });
    },
    onError: useDispatchApiError(),
  });
}

interface IUseRemoveOrganizationMemberProps {
  organizationId: string;
  userId: string;
}

export function useRemoveOrganizationMemberMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId, userId }: IUseRemoveOrganizationMemberProps) =>
      deleteOrganizationMember(organizationId, userId),
    onSuccess: (_, { organizationId }) => {
      queryClient.invalidateQueries({ queryKey: organizationMembersKeys.list(organizationId) });
    },
    onError: useDispatchApiError(),
  });
}
