import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../dialog/Dialog';

export enum ModalSize {
  Small,
  Medium,
  Large,
}

export interface IModalProps {
  title?: string;
  open?: boolean;
  modalSize?: ModalSize;
  hideCloseButton?: boolean;
  trigger?: React.ReactNode;
  triggerAsChild?: boolean;
  children: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
  onClose?: (event: React.SyntheticEvent | Event) => void;
}
/** @deprecated Use Modal.tsx instead */
/** New component uses a shared modal state
 * that simplifies implementation and removes the need for separate
 * state handling for each modal
 * When time allows, just replace this component with the new */

export default function ModalLegacy({
  title = '',
  open,
  modalSize = ModalSize.Large,
  hideCloseButton = false,
  trigger,
  triggerAsChild,
  children,
  onOpenChange,
  onClose,
}: IModalProps) {
  // override the default widths
  let contentClassName = '';
  switch (modalSize) {
    case ModalSize.Small:
      contentClassName = 'md:max-w-xl lg:max-w-xl';
      break;
    case ModalSize.Medium:
      contentClassName = 'lg:max-w-2xl';
      break;
    default:
      break;
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {trigger && <DialogTrigger asChild={triggerAsChild}>{trigger}</DialogTrigger>}
      <DialogContent
        className={contentClassName}
        hideCloseButton={hideCloseButton}
        onEscapeKeyDown={onClose}
        onInteractOutside={onClose}
        onClose={onClose}
      >
        {title && (
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
}
