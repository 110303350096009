import { IContactDto } from './models/IContactDto';
import ContactUserInformation from './ContactUserInformation';
import Text from '../../shared/components/text/Text';

interface IProps {
  contact: IContactDto;
}
export default function ContactInfoMain({ contact }: IProps) {
  return (
    <>
      <div className="mt-8">
        <Text as="h1" size="x2Large" leading="loose">
          {contact?.displayName}
        </Text>
        <div className="flex flex-1 mr-4">
          <ContactUserInformation profile={contact} />
        </div>
      </div>
    </>
  );
}
