import TimeAgo from '../../shared/components/dates/TimeAgo';
import { IOrganizationInvitationDto } from '../Companies/models/IOrganizationInvitationDto';
import { organizationBaseQuery } from '../Companies/queries/organizationQueries';
import { useDeleteOrganizationInvitationMutation } from './queries/organizationInvitationQueries';
import { RequestItems } from './RequestItems';

interface IProps {
  organizationInvitations: IOrganizationInvitationDto[];
}

export default function OrganizationInvitationRequests({ organizationInvitations }: IProps) {
  const deleteOrganizationInvitationMutation = useDeleteOrganizationInvitationMutation();
  return (
    <RequestItems
      requests={organizationInvitations}
      resourceQuery={(request: IOrganizationInvitationDto) =>
        organizationBaseQuery(request.organizationId)
      }
      text={(request, organization) => (
        <>
          Invite to company &apos;{organization?.name}&apos; sent{' '}
          <TimeAgo date={request.createdTime} /> ago.
        </>
      )}
      actions={({ id: invitationId, organizationId }) => [
        {
          text: 'Cancel',
          disabled: deleteOrganizationInvitationMutation.isPending,
          onClick: () =>
            deleteOrganizationInvitationMutation.mutate({ organizationId, invitationId }),
        },
      ]}
    />
  );
}
