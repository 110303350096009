import { ChangeEvent, useMemo, useState } from 'react';
import {
  useListShareContactRequestsQuery,
  useShareContactMutation,
} from '../Request/queries/shareContactRequestQueries';
import { IContactDto } from './models/IContactDto';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SelectListMultiSelect from '../../shared/components/lists/SelectListMultiSelect';
import { TagColor } from '../../shared/components/tags/Tag';
import { ShareContactRequestStatuses } from '../../shared/model/IShareContactRequestDto';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import SearchBar from '../../shared/components/search/SearchBar';
import useDebounce from '../../shared/hooks/useDebounce';
import { MergeContactsAndSharedRequests } from './mergecontacts/MergeContactsAndSharedRequests';
import { useContactsForSharedQuery } from './queries/contactQueries';
import HowTo from '../../shared/components/message-popups/HowTo';

const statusColorMap: Record<ShareContactRequestStatuses, TagColor> = {
  Accepted: 'green',
  Pending: 'yellow',
  Rejected: 'red',
};

interface IProps {
  contact: IContactDto;
  status?: ShareContactRequestStatuses | 'All';
}

export function ContactSharedContactsInfo({ contact, status = 'All' }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<
    ReturnType<typeof MergeContactsAndSharedRequests>
  >([]);

  const shareContactMutation = useShareContactMutation();

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleShareContact = () => {
    selectedContacts.forEach((selectedContact) => {
      if (!contact.userId) return;
      shareContactMutation.mutate({
        contactId: selectedContact.id,
        targetUserIds: [contact.userId],
      });
    });
    setSelectedContacts([]);
  };

  const debouncedSearchTerm = useDebounce(searchTerm);

  const contactsQuery = useContactsForSharedQuery(debouncedSearchTerm, contact.id);

  const shareContactRequestsQuery = useListShareContactRequestsQuery({
    targetUserId: contact.userId,
    searchType: 'Sent',
    status: status === 'All' ? ['Accepted', 'Pending', 'Rejected'] : [status],
  });

  const contactsShareToContact = useMemo(() => {
    if (!contactsQuery.isSuccess || !shareContactRequestsQuery.isSuccess) {
      return [];
    }
    const mergedContactsToContacts = MergeContactsAndSharedRequests(
      contactsQuery.data,
      shareContactRequestsQuery.data,
    );

    return mergedContactsToContacts;
  }, [
    contactsQuery.data,
    shareContactRequestsQuery.data,
    contactsQuery.isSuccess,
    shareContactRequestsQuery.isSuccess,
  ]);

  const isPendingcontactsShareToContact = contactsQuery.isPending;

  return (
    <>
      <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
      <ScrollBarWrapper className="flex flex-col">
        <SelectListMultiSelect
          key={searchTerm}
          data={contactsShareToContact}
          isLoading={isPendingcontactsShareToContact}
          selectedItems={selectedContacts}
          onSelectItems={setSelectedContacts}
          itemIdProperty={({ id }) => id}
          itemHeadlineProperty={({ displayName }) => `${displayName}`}
          itemInformationProperty={({ emailAddresses }) => emailAddresses[0]?.value}
          itemImgSrcProperty={({ photoUrl }) => photoUrl}
          itemTagsProperty={({ shareInformation }) =>
            shareInformation
              ? [
                  {
                    title: shareInformation.status,
                    color: statusColorMap[shareInformation.status],
                  },
                ]
              : []
          }
        />
      </ScrollBarWrapper>
      {!contactsQuery.isPending &&
        !contactsQuery.isError &&
        !shareContactRequestsQuery.isPending &&
        !shareContactRequestsQuery.isError && (
          <div className="mt-auto gap-2 flex pt-2">
            <Button
              color={ButtonColors.Blue}
              text={`Share ${selectedContacts.length} contact(s)  to ${contact.firstName}`}
              onClick={handleShareContact}
              disabled={selectedContacts.length === 0 || shareContactMutation.isPending}
              isFullWidth
            />

            <HowTo
              title="Share"
              description="Share other contacts with with this connection. You can share multiple contacts at once."
            />
          </div>
        )}
    </>
  );
}
