/* eslint-disable prefer-destructuring */
import { format, isToday } from 'date-fns';
import { useContext, useEffect } from 'react';
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { MessageType } from '../../../shared/constants/MessageTypes';
import { IMessageFileDto, IMessageDto, IUpdateMessageDto } from '../../../shared/model/IMessageDto';
import {
  deleteMessage,
  deleteReaction,
  getConversationMessage,
  getConversationMessages,
  setConversationRead,
  setReaction,
  updateConversationMessage,
} from '../../../shared/services/conversationService';
import { datesAreOnSameDay, messagingDividerDateFormat } from '../../../shared/utils/dateUtils';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import { updateInfiniteQueriesData } from '../../../shared/utils/queryClientUtils';
import { AppContext } from '../../../shared/context/context';
import { IReaction } from '../../../shared/model/IReaction';

import { useIsMeByUserId } from '../../../shared/auth/accountHooks';
import { IPagedResult } from '../../../shared/model/IPagedResult';
import { useConversationQuery } from './conversationQueries';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';

export type UseMessagesQueryResult = UseInfiniteQueryResult<
  InfiniteData<IMessageDto[], string | undefined>,
  Error
> & {
  add: (message: IMessageDto) => void;
  update: (message: IMessageDto, oldId?: string) => void;
  setFileUploadProgress: (fileId: string, progress: number | undefined) => void;
};

const messageKeys = {
  all: ['messages'] as const,
  lists: () => [...messageKeys.all, 'list'] as const,
  list: (conversationId: string) => [...messageKeys.lists(), conversationId] as const,
  details: (conversationId: string) => [...messageKeys.all, 'detail', conversationId] as const,
  detail: (conversationId: string, messageId: string) =>
    [...messageKeys.details(conversationId), messageId] as const,
};

export function useMessageQuery(conversationId?: string, messageId?: string) {
  const queryClient = useQueryClient();
  const { data: conversation } = useConversationQuery(conversationId);
  return useQuery({
    queryKey: messageKeys.detail(conversationId as string, messageId as string),
    queryFn: () => getConversationMessage(conversationId as string, messageId as string),
    enabled: !!conversationId && !!messageId && !!conversation,
    initialData: () => {
      const data = queryClient.getQueryData<InfiniteData<IPagedResult<IMessageDto>>>(
        messageKeys.list(conversationId as string),
      );
      if (!data) return undefined;
      const message = data.pages
        .find(
          (page) =>
            page.data.findIndex(
              (_message) => _message.conversationId === conversationId && _message.id === messageId,
            ) !== -1,
        )
        ?.data.find(
          (_message) => _message.conversationId === conversationId && _message.id === messageId,
        );

      return message;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(messageKeys.list(conversationId as string))?.dataUpdatedAt,
    staleTime: Infinity,
    select: (message) => {
      const { ...messageClone } = message;

      messageClone.displayName =
        conversation?.participants?.find((p) => p.userId === messageClone.senderUserId)
          ?.displayName || undefined;
      return messageClone;
    },
  });
}

export const useMessagesQuery = (
  conversationId?: string,
  limit?: number,
): UseMessagesQueryResult => {
  const isMe = useIsMeByUserId();
  const queryClient = useQueryClient();
  const { data: conversation } = useConversationQuery(conversationId);
  const currentUser = conversation?.participants?.find((p) => isMe(p.userId));

  const queryInfo = useInfiniteApiQuery({
    queryKey: messageKeys.list(conversationId as string),
    queryFn: () => {
      if (typeof conversation === 'undefined') return Promise.reject();
      if (limit) return getConversationMessages(conversation.id, new QueryParam('limit', limit));
      return getConversationMessages(conversation.id);
    },
    staleTime: Infinity,
    enabled: !!conversation,
    select: (data) => {
      const { pages, pageParams } = data;
      const transformedPages = pages.map((page, pageIndex) => {
        const { data: messages } = page;
        return messages.map((message, messageIndex) => {
          const messageClone: IMessageDto = { ...message };

          if (!isMe(messageClone.senderUserId) && currentUser?.lastReadTime) {
            messageClone.isUnread =
              new Date(messageClone.createdTime) > new Date(currentUser.lastReadTime);
          } else {
            messageClone.isUnread = false;
          }

          let previousMessage: IMessageDto | undefined;
          if (messageIndex + 1 < messages.length) previousMessage = messages[messageIndex + 1];
          else if (pageIndex + 1 < pages.length) previousMessage = pages[pageIndex + 1].data[0];

          if (isMe(messageClone.senderUserId)) {
            messageClone.messageType = MessageType.Outgoing;
          } else {
            messageClone.messageType = MessageType.Incoming;
          }

          messageClone.isProfileImageVisible =
            messageClone.senderUserId !== previousMessage?.senderUserId;

          if (
            messageClone.isUnread &&
            (!previousMessage ||
              !currentUser?.lastReadTime ||
              new Date(previousMessage.createdTime) < new Date(currentUser.lastReadTime))
          ) {
            messageClone.dividerHeadline = 'New';
          } else if (
            !previousMessage ||
            !datesAreOnSameDay(messageClone.createdTime, previousMessage.createdTime)
          ) {
            if (isToday(new Date(messageClone.createdTime))) {
              messageClone.dividerHeadline = 'Today';
            } else {
              messageClone.dividerHeadline = format(
                new Date(messageClone.createdTime),
                messagingDividerDateFormat,
              ).toString();
            }
          }

          messageClone.displayName =
            conversation?.participants?.find((p) => p.userId === messageClone.senderUserId)
              ?.displayName || undefined;

          return messageClone;
        });
      });

      return {
        pageParams,
        pages: transformedPages,
      };
    },
  });

  const add = (message: IMessageDto) => {
    if (message.conversationId !== conversationId) return;
    updateInfiniteQueriesData<IMessageDto>(
      queryClient,
      messageKeys.list(message.conversationId as string),
      (messages) => {
        if (messages.length > 0 && messages[0].id === message.id) {
          return messages;
        }
        return [message, ...messages];
      },
      (_, index) => index === 0,
    );
  };

  const update = (message: IMessageDto, oldId?: string) => {
    if (message.conversationId !== conversationId) return;
    updateInfiniteQueriesData<IMessageDto>(
      queryClient,
      messageKeys.list(message.conversationId as string),
      (messages) =>
        messages.map((_message) => (_message.id === (oldId ?? message.id) ? message : _message)),
    );
  };

  const setFileUploadProgress = (fileId: string, progress: number | undefined) => {
    if (!conversationId) return;
    updateInfiniteQueriesData<IMessageDto>(
      queryClient,
      messageKeys.list(conversationId),
      (_messages) => {
        const messagesClone = _messages.map((message) => {
          const messageClone: IMessageDto = { ...message };
          if (
            !messageClone.metaData ||
            !messageClone.metaData.files ||
            messageClone.metaData.files.length === 0
          ) {
            return messageClone;
          }

          messageClone.metaData.files = messageClone.metaData.files.map((file) => {
            const fileClone: IMessageFileDto = { ...file };
            if (fileClone.fileId !== fileId) return fileClone;

            fileClone.progress = progress;
            return fileClone;
          });

          return messageClone;
        });

        return messagesClone;
      },
    );
  };

  return {
    add,
    update,
    setFileUploadProgress,
    ...queryInfo,
  };
};

export const useMessageSubscription = (conversationId: string) => {
  const { state } = useContext(AppContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!('on' in state.connection)) return undefined;

    const _receiveMessage = (message: IMessageDto) => {
      if (message.conversationId !== conversationId) return;

      if (message.updatedTime) {
        updateInfiniteQueriesData<IMessageDto>(
          queryClient,
          messageKeys.list(conversationId as string),
          (messages) =>
            messages.map((_message) => {
              if (message.id === _message.id) {
                const messageClone = { ...message };
                return messageClone;
              }
              return _message;
            }),
        );
      } else {
        updateInfiniteQueriesData<IMessageDto>(
          queryClient,
          messageKeys.list(message.conversationId as string),
          (messages) => {
            if (messages.length > 0 && messages[0].id === message.id) {
              return messages;
            }
            return [message, ...messages];
          },
          (_, index) => index === 0,
        );
      }

      setConversationRead(conversationId); // TODO Mutation??
    };

    state.connection.on('ReceiveMessage', _receiveMessage);

    const _deleteMessage = (message: IMessageDto) => {
      if (message.conversationId !== conversationId) return;

      updateInfiniteQueriesData<IMessageDto>(
        queryClient,
        messageKeys.list(message.conversationId as string),
        (messages) => messages.filter((_message) => _message.id !== message.id),
      );

      setConversationRead(conversationId); // TODO Mutation??
    };

    state.connection.on('DeleteMessage', _deleteMessage);

    const _receiveReaction = (reaction: IReaction) => {
      if (reaction.conversationId !== conversationId) return;

      updateInfiniteQueriesData<IMessageDto>(
        queryClient,
        messageKeys.list(conversationId),
        (messages) =>
          messages.map((message) => {
            if (message.id === reaction.messageId) {
              const reactionsClone = message.reactions.some((r) => r.userId === reaction.userId)
                ? message.reactions.filter((r) => r.id !== reaction.id)
                : [...message.reactions];

              reactionsClone.push(reaction);
              return { ...message, reactions: reactionsClone };
            }
            return message;
          }),
      );
    };

    state.connection.on('ReceiveMessageReaction', _receiveReaction);

    const _removeMessageReaction = (reaction: IReaction) => {
      if (reaction.conversationId !== conversationId) return;

      updateInfiniteQueriesData<IMessageDto>(
        queryClient,
        messageKeys.list(conversationId),
        (messages) =>
          messages.map((message) => {
            if (message.id === reaction.messageId) {
              const messageClone = { ...message };

              messageClone.reactions = messageClone.reactions.filter((r) => r.id !== reaction.id);
              return messageClone;
            }
            return message;
          }),
      );
    };

    state.connection.on('RemoveMessageReaction', _removeMessageReaction);

    return () => {
      state.connection.off('ReceiveMessage', _receiveMessage);
      state.connection.off('DeleteMessage', _deleteMessage);
      state.connection.off('ReceiveMessageReaction', _receiveReaction);
      state.connection.off('RemoveMessageReaction', _removeMessageReaction);
      // Invalidate the messages of this conversation to trigger a refetch next time it is loaded.
      queryClient.invalidateQueries({
        queryKey: messageKeys.list(conversationId),
        refetchType: 'none',
      });
      queryClient.invalidateQueries({
        queryKey: messageKeys.details(conversationId),
        refetchType: 'none',
      });
    };
  }, [conversationId, state.connection, queryClient]);
};

interface ISetReactionProps {
  conversationId: string;
  messageId: string;
  emoji: string;
}

export function useAddReactionMutation() {
  return useMutation({
    mutationFn: ({ conversationId, messageId, emoji }: ISetReactionProps) =>
      setReaction(conversationId, messageId, emoji),
    onError: useDispatchApiError(),
  });
}

interface IDeleteReactionProps {
  message: IMessageDto;
}

export function useDeleteReactionMutation() {
  return useMutation({
    mutationFn: ({ message }: IDeleteReactionProps) =>
      deleteReaction(message.conversationId as string, message.id as string),
    onError: useDispatchApiError(),
  });
}

interface IDeleteMessageProps {
  message: IMessageDto;
}

export function useDeleteMessageMutation() {
  return useMutation({
    mutationFn: ({ message }: IDeleteMessageProps) =>
      deleteMessage(message.conversationId as string, message.id as string),
    onError: useDispatchApiError(),
  });
}

interface IUpdateMessageProps {
  conversationId: string;
  messageId: string;
  message: IUpdateMessageDto;
}

export function useUpdateMessageMutation() {
  return useMutation({
    mutationFn: ({ conversationId, messageId, message }: IUpdateMessageProps) =>
      updateConversationMessage(
        conversationId as string,
        messageId as string,
        message as IUpdateMessageDto,
      ),
    onError: useDispatchApiError(),
  });
}
