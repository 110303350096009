import { StateCreator } from 'zustand';
import { CombinedSlices } from '../useGlobalStore';

export type ViewName =
  | 'contact'
  | 'network'
  | 'pipeline'
  | 'organization'
  | 'settings'
  | 'project'
  | 'board';

export type ViewLayout = 'card' | 'list';

export interface IView {
  name: ViewName;
  isSidePanelOpen: boolean;
  activeViewLayout?: ViewLayout;
}

const defaultActiveViewLayout = 'card';

const initialViews: IView[] = [
  {
    name: 'contact',
    isSidePanelOpen: true,
    activeViewLayout: defaultActiveViewLayout,
  },
  {
    name: 'network',
    isSidePanelOpen: true,
    activeViewLayout: defaultActiveViewLayout,
  },
  {
    name: 'pipeline',
    isSidePanelOpen: true,
    activeViewLayout: defaultActiveViewLayout,
  },
  {
    name: 'organization',
    isSidePanelOpen: true,
    activeViewLayout: defaultActiveViewLayout,
  },
  {
    name: 'project',
    isSidePanelOpen: true,
    activeViewLayout: defaultActiveViewLayout,
  },
  {
    name: 'board',
    isSidePanelOpen: true,
    activeViewLayout: defaultActiveViewLayout,
  },
];

export interface ViewsSlice {
  views: IView[];
  setSidePanelOpen: (name: ViewName, isOpen: boolean) => void;
  setActiveViewLayout: (name: ViewName, setting: ViewLayout) => void;
  toggleSidePanel: (name: ViewName) => void;
  getViewSettings: (name: ViewName) => IView;
}

export const createViewSlice: StateCreator<
  CombinedSlices,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  ViewsSlice
> = (set, get) => ({
  views: initialViews,
  setSidePanelOpen: (name, isOpen) =>
    set((state) => ({
      views: state.views.map((view) => {
        if (view.name === name) {
          return {
            ...view,
            isSidePanelOpen: isOpen,
          };
        }
        return view;
      }),
    })),

  toggleSidePanel: (name) =>
    set((state) => ({
      views: state.views.map((view) => {
        if (view.name === name) {
          return {
            ...view,
            isSidePanelOpen: !view.isSidePanelOpen,
          };
        }
        return view;
      }),
    })),
  getViewSettings: (name) => get().views.find((view) => view.name === name) as IView,
  setActiveViewLayout: (name, activeViewLayout) =>
    set((state) => ({
      views: state.views.map((view) => {
        if (view.name === name) {
          return {
            ...view,
            activeViewLayout,
          };
        }
        return view;
      }),
    })),
});
