import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import RadioButton from '../../shared/components/radiobuttons/RadioButton';
import RadioButtonGroup from '../../shared/components/radiobuttons/RadioButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ButtonGroupJustifyTypes } from '../../shared/constants/ButtonGroupJustifyTypes';
import { REGEX_EMAIL } from '../../shared/constants/RegexValidations';
import Text from '../../shared/components/text/Text';
import useCheckMobileScreen from '../../shared/hooks/useCheckMobileScreen';
import Header1 from '../../shared/components/headers/Header1';
import { UserProfileDto } from './models/UserProfile';
import { useUpdateMyUserProfileMutation } from './queries/userProfileQueries';
import { useMyUserPhotoMutation } from './queries/userPhotoQueries';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';

interface IProps {
  myProfile: UserProfileDto;
}

export default function MyProfileForm({ myProfile }: IProps) {
  const myProfileMutation = useUpdateMyUserProfileMutation();
  const myProfilePhotoMutation = useMyUserPhotoMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    values: myProfile,
  });
  const isMobile = useCheckMobileScreen();

  const onSubmit: SubmitHandler<UserProfileDto> = (_myProfile) => {
    myProfileMutation.mutate(_myProfile);
  };

  const handlePhotoModalUpload = (file: Blob) => {
    myProfilePhotoMutation.mutate({ file });
  };

  const handleRemoveProfilePicture = () => {
    myProfilePhotoMutation.mutate({});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 md:grid-cols-3 divide-x-0 md:divide-x-2 ">
        <div className="px-4 md:px-12 flex flex-col gap-6">
          <div>
            <Header1>Profile</Header1>
            <ProfileAvatarForm
              url={myProfile.photoUrl}
              altText={myProfile.firstName}
              onUpload={handlePhotoModalUpload}
              onRemove={handleRemoveProfilePicture}
              isPending={myProfileMutation.isPending}
            />
          </div>
          {!isMobile && (
            <div>
              <TextAreaInput
                labelText="About me"
                name="additionalInformation"
                register={register}
                errors={errors}
                errorMessage="Maximum 4000 characters"
                validationRules={{ maxLength: 4000 }}
                className="bg-white"
              />
            </div>
          )}
        </div>
        <div className="px-4 md:px-12 flex flex-col gap-6 md:m-0 mb-4 mt-4">
          <div className="flex flex-row gap-6">
            <div className="flex-1">
              <TextFieldInput
                labelText="First name"
                name="firstName"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Required & between 2 & 400 characters"
                validationRules={{ required: true, minLength: 2, maxLength: 400 }}
              />
            </div>
            <div className="flex-1">
              <TextFieldInput
                labelText="Last name"
                name="lastName"
                type="text"
                register={register}
                errors={errors}
                errorMessage="Required & between 2 & 400 characters"
                validationRules={{ required: true, minLength: 2, maxLength: 400 }}
              />
            </div>
          </div>
          <div>
            <TextFieldInput
              labelText="Phone number"
              name="phoneNumbers.0.value"
              type="text"
              register={register}
              errors={errors}
              errorMessage="Maximum 20 characters"
              validationRules={{ maxLength: 20 }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Email address"
              name="primaryEmailAddress"
              type="text"
              disabled
              register={register}
              errors={errors}
              errorMessage="Required & between 2 & 400 characters"
              validationRules={{
                required: true,
                minLength: 2,
                maxLength: 400,
                pattern: REGEX_EMAIL,
              }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Company name"
              name="company"
              type="text"
              register={register}
              errors={errors}
              errorMessage="Maximum 400 characters"
              validationRules={{ maxLength: 400 }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Title"
              name="title"
              type="text"
              register={register}
              errors={errors}
              errorMessage="Maximum 400 characters"
              validationRules={{ maxLength: 400 }}
            />
          </div>
          <div>
            <TextFieldInput
              labelText="Location"
              name="location"
              type="text"
              register={register}
              errors={errors}
              errorMessage="Maximum 400 characters"
              validationRules={{ maxLength: 400 }}
            />
          </div>
          {isMobile && (
            <div>
              <TextAreaInput
                labelText="About me"
                name="additionalInformation"
                register={register}
                errors={errors}
                errorMessage="Maximum 4000 characters"
                validationRules={{ maxLength: 4000 }}
                className="bg-white"
              />
            </div>
          )}
        </div>
        <SpacingContainer className="px-4 md:px-12">
          <RadioButtonGroup
            name="visibility"
            label="Profile privacy"
            description="Choose the privacy of the profile"
          >
            <RadioButton name="visibility" label="Public" value="Public" register={register} />
            <RadioButton name="visibility" label="Private" value="Private" register={register} />
          </RadioButtonGroup>
          <Text as="p" size="small" brightness="light" leading="snug">
            Having a public profile makes your profile searchable within Yoin.
          </Text>
        </SpacingContainer>
      </div>

      <div className="px-12 pt-6">
        <ButtonGroup justify={ButtonGroupJustifyTypes.End}>
          <Button
            text="Revert changes"
            color={ButtonColors.White}
            disabled={!isDirty || myProfileMutation.isPending}
            onClick={() => reset(myProfile)}
          />
          <SubmitButton
            text="Save"
            isDisabled={!isValid || !isDirty || myProfileMutation.isPending}
          />
        </ButtonGroup>
      </div>
    </form>
  );
}
