import { AtSymbolIcon } from '@heroicons/react/24/outline';
import ActivityBadge, { IActivityBadgeProps } from './ActivityBadge';

export type IMentionActivityBadgeProps = Omit<IActivityBadgeProps, 'icon' | 'classNames'>;

export default function MentionActivityBadge(props: IMentionActivityBadgeProps) {
  return (
    <ActivityBadge
      icon={<AtSymbolIcon width="20" height="21" stroke="#F782C2" />}
      classNames="bg-yellow-light"
      {...props}
    />
  );
}
