import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { boardKeys } from './boardQueries';
import { deleteBoardPhoto, uploadBoardPhoto } from '../services/projectService';

type MutateBoardPhotoProps = {
  projectId: string,
  boardId: string,
  file?: Blob;
};

export function useBoardPhotoMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, boardId, file }: MutateBoardPhotoProps) => {
      if (typeof file === 'undefined') {
        return deleteBoardPhoto(projectId, boardId);
      }

      return uploadBoardPhoto(projectId, boardId, file);
    },
    onSuccess: (_, { projectId, boardId }) => {
      queryClient.invalidateQueries({ queryKey: boardKeys.detail(projectId, boardId) });
      queryClient.invalidateQueries({ queryKey: boardKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
