import { useDeleteNetworkInviteRequestMutation } from './queries/networkInviteQueries';
import { networkBaseQuery } from '../Networks/queries/networkQueries';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import { RequestItems } from './RequestItems';
import { IInviteRequestDto } from './models/IInviteRequestDto';

interface IProps {
  networkInviteRequests: IInviteRequestDto[];
}

export default function InviteToNetworkRequest({ networkInviteRequests }: IProps) {
  const deleteNetworkInviteRequestMutation = useDeleteNetworkInviteRequestMutation();

  return (
    <RequestItems
      requests={networkInviteRequests}
      resourceQuery={(request: IInviteRequestDto) => networkBaseQuery(request.sharedResourceId)}
      text={(request, network) => (
        <>
          Invite to network &apos;{network?.name}&apos; sent <TimeAgo date={request.createdTime} />{' '}
          ago.
        </>
      )}
      actions={(request) => [
        {
          text: 'Cancel',
          onClick: () => deleteNetworkInviteRequestMutation.mutate(request),
          disabled: deleteNetworkInviteRequestMutation.isPending,
        },
      ]}
    />
  );
}
