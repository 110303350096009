import { classNames } from '../../utils/stringUtils';
import BorderActiveHorizontal from '../indicators/BorderActiveHorizontal';

interface IProps<T extends string> {
  id: T;
  activeId: T;
  title: string;
  icon?: React.ReactNode;
  badgeCount?: number;
  paddingBottom?: string;
  textColor?:string;
  setActiveTab: (id: T) => void;
}

export default function TabNavItem<T extends string>({
  id,
  activeId,
  title,
  icon,
  badgeCount,
  setActiveTab,
  paddingBottom = 'pb-2 ',
  textColor = 'text',
}: IProps<T>) {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <div className="block relative">
      <nav className="mb-px flex space-x-8 relative" aria-label="Tabs">
        <button
          type="button"
          key={id}
          onClick={handleClick}
          className={classNames(
            id === activeId
              ? '!font-bold '
              : 'border-transparent text hover:text-light hover:border-gray-300',
            'flex gap-1 whitespace-nowrap',
            paddingBottom,
            'px-1 font-medium text-md items-center',
            textColor,
          )}
        >
          <span className={classNames(id === activeId ? ' text-cyan' : '')}>{!!icon && icon}</span>
          {title}
          {badgeCount ? (
            <span className="bg-blue text-white whitespace-nowrap rounded-sm px-1 font-medium text-xs items-center">
              {badgeCount}
            </span>
          ) : (
            <span className="hidden" />
          )}
          {id === activeId && <BorderActiveHorizontal thickness="thin" bottomOffset={-3} />}
        </button>
      </nav>
    </div>
  );
}
