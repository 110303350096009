import { useAccount } from '@azure/msal-react';
import { useCallback } from 'react';
import { useMyUserProfileQuery } from '../../features/Profile/queries/userProfileQueries';
import { getUserId } from './authUtils';

export type IsMeByUserIdFunc = (userId: string) => boolean;

export function useMyUserId() {
  const account = useAccount();
  const userId = getUserId(account?.idTokenClaims);
  const { data: profile } = useMyUserProfileQuery(!userId);
  if (userId) {
    return userId;
  }

  return profile?.id;
}

export function useIsMeByUserId(): IsMeByUserIdFunc {
  const activeUserId = useMyUserId();
  // Memoize the returned function to be able to use it inside useEffect hooks.
  return useCallback((userId: string) => userId === activeUserId, [activeUserId]);
}
