import ContentLoader from 'react-content-loader';

interface IProps {
  speed?: number;
}

export function SelectCardListLoader({ speed = 2 }: IProps) {
  return (
    <ContentLoader
      speed={speed}
      backgroundColor="#eeeff1"
      foregroundColor="#e0e3e6"
      style={{ width: '100%', height: '225px' }}
    >
      <rect x="0" y="8" rx="8" ry="8" width="225" height="225" />
      <rect x="235" y="8" rx="8" ry="8" width="225" height="225" />
    </ContentLoader>
  );
}
