import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { ChartBarIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import { Share2Icon, Users2Icon } from 'lucide-react';
import { IPipelineItemDto } from './models/IPipelineItemDto';
import { CalculateDragDropPositions } from '../../shared/utils/dragDropUtils';
import { useMovePipelineItemMutation, pipelineItemKeys } from './queries/itemQueries';
import { useStagesQuery } from './queries/stageQueries';
import { usePipelineStoreContext } from './context/pipeline-context';
import { usePipelineQuery } from './queries/pipelineQueries';
import PipelineCreateStage from './PipelineCreateStage';
import PipelineStage from './PipelineStage';
import Header1 from '../../shared/components/headers/Header1';
import WhiteBox from '../../shared/components/structure/WhiteBox';
import Switch from '../../shared/components/switch/Switch';
import VerticalDivider from '../../shared/components/dividers/VerticalDivider';
import { usePipelineKpiQuery } from './queries/pipelineKpiQueries';
import { ViewName } from '../../shared/store/slices/viewSlice';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import Text from '../../shared/components/text/Text';
import AddButton from '../../shared/components/buttons/AddButton';
import { ExpandableKpiIndicator } from '../../shared/components/kpi/ExpandableKpiIndicator';
import { TrendType } from './models/IPipelineKpiDto';

type Period = {
  startTime: Date;
  endTime: Date;
};

type PeriodTranslations = {
  week: string;
  month: string;
};

const now = new Date();

const defaultPeriod: Period = {
  startTime: startOfWeek(now, { weekStartsOn: 1 }),
  endTime: endOfWeek(now, { weekStartsOn: 1 }),
};

const periodTranlationsMappings: PeriodTranslations = {
  week: 'Weekly',
  month: 'Monthly',
};
function calculateKpiPeriod(time: 'week' | 'month'): Period {
  if (time === 'week') {
    return defaultPeriod;
  }

  return { startTime: startOfMonth(now), endTime: endOfMonth(now) };
}

function PipelineHeaderContainer({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col items-start justify-center space-y-2">{children}</div>;
}

function PipelineMainContainer({ children }: { children: React.ReactNode }) {
  return (
    <section className="flex flex-1 flex-row overflow-x-hidden mt-4">
      <div className="grid grid-flow-col gap-3 overflow-x-scroll horizontal-scroll">{children}</div>
    </section>
  );
}
// TODO: Move to shared folder
export function LabelWithTrend({ label, trend }: { label: string; trend: TrendType }) {
  let arrowClass = 'arrow-right';
  if (trend === 'Up') {
    arrowClass = 'arrow-up';
  } else if (trend === 'Down') {
    arrowClass = 'arrow-down';
  }
  return <span className={`text-sm ${arrowClass}`}>{label}</span>;
}

export interface IPipelineProps {
  pipelineId: string;
}

export default function Pipeline({ pipelineId }: IPipelineProps) {
  const {
    activePipelineSettings,
    selectPipeline,
    setActiveSidePanelView,
    activeSidePanelView,
    isSelectPipelineVisible,
    setIsSelectPipelineVisible,
    setActiveEditPipelineTab,
  } = usePipelineStoreContext();

  const currentView: ViewName = 'pipeline';
  const { getViewSettings, toggleSidePanel, setSidePanelOpen } = useGlobalStore();
  const viewSettings = getViewSettings(currentView);

  const kpiPeriod = useRef<Period>(defaultPeriod);

  const [kpiPeriodName, setKpiPeriodName] = useState<string>(periodTranlationsMappings.week);

  const stagesQuery = useStagesQuery({ pipelineId });
  const pipelineQuery = usePipelineQuery(pipelineId);

  const pipelineKpiQuery = usePipelineKpiQuery(
    pipelineId,
    kpiPeriod.current.startTime,
    kpiPeriod.current.endTime,
  );
  const queryClient = useQueryClient();
  const movePipelineItemMutation = useMovePipelineItemMutation();

  useEffect(() => {
    if (pipelineId) {
      selectPipeline(pipelineId);
      setActiveSidePanelView('EditPipeline');
    }
  }, [pipelineId]);

  const handleMoveStage = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceStageItems: IPipelineItemDto[] = queryClient.getQueryData(
      pipelineItemKeys.list({
        pipelineId: activePipelineSettings.pipelineId,
        stageId: source.droppableId,
      }),
    ) as IPipelineItemDto[];

    const targetStageItems: IPipelineItemDto[] = queryClient.getQueryData(
      pipelineItemKeys.list({
        pipelineId: activePipelineSettings.pipelineId,
        stageId: destination.droppableId,
      }),
    ) as IPipelineItemDto[];

    const { moveToPositionAfterItemId } = CalculateDragDropPositions<IPipelineItemDto>(
      sourceStageItems,
      targetStageItems,
      draggableId,
      destination.index,
    );

    movePipelineItemMutation.mutate({
      pipelineId: activePipelineSettings.pipelineId,
      sourceStageId: source.droppableId,
      itemId: draggableId,
      destinationIndex: destination.index,
      item: {
        moveToPositionAfterItemId,
        moveToStageId: destination.droppableId,
      },
    });
  };

  const recalculateValue = (time: string) => {
    kpiPeriod.current = calculateKpiPeriod(time as 'week' | 'month');

    setKpiPeriodName(periodTranlationsMappings[time as 'week' | 'month']);
  };

  const getBugetTextStyle = () => {
    if (!pipelineKpiQuery.data?.budgetVariance) {
      return 'text-cyan-alternative';
    }

    if (pipelineKpiQuery.data.budgetVariance === 0) {
      return 'text-yellow';
    }

    if (pipelineKpiQuery.data.budgetVariance < 0) {
      return 'text-red';
    }

    return 'text-green';
  };

  // Iv'e removed !pipelineKpiQuery.isSuccess temporarily to get rid of flickering and error when
  // switching periods. Need to find a new way to handle this so we can add it back and avoid elvis
  // operators all over the place below
  if (!pipelineQuery.isSuccess || !stagesQuery.isSuccess) {
    return <></>;
  }
  const expandableData = [
    {
      id: 'Contacts',
      label: 'Contacts',
      icon: <Users2Icon className="w-[21px] h-[21px} stroke-[#6B9080]" />,
      value: pipelineKpiQuery?.data?.numberOfNewContacts,
      trend: pipelineKpiQuery?.data?.numberOfNewContactsTrend,
    },
    {
      id: 'Networks',
      label: 'Networks',
      icon: <Share2Icon className="w-[21px] h-[21px} stroke-[#884DB7]" />,
      value: pipelineKpiQuery?.data?.numberOfNewNetworks,
      trend: pipelineKpiQuery?.data?.numberOfNewNetworksTrend,
    },
  ];

  return (
    <>
      <PipelineHeaderContainer>
        <div className="flex flex-row items-center pb-4">
          <Header1>{pipelineQuery.data.name}</Header1>
          <VerticalDivider />

          <Switch
            items={[
              { label: 'Week', value: 'week' },
              { label: 'Month', value: 'month' },
            ]}
            onChange={recalculateValue}
          />
        </div>

        <WhiteBox>
          <div className="flex flex-col justify-between p-5 w-80">
            <div className="flex flex-col">
              <Text as="h2" size="x2Large" weight="semibold" color="cyan" brightness="dark">
                Total
              </Text>

              <div className="flex flex-row items-end ">
                <CountUp
                  delay={0}
                  start={0}
                  end={pipelineQuery.data.value}
                  separator=" "
                  className="text-4xl text-cyan-alternative font-bold currency-suffix"
                />
                <span className="text-2xl text-cyan-light font-bold ml-2">
                  {pipelineQuery.data.currencyIsoCode}
                </span>
              </div>
            </div>
            <div className="flex flex-col mt-2">
              {pipelineKpiQuery?.data?.budgetVariance && pipelineKpiQuery.data.budgetAmount ? (
                <>
                  <span className="text-sm text">{kpiPeriodName} budget:</span>
                  <div className="flex flex-row">
                    <span className="text-sm text-cyan-alternative font-bold">
                      {new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 0 }).format(
                        pipelineKpiQuery.data.budgetAmount,
                      )}
                      {` ${pipelineQuery.data.currencyIsoCode}`}
                      <span className={`text-sm ml-2 font-bold ${getBugetTextStyle()}`}>
                        ({pipelineKpiQuery.data.budgetVariance > 0 ? '+' : ''}
                        {new Intl.NumberFormat('sv-SE', { maximumFractionDigits: 0 }).format(
                          pipelineKpiQuery.data.budgetVariance,
                        )}
                        )
                      </span>
                    </span>
                  </div>
                </>
              ) : (
                // TODO: Add navigation
                <AddButton
                  onClick={() => {
                    setSidePanelOpen(currentView, true);
                    setActiveEditPipelineTab('tab_budget');
                    setActiveSidePanelView('EditPipeline');
                  }}
                  text="Add budget"
                />
              )}
            </div>
          </div>

          <VerticalDivider />

          <div className="flex flex-1 flex-col items-start ml-4">
            <ExpandableKpiIndicator data={expandableData} defaultIsExpanded />
          </div>
        </WhiteBox>

        <div className="flex flex-row space-x-1 justify-end w-full">
          <ChartBarIcon
            className={`${
              isSelectPipelineVisible ? 'text-cyan-alternative' : 'text-light'
            } text-light w-6 h-6 cursor-pointer `}
            onClick={() => setIsSelectPipelineVisible(!isSelectPipelineVisible)}
          />
          <Cog6ToothIcon
            className={`${
              viewSettings.isSidePanelOpen ? 'text-cyan-alternative' : 'text-light'
            } text-light w-6 h-6 cursor-pointer `}
            onClick={() => {
              if (activeSidePanelView === 'EditPipeline' && viewSettings.isSidePanelOpen) {
                toggleSidePanel(currentView);
              } else if (activeSidePanelView === 'EditPipeline' && !viewSettings.isSidePanelOpen) {
                toggleSidePanel(currentView);
              } else {
                setActiveSidePanelView('EditPipeline');
              }
            }}
          />
        </div>
      </PipelineHeaderContainer>
      {activePipelineSettings.pipelineId && (
        <PipelineMainContainer>
          {activePipelineSettings.pipelineId && (
            <DragDropContext onDragEnd={handleMoveStage}>
              {stagesQuery.data.map((_stage) => (
                <PipelineStage key={`Stage-${_stage.id}`} stage={_stage} />
              ))}
              <PipelineCreateStage />
            </DragDropContext>
          )}
        </PipelineMainContainer>
      )}
    </>
  );
}
