import { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import MenuItemActionButton from '../../shared/components/tabs/MenuItemActionButton';
import PipelineCreateNetworkItem from './PipelineCreateNetworkItem';
import { useCreatePipelineItemMutation } from './queries/itemQueries';
import PipelineCreateContactItem from './PipelineCreateContactItem';
import { usePipelineStoreContext } from './context/pipeline-context';
import { Popover, PopoverContent, PopoverTrigger } from '../../shared/components/popovers/Popover';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import CurrencyInput from '../../shared/components/inputs/number/CurrencyInput';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import Button from '../../shared/components/buttons/Button';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Label from '../../shared/components/text/Label';
import convertFormattedCurrencyToNumber from '../../shared/utils/numberUtils';
import Header2 from '../../shared/components/headers/Header2';
import { usePipelineQuery } from './queries/pipelineQueries';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import { useShareResourceMutation } from './queries/pipelineSharedResourcesQueries';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import RadioButtonGroup from '../../shared/components/radiobuttons/RadioButtonGroup';
import RadioButton from '../../shared/components/radiobuttons/RadioButton';
import Text from '../../shared/components/text/Text';
import { ResourceType } from '../../shared/model/ResourceType';

export interface ICreateItemSettingsDto {
  stageId: string;
  type: ResourceType;
}

interface IPipelineCreateItemProps {
  stageId: string;
}

type ResourceInfo = {
  resourceId: string;
  name: string;
  resolvedType?: ResourceType;
};

export type ResourceCallback = () => Promise<ResourceInfo>;

function SelectType({ onSelect }: { onSelect: (type: ResourceType) => void }) {
  const pipelineItemManifest = useEntityManifest(ResourceType.PipelineItem);
  const networkManifest = useEntityManifest(ResourceType.Network);
  const contactManifest = useEntityManifest(ResourceType.Contact);

  return (
    <>
      <MenuItemActionButton
        text="Add Item"
        icon={
          <ProfileAvatar
            avatarProps={{
              src: pipelineItemManifest.icon,
              widthClass: 'w-5',
              heightClass: 'h-5',
            }}
          />
        }
        onClick={() => onSelect(ResourceType.PipelineItem)}
      />
      <HorizontalDivider text="Choose sales type" />
      <MenuItemActionButton
        text="Add Network"
        icon={
          <ProfileAvatar
            avatarProps={{
              src: networkManifest.icon,
              widthClass: 'w-5',
              heightClass: 'h-5',
            }}
          />
        }
        onClick={() => onSelect(ResourceType.Network)}
      />
      <MenuItemActionButton
        text="Add Contact"
        icon={
          <ProfileAvatar
            avatarProps={{
              src: contactManifest.icon,
              widthClass: 'w-5',
              heightClass: 'h-5',
            }}
          />
        }
        onClick={() => onSelect(ResourceType.Contact)}
      />
    </>
  );
}

type CreateItemProps = {
  name: string;
  type: ResourceType;
  currencyIsoCode: string;
  onCreate: (name: string, value: number, isLiveUpdated: boolean) => Promise<void>;
  onCancel?: () => void;
};

type ResourceUpdateType = 'snapshot' | 'keepInSync';

function CreateItem({ name, type, currencyIsoCode, onCreate, onCancel }: CreateItemProps) {
  const {
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name,
      value: 0,
      isLiveUpdated: 'snapshot' as ResourceUpdateType,
    },
  });

  const handleCreateItem = async () => {
    const formData = getValues();
    const { name: newName } = formData;
    const value = convertFormattedCurrencyToNumber(formData.value);
    const isLiveUpdate = formData.isLiveUpdated !== 'snapshot';
    await onCreate(newName, value, isLiveUpdate);
    reset();
  };

  return (
    <SpacingContainer>
      <Header2>Item details</Header2>
      <SpacingContainerItem>
        <TextFieldInput
          labelText="Name"
          name="name"
          type="text"
          register={register}
          errors={errors}
          errorMessage="Must enter a name"
          validationRules={{ required: true }}
        />
      </SpacingContainerItem>
      <SpacingContainerItem>
        <Label htmlFor="value" text={`Amount (${currencyIsoCode})`} />
        <CurrencyInput {...register('value')} />
      </SpacingContainerItem>
      {type !== ResourceType.PipelineItem && (
        <SpacingContainerItem>
          <RadioButtonGroup name="isLiveUpdated" label="Data sharing type">
            <RadioButton
              name="isLiveUpdated"
              label="Keep in sync"
              value="keepInSync"
              register={register}
            />
            <RadioButton
              name="isLiveUpdated"
              label="One time copy"
              value="snapshot"
              register={register}
            />

            <Text as="p" size="small" brightness="light" leading="snug">
              If the information you are sharing should be kept in sync with the resource it comes
              from or if a one time copy should be used.
            </Text>
          </RadioButtonGroup>
        </SpacingContainerItem>
      )}
      <ButtonGroup>
        {onCancel && <Button color={ButtonColors.White} text="Back" onClick={onCancel} />}
        <Button color={ButtonColors.Blue} text="Add" onClick={handleCreateItem} />
      </ButtonGroup>
    </SpacingContainer>
  );
}

type WizardState = {
  type?: ResourceType;
  name?: string;
  resourceId?: string;
  getResourceInfo?: ResourceCallback;
};

type WizardProps = IPipelineCreateItemProps & {
  open: boolean;
  onChange: (open: boolean) => void;
};

function Wizard({ stageId, open, onChange }: WizardProps) {
  const [wizardState, setWizardState] = useState<WizardState>({});

  const { activePipelineSettings } = usePipelineStoreContext();
  const { pipelineId } = activePipelineSettings;
  const pipelineQuery = usePipelineQuery(pipelineId);
  const createPipelineItemMutation = useCreatePipelineItemMutation();
  const shareResourceMutation = useShareResourceMutation();

  useEffect(() => {
    if (!open) {
      setWizardState({});
    }
  }, [open]);

  if (!pipelineQuery.isSuccess) {
    return null;
  }

  const handleSelectType = (type: ResourceType) => {
    setWizardState((state) => ({ ...state, type }));
  };

  const handleSelectResource = async (getResourceInfo: ResourceCallback) => {
    const { name, resourceId, resolvedType } = await getResourceInfo();
    setWizardState((state) => ({ ...state, name, resourceId, resolvedType }));
  };

  const handleCreateItem = async (name: string, value: number, isLiveUpdate: boolean) => {
    const { type, resourceId } = wizardState;
    let sharedResourceId;

    if (resourceId && type) {
      const result = await shareResourceMutation.mutateAsync({
        pipelineId,
        request: {
          resourceId,
          type,
          isLiveUpdated: isLiveUpdate,
        },
      });
      sharedResourceId = result.id;
    }

    await createPipelineItemMutation.mutateAsync({
      pipelineId,
      stageId,
      item: {
        name,
        value,
        sharedResourceId,
      },
    });

    onChange(false);
  };

  if (!wizardState.type) {
    return <SelectType onSelect={handleSelectType} />;
  }

  if (wizardState.type === 'Network' && !wizardState.resourceId) {
    return <PipelineCreateNetworkItem onSelect={handleSelectResource} />;
  }

  if (wizardState.type === 'Contact' && !wizardState.resourceId) {
    return <PipelineCreateContactItem onSelect={handleSelectResource} />;
  }
  const onCancelCallback = () => setWizardState(({ type }) => ({ type }));
  const onCancel = wizardState.resourceId ? onCancelCallback : undefined;
  return (
    <CreateItem
      name={wizardState.name || ''}
      type={wizardState.type}
      currencyIsoCode={pipelineQuery.data.currencyIsoCode}
      onCreate={handleCreateItem}
      onCancel={onCancel}
    />
  );
}

export default function PipelineCreateItem({ stageId }: IPipelineCreateItemProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <PlusIcon className="h-6 w-6 text-cyan-alternative stroke-[3px]" aria-hidden="true" />
      </PopoverTrigger>
      <PopoverContent align="start" sideOffset={-15} alignOffset={15} className="w-64">
        <Wizard stageId={stageId} open={isOpen} onChange={setIsOpen} />
      </PopoverContent>
    </Popover>
  );
}
