import { useNetworkPrivilegesQuery } from './queries/networkPriviligesQueries';
import { useNetworkQuery } from './queries/networkQueries';
import MemberList from '../Members/MemberList';
import { ResourceType } from '../../shared/model/ResourceType';

interface INetworkMembersProps {
  networkId: string;
}

export default function NetworkMembers({ networkId }: INetworkMembersProps) {
  const networkPrivilegesQuery = useNetworkPrivilegesQuery(networkId);
  const networkPrivileges = networkPrivilegesQuery.data;
  const networkQuery = useNetworkQuery(networkId);
  const network = networkQuery.data;

  if (!network || !networkPrivileges) return <></>;

  return (
    <MemberList
      privileges={{ ...networkPrivileges, canReadMembers: true }}
      tenantId={network.tenantId}
      resourceIds={network.id}
      type={ResourceType.Network}
      resolveConfirmText={(member) =>
        `Are you sure that you want to remove ${member?.name} from the network?`
      }
      resolveHelpText={() =>
        'See the members of this network. You can connect with and follow contacts you are not yet connected to. Additionally, you can remove contacts from the network without removing them from your contact list.'
      }
    />
  );
}
