import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  ColumnHelper,
  createColumnHelper,
  DisplayColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  RowData,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { Dispatch, SetStateAction } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table';
import { Checkbox } from '../checkbox/Checkbox';

export type DataTableColumnHelper<TData extends RowData> = ColumnHelper<TData> & {
  multiSelect: () => DisplayColumnDef<TData>;
  actions: (
    cellCallback: (context: CellContext<TData, unknown>) => JSX.Element,
  ) => DisplayColumnDef<TData>;
};

export const createDataTableColumnHelper = <
  TData extends RowData,
>(): DataTableColumnHelper<TData> => {
  const columnHelper = createColumnHelper<TData>();
  return {
    display: columnHelper.display,
    accessor: columnHelper.accessor,
    group: columnHelper.group,
    multiSelect: () =>
      columnHelper.display({
        id: 'select',
        meta: {
          size: '50px',
        },
        minSize: 50,
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && 'indeterminate')
            }
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value);
            }}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        ),
      }),
    actions: (cellCallback) =>
      columnHelper.display({
        id: 'action',
        meta: {
          size: '65px',
        },
        minSize: 65,
        cell: cellCallback,
      }),
  };
};

export interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  getRowId?: (row: TData) => string;
  globalFilter?: string;
  columnFilters: ColumnFiltersState;
  onColumnFiltersChange: Dispatch<SetStateAction<ColumnFiltersState>>;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: Dispatch<SetStateAction<RowSelectionState>>;
  isLoading?: boolean;
}

export default function DataTable<TData, TValue>({
  columns,
  data,
  getRowId,
  globalFilter,
  columnFilters,
  rowSelection = {},
  onColumnFiltersChange,
  onRowSelectionChange,
  isLoading = false,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getRowId,
    state: {
      columnFilters,
      globalFilter,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange,
    onRowSelectionChange,
  });
  return (
    <div>
      <Table className="table-fixed">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const startWidth = (header.column.columnDef.meta as { size?: string })?.size || 'auto';
                const minWidth = header.column.columnDef.minSize;
                const styles = {
                  width: startWidth,
                  minWidth,
                };
                return (
                  <TableHead key={header.id} style={styles}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={columns.length}>Loading...</TableCell>
            </TableRow>
          )}
          {!isLoading &&
            table.getRowModel().rows.length > 0 &&
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {!isLoading && table.getRowModel().rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length} className="italic">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
