import { useContext } from 'react';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ToastType } from '../../../shared/components/toasts/constants/ToastTypes';
import { AppContext } from '../../../shared/context/context';
import { IContactDto } from '../models/IContactDto';
import {
  createContact,
  deleteContact,
  getContacts,
  getContact,
  updateContact,
} from '../services/contactService';
import { createToast } from '../../../shared/services/toastService';
import { getInitalDataPropsFromQueries } from '../../../shared/utils/queryClientUtils';
import { ICreateContactDto } from '../models/ICreateContactDto';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { followUser } from '../../Profile/services/userActionService';
import { userProfileKeys } from '../../Profile/queries/userProfileQueries';
import { GetContactsParams } from '../models/GetContactsParams';

export const contactKeys = {
  all: ['contacts'] as const,
  lists: () => [...contactKeys.all, 'list'] as const,
  list: (props: GetContactsParams) => [...contactKeys.lists(), props] as const,
  details: () => [...contactKeys.all, 'detail'] as const,
  detail: (id: string) => [...contactKeys.details(), id] as const,
};

const STALE_TIME = 1000 * 60; // 1 minute, contacts aren't updated that often.

export const contactsBaseQuery = (props: GetContactsParams, options?: Omit<UseQueryOptions<Awaited<ReturnType<typeof getContacts>>>, 'queryKey' | 'queryFn' | 'staleTime' | 'placeholderData'>) => ({
  queryKey: contactKeys.list(props),
  queryFn: () => getContacts(props),
  staleTime: STALE_TIME,
  placeholderData: keepPreviousData,
  ...options,
});

export const contactBaseQuery = (id: string, queryClient: QueryClient) => ({
  queryKey: contactKeys.detail(id as string),
  queryFn: () => getContact(id as string),
  ...getInitalDataPropsFromQueries<IContactDto>(
    queryClient,
    contactKeys.lists(),
    (contact) => contact.id === id,
  ),
  staleTime: STALE_TIME,
});

export const contactUserQuery = (userId: string) =>
({
  queryKey: contactKeys.list({ userId }),
  queryFn: () => getContacts({ userId }),
  staleTime: STALE_TIME,
} as const);

export const contactConnectionQuery = (queryClient: QueryClient, connectionId: string) =>
({
  queryKey: contactKeys.list({ connectionId }),
  queryFn: () =>
    getContacts({ connectionId }).then((contacts) =>
      contacts.length > 0
        ? contacts[0]
        : Promise.reject(new Error('Connection contact not found!')),
    ),
} as const);

export function useContactQuery(id?: string, enabled = true) {
  const queryClient = useQueryClient();
  return useQuery({ ...contactBaseQuery(id as string, queryClient), enabled: !!id && enabled });
}

export function useContactsQuery(props: GetContactsParams, options?: { enabled?: boolean }) {
  return useQuery({
    ...contactsBaseQuery(props, options),
  });
}

// Used to get contacts that can be shared to a connection.
// excludeContactId is used to exclude the connection contact from the list.
export function useContactsForSharedQuery(searchTerm?: string, excludeContactId?: string) {
  return useQuery({
    ...contactsBaseQuery({ searchTerm }),
    select: (contacts: IContactDto[]) =>
      contacts.filter((contact) => contact.id !== excludeContactId),
  });
}

// Used to get contacts that can be shared through the chat.
export function useContactsForMentionShareQuery<TData>(
  searchTerm: string | null,
  transformFunc: (contacts: IContactDto) => TData,
  excludeUserIds?: string[],
) {
  return useQuery({
    ...contactsBaseQuery({
      limit: 10,
      searchTerm: searchTerm || undefined,
      searchFields: ['FirstName', 'LastName'],
    }),
    select: (contacts: IContactDto[]) =>
      contacts
        .filter(
          (contact) =>
            !(
              contact.userId &&
              excludeUserIds &&
              excludeUserIds.includes(contact.userId)
            ),
        )
        .map(transformFunc),
    enabled: searchTerm !== null && searchTerm !== undefined,
  });
}

export function useIsFollowingQuery(userId?: string) {
  return useQuery({
    ...contactUserQuery(userId || ''),
    select: (data) => data.length > 0,
    enabled: !!userId,
  });
}

export interface IFollowUserProps {
  userId: string;
  firstName: string;
  lastName: string;
  contactId?: string;
}

export function useFollowMutation() {
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);
  return useMutation({
    mutationFn: ({ userId, contactId }: IFollowUserProps) =>
      followUser(userId, contactId),
    onSuccess: (_, { firstName, lastName }) => {
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
      queryClient.invalidateQueries({ queryKey: userProfileKeys.lists() });
      dispatch(
        createToast(
          'Success!',
          ToastType.Success,
          `You are now following ${firstName} ${lastName}`,
        ),
      );
    },
    onError: useDispatchApiError(),
  });
}

export function useDeleteContactMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ contactId }: { contactId: string }) =>
      deleteContact(contactId).then((response) => response.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}

export function useUpdateContactMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ contact, id }: { contact: IContactDto; id: string }) =>
      updateContact(contact, id).then((response) => response.data),
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
      queryClient.invalidateQueries({ queryKey: contactKeys.detail(id) });
    },
    onError: useDispatchApiError(),
  });
}

interface ICreateContactProps {
  contact: ICreateContactDto;
}

export function useCreateContactMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ contact }: ICreateContactProps) => createContact(contact),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: contactKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
