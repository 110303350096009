import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import ProjectCreateEditModal from './ProjectCreateEditModal';
import useModals from '../../shared/components/modal/hooks/useModal';

export function ProjectActionButtons() {
  const { openModal } = useModals();

  return (
    <Button
      color={ButtonColors.Blue}
      text="+ Add Project"
      onClick={() =>
        openModal({
          title: 'Create new project',
          content: <ProjectCreateEditModal />,
        })
      }
    />
  );
}
