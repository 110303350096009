import ProfileAvatar from '../../avatar/ProfileAvatar';

interface MemberRowCellCellProps {
  maxCount?: number;
  members: { id: string; photoUrl?: string }[];
  fallbackSrc: string;
}

export default function MemberRowCell({
  members,
  fallbackSrc,
  maxCount = 5,
}: MemberRowCellCellProps) {
  const actualMembers = Array(Math.min(members.length, maxCount));
  const difference = actualMembers.length - maxCount;
  return (
    <div className=" flex items-center-space-x-3">
      {members.length === 0 && '-'}
      {members.map((item) => (
        <div className="first:ml-0 -ml-3" key={`profile_${item.id}`}>
          <ProfileAvatar
            avatarProps={{
              src: item.photoUrl || fallbackSrc,
              widthClass: 'w-6',
              heightClass: 'h-6',
            }}
          />
        </div>
      ))}
      {difference > 0 && (
        <div className="-ml-3 gap-2 flex w-6 h-6 items-center justify-center rounded-full text-white text-xs bg-cyan-alternative">
          <span>+{difference}</span>
        </div>
      )}
    </div>
  );
}
