import { useMutation } from '@tanstack/react-query';
import { ResourceIds } from '../../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../../shared/model/ResourceType';
import { visit } from '../services/visitService';

interface UseVisitMutationParams {
  resourceType: ResourceType;
  resourceId: ResourceIds;
}

export function useVisitMutation() {
  return useMutation({
    mutationFn: ({ resourceType, resourceId }: UseVisitMutationParams) =>
      visit(resourceType, resourceId),
    scope: { id: 'visit' }, // This is used to prevent multiple mutations from running at the same time
  });
}
