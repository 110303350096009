import { PlusIcon } from 'lucide-react';
import { useNavigate } from 'react-router';
import AddButton from '../../shared/components/buttons/AddButton';
import { CompanyCreateEditModal } from './CompanyCreateEditModal';
import useModals from '../../shared/components/modal/hooks/useModal';

export default function CompanyNavActions() {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModals();

  const handleCreateOrganizationSuccess = (organizationId: string) => {
    navigate(`/companies/${organizationId}`);
    closeModal();
  };

  const openEditCreateModal = () => {
    openModal({
      title: 'Create new company',
      modalSize: 'small',
      content: <CompanyCreateEditModal onSuccess={handleCreateOrganizationSuccess} />,
    });
  };

  return (
    <>
      <span className="hidden 2xl:inline-block mt-4 ml-10">
        <AddButton onClick={openEditCreateModal} text="Add Company" />
      </span>

      <div className="2xl:hidden flex w-7 justify-end  pb-1 ">
        <PlusIcon
          onClick={openEditCreateModal}
          className="h-6 w-6 text-cyan stroke-[3px] cursor-pointer"
          aria-hidden="true"
        />
      </div>
    </>
  );
}
