import { ColumnDef, FilterFn } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { IActivityItem } from '../../shared/components/activity/IActivityItem';
import { ErrorComponent } from '../../shared/components/error-boundary/ErrorComponent';
import { ListLayout } from '../../shared/components/layout/pages/ListLayout';
import ActionRowCell from '../../shared/components/table/cells/ActionRowCell';
import { createDataTableColumnHelper } from '../../shared/components/table/DataTable';
import MemberRowCell from '../../shared/components/table/cells/MembersRowCell';
import PillRowCell, { IPillValue } from '../../shared/components/table/cells/PillRowCell';
import ProfileRowCell from '../../shared/components/table/cells/ProfileRowCell';
import StandardRowCell from '../../shared/components/table/cells/StandardRowCell';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { INetworkDto, NetworkTypes } from '../../shared/model/INetwork';
import { ResourceType } from '../../shared/model/ResourceType';
import { createActivityColumnDefinition } from '../Tracking/trackingHelpers';
import VisibilityHeaderCell from './cell/VisibilityHeaderCell';
import { NetworkContextProvider } from './context/network-context';
import useNetworkActions from './hooks/useNetworkActions';
import { useNetworksActions } from './hooks/useNetworksActions';
import { useNetworkHeaderActions } from './hooks/useNetworkHeaderActions';
import NetworkInfoModals from './NetworkInfoModals';
import { useNetworksInfiniteQuery } from './queries/networkQueries';

const networkTypes: IPillValue[] = [
  {
    color: 'green',
    label: 'Public',
    value: NetworkTypes.Public,
  },
  {
    color: 'purple',
    label: 'Private',
    value: NetworkTypes.Private,
  },
];

const activityHeaderItems = [
  { type: 'Contact', label: 'New contacts' },
  { type: 'Conversation', label: 'New conversations' },
  { type: 'Task', label: 'New tasks' },
  { type: 'Mention', label: 'New mentions' },
] as IActivityItem[];

const customFilterFunction: FilterFn<INetworkDto> = (row, columnId, filterValue) => {
  const value = row.getValue(columnId) as string;
  return value.toLowerCase().includes(filterValue.toLowerCase());
};

function useNetworkListColumns() {
  const myUserId = useMyUserId();
  const networkManifest = useEntityManifest(ResourceType.Network);
  const contactManifest = useEntityManifest(ResourceType.Contact);
  return useMemo(() => {
    const columnHelper = createDataTableColumnHelper<INetworkDto>();

    return [
      // columnHelper.multiSelect(),
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        filterFn: customFilterFunction,
        minSize: 250,
        cell: ({ row, getValue }) => (
          <ProfileRowCell
            photoUrl={row.original.photoUrl || networkManifest.icon}
            name={getValue()}
          />
        ),
      }),
      columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        filterFn: customFilterFunction,
        cell: ({ getValue }) => <StandardRowCell text={getValue()} />,
      }),
      columnHelper.accessor('memberCount', {
        meta: {
          size: '175px',
        },
        header: () => 'Members',
        cell: ({ getValue }) => (
          <MemberRowCell
            members={Array(getValue())
              .fill(1)
              .map((_, i) => ({ id: i.toString() }))}
            fallbackSrc={contactManifest.icon}
          />
        ),
      }),
      columnHelper.accessor('type', {
        id: 'visibility',
        meta: {
          size: '140px',
        },
        header: ({ column }) => <VisibilityHeaderCell column={column} />,
        cell: ({ getValue }) => <PillRowCell values={networkTypes} value={getValue()} />,
        filterFn: ({ getValue }, columnId, filterValue: string[]) => {
          if (filterValue.length === 0) {
            return true;
          }
          return filterValue.includes(getValue(columnId));
        },
      }),
      columnHelper.accessor('visits', {
        ...createActivityColumnDefinition<INetworkDto>({
          id: 'visits',
          activityItems: activityHeaderItems,
          myUserId: myUserId || '',
        }),
      }),
      columnHelper.actions(({ row }) => (
        <NetworkContextProvider>
          <ActionRowCell
            resolver={(isOpen) => useNetworkActions(isOpen ? row.original.id : undefined)}
          />
          <NetworkInfoModals network={row.original} />
        </NetworkContextProvider>
      )),
    ] as ColumnDef<INetworkDto>[];
  }, []);
}

export function NetworksList() {
  const columns = useNetworkListColumns();
  const networkQuery = useNetworksInfiniteQuery();

  const headerActions = useNetworkHeaderActions();
  const networksActions = useNetworksActions();

  if (networkQuery.isError) {
    return <ErrorComponent queryResults={[networkQuery]} />;
  }

  return (
    <ListLayout
      title="Networks"
      headerActions={headerActions}
      actions={networksActions}
      columns={columns}
      query={networkQuery}
    />
  );
}
