import { Fragment } from 'react';
import { ButtonColors } from '../../../constants/ButtonColors';
import IconButton from '../../buttons/IconButton';
import Header1 from '../../headers/Header1';
import { cn, isJSXElement } from '../../../../lib/utils';

export interface IServiceHeaderAction {
  id: string;
  icon: React.ElementType | JSX.Element;
  isActive?: boolean;
  onClick: () => Promise<void>;
}

export interface IServiceHeaderProps {
  text: string;
  actions?: IServiceHeaderAction[];
}

function Layout({ children }: { children: React.ReactNode }) {
  return <div className="flex lg:gap-24">{children}</div>;
}

function ActionsRow({ children }: { children: React.ReactNode }) {
  return <div className="flex gap-2 flex-1 justify-end">{children}</div>;
}

export default function ServiceHeader({ text, actions }: IServiceHeaderProps) {
  const actualActions = actions ?? [];

  return (
    <Layout>
      <Header1>{text}</Header1>
      {actualActions.length > 0 && (
        <ActionsRow>
          {actualActions.map((item) => (
            <Fragment key={item.id}>
              {!isJSXElement(item.icon) ? (
                <IconButton
                  icon={
                    <item.icon
                      className={cn(
                        'w-6 h-6 stroke-2',
                        item.isActive ? 'text-cyan ' : 'text-gray-dark ',
                      )}
                    />
                  }
                  onClick={item.onClick}
                  color={ButtonColors.Gray}
                />
              ) : (
                item.icon
              )}
            </Fragment>
          ))}
        </ActionsRow>
      )}
    </Layout>
  );
}
