import { SpacerDistance, distanceClassLookup } from '../spacing/HorizontalSpacer';

export interface IHorizontalDividerProps {
  text?: string;
  distance?: SpacerDistance;
}

export default function HorizontalDivider({ text, distance }: IHorizontalDividerProps) {
  const spacer = distanceClassLookup[distance || 'medium'];
  if (text) {
    return (
      <div className={`${spacer} relative flex items-center`}>
        <div className="flex-grow border-t border-gray-300 " />
        <span className="flex-shrink mx-4 text-gray-400 text-xs">{text}</span>
        <div className="flex-grow border-t border-gray-300 " />
      </div>
    );
  }

  return (
    <div className={`${spacer} relative flex items-center`}>
      <div className="flex-grow border-t border-gray-300 " />
    </div>
  );
}
