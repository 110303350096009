/* eslint-disable no-param-reassign */
import axios from 'axios';
import { msalInstance } from '../auth/authConfig';
import { baseURL } from './configuration';
import { getAccessToken } from '../auth/authUtils';

const apiClient = axios.create({
  baseURL,
  // Make it possible to use ngrok in development
  headers: {
    ...(process.env.NODE_ENV === 'development'
      ? {
          'ngrok-skip-browser-warning': '69420',
          'Access-Control-Allow-Origin': '*',
          'Referrer-Policy': 'no-referrer-when-downgrade',
        }
      : undefined),
    patch: {
      'Content-Type': 'application/merge-patch+json',
    },
  },
  paramsSerializer: {
    indexes: null, // Prevent axios from adding indexes to array params
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken(msalInstance);

    if (typeof config.headers !== 'undefined') {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default apiClient;
