export interface IActivityBadgeProps {
  icon: JSX.Element;
  classNames: string;
  value?: number;
  label?: string;
}

export default function ActivityBadge({ icon, classNames, value, label }: IActivityBadgeProps) {
  const maxCount = 99;
  return (
    <span className="flex items-center ">
      <span
        className={` relative rounded-2xl w-10 h-7 flex items-center justify-center  ${classNames}`}
      >
        {icon}
        {value !== undefined && (
          <div className="absolute min-w-4 text-center px-[2px] -right-1 -bottom-0 rounded-md text-white text-xs bg-blue">
            <span className="">{value > maxCount ? `${maxCount}+` : value}</span>
          </div>
        )}
      </span>
      {label && <span className="ml-2">{label}</span>}
    </span>
  );
}
