import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Text from '../../text/Text';
import ProfileAvatar from '../../avatar/ProfileAvatar';
import { IColorSetting } from '../../../hooks/useEntityManifest';

interface IProps {
  headline: string | undefined;
  subHeadline?: string | undefined;
  imageSrc: string;
  colorSetting: IColorSetting;
  resourceId?: string;
  resourceLink?: string;
  showLinkToResource?: boolean;
}

export default function SidePanelHeadline({
  headline,
  subHeadline,
  imageSrc,
  colorSetting,
  resourceId,
  resourceLink,
  showLinkToResource = false,
}: IProps) {
  return (
    <header
      className={`flex items-center p-4 relative rounded-t-lg h-20 ${colorSetting.background} ${colorSetting.text}`}
    >
      <div className="absolute top-12">
        <ProfileAvatar
          avatarProps={{
            src: imageSrc,
            alt: headline,
            widthClass: 'w-12',
            heightClass: 'h-12',
          }}
        />
      </div>
      <div className="flex-column ml-20 absolute top-12">
        <h1
          className={`text-2xl font-medium text-white truncate max-w-[240px] ${colorSetting.text}`}
        >
          {headline}
        </h1>
        {resourceId && showLinkToResource ? (
          <Link to={resourceLink || ''}>
            <div className="flex flex-1 items-center mt-1">
              <ArrowTopRightOnSquareIcon className="w-4 h-4 text-light mr-1" />
              <Text size="xSmall" brightness="light">
                Go to resource {subHeadline && <span className="mx-1">| {subHeadline}</span>}
              </Text>
            </div>
          </Link>
        ) : (
          <div className="mt-1">
            <Text size="xSmall" brightness="light">
              {subHeadline}
            </Text>
          </div>
        )}
      </div>
    </header>
  );
}
