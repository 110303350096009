import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConnectionRequestStatuses } from '../../../shared/constants/ConnectionRequestStatuses';
import QueryParam from '../../../shared/utils/query-string-builder/QueryParam';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { InviteRequestType } from '../models/InviteRequestType';
import { deleteInviteRequest, getInviteRequest } from '../services/inviteRequestService';

interface IUseProjectInviteRequestProps {
  type?: 'Sent' | 'Received';
  status?: ConnectionRequestStatuses;
}

const projectInviteRequestKeys = {
  all: ['project-invite-request'] as const,
  lists: () => [...projectInviteRequestKeys.all, 'list'] as const,
  list: (props: IUseProjectInviteRequestProps) =>
    [...projectInviteRequestKeys.lists(), props] as const,
};

export function useProjectInviteRequestsQuery({ type, status }: IUseProjectInviteRequestProps) {
  return useQuery({
    queryKey: projectInviteRequestKeys.list({ type, status }),
    queryFn: () =>
      getInviteRequest(
        InviteRequestType.Project,
        new QueryParam('type', type),
        new QueryParam('status', status),
      ),
  });
}

interface IDeleteProjectInviteRequestProps {
  id: string;
}

export function useDeleteProjectInviteRequestMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: IDeleteProjectInviteRequestProps) =>
      deleteInviteRequest(InviteRequestType.Project, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectInviteRequestKeys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
