interface IProps {
  children: React.ReactNode;
}

export default function MainPanelDesktop({ children }: IProps) {
  return (
    <section className="flex-1 overflow-hidden">
      {children}
    </section>
  );
}
