import { Dispatch, SetStateAction, useCallback, useState } from 'react';

/**
 * Used to implement a component that can be both controlled and uncontrolled.
 * Use the same way as useState, but pass value and onChange from props as arguments.
 * If the value is undefined, the internal state will be used.
 *
 * @param controlledValue
 * @param controlledOnChange
 * @param defaultValue
 * @returns
 */
export function useOptionalControlledState<TValue>(
  controlledValue: TValue | undefined,
  controlledOnChange: Dispatch<SetStateAction<TValue>> | undefined,
  defaultValue: TValue,
) {
  const isControlled = controlledValue !== undefined;

  const [internalValue, setInternalValue] = useState(defaultValue);

  const onChange = useCallback(
    (value: SetStateAction<TValue>) => {
      if (controlledOnChange) {
        controlledOnChange(value);
      }

      if (!isControlled) {
        setInternalValue(value);
      }
    },
    [isControlled, controlledOnChange],
  );

  return [isControlled ? controlledValue : internalValue, onChange] as const;
}
