import ProjectsCardView from './ProjectsCardView';
import ProjectsListView from './ProjectsListView';
import { ViewLayout } from '../../shared/store/slices/viewSlice';

interface IProps {
  activeViewLayout?: ViewLayout;
}

export default function ProjectsSearch({ activeViewLayout = 'card' }: IProps) {
  return <>{activeViewLayout === 'card' ? <ProjectsCardView /> : <ProjectsListView />}</>;
}
