import { ExpandableCard } from '../../shared/components/cards/ExpandableCard';
import { ContactUserProfileLink } from './ContactUserProfileLink';
import { ProfilePropertyList } from '../Profile/ProfilePropertyList';
import { CardMenuBarV2 } from '../../shared/components/cards/CardMenuBar';
import Text from '../../shared/components/text/Text';
import ProfileAvatar from '../../shared/components/avatar/ProfileAvatar';
import { IContactDto } from './models/IContactDto';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import useUserActions from './hooks/useUserActions';
import { IEntityCardProps } from '../../shared/components/cards/EntityCardProps';
import { UserProfileDto } from '../Profile/models/UserProfile';
import { ResourceType } from '../../shared/model/ResourceType';
import { useIsMeByUserId } from '../../shared/auth/accountHooks';

interface IProps extends IEntityCardProps<IContactDto | UserProfileDto> {
  // Need this to help distinguish between IContactDto and UserProfileDto
  userId: string;
}

export function SocialContactCard({
  entity,
  userId,
  menuItems,
  headerRenderer,
  header,
  defaultOpen = true,
}: IProps) {
  const entityManifest = useEntityManifest(ResourceType.Contact);
  const isMe = useIsMeByUserId();
  const { displayName } = entity;

  const socialContactActions = useUserActions(userId);
  const actualHeader = header || (
    <Text as="p" size="small" weight="medium">
      {displayName}
    </Text>
  );
  const actualHeaderRenderer =
    headerRenderer ||
    ((isOpen: boolean) => {
      const avatar = (
        <ProfileAvatar
          avatarProps={{
            src: entity.photoUrl || entityManifest.icon,
            alt: entity.displayName,
            widthClass: 'w-10',
            heightClass: 'h-10',
          }}
        />
      );

      return (
        <div className="flex items-center space-x-3">
          <div className="flex-shrink-0">
            {isOpen && <ContactUserProfileLink userId={userId}>{avatar}</ContactUserProfileLink>}
            {!isOpen && avatar}
          </div>
          <div className="flex-none overflow-x-hidden px-1">
            {isOpen && (
              <ContactUserProfileLink userId={userId}>{actualHeader}</ContactUserProfileLink>
            )}
            {!isOpen && actualHeader}
          </div>
        </div>
      );
    });

  const leftMenuItems = !isMe(userId || '') ? socialContactActions.menuItems : [];
  const rightMenuItems = menuItems || [];
  return (
    <ExpandableCard renderHead={actualHeaderRenderer} as="div" defaultOpen={defaultOpen}>
      <div className="mt-2">
        <ProfilePropertyList profile={entity} />
      </div>
      <CardMenuBarV2 leftItems={leftMenuItems} rightItems={rightMenuItems} />
    </ExpandableCard>
  );
}
