import { UserPlusIcon } from '@heroicons/react/24/outline';
import { ILayoutActionItem } from '../../../shared/components/layout/pages/types';
import { ContactCreateModal } from '../ContactCreateModal';

export function useContactsActions(): ILayoutActionItem[] {
  return [
    {
      id: 'create',
      text: 'Add Contact',
      icon: UserPlusIcon,
      render: (button) => <ContactCreateModal trigger={button} triggerAsChild />,
    },
  ];
}
