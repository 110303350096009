import { NavigationClient, NavigationOptions } from '@azure/msal-browser';
import { NavigateFunction } from 'react-router';

/**
 * Overrides the default internal navigation of MSAL to use the navigate
 * function provided by react-router-dom
 *
 * Example taken from: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/b2c-sample/src/utils/NavigationClient.js
 */
export class ReactRouterNavigationClient extends NavigationClient {
  constructor(navigate: NavigateFunction) {
    super();
    this.navigate = navigate;
  }

  private navigate: NavigateFunction;

  /**
   * Navigates to other pages within the same web application
   * You can use the useNavigate hook provided by react-router-dom
   * to take advantage of client-side routing
   * @param url
   * @param options
   * @override
   */
  async navigateInternal(url: string, options: NavigationOptions): Promise<boolean> {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      this.navigate(relativePath, { replace: true });
    } else {
      this.navigate(relativePath);
    }

    return false;
  }
}
