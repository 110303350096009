import { PublicClientApplication } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactRouterNavigationClient } from './ReactRouterNavigationClient';

interface IProps {
  msalInstance: PublicClientApplication;
  children: React.ReactNode;
}

/**
 * Component that sets up a MSAL custom navigation client
 * that uses navigate function from React Router.
 *
 * Example taken from: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/b2c-sample/src/App.js
 */
export function MsalReactRouterNavigation({ msalInstance, children }: IProps) {
  const navigate = useNavigate();
  const navigationClient = new ReactRouterNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render -
  // delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return <>{children}</>;
}
