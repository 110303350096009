import { useDeleteConnectionRequestMutation } from './queries/connectionRequestQueries';
import { IConnectionRequestDto } from '../../shared/model/IConnectionRequestDto';
import TimeAgo from '../../shared/components/dates/TimeAgo';
import { RequestItems } from './RequestItems';

interface IProps {
  connectionRequests: IConnectionRequestDto[];
}

export default function ConnectionRequest({ connectionRequests }: IProps) {
  const deleteConnectionRequestMutation = useDeleteConnectionRequestMutation();

  return (
    <RequestItems
      requests={connectionRequests}
      text={(request) => (
        <>
          Connection request sent <TimeAgo date={request.createdTime} /> ago.
        </>
      )}
      actions={(request) => [
        {
          text: 'Cancel',
          onClick: () => deleteConnectionRequestMutation.mutate(request),
          disabled: deleteConnectionRequestMutation.isPending,
        },
      ]}
    />
  );
}
