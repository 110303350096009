import { useMsal } from '@azure/msal-react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logoY.svg';
import Notifications from '../../../../features/Notifications/Notifications';
import { Filler } from '../Filler';
import Text from '../../text/Text';
import { Dialog, DialogContent, DialogTrigger } from '../../dialog/Dialog';
import { cn } from '../../../../lib/utils';
import useMainNavigation from './hooks/useMainNavigation';

function navigationItemCN(inset: boolean, className: string | undefined) {
  return cn('block px-3 text-base font-medium text', inset ? 'ml-4' : 'py-2', className);
}

type NavigationItemProps<T extends React.ElementType> = React.ComponentPropsWithRef<T> & {
  className?: string;
  inset?: boolean;
};

function NavigationLink({ className, inset = false, ...props }: NavigationItemProps<typeof Link>) {
  return <Link {...props} className={navigationItemCN(inset, className)} />;
}

function NavigationButton({ className, inset = false, ...props }: NavigationItemProps<'button'>) {
  return <button {...props} type="button" className={navigationItemCN(inset, className)} />;
}

function NavigationAnchor({
  className,
  inset = false,
  children,
  ...props
}: NavigationItemProps<'a'>) {
  return (
    <a {...props} className={navigationItemCN(inset, className)}>
      {children}
    </a>
  );
}

function NavigationHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="block py-2 px-3">
      <Text as="span" brightness="dark" weight="medium">
        {children}
      </Text>
    </div>
  );
}

function NavigationMenu() {
  const [open, setOpen] = useState(false);
  const { instance } = useMsal();

  const sidebarNavItems = useMainNavigation();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Bars3Icon className="h-8 w-8" aria-hidden="true" />
        <span className="sr-only">Open main menu</span>
      </DialogTrigger>
      <DialogContent>
        <nav>
          {sidebarNavItems.map((item) => (
            <Fragment key={item.name}>
              <NavigationLink
                to={item.route}
                onClick={(e) => {
                  if (item.isDisabled) {
                    e.preventDefault();
                  } else {
                    setOpen(false);
                  }
                }}
              >
                {item.name}
              </NavigationLink>
              {item.children && item.children.length > 0 && (
                <>
                  {item.children.map((child) => (
                    <NavigationLink
                      to={child.route}
                      key={child.name}
                      onClick={() => setOpen(false)}
                      inset
                    >
                      {child.name}
                    </NavigationLink>
                  ))}
                </>
              )}
            </Fragment>
          ))}

          <NavigationHeader>Privacy & Terms</NavigationHeader>
          <NavigationAnchor
            href="/user_agreements/Privacy_Notice_v1.0.pdf"
            target="_blank"
            rel="noreferrer"
            inset
          >
            Privacy Notice
          </NavigationAnchor>
          <NavigationAnchor
            href="/user_agreements/Terms_of_use_v1.0.pdf"
            target="_blank"
            rel="noreferrer"
            inset
          >
            Terms of Use
          </NavigationAnchor>
          <NavigationButton onClick={() => instance.logoutRedirect()}>Sign out</NavigationButton>
        </nav>
      </DialogContent>
    </Dialog>
  );
}

export default function HeaderMobile() {
  return (
    <>
      <header className="flex items-center flex-shrink-0 space-x-3 h-16 px-4">
        {/* Logo area */}
        <div>
          <Link to="/" className="flex items-start justify-start">
            <img className="h-8 w-auto sm:h-8" src={logo} alt="Yoin" />
          </Link>
        </div>
        <Filler />
        <Notifications />
        <NavigationMenu />
      </header>
    </>
  );
}
