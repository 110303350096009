import { useEffect, useState } from 'react';

export type ScreenSize = 'large' | 'medium' | 'small';

const useCheckScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>('large');

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 768) {
      setScreenSize('small');
    } else if (window.innerWidth >= 768 && window.innerWidth <= 1536) {
      setScreenSize('medium');
    } else if (window.innerWidth > 1536) {
      setScreenSize('large');
    }
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return { screenSize };
};

export default useCheckScreenSize;
