import { ReactElement } from 'react';
import WrapperDesktop from '../screen-discriminators/WrapperDesktop';
import Text from '../text/Text';

interface IProps {
  imageUrl?: string;
  text: ReactElement;
}
export default function SelectPrompt({ imageUrl, text }: IProps) {
  return (
    <WrapperDesktop>
      <section className="h-full w-full overflow-hidden fade-in-element">
        <div className="flex flex-col justify-center items-center gap-4 text-center h-full px-4 md:px-6 lg:px-8">
          <img src={imageUrl} alt={imageUrl} className="max-w-md" />
          <Text as="h1" size="xLarge" brightness="normal" leading="snug">
            {text}
          </Text>
        </div>
      </section>
    </WrapperDesktop>
  );
}
