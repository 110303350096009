import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import { useInvitationsQuery } from '../Request/queries/invitationQueries';
import { InvitationStatus } from '../../shared/model/IInvitationDto';
import { ConnectionRequestStatuses } from '../../shared/constants/ConnectionRequestStatuses';
import { useBoardInviteRequestsQuery } from '../Request/queries/boardInviteQueries';
import { useNotificationsQuery } from './notificationQueries';
import NotificationList from './NotificationList';
import { useConnectionRequestsQuery } from '../Request/queries/connectionRequestQueries';
import { useNetworkInviteRequestsQuery } from '../Request/queries/networkInviteQueries';
import { IMenuItem } from '../../shared/model/IMenuItem';
import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';
import { useOrganizationInvitationsQuery } from '../Request/queries/organizationInvitationQueries';
import Requests from '../Request/Requests';
import Text from '../../shared/components/text/Text';
import { useProjectInviteRequestsQuery } from '../Request/queries/projectInviteQueries';
import { getInfiniteQueryDataLength } from '../../shared/utils/queryClientUtils';

type TabIds = 'all' | 'contacts' | 'networks' | 'requests' | 'board' | 'project';

interface IProps {
  closeCallback: () => void;
  onExecuteAction: (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => void;
}

const unreadFilter = (n: INotificationDto) => !n.isRead;

export default function NotificationsTray({ closeCallback, onExecuteAction }: IProps) {
  const [activeTabId, setActiveTabId] = useState<TabIds>('all');

  const allNotificationsQuery = useNotificationsQuery();
  const contactNotificationsQuery = useNotificationsQuery({ notificationArea: 'Contact' });
  const networkNotificationsQuery = useNotificationsQuery({ notificationArea: 'Network' });
  const boardNotificationsQuery = useNotificationsQuery({ notificationArea: 'Board' });
  const projectNotificationsQuery = useNotificationsQuery({ notificationArea: 'Project' });

  const numberOfUnreadNotifications = getInfiniteQueryDataLength(
    allNotificationsQuery.data,
    unreadFilter,
  );
  const numberOfUnreadContactNotifications = getInfiniteQueryDataLength(
    contactNotificationsQuery.data,
    unreadFilter,
  );
  const numberOfUnreadNetworkNotifications = getInfiniteQueryDataLength(
    networkNotificationsQuery.data,
    unreadFilter,
  );
  const numberOfUnreadBoardsNotifications = getInfiniteQueryDataLength(
    boardNotificationsQuery.data,
    unreadFilter,
  );
  const numberOfUnreadProjectsNotifications = getInfiniteQueryDataLength(
    projectNotificationsQuery.data,
    unreadFilter,
  );

  const projectInviteRequestsQuery = useProjectInviteRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });

  const boardInviteRequestsQuery = useBoardInviteRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });

  const invitationsQuery = useInvitationsQuery({
    status: InvitationStatus.Pending,
  });

  const networkInviteRequestsQuery = useNetworkInviteRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });

  const connectionRequestsQuery = useConnectionRequestsQuery({
    status: ConnectionRequestStatuses.Pending,
    type: 'Sent',
  });
  const organizationInvitationsQuery = useOrganizationInvitationsQuery({
    status: ['Pending'],
    type: 'Sent',
  });

  const allInvitationsData = [
    ...(invitationsQuery.data || []),
    ...(connectionRequestsQuery.data || []),
    ...(networkInviteRequestsQuery.data || []),
    ...(boardInviteRequestsQuery.data || []),
    ...(organizationInvitationsQuery.data || []),
    ...(projectInviteRequestsQuery.data || []),
  ];

  const menuItems: IMenuItem[] = [
    {
      id: 'project',
      title: 'Projects',
      onClick: () => setActiveTabId('project'),
      badgeCount: numberOfUnreadProjectsNotifications,
    },
    {
      id: 'board',
      title: 'Boards',
      onClick: () => setActiveTabId('board'),
      badgeCount: numberOfUnreadBoardsNotifications,
    },
    {
      id: 'requests',
      title: 'Requests',
      onClick: () => setActiveTabId('requests'),
      badgeCount: allInvitationsData.length,
    },
  ];

  return (
    <div className="flex flex-col h-full">
      <div className="hidden sm:flex justify-between items-start px-2 pt-2">
        <div className="ml-1">
          <Text as="h1" size="x2Large" color="cyan" weight="bold" brightness="dark">
            Activity
          </Text>
        </div>
        <button
          type="button"
          className="rounded-md text hover:text-light outline-none"
          onClick={closeCallback}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div className="flex gap-3 px-4 sm:px-1 pb-0.5 sm:mt-4">
        <TabNavItem
          textColor="gray-dark"
          id="all"
          title="All"
          badgeCount={numberOfUnreadNotifications}
          activeId={activeTabId}
          setActiveTab={setActiveTabId}
        />

        <TabNavItem
          textColor="gray-dark"
          id="contacts"
          title="Contacts"
          badgeCount={numberOfUnreadContactNotifications}
          activeId={activeTabId}
          setActiveTab={setActiveTabId}
        />
        <TabNavItem
          textColor="gray-dark"
          id="networks"
          title="Networks"
          badgeCount={numberOfUnreadNetworkNotifications}
          activeId={activeTabId}
          setActiveTab={setActiveTabId}
        />
        <TabDropdownItem
          menuItems={menuItems}
          isActive={
            activeTabId === 'requests' || activeTabId === 'board' || activeTabId === 'project'
          }
        />
      </div>

      <TabContentWrapper customCss="!p-0 relative border-slate-300 border-t ">
        <TabContent id="all" activeTabId={activeTabId}>
          <NotificationList
            query={allNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>

        <TabContent id="contacts" activeTabId={activeTabId}>
          <NotificationList
            query={contactNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>

        <TabContent id="networks" activeTabId={activeTabId}>
          <NotificationList
            query={networkNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>

        <TabContent id="requests" activeTabId={activeTabId}>
          <Requests
            connectionRequestsQuery={connectionRequestsQuery}
            networkInviteRequestsQuery={networkInviteRequestsQuery}
            pipelineInviteRequestsQuery={boardInviteRequestsQuery}
            organizationInviteRequestsQuery={organizationInvitationsQuery}
            invitationsQuery={invitationsQuery}
          />
        </TabContent>
        <TabContent id="board" activeTabId={activeTabId}>
          <NotificationList
            query={boardNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>
        <TabContent id="project" activeTabId={activeTabId}>
          <NotificationList
            query={projectNotificationsQuery}
            closeCallback={closeCallback}
            onExecuteAction={onExecuteAction}
          />
        </TabContent>
      </TabContentWrapper>
    </div>
  );
}
