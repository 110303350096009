// modalStore.ts
import { StateCreator } from 'zustand';
import { CombinedSlices } from '../useGlobalStore';

export type ModalSize = 'small' | 'medium' | 'large';

interface IModalContentProps {
  title: string;
  content: React.ReactNode | null;
  modalSize?: ModalSize;
  hideCloseButton?: boolean;
}

const defaultModalProps: IModalContentProps = {
  title: '',
  content: null,
  modalSize: 'large',
  hideCloseButton: false,
};

export interface ModalSlice {
  modalContentProps: IModalContentProps;
  isOpen: boolean;
  openModal: (modalProps: IModalContentProps) => void;
  closeModal: () => void;
}

export const createModalSlice: StateCreator<
  CombinedSlices,
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  [],
  ModalSlice
> = (set) => ({
  isOpen: false,
  modalContentProps: defaultModalProps,

  openModal: (modalContentProps) => set({ isOpen: true, modalContentProps }),
  closeModal: () => set({ isOpen: false, modalContentProps: defaultModalProps }),
});

export default createModalSlice;
