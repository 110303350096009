import MainPanelDesktop from '../../shared/components/layout/application/MainPanelDesktop';
import MainPanelMobile from '../../shared/components/layout/application/MainPanelMobile';
import WrapperDesktop from '../../shared/components/screen-discriminators/WrapperDesktop';
import WrapperMobile from '../../shared/components/screen-discriminators/WrapperMobile';
import PipelineSidePanel from './PipelineSidePanel';
import PipelineLayout from './PipelineLayout';
import { PipelineContextProvider } from './context/pipeline-context';
import Pipeline from './Pipeline';

export interface PipelinesProps {
  projectId: string;
  boardId: string;
  pipelineId: string;
}

export default function Pipelines({ projectId, boardId, pipelineId }: PipelinesProps) {
  return (
    <PipelineContextProvider>
      <WrapperDesktop>
        <MainPanelDesktop>
          <PipelineLayout>
            <Pipeline pipelineId={pipelineId} />
          </PipelineLayout>
        </MainPanelDesktop>
        <PipelineSidePanel projectId={projectId} boardId={boardId} pipelineId={pipelineId} />
      </WrapperDesktop>
      <WrapperMobile>
        <MainPanelMobile>Mobile sales coming soon</MainPanelMobile>
      </WrapperMobile>
    </PipelineContextProvider>
  );
}
