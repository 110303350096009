import { UseQueryResult } from '@tanstack/react-query';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Outlet, To, useNavigate } from 'react-router-dom';
import { cn } from '../../../../lib/utils';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import useIsBaseRoute from '../../../hooks/useIsNavigationMode';
import { FloatingActionButton } from '../../buttons/FloatingActionButton';
import NavigationList from '../../navigation/NavigationList';
import ScrollBarWrapper from '../../scrolling/ScrollBarWrapper';
import SearchBar from '../../search/SearchBar';
import ServiceHeader, { IServiceHeaderAction } from '../building-blocks/ServiceHeader';
import TabContent from '../../tabs/TabContent';
import TabNavItem from '../../tabs/TabNavItem';
import Text from '../../text/Text';
import { ILayoutActionItem } from './types';

export interface IDetailListNavigationItem {
  id: string;
  to: To;
  title: string;
  information: string;
  secondaryInformation?: string;
  imgSource: string;
  highlightInformation: boolean;
  showHighlightIndicator: boolean;
}

export type DetailListItemDataSelector<T> = (item: T) => IDetailListNavigationItem;
export type DetailListItemGroupSelector<T> = (item: T) => string;

// Haven't found a way to not default T to 'any' here.
// Since IDetailListLayoutProps can have sources with different data types,
// it is hard to infer the type of the data.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IDetailListNavigationSource<T = any> {
  id: string;
  title: string;
  icon: React.ElementType;
  // TODO: Handle Infinite queries.
  query: UseQueryResult<T[]>;
  itemDataSelector: DetailListItemDataSelector<T>;
  itemGroupSelector?: DetailListItemGroupSelector<T>;
}

interface IDetailListLayoutProps {
  title: string;
  navigationSources: IDetailListNavigationSource[];
  viewNavigationActions?: IServiceHeaderAction[];
  actions?: ILayoutActionItem[];
  searchTerm: string;
  onSearchTermChange: (searchTerm: string) => void;
  searchPlaceholder?: string;
}

// Helper function to infer the type of the navigation source.
export function createNavigationSource<T>(source: IDetailListNavigationSource<T>) {
  return source;
}

function useNavigateToFirstItem(
  isMobile: boolean,
  isBaseRoute: boolean,
  query: UseQueryResult<unknown[]>,
  itemDataSelector: DetailListItemDataSelector<unknown>,
) {
  const navigate = useNavigate();
  const { data, isSuccess } = query;

  useEffect(() => {
    if (isMobile || !isBaseRoute || !isSuccess || data.length === 0) return;

    const firstItem = data[0] as { to: To };
    const firstItemTo = itemDataSelector(firstItem).to;

    navigate(firstItemTo, { replace: true });
  }, [data, isMobile, isBaseRoute, isSuccess, navigate]);
}

export function DetailListNavigation({
  query,
  itemDataSelector,
  itemGroupSelector,
}: Pick<IDetailListNavigationSource, 'query' | 'itemDataSelector' | 'itemGroupSelector'>) {
  const { data, isLoading } = query;
  if (itemGroupSelector) {
    const groups = groupBy(data, itemGroupSelector);

    return (
      <>
        {Object.entries(groups).map(([group, groupData]) => (
          <div key={group}>
            <div className="ml-3 mt-2">
              <Text as="h3" weight="medium">
                {group}
              </Text>
            </div>
            <NavigationList
              data={groupData}
              isPending={isLoading}
              itemIdProperty={(item) => itemDataSelector(item).id}
              itemUrlProperty={(item) => itemDataSelector(item).to.toString()}
              itemHeadlineProperty={(item) => itemDataSelector(item).title}
              itemInformationProperty={(item) => itemDataSelector(item).information}
              itemSecondaryInformationProperty={(item) =>
                itemDataSelector(item).secondaryInformation ?? ''
              }
              itemImgSrcProperty={(item) => itemDataSelector(item).imgSource}
              highlightInformationProperty={(item) => itemDataSelector(item).highlightInformation}
              showHighlightIndicator={(item) => itemDataSelector(item).showHighlightIndicator}
            />
          </div>
        ))}
      </>
    );
  }

  return (
    <NavigationList
      data={data ?? []}
      isPending={isLoading}
      itemIdProperty={(item) => itemDataSelector(item).id}
      itemUrlProperty={(item) => itemDataSelector(item).to.toString()}
      itemHeadlineProperty={(item) => itemDataSelector(item).title}
      itemInformationProperty={(item) => itemDataSelector(item).information}
      itemSecondaryInformationProperty={(item) => itemDataSelector(item).secondaryInformation ?? ''}
      itemImgSrcProperty={(item) => itemDataSelector(item).imgSource}
      highlightInformationProperty={(item) => itemDataSelector(item).highlightInformation}
      showHighlightIndicator={(item) => itemDataSelector(item).showHighlightIndicator}
    />
  );
}

export function DetailListLayout({
  title,
  navigationSources,
  viewNavigationActions,
  actions,
  searchTerm,
  onSearchTermChange,
  searchPlaceholder,
}: IDetailListLayoutProps) {
  const [activeTab, setActiveTab] = useState(navigationSources[0].id);

  const isMobile = useCheckMobileScreen();
  const isBaseRoute = useIsBaseRoute(); // Questionable implementation for more complicated routes.

  // Automatically navigate to the first item in the first tab when the layout is in base route.
  // Doesn't apply when in mobile mode.
  const activeNavigationSource = navigationSources.find((source) => source.id === activeTab);
  if (!activeNavigationSource) throw new Error('Active navigation source not found.');

  useNavigateToFirstItem(
    isMobile,
    isBaseRoute,
    activeNavigationSource.query,
    activeNavigationSource.itemDataSelector,
  );

  return (
    <div className="flex h-full w-full overflow-hidden">
      {(!isMobile || isBaseRoute) && (
        <div className="relative flex flex-col flex-1 md:flex-none md:w-96 h-full gap-5 overflow-hidden px-3 md:px-6 pb-3 md:py-4 transition-all md:transition-none ease-in-out">
          <ServiceHeader text={title} actions={viewNavigationActions} />
          {/* text-sm is added contextually everywhere we use the tab components. */}
          <div
            className={cn(
              'flex text-sm',
              navigationSources.length > 2 ? 'justify-between' : 'gap-20',
            )}
          >
            {navigationSources.map((source) => (
              <TabNavItem
                key={source.id}
                id={source.id}
                title={source.title}
                icon={<source.icon className="w-5 h-5" />}
                activeId={activeTab}
                setActiveTab={setActiveTab}
              />
            ))}
          </div>
          <nav className="flex flex-col flex-1 overflow-hidden">
            {navigationSources.map((source) => (
              <TabContent key={source.id} id={source.id} activeTabId={activeTab}>
                <SearchBar
                  searchTerm={searchTerm}
                  onSearchChange={(e) => onSearchTermChange(e.target.value)}
                  placeholder={searchPlaceholder}
                />
                <ScrollBarWrapper className="mt-3">
                  <DetailListNavigation {...source} />
                </ScrollBarWrapper>
              </TabContent>
            ))}
          </nav>
          {/* TODO: Floating menu */}
          {actions &&
            actions.length > 0 &&
            actions[0].render &&
            actions[0].render(
              <FloatingActionButton
                text={actions[0].text}
                icon={actions[0].icon}
                className="mr-3 md:mr-6"
              />,
            )}
          {actions && actions.length > 0 && !actions[0].render && (
            <FloatingActionButton
              text={actions[0].text}
              icon={actions[0].icon}
              onClick={actions[0].onClick}
              className="mr-3 md:mr-6"
            />
          )}
        </div>
      )}
      {(!isMobile || !isBaseRoute) && (
        <div className="flex-1 h-full overflow-hidden">
          <Outlet />
        </div>
      )}
    </div>
  );
}
