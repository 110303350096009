import React, { useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  useDismiss,
  useRole,
  useHover,
  useInteractions,
  FloatingFocusManager,
  autoPlacement,
} from '@floating-ui/react';

interface IProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  hoverDelay?: number;
}

/**
 * @deprecated DO NOT USE THIS COMPONENT. DEPRECATED.
 * If needed, add this functionality to our Popover component instead,
 * or add the HoverCard component from shadcn/ui.
 */
function HoverPopover({ trigger, children, hoverDelay = 300 }: IProps) {
  const [open, setOpen] = useState(false);
  const hoverTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const handleOpenChange = (nextOpen: boolean) => {
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);

    hoverTimeout.current = setTimeout(() => {
      setOpen(nextOpen);
    }, hoverDelay);
  };

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    middleware: [
      offset({ crossAxis: 10 }),
      autoPlacement({
        alignment: 'start',
        autoAlignment: false,
      }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    dismiss,
    role,
  ]);

  return (
    <>
      <div ref={refs.setReference} className="border-0 outline-0" {...getReferenceProps()}>
        {trigger}
      </div>
      {open && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            className="absolute left-0 top-0 z-[11] max-w-max focus:outline-none"
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {children}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
}

export default HoverPopover;
