import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { IMessageFileDto } from '../../shared/model/IMessageDto';
import MessageFileAttachmentProgress from './MessageFileAttachmentProgress';

interface IProps {
  file: IMessageFileDto;
  onClick: (file: IMessageFileDto) => void;
}

export default function MessageFileAttachment({ file, onClick }: IProps) {
  return (
    <>
      <button
        type="button"
        onClick={() => onClick(file)}
        className="flex justify-center bg-gray border rounded py-1 px-2"
      >
        <span>{file.name}</span>
        <ArrowDownTrayIcon className="h-5 w-5 ml-1 top self-center" />
      </button>

      {file.progress !== undefined && <MessageFileAttachmentProgress progress={file.progress} />}
    </>
  );
}
