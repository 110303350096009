import {
  ChatBubbleLeftIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import {
  createNavigationSource,
  DetailListLayout,
} from '../../shared/components/layout/pages/DetailListLayout';
import useDebounce from '../../shared/hooks/useDebounce';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { useLexicalMarkdownToPlainText } from '../../shared/lexical/markdown/useLexicalMarkdownToPlainText';
import { useStandaloneEditor } from '../../shared/lexical/useStandaloneEditor';
import { ResourceType } from '../../shared/model/ResourceType';
import { MESSAGE_EDITOR_CONFIG } from '../Messaging/editor/messageEditorConfig';
import { useContactsActions } from './hooks/useContactsActions';
import { useConnectionsForChatQuery } from './queries/connectionQueries';
import { useContactsQuery } from './queries/contactQueries';

export function ContactsDetailList() {
  const { icon: defaultIcon } = useEntityManifest(ResourceType.Contact);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  const chatConnectionsQuery = useConnectionsForChatQuery({ searchTerm: debouncedSearchTerm });
  const alphabeticallySortedContactsQuery = useContactsQuery({ searchTerm: debouncedSearchTerm });

  const { editor } = useStandaloneEditor(MESSAGE_EDITOR_CONFIG);
  const markdownToPlainText = useLexicalMarkdownToPlainText(editor);

  const actions = useContactsActions();

  return (
    <DetailListLayout
      title="Contacts"
      navigationSources={[
        createNavigationSource({
          id: 'chat',
          title: 'Chat',
          icon: ChatBubbleLeftIcon,
          query: chatConnectionsQuery,
          itemDataSelector: (item) => ({
            id: item.id,
            to: item.contactId,
            title: item.displayName,
            information: item.latestMessage ? markdownToPlainText(item.latestMessage?.text) : '',
            secondaryInformation: item.latestMessage?.createdTimeFriendly ?? '',
            imgSource: item.photoUrl || defaultIcon,
            highlightInformation: item.latestMessage?.isUnread ?? false,
            showHighlightIndicator: item.latestMessage?.isUnread ?? false,
          }),
        }),
        createNavigationSource({
          id: 'az',
          title: 'A-Z',
          icon: ClipboardDocumentCheckIcon,
          query: alphabeticallySortedContactsQuery,
          itemDataSelector: (item) => ({
            id: item.id,
            to: item.id,
            title: item.displayName,
            information: `${item.title} ${item.title !== '' && item.company !== '' ? 'at' : ''} ${
              item.company
            }`.trim(),
            imgSource: item.photoUrl || defaultIcon,
            highlightInformation: false,
            showHighlightIndicator: false,
          }),
          itemGroupSelector: ({ displayName }) => displayName.charAt(0).toUpperCase(),
        }),
      ]}
      searchTerm={searchTerm}
      onSearchTermChange={setSearchTerm}
      searchPlaceholder="Search to find contacts"
      actions={actions}
    />
  );
}
