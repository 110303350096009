import { ArrowRightIcon, PencilIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { IMenuItem } from '../../../shared/model/IMenuItem';
import { useProjectPrivilegesQuery } from '../queries/projectPriviligesQueries';
import { IProjectDto } from '../models/IProjectDto';
import { useGlobalStore } from '../../../shared/store/useGlobalStore';
import InviteMember from '../../Members/MemberInvite';
import { ResourceType } from '../../../shared/model/ResourceType';
import ProjectCreateEditModal from '../ProjectCreateEditModal';
import useModals from '../../../shared/components/modal/hooks/useModal';

export default function useProjectActionButtons(project: IProjectDto) {
  const privilegesQuery = useProjectPrivilegesQuery(project?.id);
  const privileges = privilegesQuery.data;
  const { openModal, closeModal } = useModals();

  const setProjectIsConfirmDeleteOpen = useGlobalStore(
    (state) => state.setProjectIsConfirmDeleteOpen,
  );
  const setProjectIsConfirmLeaveOpen = useGlobalStore(
    (state) => state.setProjectIsConfirmLeaveOpen,
  );
  const setCurrentProject = useGlobalStore((state) => state.setCurrentProject);

  const menuItems: IMenuItem[] = [];

  if (privileges?.canEdit) {
    menuItems.push({
      id: 'Edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () => {
        openModal({
          title: project ? 'Update project' : 'Create new project',
          content: <ProjectCreateEditModal project={project} />,
        });
      },
    });
  }
  if (privileges?.canInviteMember) {
    menuItems.push({
      id: 'Invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () => {
        openModal({
          title: 'Invite member',
          content: (
            <InviteMember
              resourceIds={project.id ? [project.id] : []}
              type={ResourceType.Project}
              onComplete={closeModal}
            />
          ),
        });
      },
    });
  }
  if (privileges?.canDelete) {
    menuItems.push({
      id: 'Delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () => {
        setCurrentProject(project);
        setProjectIsConfirmDeleteOpen(true);
      },
    });
  } else {
    menuItems.push({
      id: 'Leave',
      title: 'Leave',
      icon: ArrowRightIcon,
      onClick: () => {
        setCurrentProject(project);
        setProjectIsConfirmLeaveOpen(true);
      },
    });
  }

  return { items: menuItems, isPending: privilegesQuery.isPending };
}
