import { useState } from 'react';
import { EllipsisHorizontalIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { IMenuItem } from '../../model/IMenuItem';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './DropdownMenu';
import { renderMenuItems } from './utils';
import Spinner from '../loading/Spinner';

export type MenuTriggerIconSize = 'small' | 'medium' | 'large' | 'xLarge';

export interface ActionsDropdownMenuProps {
  resolver: (isOpen: boolean) => { items: IMenuItem[]; isPending: boolean };
  menuTriggerIconType?: 'horizontal' | 'vertical';
  menuTriggerIconSize?: MenuTriggerIconSize;
}

export const menuTriggerIconSizeLookup: Record<MenuTriggerIconSize, string> = {
  small: 'w-7 h-7',
  medium: 'w-8 h-8',
  large: 'w-9 h-9',
  xLarge: 'w-10 h-10',
};

export default function ActionsDropdownMenu({
  resolver,
  menuTriggerIconType = 'horizontal',
  menuTriggerIconSize = 'medium',
}: ActionsDropdownMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { items, isPending } = resolver(isOpen);
  const menuTriggerIconSizeClassName = menuTriggerIconSizeLookup[menuTriggerIconSize];
  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger className="flex justify-center items-center">
        {menuTriggerIconType === 'horizontal' ? (
          <EllipsisHorizontalIcon className={menuTriggerIconSizeClassName} />
        ) : (
          <EllipsisVerticalIcon className={menuTriggerIconSizeClassName} />
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {isPending ? (
          <div className="p-8 flex items-center justify-center">
            <Spinner size={10} />
          </div>
        ) : (
          renderMenuItems(items)
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
