import { IMenuItem } from '../../../model/IMenuItem';
import ActionsDropdownMenu from '../../drop-down-menu/ActionsDropdownMenu';

interface ActionRowCellProps {
  resolver: (isOpen: boolean) => { items: IMenuItem[]; isPending: boolean };
}

export default function ActionRowCell({ resolver }: ActionRowCellProps) {
  return <ActionsDropdownMenu resolver={resolver} />;
}
