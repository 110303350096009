import { useQuery } from '@tanstack/react-query';
import { IProjectPrivileges } from '../models/IProjectDto';
import { getPrivileges } from '../../Members/services/MemberService';
import { ResourceType } from '../../../shared/model/ResourceType';
import { IBoardPrivileges } from '../models/IBoardDto';

const boardPrivilegesKeys = {
  all: ['board-privileges'] as const,
  details: () => [...boardPrivilegesKeys.all, 'detail'] as const,
  detail: (projectId: string, boardId: string) =>
    [...boardPrivilegesKeys.details(), projectId, boardId] as const,
};

export function useboardPriviligesQueries(projectId?: string, boardId?: string) {
  return useQuery({
    queryKey: boardPrivilegesKeys.detail(projectId!, boardId!),
    queryFn: () => getPrivileges(ResourceType.Board, [projectId!, boardId!]),
    enabled: !!projectId && !!boardId,
    select: (data): IBoardPrivileges => ({
      canEdit: data.privileges.includes('Board.ReadWrite'),
      canDelete: data.privileges.includes('Board.Delete'),
      canReadMembers: data.privileges.includes('Board.Member.Read'),
      canInviteMember: data.privileges.includes('Board.Member.Invite'),
      canRemoveMember: data.privileges.includes('Board.Member.Remove'),
    }),
  });
}
