import { DraggableProvided } from 'react-beautiful-dnd';
import { CalendarIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  ExpandableCard,
  ExpandableCardRefType,
} from '../../shared/components/cards/ExpandableCard';
import DateOnly from '../../shared/components/dates/DateOnly';
import TextAreaInputAutosized from '../../shared/components/inputs/text/TextAreaInputAutosized';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { IContactNoteDto } from './models/IContactNoteDto';
import { CardMenuBarV2, ICardMenuItem } from '../../shared/components/cards/CardMenuBar';
import Text from '../../shared/components/text/Text';

interface IProps {
  note: IContactNoteDto;
  draggableProvided: DraggableProvided;
  onUpdateNote: (note: IContactNoteDto) => void;
  onDeleteNote: (note: IContactNoteDto) => void;
}

export default function ContactPrivateNote({
  note,
  draggableProvided,
  onUpdateNote,
  onDeleteNote,
}: IProps) {
  const expandableCardRef = useRef<ExpandableCardRefType>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const {
    register,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm<IContactNoteDto>({
    mode: 'onChange',
    defaultValues: useMemo(() => note, [note]),
  });

  const toggleOpenClosed = () => {
    if (!expandableCardRef.current) return;
    expandableCardRef.current.toggle();
  };

  const handleUpdate = () => {
    const data = getValues();
    data.note = data.note.trimEnd();

    onUpdateNote(data);
    toggleOpenClosed();
  };

  const onKeyPressEventHandler = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key !== 'Enter' || !event.ctrlKey || !isValid || !isDirty) {
      return;
    }

    event.preventDefault();
    handleUpdate();
  };

  const rightItems: ICardMenuItem[] = [
    {
      key: `${note.id}-delete`,
      label: 'Delete',
      color: ButtonColors.Red,
      icon: (classes) => <TrashIcon className={classes} />,
      // Prevent onBlur for text area to happen before onClick
      onMouseDown: (e) => {
        e.preventDefault();
      },
      onClick: () => {
        onDeleteNote(note);
      },
    },
  ];
  return (
    <ExpandableCard
      as="div"
      ref={expandableCardRef}
      draggableProvided={draggableProvided}
      onOpen={() => {
        if (textAreaRef.current) textAreaRef.current.focus();
      }}
      renderHead={(isOpen) => (
        <>
          {!isOpen && (
            <>
              <div className="cursor-pointer mb-2 px-1">
                <Text size="small">{note.note}</Text>
              </div>

              <div className="flex items-center space-x-1 pl-1 mt-2">
                <Text size="xSmall" brightness="light" truncate>
                  <CalendarIcon className="w-3 h-3" />{' '}
                </Text>
                <Text size="xSmall" brightness="light" truncate>
                  <DateOnly date={note.createdTime} />
                </Text>
              </div>
            </>
          )}
          {isOpen && (
            <TextAreaInputAutosized
              ref={textAreaRef}
              name="note"
              register={register}
              placeholder="Start typing to add a note"
              errors={errors}
              errorMessage="Length must be between 1 & 4000"
              validationRules={{ required: true, maxLength: 4000 }}
              onKeyDown={onKeyPressEventHandler}
              inputCss="px-1 py-1 text-light bg-transparent border-0"
              inputFocusCss="focus:ring-0 focus:border-0"
              onInputBlur={() => {
                if (!isValid || !isDirty) {
                  toggleOpenClosed();
                  return;
                }
                handleUpdate();
              }}
            />
          )}
        </>
      )}
    >
      <div className="flex items-center space-x-1 pl-1 my-2">
        <Text size="xSmall" brightness="light" truncate>
          <CalendarIcon className="w-3 h-3" />{' '}
        </Text>
        <Text size="xSmall" brightness="light" truncate>
          <DateOnly date={note.createdTime} />
        </Text>
      </div>
      <CardMenuBarV2 rightItems={rightItems} />
    </ExpandableCard>
  );
}
