import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import { IOrganizationInvitationFilters } from '../../Companies/models/IOrganizationInvitationFilters';
import {
  deleteOrganizationInvitation,
  getOrganizationInvitations,
} from '../../Companies/queries/organizationServices';

const keys = {
  all: ['organization-invitations'] as const,
  lists: () => [...keys.all, 'list'] as const,
  list: (props: IOrganizationInvitationFilters) => [...keys.lists(), props] as const,
};

export function useOrganizationInvitationsQuery(filters: IOrganizationInvitationFilters) {
  return useQuery({
    queryKey: keys.list(filters),
    queryFn: () => getOrganizationInvitations(filters),
  });
}

export function useDeleteOrganizationInvitationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organizationId,
      invitationId,
    }: {
      organizationId: string;
      invitationId: string;
    }) => deleteOrganizationInvitation(organizationId, invitationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.lists() });
    },
    onError: useDispatchApiError(),
  });
}
