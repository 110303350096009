import React, { useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { TrendType } from '../../../features/Pipelines/models/IPipelineKpiDto';

interface IKpi {
  value: string | number | undefined;
  label?: string;
  trend?: TrendType;
  icon: React.ReactElement;
}

export function LabelWithTrend({ label, trend }: { label: string; trend: TrendType }) {
  let arrowClass = 'arrow-right';
  if (trend === 'Up') {
    arrowClass = 'arrow-up';
  } else if (trend === 'Down') {
    arrowClass = 'arrow-down';
  }
  return <span className={`text-sm ${arrowClass}`}>{label}</span>;
}

export function KpiIndicator({ value, label, trend, icon }: IKpi) {
  return (
    <div className="flex flex-col items-center justify-center px-3">
      <div className="flex items-center gap-1">
        {icon}
        <span className="text-sm">{value}</span>
        {!label && <LabelWithTrend label={label || ''} trend={trend as TrendType} />}
      </div>
      {label && <LabelWithTrend label={label || ''} trend={trend as TrendType} />}
    </div>
  );
}

interface KpiIndicatorProps {
  data: IKpi[];
  defaultIsExpanded?: boolean;
  canExpand?: boolean;
}

export function ExpandableKpiIndicator({
  data,
  defaultIsExpanded = false,
  canExpand = false,
}: KpiIndicatorProps) {
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex gap-6 items-center py-6  h-6  ">
      {isExpanded && (
        <div className="flex ">
          {data.map((item) => (
            <KpiIndicator
              key={item.label}
              value={item.value}
              label={item.label}
              trend={item.trend as TrendType}
              icon={item.icon}
            />
          ))}
        </div>
      )}

      {!isExpanded && (
        <div className="flex gap-4  ">
          {data.map((item) => (
            <KpiIndicator
              key={item.label}
              value={item.value}
              icon={item.icon}
              trend={item.trend as TrendType}
            />
          ))}
        </div>
      )}
      {canExpand && (
        <button type="button" onClick={toggleExpanded}>
          <div>
            {!isExpanded ? (
              <ChevronDownIcon className="w-5 h-5" />
            ) : (
              <ChevronRightIcon className="w-5 h-5" />
            )}
          </div>
        </button>
      )}
    </div>
  );
}
