import { createContext, useState, useContext, ReactNode } from 'react';

interface Store {
  isEditBoardModalOpen: boolean;
  setIsEditBoardModalOpen: (isOpen: boolean) => void;
  isBoardInviteMemberModalOpen: boolean;
  setBoardInviteMemberModalOpen: (isOpen: boolean) => void;
  isBoardConfirmLeaveOpen: boolean;
  setBoardIsConfirmLeaveOpen: (isOpen: boolean) => void;
  isBoardConfirmDeleteOpen: boolean;
  setBoardIsConfirmDeleteOpen: (isOpen: boolean) => void;
  closeSidebar: () => void;
}

const useBoardStore = (): Store => {
  const [isEditBoardModalOpen, setIsEditBoardModalOpen] = useState(false);
  const [isBoardInviteMemberModalOpen, setBoardInviteMemberModalOpen] = useState(false);
  const [isBoardConfirmLeaveOpen, setBoardIsConfirmLeaveOpen] = useState(false);
  const [isBoardConfirmDeleteOpen, setBoardIsConfirmDeleteOpen] = useState(false);

  const closeSidebar = () => undefined;

  return {
    isEditBoardModalOpen,
    setIsEditBoardModalOpen,
    isBoardInviteMemberModalOpen,
    setBoardInviteMemberModalOpen,
    isBoardConfirmLeaveOpen,
    setBoardIsConfirmLeaveOpen,
    isBoardConfirmDeleteOpen,
    setBoardIsConfirmDeleteOpen,
    closeSidebar,
  };
};

export const BoardStoreContext = createContext<Store | null>(null);

export function BoardContextProvider({ children }: { children: ReactNode }) {
  return (
    <BoardStoreContext.Provider value={useBoardStore()}>
      {children}
    </BoardStoreContext.Provider>
  );
}

export const useBoardStoreContext = (): Store => {
  const store = useContext(BoardStoreContext);

  if (!store) {
    throw new Error('useBoardStoreContext must be used within a BoardContextProvider');
  }

  return store;
};
