import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import clsx from 'clsx';
import { ButtonColors } from '../../constants/ButtonColors';

const hoverColorLookup: { [key in ButtonColors]: string } = {
  [ButtonColors.White]: 'text-gray-200',
  [ButtonColors.Gray]: 'text-light',
  [ButtonColors.Green]: 'text-green-dark',
  [ButtonColors.Cyan]: 'text-cyan-dark',
  [ButtonColors.Red]: 'text-red-dark',
  [ButtonColors.Blue]: 'bg-cyan-dark ',
};

const colorLookup: { [key in ButtonColors]: string } = {
  [ButtonColors.White]: `text-white disabled:text-white hover:${
    hoverColorLookup[ButtonColors.White]
  }`,
  [ButtonColors.Gray]: `text disabled:text-disabled hover:${hoverColorLookup[ButtonColors.Gray]}`,
  [ButtonColors.Green]: `text-green disabled:text-green-light hover:${
    hoverColorLookup[ButtonColors.Green]
  }`,
  [ButtonColors.Cyan]: `text-cyan-alternative disabled:text-cyan-light hover:${
    hoverColorLookup[ButtonColors.Cyan]
  }`,
  [ButtonColors.Red]: `text-red disabled:text-red-light hover:${
    hoverColorLookup[ButtonColors.Red]
  }`,
  [ButtonColors.Blue]: `disabled:text-white-600 hover:text-white-700 text-white bg-blue hover:${
    hoverColorLookup[ButtonColors.White]
  } border-transparent`,
};

interface IProps {
  color: ButtonColors;
  icon: React.ReactElement;
  isDisabled?: boolean;
  orientation?: 'horizontal' | 'vertical';
  grow?: boolean;
  isActive?: boolean;
  title?: string;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | SubmitHandler<any>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
}

const IconButton = React.forwardRef<HTMLButtonElement, IProps>(
  (
    {
      color,
      icon,
      isDisabled = false,
      grow = false,
      isActive = false,
      orientation = 'vertical',
      label,
      ...props
    },
    ref,
  ) => {
    const classes = clsx(
      'flex items-center justify-center disabled:opacity-30',
      grow && 'grow',
      orientation === 'vertical' ? 'flex-col' : 'flex-row',
      isActive && hoverColorLookup[color],
    );
    return (
      <button
        ref={ref}
        type="button"
        {...props}
        disabled={isDisabled}
        className={`${classes} ${colorLookup[color]}`}
      >
        {icon}
        {label && <span className="max-w-12 text-xs leading-snug">{label}</span>}
      </button>
    );
  },
);

export default IconButton;
