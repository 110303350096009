import { Bars4Icon, ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { useMatch, useNavigate } from 'react-router-dom';
import { IServiceHeaderAction } from '../../../shared/components/layout/building-blocks/ServiceHeader';

export function useNetworkHeaderActions(): IServiceHeaderAction[] {
  const navigate = useNavigate();
  const networksPathMatch = useMatch({ path: '/networks/*' });
  const networksListPathMatch = useMatch({ path: '/networks/list' });
  return [
    {
      id: 'social',
      icon: ChatBubbleOvalLeftEllipsisIcon,
      isActive:
        networksPathMatch !== null &&
        networksListPathMatch === null,
      onClick: async () => navigate('/networks'),
    },
    {
      id: 'list',
      icon: Bars4Icon,
      isActive: networksListPathMatch !== null,
      onClick: async () => navigate('/networks/list'),
    },
  ];
}
