import { HTMLProps } from 'react';
import { As } from '../../utils/types';
import { TextColor } from '../text/Text';

type ValidTags = 'button' | 'span' | 'div';

type IPillProps = As<ValidTags> & {
  label: string;
  color: TextColor;
  onClick?: () => void;
};

const pillColors: { [key in TextColor]: string } = {
  purple: 'bg-purple-light text-purple-dark',
  yellow: 'bg-yellow-light text-yellow-dark',
  green: 'bg-green-light text-green-dark',
  red: 'bg-red-light text-red-dark',
  normal: '',
  cyan: '',
  white: '',
  blue: '',
  gray: '',
};

export function Pill({ as = 'button', label, color, onClick }: IPillProps) {
  const _color = pillColors[color];
  const className = `flex h-[26px] justify-center items-center content-center rounded-full text-center text-xs font-medium whitespace-nowrap w-[85px] truncate ${_color} z-10`;

  const Tag: string = as;
  const attributes: HTMLProps<HTMLElement> = { className, onClick };

  return <Tag {...attributes}> {label} </Tag>;
}
