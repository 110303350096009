import { useId } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UseInfiniteApiQueryResult } from '../../hooks/useInfiniteApiQuery';
import ScrollBarWrapper from './ScrollBarWrapper';

interface InfiniteApiQueryScrollRenderFnProps<TData> {
  data: TData[];
}

type InfiniteApiQueryScrollRenderFn<TData> = (
  props: InfiniteApiQueryScrollRenderFnProps<TData>,
) => React.ReactNode;

interface IProps<TData> {
  query: UseInfiniteApiQueryResult<TData>;
  className?: string;
  loader?: React.ReactNode;
  children: React.ReactNode | InfiniteApiQueryScrollRenderFn<TData>;
}

function DefaultLoader() {
  return (
    <div className="py-4 text-center">
      <p>Loading...</p>
    </div>
  );
}

export function InfiniteApiQueryScroll<TData>({
  query,
  className,
  loader,
  children,
}: IProps<TData>) {
  const scrollbarId = useId();

  const { data, fetchNextPage, hasNextPage } = query;
  const flattenedData = data?.pages?.flat() || [];

  return (
    <ScrollBarWrapper id={scrollbarId} className={className}>
      <InfiniteScroll
        scrollableTarget={scrollbarId}
        dataLength={flattenedData.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={loader || <DefaultLoader />}
      >
        {typeof children === 'function' ? children({ data: flattenedData }) : children}
      </InfiniteScroll>
    </ScrollBarWrapper>
  );
}
