import { clsx, type ClassValue } from 'clsx';
import { isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';

// Function used by shadcn-ui to merge tailwind classes and clsx classes with ease
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Type guard to check if a value is a JSX.Element
export function isJSXElement(value: any): value is JSX.Element {
  return isValidElement(value);
}
