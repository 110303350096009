import { forwardRef } from 'react';

interface IProps {
  text: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  data?: number;
  onClick: React.MouseEventHandler<Element>;
}

const MenuItemActionButton = forwardRef<HTMLButtonElement, IProps>(
  ({ text, disabled, icon, data, onClick }: IProps, ref) => (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${
        icon ? 'flex flex-row px-1' : 'block px-4'
      } py-2 text-left text-sm text hover:bg-gray-light w-full disabled:text-disabled flex flex-1 justify-between`}
    >
      {icon && <span className="pr-2">{icon}</span>}
      {text}
      {data ? (
        <span className="bg-blue text-white whitespace-nowrap rounded-sm px-1 font-medium text-xs items-center">
          {data}
        </span>
      ) : (
        <span className="hidden" />
      )}
    </button>
  ),
);

export default MenuItemActionButton;
