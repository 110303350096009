import { IPagedResult } from '../../../shared/model/IPagedResult';
import apiClient from '../../../shared/utils/apiClient';
import { UserProfileDto, UpdateUserProfileDto, ListUsersParams } from '../models/UserProfile';

export async function getUserProfile(id: string) {
  return apiClient.get<UserProfileDto>(`v1/users/${id}/profile`).then((response) => response.data);
}

export async function getMyUserProfile() {
  return getUserProfile('my');
}

export async function getUsers(params: ListUsersParams) {
  return apiClient
    .get<IPagedResult<UserProfileDto>>('v1/users', { params })
    .then((response) => response.data);
}

export async function updateMyUserProfile(profile: UpdateUserProfileDto) {
  return apiClient.patch('v1/users/my/profile', profile);
}
