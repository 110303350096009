import {
  DescriptionList,
  IDescriptionListItem,
} from '../../shared/components/lists/DescriptionList';
import { INetworkDto } from '../../shared/model/INetwork';

export interface IProfilePropertyListProps {
  network: Partial<INetworkDto>;
}

function toItems(network: Partial<INetworkDto>) {
  const items = [] as IDescriptionListItem[];
  if (network.name) {
    items.push({ name: 'Name', value: network.name });
  }
  if (network.visibility) {
    items.push({ name: 'Visibility', value: network.visibility });
  }
  if (network.type) {
    items.push({ name: 'Type', value: network.type });
  }
  if (network.memberCount) {
    items.push({ name: 'Member count', value: network.memberCount.toString() });
  }
  if (network.description) {
    items.push({ name: 'Description', value: network.description, columnSpan: 2 });
  }

  return items;
}

export function NetworkPropertyList({ network }: IProfilePropertyListProps) {
  return <DescriptionList items={toItems(network)} />;
}
