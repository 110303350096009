import { IInvitationDto } from '../model/IInvitationDto';
import { ResourceType } from '../model/ResourceType';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';

export const getInvitation = (id: string | undefined) =>
  apiClient.get(`v1/invitations/${id}`).then((response) => response.data);

export const createInvitation = (
  emailAddress: string,
  resourceId?: string,
  resourceType?: ResourceType,
) =>
  apiClient
    .post('v1/invitations/', { emailAddress, resourceId, resourceType })
    .then((response) => response.data.data);

export const acceptInvitation = (id: string) =>
  apiClient.post(`v1/invitations/${id}/accept`).then((response) => response.data.data);

export const getInvitationsRequest = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/invitations/${queryString}`)
    .then<IInvitationDto[]>((response) => response.data.data);
};

export const resendInvitation = (id: string) => apiClient.put(`v1/invitations/${id}/resend`);
