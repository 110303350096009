import { useNavigate, useRoutes } from 'react-router';
import ProfileAvatar from '../../avatar/ProfileAvatar';

interface IProps {
  name: string;
  photoUrl: string;
  url?: string;
}

export default function ProfileRowCell({ name, photoUrl, url }: IProps) {
  const classNames = 'ml-2 align-middle';
  const navigate = useNavigate();
  return (
    <div className="flex items-center">
      <ProfileAvatar
        avatarProps={{
          src: photoUrl,
          widthClass: 'w-6',
          heightClass: 'h-6',
        }}
      />
      {name && !url && <span className={classNames}>{name}</span>}
      {name && url && (
        <a
          href={url}
          onClick={(e) => {
            e.preventDefault();
            navigate(url);
          }}
          className={classNames}
        >
          {name}
        </a>
      )}
    </div>
  );
}
