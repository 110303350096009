import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import React from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ProfileAvatarForm from '../../shared/components/avatar/ProfileAvatarForm';
import { ICreateUpdateProjectDto, IProjectDto } from './models/IProjectDto';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { useProjectPhotoMutation } from './queries/projectPhotoQueries';

type UseProjectCreateFormProps = {
  values?: IProjectDto;
};

export function useProjectCreateEditForm({ values }: UseProjectCreateFormProps = {}) {
  return useForm<ICreateUpdateProjectDto>({
    mode: 'onChange',
    values,
  });
}

type Props = {
  form: UseFormReturn<ICreateUpdateProjectDto>;
  submitButtonText?: string;
  cancelButtonText?: string;
  project?: IProjectDto;
  isDisabled?: boolean;
  onSubmit: SubmitHandler<ICreateUpdateProjectDto>;
  onCancel: (event: React.BaseSyntheticEvent) => void;
};

export function ProjectCreateEditForm({
  form,
  submitButtonText,
  cancelButtonText = 'Cancel',
  project,
  isDisabled = false,
  onSubmit,
  onCancel,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = form;

  const _onSubmit = (_project: ICreateUpdateProjectDto) => {
    if (!isValid || !isDirty) return;

    onSubmit(_project);
    reset();
  };
  const manifest = useEntityManifest(ResourceType.Project);
  const photoMutations = useProjectPhotoMutation();
  const isUpdate = !!project;
  const _submitButtonText = submitButtonText || (isUpdate ? 'Save' : 'Create');
  return (
    <form onSubmit={handleSubmit(_onSubmit)} className="text-left">
      <SpacingContainer>
        {isUpdate && (
          <SpacingContainerItem>
            <ProfileAvatarForm
              url={project.photoUrl || manifest.icon}
              altText={project.displayName}
              onUpload={(file) => photoMutations.mutate({ id: project.id, file })}
              onRemove={() => photoMutations.mutate({ id: project.id })}
              isPending={photoMutations.isPending}
            />
          </SpacingContainerItem>
        )}

        <SpacingContainerItem>
          <TextFieldInput
            labelText="Name"
            name="name"
            type="text"
            register={register}
            errors={errors}
            errorMessage="Must enter a name"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <TextAreaInput
            labelText="Description"
            name="description"
            rows={3}
            register={register}
            errors={errors}
            errorMessage="Must enter a description"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <ButtonGroup>
          <Button
            color={ButtonColors.White}
            text={cancelButtonText}
            disabled={isDisabled}
            onClick={onCancel}
          />
          <SubmitButton text={_submitButtonText} isDisabled={!isValid || !isDirty || isDisabled} />
        </ButtonGroup>
      </SpacingContainer>
    </form>
  );
}
