import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * Props
 * @export
 * @interface IUseVisitTrackingProps
 */
export interface IUseVisitTrackingProps {
  /**
   * Callback that will be called when a "visit" event occurs
   * @memberof IUseVisitTrackingProps
   */
  onVisit: () => void;
}

/**
 * Hook to track visits of the user for a specific components.
 * Will execute the onVisit on route changes but also if
 * user is navigating away from the application itself.
 * @export
 * @param {IUseVisitTrackingProps} { onVisit }
 */
export default function useVisitTracking({ onVisit }: IUseVisitTrackingProps) {
  const location = useLocation();
  const onVisitCallback = useCallback(onVisit, []);

  // Track if route change
  useEffect(() => {
    onVisitCallback();
    return () => onVisitCallback();
  }, [location]);

  // Track if user leaves the application completely
  useEffect(() => {
    window.addEventListener('beforeunload', onVisitCallback);
    return () => {
      window.removeEventListener('beforeunload', onVisitCallback);
    };
  }, [location]);
}
