import { SubmitHandler, useForm, UseFormRegister, UseFormReturn } from 'react-hook-form';
import React from 'react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import SubmitButton from '../../shared/components/buttons/SubmitButton';
import Header2 from '../../shared/components/headers/Header2';
import TextAreaInput from '../../shared/components/inputs/text/TextAreaInput';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { ICreateBoardDto, BoardType } from './models/IBoardDto';
import CurrencySelector from '../../shared/components/selectors/CurrencySelector';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import RadioButtonGroup from '../../shared/components/radiobuttons/RadioButtonGroup';
import RadioButton from '../../shared/components/radiobuttons/RadioButton';
import Text from '../../shared/components/text/Text';

const defaultValues: ICreateBoardDto = {
  name: '',
  description: '',
  tenantId: '',
  type: BoardType.Pipeline,
  resourcePayload: {},
};

type UseBoardCreateFormProps = {
  values?: ICreateBoardDto;
};

function PipelineResourceForm({ register }: { register: UseFormRegister<ICreateBoardDto> }) {
  return (
    <SpacingContainerItem>
      <CurrencySelector register={register} name="resourcePayload.currencyIsoCode" />
    </SpacingContainerItem>
  );
}

export function useBoardCreateForm({ values }: UseBoardCreateFormProps = {}) {
  return useForm<ICreateBoardDto>({
    mode: 'onChange',
    values,
    defaultValues,
  });
}

type Props = {
  form: UseFormReturn<ICreateBoardDto>;
  submitButtonText?: string;
  cancelButtonText?: string;
  isDisabled?: boolean;
  onSubmit: SubmitHandler<ICreateBoardDto>;
  onCancel: (event: React.BaseSyntheticEvent) => void;
};

export function BoardCreateForm({
  form,
  submitButtonText,
  cancelButtonText = 'Cancel',
  isDisabled = false,
  onSubmit,
  onCancel,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = form;

  const _onSubmit = (_board: ICreateBoardDto) => {
    if (!isValid || !isDirty) return;

    onSubmit(_board);
    reset();
  };
  const boardType = watch('type');
  const _submitButtonText = submitButtonText || 'Create';
  const _title = 'Create new board';

  return (
    <form onSubmit={handleSubmit(_onSubmit)} className="text-left">
      <SpacingContainer>
        <Header2>{_title}</Header2>
        <SpacingContainerItem>
          <TextFieldInput
            labelText="Name"
            name="name"
            type="text"
            register={register}
            errors={errors}
            errorMessage="Must enter a name"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <TextAreaInput
            labelText="Description"
            name="description"
            rows={3}
            register={register}
            errors={errors}
            errorMessage="Must enter a description"
            validationRules={{ required: true }}
          />
        </SpacingContainerItem>
        <SpacingContainerItem>
          <RadioButtonGroup name="type" label="Board type">
            <RadioButton name="type" label="Pipeline" value="Pipeline" register={register} />
          </RadioButtonGroup>
          <Text as="p" size="small" brightness="light" leading="snug">
            The type of the board that will be created. Different type of boards can be used to
            simplify different sales work.
          </Text>
        </SpacingContainerItem>
        <HorizontalDivider text="Board type details" />
        {boardType === BoardType.Pipeline && <PipelineResourceForm register={register} />}
        <ButtonGroup>
          <Button
            color={ButtonColors.White}
            text={cancelButtonText}
            disabled={isDisabled}
            onClick={onCancel}
          />
          <SubmitButton text={_submitButtonText} isDisabled={!isValid || !isDirty || isDisabled} />
        </ButtonGroup>
      </SpacingContainer>
    </form>
  );
}
