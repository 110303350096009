import ListItem from './ListItem';
import { ListLoader } from './ListLoader';
import { BreakoutButton } from '../buttons/BreakoutButton';
import Text from '../text/Text';
import HorizontalSpacer from '../spacing/HorizontalSpacer';
import Tag, { TagProps } from '../tags/Tag';
import ListEmptyState from './ListEmptyState';
import ProfileAvatar from '../avatar/ProfileAvatar';

interface IProps<T> {
  isLoading: boolean;
  headline?: string;
  data?: T[];
  selectedItems?: T[];
  itemIdProperty: (item: T) => string | undefined;
  itemHeadlineProperty: (item: T) => string;
  itemInformationProperty: (item: T) => string;
  itemImgSrcProperty: (item: T) => string;
  onSelectItems?: (entities: T[]) => void;
  itemTagsProperty: (item: T) => TagProps[];
}

export default function SelectListMultiSelect<T extends object>({
  data,
  isLoading = false,
  headline,
  selectedItems,
  itemIdProperty,
  itemHeadlineProperty,
  itemInformationProperty,
  itemImgSrcProperty,
  onSelectItems,
  itemTagsProperty,
}: IProps<T>) {
  const handleSelectEntity = (item: T) => {
    if (!onSelectItems) return;

    const isItemAlreadySelected = selectedItems?.find(
      (selectedItem) => itemIdProperty(selectedItem) === itemIdProperty(item),
    );

    const updatedSelectedItems: T[] = isItemAlreadySelected
      ? selectedItems?.filter(
          (selectedItem) => itemIdProperty(selectedItem) !== itemIdProperty(item),
        ) || []
      : [...(selectedItems || []), item];

    onSelectItems(updatedSelectedItems);
  };

  return (
    <>
      {headline && <h1 className="text-1xl mt-4 mb-2 font-medium text">{headline}</h1>}
      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          {!data?.length ? (
            <>
              <HorizontalSpacer distance="small" />
              <ListEmptyState>No items found</ListEmptyState>
            </>
          ) : (
            <ul>
              {data?.map((item) => {
                const itemAlreadySelected = selectedItems?.find(
                  (selectedItem) => itemIdProperty(selectedItem) === itemIdProperty(item),
                );
                return (
                  <ListItem
                    key={itemIdProperty(item)}
                    isSelected={!!itemAlreadySelected}
                    isInteractive={!!onSelectItems}
                  >
                    <div className="flex-shrink-0">
                      <ProfileAvatar
                        avatarProps={{
                          src: itemImgSrcProperty(item),
                          alt: itemHeadlineProperty(item),
                          widthClass: 'w-10',
                          heightClass: 'h-10',
                        }}
                      />
                    </div>
                    <div className="flex-1 ml-auto overflow-x-hidden min-w-0">
                      <Text as="p" size="small" weight="medium" truncate>
                        {itemHeadlineProperty(item)}
                      </Text>
                      <Text as="p" size="xSmall" brightness="light" truncate>
                        {itemInformationProperty(item)}
                      </Text>
                    </div>
                    {Array.isArray(itemTagsProperty(item)) && itemTagsProperty(item).length > 0 && (
                      <div>
                        {itemTagsProperty(item).map((tag) => (
                          <Tag key={tag.title} title={tag.title} color={tag.color} />
                        ))}
                      </div>
                    )}

                    <BreakoutButton text="Select" hidden onClick={() => handleSelectEntity(item)} />
                  </ListItem>
                );
              })}
            </ul>
          )}
        </>
      )}
    </>
  );
}
