import { useRef, useState } from 'react';
import Text from '../../shared/components/text/Text';
import { useShareContactRequestsCountByStatusQuery } from '../Request/queries/shareContactRequestQueries';
import { IContactDto } from './models/IContactDto';
import {
  ExpandableCard,
  ExpandableCardRefType,
} from '../../shared/components/cards/ExpandableCard';
import logo from '../../assets/images/company-icon.svg';
import share from '../../assets/images/share-contact.svg';

import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import ContactUserTabBody from './ContactUserTabBody';
import { ShareContactRequestStatuses } from '../../shared/model/IShareContactRequestDto';
import Howto from '../../shared/components/message-popups/HowTo';
import { ContactSharedContactsKpiList } from './ContactSharedContactsKpiList';
import OldKpiIndicator from '../../shared/components/kpi/OldKpiIndicator';

interface IProps {
  contact: IContactDto;
}

type View = ShareContactRequestStatuses | 'All' | undefined;

export function ContactInfoTab({ contact }: IProps) {
  const cardRef = useRef<ExpandableCardRefType>(null);
  const [viewState, setViewState] = useState<View>(undefined);
  const shareContactRequestsQuery = useShareContactRequestsCountByStatusQuery({
    sharedContactId: contact.id,
    searchType: 'Sent',
    status: ['Accepted', 'Pending', 'Rejected'] || Number,
  });

  const data = shareContactRequestsQuery?.data;
  const { Total, Pending, Accepted, Rejected } = data || {};

  const handleToggle = (status: View) => {
    setViewState(status);
  };

  const renderSharedContactsInfo = () => {
    if (
      data &&
      ((viewState === 'All' && Total) ||
        (viewState === 'Accepted' && Accepted) ||
        (viewState === 'Pending' && Pending) ||
        (viewState === 'Rejected' && Rejected))
    ) {
      return (
        <ScrollBarWrapper>
          <ContactSharedContactsKpiList key={contact.id} contact={contact} status={viewState} />
        </ScrollBarWrapper>
      );
    }

    return null;
  };

  return (
    <div className="flex h-full flex-col overflow-y-hidden">
      <ScrollBarWrapper>
        <Text as="p" size="small" weight="medium" truncate>
          About
        </Text>
        <ExpandableCard
          as="div"
          ref={cardRef}
          renderHead={() => (
            <div className="flex-1 overflow-x-hidden">
              <div className="flex flex-row space-x-4">
                <img className="w-8 h-8 " src={logo} alt={logo} />
                <Text as="p" size="small" brightness="normal" weight="bold" whitespace="normal">
                  {contact?.company ? contact.company : 'Company name'}
                </Text>
              </div>
            </div>
          )}
        >
          <div className="mt-2 flex flex-wrap ">
            <ContactUserTabBody user={contact} />
          </div>
        </ExpandableCard>

        <Text as="p" size="small" weight="medium" truncate>
          Activity
        </Text>

        <ExpandableCard
          as="div"
          defaultOpen
          renderHead={() => (
            <div className="flex-1 overflow-x-hidden">
              <div className="flex flex-row space-x-4">
                <img className="w-8 h-8 " src={share} alt={share} />
                <Text as="p" size="small" brightness="normal" weight="bold" whitespace="normal">
                  Shared contacts
                </Text>
              </div>
              <div className="flex flex-row space-x-3 px-10 ">
                {shareContactRequestsQuery.data && (
                  <>
                    <OldKpiIndicator
                      value={Total}
                      textColor="cyan"
                      content="Total"
                      isActive={viewState === Total}
                      onClick={() => handleToggle('All')}
                    />
                    <OldKpiIndicator
                      value={Accepted}
                      textColor="green"
                      content="Accepted"
                      isActive={viewState === 'Accepted'}
                      onClick={() => handleToggle('Accepted')}
                    />
                    <OldKpiIndicator
                      value={Pending}
                      textColor="yellow"
                      content="Pending"
                      isActive={viewState === 'Pending'}
                      onClick={() => handleToggle('Pending')}
                    />
                    <OldKpiIndicator
                      value={Rejected}
                      textColor="red"
                      content="Rejected"
                      isActive={viewState === 'Rejected'}
                      onClick={() => handleToggle('Rejected')}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        >
          {renderSharedContactsInfo()}
        </ExpandableCard>
      </ScrollBarWrapper>
      <Howto
        title="Info"
        description="View your contact's information and the number of shared contacts."
      />
    </div>
  );
}
