import React from 'react';
import Text from '../text/Text';
import Label from '../text/Label';
import SpacingContainer from '../spacing/SpacingContainer';

interface IProps {
  name: string;
  label: string;
  description?: string;
  children: React.ReactNode;
}

export default function RadioButtonGroup({ name, label, description, children }: IProps) {
  return (
    <SpacingContainer>
      <div>
        <Label htmlFor={name} text={label} />
        {description && (
          <Text as="p" size="small" brightness="light" leading="normal">
            {description}
          </Text>
        )}
      </div>
      <SpacingContainer size="small">{children}</SpacingContainer>
    </SpacingContainer>
  );
}
