import { contactKeys } from '../../features/Contacts/queries/contactQueries';
import { networkKeys } from '../../features/Networks/queries/networkQueries';
import { connectWithUser } from '../../features/Profile/services/userActionService';
import { boardKeys } from '../../features/Projects/queries/boardQueries';
import { projectKeys } from '../../features/Projects/queries/projectQueries';
import { InviteRequestType } from '../../features/Request/models/InviteRequestType';
import {
  acceptInvitation,
  rejectInvitation,
} from '../../features/Request/services/inviteRequestService';
import { organizationKeys } from '../../features/Companies/queries/organizationQueries';
import {
  acceptOrganizationInvitation,
  rejectOrganizationInvitation,
} from '../../features/Companies/queries/organizationServices';
import {
  NotificationArea,
} from '../constants/NotificationConstants';
import { ResourceIds, resolveEndpointName } from '../hooks/useEntityManifest';
import {
  Action,
  INotificationDto,
  IResourceLink,
  NotificationResourceType,
} from '../model/INotificationDto';
import { INotificationResponseDto } from '../model/INotificationResponseDto';
import { IPagedResult } from '../model/IPagedResult';
import { ResourceType } from '../model/ResourceType';
import apiClient from '../utils/apiClient';
import QueryParam from '../utils/query-string-builder/QueryParam';
import queryStringBuilder from '../utils/query-string-builder/queryStringBuilder';
import { acceptConnectionRequest, rejectConnectionRequest } from './connectionRequestService';
import { acceptShareContactRequest, rejectShareContactRequest } from './shareContactRequestService';

const resolveNotificationResourceReferenceIds = (
  startPath: string,
  type: string,
  payload: { [key: string]: string },
) => {
  const resourceIds: ResourceIds = [];
  let actualStartPath = startPath;
  let resourceReferenceId = payload[`${actualStartPath}.id`];
  while (resourceReferenceId) {
    resourceIds.push(resourceReferenceId);
    if (type === payload[`${actualStartPath}.type`]) {
      // we have resolved all ids from startPath to the matching type and can exit
      break;
    }

    actualStartPath += '.child';
    resourceReferenceId = payload[`${actualStartPath}.id`];
  }

  return resourceIds as ResourceIds;
};

const getResourceLink = (
  type: NotificationResourceType,
  startPath: string,
  payload: { [key: string]: string },
  query?: string,
): IResourceLink => {
  let text;
  let resourceUrl;
  let resourceIds;

  const resolveResourceId = () => payload[`${startPath}.id`];

  switch (type) {
    case NotificationResourceType.Contact:
      text = 'Go to contact';
      resourceIds = resolveNotificationResourceReferenceIds(
        startPath,
        ResourceType.Contact,
        payload,
      );
      resourceUrl = resolveEndpointName(ResourceType.Contact, resourceIds);
      break;
    case NotificationResourceType.Network:
      text = 'Go to network';
      resourceIds = resolveNotificationResourceReferenceIds(
        startPath,
        ResourceType.Network,
        payload,
      );
      resourceUrl = resolveEndpointName(ResourceType.Network, resourceIds);
      break;
    case NotificationResourceType.Profile:
      text = 'Go to contact';
      resourceUrl = `/contacts/external/${resolveResourceId()}`;
      resourceIds = [resolveResourceId()];
      break;
    case NotificationResourceType.Conversation:
      text = 'Go to conversation';
      resourceUrl = `/conversations/${resolveResourceId()}`; // Actually not an existing route but not using that.
      resourceIds = [resolveResourceId()];
      break;
    case NotificationResourceType.Pipeline:
      text = 'Go to pipeline';
      resourceIds = resolveNotificationResourceReferenceIds(
        startPath,
        ResourceType.Pipeline,
        payload,
      );
      resourceUrl = resolveEndpointName(ResourceType.Pipeline, resourceIds);
      break;
    case NotificationResourceType.Board:
      text = 'Go to board';
      resourceIds = resolveNotificationResourceReferenceIds(startPath, ResourceType.Board, payload);
      resourceUrl = resolveEndpointName(ResourceType.Board, resourceIds);
      break;
    case NotificationResourceType.Project:
      text = 'Go to project';
      resourceIds = resolveNotificationResourceReferenceIds(
        startPath,
        ResourceType.Project,
        payload,
      );
      resourceUrl = resolveEndpointName(ResourceType.Project, resourceIds);
      break;
    case NotificationResourceType.Organization:
      text = 'Go to company';
      resourceIds = resolveNotificationResourceReferenceIds(
        startPath,
        ResourceType.Organization,
        payload,
      );
      resourceUrl = resolveEndpointName(ResourceType.Organization, resourceIds);
      break;
    default:
      throw Error('Type not found');
  }

  return {
    url: resourceUrl + (query ? `?${query}` : ''),
    ids: resourceIds,
    text,
    type,
  };
};

const handleResourceNotification = (
  resourceType: NotificationResourceType,
  area: NotificationArea,
  inviteRequestType: InviteRequestType,
  resourceKeyLists: readonly string[],
  notification: INotificationDto,
): INotificationDto => {
  const resourceTypeName = area.toLocaleLowerCase();
  const notificationClone = notification;
  // Invite to resource
  if (notificationClone.type === 'Request' && notificationClone.area === area) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} has invited you to the ${resourceTypeName} ${notificationClone.payload['resource.displayName']}.`;
    notificationClone.acceptMessage = `The ${resourceTypeName} invitation was accepted`;
    notificationClone.actions = [
      new Action(
        'Accept',
        'Accept',
        () => acceptInvitation(inviteRequestType, notificationClone.payload['resource.id']),
        [resourceKeyLists],
      ),
      new Action('Reject', 'Reject', () =>
        rejectInvitation(inviteRequestType, notificationClone.payload['resource.id']),
      ),
    ];
    notificationClone.resourceLink =
      notificationClone.response === 'Accept'
        ? getResourceLink(resourceType, 'resource.child', notificationClone.payload)
        : undefined;
  }

  if (
    notificationClone.type === 'Response' &&
    notificationClone.area === area &&
    notificationClone.response === 'Accept'
  ) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} accepted your invitation to the ${resourceTypeName} ${notificationClone.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notificationClone.payload['resource.child.id']
      ? getResourceLink(resourceType, 'resource.child', notificationClone.payload)
      : undefined;
  }

  if (
    notificationClone.type === 'Response' &&
    notificationClone.area === area &&
    notificationClone.response === 'Reject'
  ) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} rejected your invitation to the ${resourceTypeName} ${notificationClone.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notificationClone.payload['resource.child.id']
      ? getResourceLink(resourceType, 'resource.child', notificationClone.payload)
      : undefined;
  }

  // Resource deleted
  if (
    notificationClone.type === 'General' &&
    notificationClone.area === area &&
    notificationClone.reason === 'ResourceDeleted'
  ) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} deleted the ${resourceTypeName} ${notificationClone.payload['resource.displayName']}.`;
  }

  // Resource member left
  if (
    notificationClone.type === 'General' &&
    notificationClone.area === area &&
    notificationClone.reason === 'MemberRemoved'
  ) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} left the ${resourceTypeName} ${notificationClone.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notificationClone.payload['resource.id']
      ? getResourceLink(resourceType, 'resource', notificationClone.payload)
      : undefined;
  }

  // Resource member added
  if (
    notificationClone.type === 'General' &&
    notificationClone.area === area &&
    notificationClone.reason === 'MemberAdded'
  ) {
    notificationClone.message = `${notificationClone.payload['resource.child.displayName']} joined the ${resourceTypeName} ${notificationClone.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notificationClone.payload['resource.id']
      ? getResourceLink(resourceType, 'resource', notificationClone.payload)
      : undefined;
  }

  // Resource member added by accepting invitation
  if (
    notificationClone.type === 'General' &&
    notificationClone.area === area &&
    notificationClone.reason === 'InviteRequestAccepted'
  ) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} joined the ${resourceTypeName} ${notificationClone.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notificationClone.payload['resource.id']
      ? getResourceLink(resourceType, 'resource', notificationClone.payload)
      : undefined;
  }

  // Activities
  if (
    notificationClone.type === 'General' &&
    notificationClone.area === area &&
    notificationClone.reason === 'ActivityAssigned'
  ) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} have assigned you to the activity ${notificationClone.payload['resource.child.displayName']}.`;
    notificationClone.resourceLink = getResourceLink(
      resourceType,
      'resource',
      notificationClone.payload,
      'tab=tab_activities',
    );
  }

  if (
    notificationClone.type === 'General' &&
    notificationClone.area === area &&
    notificationClone.reason === 'ActivityAssigned'
  ) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} have assigned you to the activity '${notificationClone.payload['resource.child.displayName']}' in the ${resourceTypeName} '${notificationClone.payload['resource.displayName']}'.`;
    notificationClone.resourceLink = getResourceLink(
      resourceType,
      'resource',
      notificationClone.payload,
      'tab=tab_activities',
    );
  }

  if (
    notificationClone.type === 'General' &&
    notificationClone.area === area &&
    notificationClone.reason === 'ActivityCompleted'
  ) {
    notificationClone.message = `${notificationClone.payload['source.displayName']} completed the activity '${notificationClone.payload['resource.child.displayName']}' in the ${resourceTypeName} '${notificationClone.payload['resource.displayName']}'.`;
    notificationClone.resourceLink = getResourceLink(
      resourceType,
      'resource',
      notificationClone.payload,
      'tab=tab_activities',
    );
  }

  return notificationClone;
};

/**
 * Creates a complete notification message including language support (will come later)
 * @param notification takes in the notification from the API
 * @returns a cloned notification that contains a proper message and action
 */
const finalizeNotification = (notification: INotificationDto): INotificationDto => {
  const notificationClone = { ...notification };

  // Connection
  if (
    notification.type === 'Request' &&
    notification.area === 'Connection'
  ) {
    if (notification.payload !== null) {
      notificationClone.message = `${notification.payload['source.displayName']} wants to connect with you.`;
      notificationClone.acceptMessage = 'The connection request was accepted.';
      notificationClone.resourceLink = getResourceLink(
        NotificationResourceType.Profile,
        'source',
        notification.payload,
      );
    }
    notificationClone.actions = [
      new Action(
        'Accept',
        'Accept',
        () => acceptConnectionRequest(notification.payload['resource.id']),
        [contactKeys.lists()],
      ),
      new Action('Reject', 'Reject', () =>
        rejectConnectionRequest(notification.payload['resource.id']),
      ),
    ];
  }

  if (
    notification.type === 'Response' &&
    notification.area === 'Connection' &&
    notification.response === 'Accept'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} has connected with you.`;
    notificationClone.resourceLink = getResourceLink(
      NotificationResourceType.Profile,
      'source',
      notification.payload,
    );
  }

  if (
    notification.type === 'Response' &&
    notification.area === 'Connection' &&
    notification.response === 'Reject'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} has rejected to connect with you.`;
    notificationClone.resourceLink = getResourceLink(
      NotificationResourceType.Profile,
      'source',
      notification.payload,
    );
  }

  if (
    notification.type === 'General' &&
    notification.area === 'Connection'
  ) {
    notificationClone.message = `You have been connected with ${notification.payload['source.displayName']}.`;
    notificationClone.resourceLink = getResourceLink(
      NotificationResourceType.Profile,
      'source',
      notification.payload,
    );
  }

  // Recommend contact (Legacy)
  if (
    notification.type === 'Request' &&
    notification.area === 'Contact' &&
    notification.reason === 'RecommendContactRequestCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} wants you to connect with ${notification.payload['resource.displayName']}.`;
    notificationClone.acceptMessage = `A connection request has been sent to ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(NotificationResourceType.Profile, 'resource.child', notification.payload)
      : undefined;
  }

  if (
    notification.type === 'General' &&
    notification.area === 'Contact' &&
    notification.reason === 'RecommendContactRequestCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} wants you to connect with ${notification.payload['resource.displayName']}, you are already connected.`;
  }

  if (
    notification.type === 'Response' &&
    notification.area === 'Contact' &&
    notification.reason === 'RecommendContactRequestAccepted' &&
    notification.response === 'Accept'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your recommendation of ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(NotificationResourceType.Profile, 'resource.child', notification.payload)
      : undefined;
  }

  if (
    notification.type === 'Response' &&
    notification.area === 'Contact' &&
    notification.reason === 'RecommendContactRequestRejected' &&
    notification.response === 'Reject'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} rejected your recommendation of ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(NotificationResourceType.Profile, 'resource.child', notification.payload)
      : undefined;
  }

  // Share contact
  if (
    notification.type === 'Request' &&
    notification.area === 'Contact' &&
    notification.reason === 'ShareContactRequestCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} wants to share contact ${notification.payload['resource.displayName']} with you.`;
    notificationClone.acceptMessage = `Contact ${notification.payload['resource.displayName']} has been added.`;
    notificationClone.actions = [
      new Action(
        'Add',
        'Accept',
        () => acceptShareContactRequest(notification.payload['resource.id']),
        [contactKeys.lists()],
      ),
    ];
    if (notification.payload['resource.child.id']) {
      notificationClone.actions.push(
        new Action(
          'Connect',
          'Accept',
          async () => {
            await acceptShareContactRequest(notification.payload['resource.id']);
            return connectWithUser(notification.payload['resource.child.id']);
          },
          [contactKeys.lists()],
        ),
      );
    }
    notificationClone.actions.push(
      new Action('Ignore', 'Reject', () =>
        rejectShareContactRequest(notification.payload['resource.id']),
      ),
    );
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(NotificationResourceType.Profile, 'resource.child', notification.payload)
      : undefined;
  }

  if (
    notification.type === 'General' &&
    notification.area === 'Contact' &&
    notification.reason === 'ShareContactRequestCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} wants to share contact ${notification.payload['resource.displayName']} with you. You are already connected`;
    notificationClone.resourceLink = notification.payload['resource.child.id']
      ? getResourceLink(NotificationResourceType.Profile, 'resource.child', notification.payload)
      : undefined;
  }

  if (
    notification.type === 'Response' &&
    notification.area === 'Contact' &&
    notification.reason === 'ShareContactRequestAccepted' &&
    notification.response === 'Accept'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your sharing of contact ${notification.payload['resource.displayName']}.`;
    if (notification.payload['resource.child.type'] === 'Contact') {
      notificationClone.resourceLink = getResourceLink(
        NotificationResourceType.Contact,
        'resource.child',
        notification.payload,
      );
    } else if (notification.payload['resource.child.id']) {
      notificationClone.resourceLink = getResourceLink(
        NotificationResourceType.Profile,
        'resource.child',
        notification.payload,
      );
    }
  }

  if (
    notification.type === 'Response' &&
    notification.area === 'Contact' &&
    notification.reason === 'ShareContactRequestRejected' &&
    notification.response === 'Reject'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} rejected your sharing of contact ${notification.payload['resource.displayName']}.`;
    if (notification.payload['resource.child.type'] === 'Contact') {
      notificationClone.resourceLink = getResourceLink(
        NotificationResourceType.Contact,
        'resource.child',
        notification.payload,
      );
    } else if (notification.payload['resource.child.id']) {
      notificationClone.resourceLink = getResourceLink(
        NotificationResourceType.Profile,
        'resource.child',
        notification.payload,
      );
    }
  }

  // Invitation
  if (
    notification.type === 'Response' &&
    notification.area === 'Invitation' &&
    notification.response === 'Accept'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your invitation to Yoin. You are now connected.`;
    notificationClone.resourceLink = getResourceLink(
      NotificationResourceType.Profile,
      'source',
      notification.payload['source.id'],
    );
  }

  // Conversations
  if (
    notification.type === 'General' &&
    notification.area === 'Message' &&
    (notification.reason === 'ReactionCreated' || notification.reason === 'ReactionUpdated')
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} reacted (${notification.payload['resource.child.child.displayName']}) to your message "${notification.payload['resource.child.displayName']}".`;
    notificationClone.resourceLink = getResourceLink(
      NotificationResourceType.Conversation,
      'resource',
      notification.payload,
      `messageId=${notification.payload['resource.child.id']}`,
    );
  }

  if (
    notification.type === 'General' &&
    notification.area === 'Message' &&
    notification.reason === 'Mention'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} mentioned you in a message.`;
    notificationClone.resourceLink = getResourceLink(
      NotificationResourceType.Conversation,
      'resource',
      notification.payload,
      `messageId=${notification.payload['resource.child.id']}`,
    );
  }

  handleResourceNotification(
    NotificationResourceType.Network,
    'Network',
    InviteRequestType.Network,
    networkKeys.lists(),
    notificationClone,
  );
  handleResourceNotification(
    NotificationResourceType.Board,
    'Board',
    InviteRequestType.Board,
    boardKeys.lists(),
    notificationClone,
  );
  handleResourceNotification(
    NotificationResourceType.Project,
    'Project',
    InviteRequestType.Project,
    projectKeys.lists(),
    notificationClone,
  );

  // Company (Organization)
  if (
    notification.area === 'Organization' &&
    notification.type === 'General' &&
    notification.reason === 'OrganizationMemberAdded'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} joined the company ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(NotificationResourceType.Organization, 'resource', notification.payload)
      : undefined;
  }

  if (
    notification.area === 'Organization' &&
    notification.type === 'Request' &&
    notification.reason === 'OrganizationInvitationCreated'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} has invited you to the company ${notification.payload['resource.displayName']}.`;
    notificationClone.acceptMessage = 'The company invitation was accepted';
    notificationClone.actions = [
      new Action(
        'Accept',
        'Accept',
        () =>
          acceptOrganizationInvitation(
            notification.payload['resource.id'],
            notification.payload['resource.child.id'],
          ),
        [organizationKeys.lists()],
      ),
      new Action('Reject', 'Reject', () =>
        rejectOrganizationInvitation(
          notification.payload['resource.id'],
          notification.payload['resource.child.id'],
        ),
      ),
    ];
    notificationClone.resourceLink =
      notification.response === 'Accept' &&
      notification.payload['resource.id']
        ? getResourceLink(NotificationResourceType.Organization, 'resource', notification.payload)
        : undefined;
  }

  if (
    notification.area === 'Organization' &&
    notification.type === 'Response' &&
    notification.reason === 'OrganizationInvitationAccepted'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} accepted your invitation to the company ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(NotificationResourceType.Organization, 'resource', notification.payload)
      : undefined;
  }

  if (
    notification.area === 'Organization' &&
    notification.type === 'Response' &&
    notification.reason === 'OrganizationInvitationRejected'
  ) {
    notificationClone.message = `${notification.payload['source.displayName']} rejected your invitation to the company ${notification.payload['resource.displayName']}.`;
    notificationClone.resourceLink = notification.payload['resource.id']
      ? getResourceLink(NotificationResourceType.Organization, 'resource', notification.payload)
      : undefined;
  }

  return notificationClone;
};

export const respond = (id: string, data: INotificationResponseDto) =>
  apiClient.put(`v1/notifications/${id}/respond`, data).then((response) => response.data.data);

export const getNotifications = (...queryParams: QueryParam[]) => {
  const queryString = queryStringBuilder()
    .add(...queryParams)
    .toQueryString();
  return apiClient
    .get(`v1/notifications${queryString}`)
    .then<IPagedResult<INotificationDto>>((response) => response.data);
};

export const readNotification = (id: string) =>
  apiClient.put(`v1/notifications/${id}/read`).then((response) => response.data.data);

export default finalizeNotification;
