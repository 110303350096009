import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonColors } from '../../constants/ButtonColors';
import {
  ConversationAttachmentType,
  IConversationAttachmentDto,
} from '../../model/IConversationAttachmentDto';
import { getFileLease } from '../../services/conversationFileService';
import { downloadUrlByLink } from '../../utils/fileUtils';
import ConfirmDialog from '../alert-dialog/ConfirmDialog';
import Button from '../buttons/Button';
import ButtonGroup from '../buttons/ButtonGroup';
import SubmitButton from '../buttons/SubmitButton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../drop-down-menu/DropdownMenu';
import TextFieldInput from '../inputs/text/TextFieldInput';
import ModalLegacy from '../modal/ModalLegacy';
import {
  useDeleteFileAttachmentMutation,
  useRenameFileAttachmentMutation,
} from './queries/fileAttachmentQueries';
import {
  useDeleteLinkAttachmentMutation,
  useRenameLinkAttachmentMutation,
} from './queries/linkAttachmentQueries';

interface IRenameForm {
  name: string;
}

interface IProps {
  attachment: IConversationAttachmentDto;
}

interface IRenameAttachmentItemProps extends IProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

function RenameAttachmentModal({ open, onOpenChange, attachment }: IRenameAttachmentItemProps) {
  const renameLinkAttachmentMutation = useRenameLinkAttachmentMutation();
  const renameFileAttachmentMutation = useRenameFileAttachmentMutation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IRenameForm>({
    mode: 'onChange',
    defaultValues: {
      name: attachment.name ?? '',
    },
  });

  const handleSuccess = () => {
    onOpenChange(false);
  };

  const submit = (data: IRenameForm) => {
    if (attachment.type === ConversationAttachmentType.File) {
      renameFileAttachmentMutation.mutate(
        { ...attachment, ...data },
        {
          onSuccess: handleSuccess,
        },
      );
    } else if (attachment.type === ConversationAttachmentType.Link) {
      renameLinkAttachmentMutation.mutate(
        { ...attachment, ...data },
        {
          onSuccess: handleSuccess,
        },
      );
    }
  };

  return (
    <ModalLegacy open={open} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit(submit)} className="text-left space-y-8">
        <div>
          <TextFieldInput
            labelText="Name"
            name="name"
            type="text"
            register={register}
            errors={errors}
            errorMessage="Name is required and cannot be exceed 255 characters"
            validationRules={{ required: true, maxLength: 255 }}
          />
        </div>
        <ButtonGroup>
          <Button color={ButtonColors.White} text="Cancel" onClick={() => onOpenChange(false)} />
          <SubmitButton text="Rename" isDisabled={!isValid} />
        </ButtonGroup>
      </form>
    </ModalLegacy>
  );
}

export function AttachmentActionMenu({ attachment }: IProps) {
  const [renameOpen, setRenameOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const deleteLinkAttachmentMutation = useDeleteLinkAttachmentMutation();
  const deleteFileAttachmentMutation = useDeleteFileAttachmentMutation();

  const handleDownload = () => {
    getFileLease(attachment.conversationId, attachment.id).then(({ url }) => {
      downloadUrlByLink(url, attachment.name);
    });
  };

  const handleDelete = () => {
    if (attachment.type === ConversationAttachmentType.File) {
      deleteFileAttachmentMutation.mutate(attachment);
    } else if (attachment.type === ConversationAttachmentType.Link) {
      deleteLinkAttachmentMutation.mutate(attachment);
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisHorizontalIcon className="w-5 h-5" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" hidden={renameOpen || deleteOpen}>
          {attachment.type === ConversationAttachmentType.File && (
            <DropdownMenuItem onClick={handleDownload}>Download</DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={() => setRenameOpen(true)}>Rename</DropdownMenuItem>
          <DropdownMenuItem onClick={() => setDeleteOpen(true)}>Delete</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmDialog
        open={deleteOpen}
        onOpenChange={setDeleteOpen}
        title="Delete Attachment"
        description={`Are you sure that you want to delete the ${
          attachment.type === ConversationAttachmentType.File ? 'file' : 'link'
        } ${attachment.name}?`}
        actionText="Delete"
        onAction={handleDelete}
      />
      <RenameAttachmentModal
        open={renameOpen}
        onOpenChange={setRenameOpen}
        attachment={attachment}
      />
    </>
  );
}
