import { createContext, useState, useContext, ReactNode, Dispatch, SetStateAction } from 'react';

interface INetworkStore {
  isInviteMemberModalOpen: boolean;
  setInviteMemberModalOpen: Dispatch<SetStateAction<boolean>>;
  isConfirmDeleteNetworkOpen: boolean;
  setIsConfirmDeleteNetworkOpen: Dispatch<SetStateAction<boolean>>;
  isEditNetworkModalOpen: boolean;
  setIsEditNetworkModalOpen: Dispatch<SetStateAction<boolean>>;
  isConfirmLeaveNetworkOpen: boolean;
  setIsConfirmLeaveNetworkOpen: Dispatch<SetStateAction<boolean>>;
}

const useNetworkStore = (): INetworkStore => {
  const [isInviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);
  const [isConfirmDeleteNetworkOpen, setIsConfirmDeleteNetworkOpen] = useState(false);
  const [isEditNetworkModalOpen, setIsEditNetworkModalOpen] = useState(false);
  const [isConfirmLeaveNetworkOpen, setIsConfirmLeaveNetworkOpen] = useState(false);

  return {
    isInviteMemberModalOpen,
    setInviteMemberModalOpen,
    isConfirmDeleteNetworkOpen,
    setIsConfirmDeleteNetworkOpen,
    isEditNetworkModalOpen,
    setIsEditNetworkModalOpen,
    isConfirmLeaveNetworkOpen,
    setIsConfirmLeaveNetworkOpen,
  };
};

export const NetworkStoreContext = createContext<INetworkStore | null>(null);

export function NetworkContextProvider({ children }: { children: ReactNode }) {
  return (
    <NetworkStoreContext.Provider value={useNetworkStore()}>
      {children}
    </NetworkStoreContext.Provider>
  );
}

export const useNetworkStoreContext = (): INetworkStore => {
  const store = useContext(NetworkStoreContext);

  if (!store) {
    throw new Error('useNetworkStoreContext must be used within a NetworkContextProvider');
  }

  return store;
};
