import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';
import usePipelineInfoMenuItems from './hooks/usePipelineInfoActionButtons';

interface IProps {
  pipelineId: string;
}
export default function PipelineInfoActionButtons({ pipelineId }: IProps) {
  const menuItems = usePipelineInfoMenuItems(pipelineId);
  return <TabDropdownItem isActive={false} menuItems={menuItems} />;
}
