import { useState } from 'react';
import { FaceSmileIcon } from '@heroicons/react/24/solid';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Popover, PopoverContent, PopoverTrigger } from '../popovers/Popover';

export type EmojiType = {
  native: string;
};

interface IEmojiPickerProps {
  onSelect: (emoji: EmojiType) => void;
}

export function EmojiPicker({ onSelect }: IEmojiPickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger>
        <FaceSmileIcon className="hidden md:block w-5 h-5" />
      </PopoverTrigger>
      <PopoverContent
        align="end"
        sideOffset={15}
        alignOffset={15}
        className="p-0"
      >
        <Picker
          data={data}
          theme="light"
          onEmojiSelect={(emoji: EmojiType) => {
            onSelect(emoji);
            setIsOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
}
