import { DisplayColumnDef } from '@tanstack/react-table';
import { ActivityItemType, IActivityItem } from '../../shared/components/activity/IActivityItem';
import { activityItemTypeToVisitValueLookup, IVisitResource } from './models/IVisitResource';
import ActivityHeaderCell from '../../shared/components/table/cells/ActivityHeaderCell';
import ActivityRowCell from '../../shared/components/table/cells/ActivityRowCell';

export interface VisitResourceFilterProps {
  item?: IVisitResource;
  filterValue: ActivityItemType[];
}

export const activityFilter = ({ item, filterValue }: VisitResourceFilterProps) => {
  if (filterValue.length === 0) {
    return true;
  }
  const match = filterValue.some((value) => {
    const valueAccessor = activityItemTypeToVisitValueLookup[value];
    const visitValue = valueAccessor(item);
    return visitValue > 0;
  });
  return match;
};

export interface ActivityColumnAccessorProps<T> {
  id: string;
  activityItems: IActivityItem[];
  myUserId: string;
}

// Not solving the typescript typesystem to allow me to use columnhelper.accessor.
// Instead focus on the column definition
export function createActivityColumnDefinition<T>({
  id,
  activityItems,
  myUserId,
}: ActivityColumnAccessorProps<T>) {
  return {
    id,
    meta: {
      size: '236px',
    },
    minSize: 236,
    header: ({ column }) => <ActivityHeaderCell items={activityItems} column={column} />,
    filterFn: ({ getValue }, columnId, filterValue: ActivityItemType[]) => {
      const value: IVisitResource[] = getValue(columnId) || [];
      const item = value.find((visit) => visit.userId === myUserId);
      return activityFilter({ item, filterValue });
    },
    cell: ({ getValue }) => {
      const myVisit = getValue().find((visit) => visit.userId === myUserId);
      const items = activityItems.map((item) => ({
        type: item.type,
        value: activityItemTypeToVisitValueLookup[item.type](myVisit),
      })) as IActivityItem[];
      return <ActivityRowCell items={items} />;
    },
  } as DisplayColumnDef<T, IVisitResource[]>;
}
